import React, { Component } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import './AvatarSurvey.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ChevronDown } from 'react-feather'

import QuestionSelector from './QuestionSelector/QuestionSelector'
import Survey from './Survey/Survey'
import AddQuestionModal from './Modals/AddQuestionModal'
import SubmitEvent from './SubmitEvent/SubmitEvent'
import AddEventModal from './Modals/AddEventModal'
import SurveySettingsModal from './Modals/SurveySettingsModal'
import questionTypes from './Survey/data/avatarQuestionTypes'
import Loading from '../../../templates/UI/Loading/Loading'
import callAPI from '../../../helpers/callAPI'

class AvatarSurvey extends Component {
    state = {
        dataLoaded: false,
        submitting: false,
        step: 1,
        stepList: [],
        maxStep: 0,
        draggedQuestionId: 0,
        dragging: false,
        selectedQuestionOrderNumber: 1,
        surveyDescription: 'New Survey',
        addQuestionModalOpen: false,
        addEventModalOpen: false,
        surveySettingsModalOpen: false,
        survey: [],
        events: [],
        active_on_properties: [],
        submitButton: 'Submit',
        submitDisclaimer: '',
        fraudCheck: true,
        reportDropdownOpen: false,
        pushnami: {
            enabled: false,
            id: '',
            step: '',
        },
        backButton: {
            enabled: false,
            url: '',
        },
    }

    componentDidMount() {
        const fetchData = async () => {
            let surveyId = !isNaN(this.props.surveyId) ? this.props.surveyId : 0

            if (surveyId > 0) {
                // If > 0 , it's editing an existing site. Use the PUT url
                // Fetch the info for this site and set the defaults!

                const result = await callAPI(`avatar/survey/${surveyId}`, 'GET')
                if (result['success'] === true) {
                    this.setState({
                        ...this.state,
                        dataLoaded: true,
                        maxStep: result.maxStep,
                        surveyDescription: result.surveyDescription,
                        survey: result.survey,
                        events: result.events,
                        active_on_properties: result.activeOnProperties,
                        submitButton: result.submitButton,
                        submitDisclaimer: result.submitDisclaimer,
                        fraudCheck: result.fraudCheck,
                        pushnami: result.pushnami,
                        backButton: result.backButton,
                    })
                    document.title = `Avatar Survey: ${result.surveyDescription}`
                } else if (result['success'] === false) {
                    this.props.showSystemMessage('error', result.errors.message)
                    document.title = `Avatar Survey`
                } else {
                    this.props.showSystemMessage('error', 'Error fetching survey data.')
                    document.title = `Avatar Survey`
                }
            } else {
                // If 0, it's a new site. Use the POST url
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    action: 'create',
                })
            }
        }
        fetchData()
    }

    handleSurveyDescription = (event) => {
        this.setState({
            ...this.state,
            surveyDescription: event.target.value,
        })
    }

    handleChange = (questionId) => (event) => {
        //Find the array location of the question at hand
        //let arrayIndex = this.state.survey.findIndex(x => x.questionId === questionId);
        //newSurvey[arrayIndex].question = event.target.value;

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)
        question.question = event.target.value

        //Update the question
        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangePlaceholder = (questionId) => (event) => {
        //Find the array location of the question at hand
        //let arrayIndex = this.state.survey.findIndex(x => x.questionId === questionId);
        //newSurvey[arrayIndex].question = event.target.value;

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)
        question.placeholder = event.target.value

        //Update the question
        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeDisclaimer = (questionId) => (event) => {
        //Find the array location of the question at hand
        //let arrayIndex = this.state.survey.findIndex(x => x.questionId === questionId);
        //newSurvey[arrayIndex].question = event.target.value;

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)
        question.disclaimer = event.target.value

        //Update the question
        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeCallToAction = (questionId) => (event) => {
        //Find the array location of the question at hand
        //let arrayIndex = this.state.survey.findIndex(x => x.questionId === questionId);
        //newSurvey[arrayIndex].question = event.target.value;

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)
        question.callToAction = event.target.value

        //Update the question
        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeNegCallToAction = (questionId) => (event) => {
        //Find the array location of the question at hand
        //let arrayIndex = this.state.survey.findIndex(x => x.questionId === questionId);
        //newSurvey[arrayIndex].question = event.target.value;

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)
        question.negCallToAction = event.target.value

        //Update the question
        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeCharLimit = (questionId) => (event) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)
        question.charLimit = event.target.value

        //Update the question
        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleAddMultipleChoice = (questionId) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        // Get the highest optionId
        let tempQuestionId = 0
        if (question.options) {
            tempQuestionId =
                question.options.length > 0
                    ? Math.max.apply(
                        Math,
                        question.options.map((question) => {
                            return question.optionId ? question.optionId : 0
                        })
                    )
                    : 0
        } else {
            // Initialize the options array
            question.options = []
        }

        question.options.push({
            optionId: tempQuestionId + 1,
            dbOptionId: 0,
            value: '',
        })

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleRemoveMultipleChoice = (questionId, optionId) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        let optionIndex = question.options.findIndex((option) => option.optionId === optionId)

        question.options.splice(optionIndex, 1)

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeMultipleChoice = (questionId, optionId) => (event) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        let option = question.options.find((option) => option.optionId === optionId)

        option.value = event.target.value

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeRedirectList = (questionId, optionId, type) => (event) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        let option = question.options.find((option) => option.optionId === optionId)

        if (type === 'description') option.description = event.target.value
        if (type === 'url') option.url = event.target.value
        if (type === 'value') option.value = event.target.value
        if (type === 'image') option.image = event.target.files[0] ? event.target.files[0] : option.image

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleRemoveImage = (questionId, optionId) => (e) => {

        console.log('coming at removeImage')

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        let option = question.options.find((option) => option.optionId === optionId)

        option.image = ''

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleCustomEventMap = (questionId) => (event) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        question.customMapping = event.target.checked ? 'Y' : 'N'

        // console.log('mapping', event.target.checked, question.customMapping)

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleDrag = (e) => {
        let draggedQuestionId = e.currentTarget.id
        let draggedOrder = e.currentTarget.order
        this.setState({
            ...this.state,
            dragging: true,
            selectedQuestionOrderNumber: parseInt(draggedOrder),
            draggedQuestionId: parseInt(draggedQuestionId),
        })
    }

    handleDrop = (e) => {
        const dragQuestion = this.state.survey.find((question) => question.questionId === this.state.draggedQuestionId)
        const dropQuestion = this.state.survey.find((question) => question.questionId === parseInt(e.currentTarget.id))

        const dragQuestionOrder = dragQuestion.order
        const dropQuestionOrder = dropQuestion.order

        // We have to see where we can drop stuff, based on the condition logic.
        // 1. Minimum drop order based on the DRAGGED QUESTION. Get the highest order number of the conditions of the draggedQuestion. The drop location CANNOT be lower or equal to that number.
        // Check if the dragQuestion has any conditions. if it does, only order numbers lower than the dropquestion order is allowed.

        let minimumDropOrder = 0
        let maximumDropOrder = this.state.survey.length + 1

        if (dragQuestion.conditions.length > 0) {
            let arrayOfConditionQuestionIds = []
            let arrayOfConditionQuestionOrderNumbers = []

            dragQuestion.conditions.map((condition) => {
                return condition.map((andCondition) => {
                    return arrayOfConditionQuestionIds.push(andCondition.questionId)
                })
            })

            // Get the order number for each of the condition questions
            arrayOfConditionQuestionIds.forEach((conditionQuestionId) => {
                let conditionQuestionObject = this.state.survey.find((question) => question.questionId === conditionQuestionId)
                let conditionQuestionOrder = conditionQuestionObject.order
                arrayOfConditionQuestionOrderNumbers.push(conditionQuestionOrder)
            })

            minimumDropOrder = Math.max(...arrayOfConditionQuestionOrderNumbers)
        }

        // 2. Maximum drop order based on the DROPPED QUESTION. If any question order below the droppedQuestion order has a condition EQUAL to the condition of the drag condition.

        // Have to get all the questions, who have a condition who's order number is the DRAG question.
        let questionsBasedOnDrag = this.state.survey.filter((question) => {
            let merged = [].concat.apply([], question.conditions)
            if (merged.find((condition) => condition.questionId === dragQuestion.questionId)) {
                return true
            } else {
                return false
            }
        })

        if (questionsBasedOnDrag.length > 0) {
            // Have to get the order number of the MINIMUM question from that list
            let arrayOfOrderNumbers = []

            questionsBasedOnDrag.forEach((question) => {
                arrayOfOrderNumbers.push(question.order)
            })

            // That is the MAXIMUM drop location
            maximumDropOrder = Math.min(...arrayOfOrderNumbers)
        }

        //console.log('min', minimumDropOrder)
        //console.log('max', maximumDropOrder)

        if (dropQuestionOrder > minimumDropOrder && dropQuestionOrder < maximumDropOrder) {
            let newQuestionState = this.state.survey.map((question) => {
                if (question.questionId === this.state.draggedQuestionId) {
                    //console.log(`A. Question ${question.questionId} is order ${question.order}, switching to order ${dropQuestionOrder}`)
                    question.order = dropQuestionOrder
                }

                if (dragQuestionOrder < dropQuestionOrder && question.questionId !== this.state.draggedQuestionId) {
                    // If we're moving a lower ordered question up
                    if (question.order > dragQuestionOrder && question.order <= dropQuestionOrder) {
                        //console.log(`B. Question ${question.questionId} is order ${question.order}, switching to order ${question.order - 1}`)
                        question.order = question.order - 1
                    }
                } else if (dragQuestionOrder > dropQuestionOrder && question.questionId !== this.state.draggedQuestionId) {
                    // If we're moving a higher ordered question down
                    if (question.order >= dropQuestionOrder && question.order < dragQuestionOrder) {
                        //console.log(`B. Question ${question.questionId} is order ${question.order}, switching to order ${question.order + 1}`)
                        question.order = question.order + 1
                    }
                }

                return question
            })

            // Order by order number
            newQuestionState = _.orderBy(newQuestionState, ['order'])

            // Also set the current step to the one being dragged (the new order os the DROP order though)
            this.setState({
                ...this.state,
                dragging: false,
                step: dropQuestionOrder,
                selectedQuestionOrderNumber: dropQuestionOrder,
                survey: newQuestionState,
            })
        } else {
            // Reset the dragging states, but don't update the survey
            this.setState({
                ...this.state,
                dragging: false,
                step: dragQuestionOrder,
                selectedQuestionOrderNumber: dragQuestionOrder,
            })
        }
    }

    handleSelectQuestion = (questionOrderNumber) => {
        this.setState({
            ...this.state,
            step: questionOrderNumber,
            selectedQuestionOrderNumber: questionOrderNumber,
        })
    }

    getDraggedQuestionOrder = () => {
        let dragQuestion
        let draggedQuestionOrder = 0

        if (this.state.draggedQuestionId > 0) {
            dragQuestion = this.state.survey.find((question) => question.questionId === this.state.draggedQuestionId)
            if (dragQuestion) {
                draggedQuestionOrder = dragQuestion.order
            }
        }

        return draggedQuestionOrder
    }

    toggleAddQuestionModal = () => {
        this.setState({
            ...this.state,
            addQuestionModalOpen: !this.state.addQuestionModalOpen,
        })
    }

    handleAddQuestion = (question, type) => {
        // Get the max questionId
        let startQuestion =
            this.state.survey.length > 0
                ? Math.max.apply(
                    Math,
                    this.state.survey.map((question) => {
                        return question.questionId
                    })
                )
                : 0

        // Get options if any
        let options = questionTypes.find((questionType) => questionType.type === type).options !== undefined ? questionTypes.find((questionType) => questionType.type === type).options : []

        let newQuestion = {
            questionId: startQuestion + 1, //This is just a temporary ID for handling this ID until publication.
            dbQuestionId: 0, // This means it's a new question
            order: this.state.survey.length + 1,
            type: type, // text short
            placeholder: '',
            question: question,
            options: options,
            conditions: [],
            parameters: [],
            customMapping: 'N',
        }

        let survey = _.cloneDeep(this.state.survey)

        survey.push(newQuestion)

        // Close the modal
        this.setState({
            ...this.state,
            survey: survey,
            addQuestionModalOpen: false,
        })
    }

    handleAddLogic = (questionId) => {
        // Add a condition for the first question to the current question
        const survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => {
            return question.questionId === questionId
        })

        // Push a new condition, with conditionId = 0 and the rest for question 1

        let conditionableTypes = questionTypes
            .filter((questionType) => {
                return questionType.conditionable === true
            })
            .map((conditionableType) => {
                return conditionableType.type
            })

        // Get the questionId for the first conditionable question
        let firstQuestion = survey.find((question) => {
            return conditionableTypes.includes(question.type)
        })

        // Get the values
        let value = ''

        if (firstQuestion.options) {
            if (firstQuestion.options[0]) {
                value = firstQuestion.options[0].value
            }
        }

        let operator = ''
        if (firstQuestion.type === 'TI') {
            // Only applies to text int
            operator = 'EQ'
        }

        question.conditions.push([
            {
                conditionId: 0,
                questionId: firstQuestion.questionId,
                value: value,
                operator: operator,
            },
        ])

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleAddInnerLogic = (questionId, outerIndex) => {
        // Add a condition for the first question to the current question
        const survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => {
            return question.questionId === questionId
        })

        // Push a new condition, with conditionId = 0 and the rest for question 1

        // Get the questionId for the order 1 question
        let conditionableTypes = questionTypes
            .filter((questionType) => {
                return questionType.conditionable === true
            })
            .map((conditionableType) => {
                return conditionableType.type
            })

        // Get the questionId for the first conditionable question
        let firstQuestion = survey.find((question) => {
            return conditionableTypes.includes(question.type)
        })

        // Get the values
        let value = ''
        if (firstQuestion.options) {
            if (firstQuestion.options[0]) {
                value = firstQuestion.options[0].value
            }
        }

        let operator = ''
        if (firstQuestion.type === 'TI') {
            // Only applies to text int
            operator = 'EQ'
        }

        question.conditions[outerIndex].push({
            conditionId: 0,
            questionId: firstQuestion.questionId,
            value: [value],
            operator: operator,
        })

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeLogicQuestion = (event, questionId, outerIndex, innerIndex) => {
        let conditionQuestionId = parseInt(event.target.value)

        // Add a condition for the first question to the current question
        const survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => {
            return question.questionId === questionId
        })

        // Get the values for the condition question

        let conditionQuestion = survey.find((question) => {
            return question.questionId === conditionQuestionId
        })

        let value = ''
        if (conditionQuestion.options && conditionQuestion.options.length > 0) {
            value = conditionQuestion.options[0].value
        }

        let operator = ''
        if (conditionQuestion.type === 'TI') {
            // Only applies to text int
            operator = 'EQ'
        }

        question.conditions[outerIndex][innerIndex] = {
            conditionId: question.conditions[outerIndex][innerIndex].conditionId,
            questionId: conditionQuestionId,
            value: value,
            operator: operator,
        }

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeLogicAnswer = (event, questionId, key, outerConditionIndex, innerConditionIndex) => {
        let choice = event.target.value

        // Add a condition for the first question to the current question
        const survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => {
            return question.questionId === questionId
        })

        /*             
            [ [{} AND {}] OR [{}] ] 

            question.conditions [ outerIndex0 [ innerIndex0{}, innerIndex1{} ], outerIndex1[ innerIndex0{}] ]            
        
        */

        // Update the value for this particular condition
        // The key could be 'value', 'operator', etc. Anything key within the condition object

        question.conditions[outerConditionIndex][innerConditionIndex][key] = choice

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleRemoveLogic = (questionId, outerConditionIndex, innerConditionIndex) => {
        // Find the condition to remove
        const survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => {
            return question.questionId === questionId
        })

        question.conditions[outerConditionIndex].splice(innerConditionIndex, 1)

        // If the outerCondition is empty, splice that the whole thing
        if (question.conditions[outerConditionIndex].length === 0) {
            question.conditions.splice(outerConditionIndex, 1)
        }

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleRemoveQuestion = (questionId) => {
        const survey = _.cloneDeep(this.state.survey)

        let deleteQuestionIndex = survey.findIndex((question) => question.questionId === questionId)

        // We need to change the order of all questions after it to -1
        survey.forEach((question, index) => {
            if (index > deleteQuestionIndex) {
                question.order = question.order - 1
            }
        })

        // Now we can remove it
        survey.splice(deleteQuestionIndex, 1)

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    toggleAddEventModal = (id) => {
        this.setState({
            ...this.state,
            addEventModalOpen: id,
        })
    }

    handleEditEvent = (eventId, dbEventId, eventName, mode, action, url, postType, postResponseType, postJsonKey, postJsonValue, postTextValue, postSaleKey, postDataKey, eventCompleteMsg, listArrayKey, listTitleKey, listParagraphKey, listUrlKey, listImageKey, listButtonText, conditions, questionMap, fraudOverrides, rateName, payout, trackingType, trafficType, merchantId, override, prepop, editing) => {
        let theEventId = parseInt(eventId)
        let events = _.cloneDeep(this.state.events)

        if (editing) {
            // This is an edit, get the index of this event
            let index = events.findIndex((event) => {
                return event.eventId === theEventId
            })

            events[index] = {
                eventId: theEventId,
                dbEventId: parseInt(dbEventId),
                eventName: eventName,
                mode: parseInt(mode),
                action: action,
                url: url,
                postType: postType,
                postResponseType: postResponseType,
                postJsonKey: postJsonKey,
                postJsonValue: postJsonValue,
                postTextValue: postTextValue,
                postSaleKey: postSaleKey,
                postDataKey: postDataKey,
                eventCompleteMsg: eventCompleteMsg,
                listArrayKey: listArrayKey,
                listTitleKey: listTitleKey,
                listParagraphKey: listParagraphKey,
                listUrlKey: listUrlKey,
                listImageKey: listImageKey,
                listButtonText: listButtonText,
                conditions: conditions,
                questionMap: questionMap,
                fraudOverrides: fraudOverrides,
                rateName: rateName,
                payout: payout,
                trackingType: trackingType,
                trafficType: trafficType,
                merchantId: merchantId,
                prepop: prepop,
            }
        } else {
            // Adding a new event

            let eventObject = {
                eventId: theEventId,
                dbEventId: 0,
                eventName: eventName,
                mode: parseInt(mode),
                action: action,
                url: url,
                postType: postType,
                postResponseType: postResponseType,
                postJsonKey: postJsonKey,
                postJsonValue: postJsonValue,
                postTextValue: postTextValue,
                postSaleKey: postSaleKey,
                postDataKey: postDataKey,
                eventCompleteMsg: eventCompleteMsg,
                listArrayKey: listArrayKey,
                listTitleKey: listTitleKey,
                listParagraphKey: listParagraphKey,
                listUrlKey: listUrlKey,
                listImageKey: listImageKey,
                listButtonText: listButtonText,
                conditions: conditions,
                questionMap: questionMap,
                fraudOverrides: fraudOverrides,
                rateName: rateName,
                payout: payout,
                trackingType: trackingType,
                trafficType: trafficType,
                merchantId: merchantId,
                prepop: prepop,
            }

            events.push(eventObject)
        }

        this.setState({
            ...this.state,
            addEventModalOpen: false,
            events: events,
        })
    }

    handleDeleteEvent = (eventId) => {
        let events = _.cloneDeep(this.state.events)

        let eventIndex = events.findIndex((event) => event.eventId === eventId)

        events.splice(eventIndex, 1)

        this.setState({
            ...this.state,
            events: events,
        })
    }

    handlePublishSurvey = () => {
        const fetchData = async () => {
            let method = !isNaN(this.props.surveyId) ? 'PUT' : 'POST'
            let fetchUrl = !isNaN(this.props.surveyId) ? `avatar/survey/${this.props.surveyId}` : `avatar/survey`

            let formData = {
                description: this.state.surveyDescription,
                submitButton: this.state.submitButton,
                submitDisclaimer: this.state.submitDisclaimer,
                fraudCheck: this.state.fraudCheck,
                pushnami: this.state.pushnami,
                backButton: this.state.backButton,
                survey: this.state.survey,
                events: this.state.events,
            }

            this.setState({
                ...this.state,
                submitting: true,
            })

            const result = await callAPI(fetchUrl, method, formData)
            if (result['success'] === true) {
                // Redirect to the editing page
                if (method === 'POST') {
                    this.props.showSystemMessage('success', 'Survey has been created.')
                    this.props.history.push(`/avatar/survey/${result.surveyId}`)
                } else {
                    this.props.showSystemMessage('success', 'Survey has been updated.')
                    this.setState({
                        ...this.state,
                        submitting: false,
                        maxStep: result.maxStep,
                        surveyDescription: result.surveyDescription,
                        survey: result.survey,
                        events: result.events,
                        activeOnProperties: result.activeOnProperties,
                    })
                }
                return true
            } else {
                this.setState({
                    ...this.state,
                    submitting: false,
                })
                this.props.showSystemMessage('error', result.errors.message)
                return false
            }
        }
        fetchData()

        this.state.survey.map(survey => {
            if (survey.type === 'RDL') {

                survey.options.map(async option => {

                    let formData = new FormData()

                    console.log('image.option: ', option.image)
                    formData.append('image', option.image)

                    return Promise.all(callAPI(`avatar/survey/${this.props.surveyId}/${survey.questionId}/${option.optionId}/uploadImage`, 'POST', formData, true)).then(response => response).catch(error => error)
                })
            }
        })

        // if (redirectListExists) {
        //     // make the call
        //     const result = callAPI(`avatar/survey/${this.props.surveyId}/uploadImage`, 'POST', tempOptions, true)

        //     if (result['success'] === true) {
        //         console.log('Success')
        //     } else {
        //         console.log('False')
        //     }
        // }
    }

    getActiveProperties = () => {
        return (
            <div className="alert alert-primary">
                <strong className="text-small">This survey is being used on the following properties:</strong>
                {this.state.active_on_properties.map((property) => {
                    return (
                        <div className="badge badge-light text-left border ml-2">
                            <Link to={`/avatar/property/${property.property_id}`}>Property {property.property_id}</Link> {property.domain}
                        </div>
                    )
                })}
            </div>
        )
    }

    handleAddParam = (questionId) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        question.parameters.push({ parameterId: 0, name: '' })

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleChangeParam = (e, questionId, index) => {
        const parameterId = e.target.value
        const parameterName = e.target.options[e.target.selectedIndex].text

        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        question.parameters.splice(index, 1, { parameterId: parameterId, name: parameterName })

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    handleRemoveParam = (questionId, parameterId) => {
        let survey = _.cloneDeep(this.state.survey)

        let question = survey.find((question) => question.questionId === questionId)

        let paramIndex = question.parameters.findIndex((param) => param.parameterId === parameterId)

        question.parameters.splice(paramIndex, 1)

        this.setState({
            ...this.state,
            survey: survey,
        })
    }

    toggleSettingsModal = () => {
        this.setState({
            ...this.state,
            surveySettingsModalOpen: !this.state.surveySettingsModalOpen,
        })
    }

    handleSurveySettings = (submitButton, submitDisclaimer, fraudCheck, pushnami, backButton) => {
        this.setState({
            ...this.state,
            surveySettingsModalOpen: false,
            submitButton: submitButton,
            submitDisclaimer: submitDisclaimer,
            fraudCheck: fraudCheck,
            pushnami: pushnami,
            backButton: backButton,
        })
    }

    reportToggle = () => {
        this.setState({
            ...this.state,
            reportDropdownOpen: !this.state.reportDropdownOpen,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Avatar Survey Builder</h1>
                </div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.active_on_properties.length > 0 && this.getActiveProperties()}
                        <div className="mb-2">
                            <div className="card">
                                <div className="p-3 d-flex justify-content-between align-items-center border-faint">
                                    <div className="d-flex align-items-center">
                                        <div className="mb-h1-dark">Description:</div>
                                        <div>
                                            <input type="text" className="form-control ml-2" value={this.state.surveyDescription} onChange={this.handleSurveyDescription} />
                                        </div>
                                        <button className="btn btn-secondary ml-3" onClick={this.toggleSettingsModal}>
                                            <FontAwesomeIcon icon="cog" /> Settings
                                        </button>
                                        <Dropdown isOpen={this.state.reportDropdownOpen} toggle={this.reportToggle} direction="down">
                                            <DropdownToggle tag="button" onClick={this.toggle} data-toggle="dropdown" aria-expanded={this.state.reportDropdownOpen} className="btn btn-secondary ml-2">
                                                Reports <ChevronDown size={14} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <Link className="dropdown-item" to={`/avatar/reports/survey_questions?surveyId=${this.props.surveyId}`} onClick={this.toggle}>
                                                    Dropoff Report
                                                </Link>
                                                <DropdownItem divider className="m-0" />
                                                <Link className="dropdown-item" to={`/avatar/reports/survey_responses?surveyId=${this.props.surveyId}`} onClick={this.toggle}>
                                                    Response Report
                                                </Link>
                                                <DropdownItem divider className="m-0" />
                                                <Link className="dropdown-item" to={`/avatar/reports/affiliateSummary?surveyId=${this.props.surveyId}`} onClick={this.toggle}>
                                                    Affiliate Summary Report
                                                </Link>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <SurveySettingsModal open={this.state.surveySettingsModalOpen} toggleModal={this.toggleSettingsModal} submitButton={this.state.submitButton} submitDisclaimer={this.state.submitDisclaimer} fraudCheck={this.state.fraudCheck} pushnami={this.state.pushnami} backButton={this.state.backButton} handleSurveySettings={(submitButton, submitDisclaimer, fraudCheck, pushnami, backButton) => this.handleSurveySettings(submitButton, submitDisclaimer, fraudCheck, pushnami, backButton)} />
                                    </div>
                                    {!this.state.submitting ? (
                                        <button className="btn btn-primary" onClick={this.handlePublishSurvey}>
                                            <FontAwesomeIcon icon="paper-plane" /> {!isNaN(this.props.surveyId) ? 'Publish Survey' : 'Create Survey'}
                                        </button>
                                    ) : (
                                        <button className="btn btn-secondary" disabled>
                                            <FontAwesomeIcon icon="spinner" spin />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h1 className="mb-h1-dark">Questions</h1>
                                        <button className="btn btn-warning btn-sm" onClick={this.toggleAddQuestionModal}>
                                            + New
                                        </button>
                                        <AddQuestionModal open={this.state.addQuestionModalOpen} closeModal={this.toggleAddQuestionModal} handleAddQuestion={this.handleAddQuestion} />
                                    </div>
                                    <div className="card-body avatar-survey-side-panel">
                                        {this.state.survey.map((survey) => {
                                            return <QuestionSelector key={survey.questionId} questionId={survey.questionId} order={survey.order} type={survey.type} survey={this.state.survey} events={this.state.events} question={survey.question} conditions={survey.conditions} parameters={survey.parameters} handleDrag={this.handleDrag} handleDrop={this.handleDrop} handleSelectQuestion={this.handleSelectQuestion} draggedQuestionOrderNumber={this.getDraggedQuestionOrder()} selectedQuestionOrderNumber={this.state.selectedQuestionOrderNumber} dragging={this.state.dragging} handleAddLogic={this.handleAddLogic} handleAddInnerLogic={this.handleAddInnerLogic} handleChangeLogicQuestion={this.handleChangeLogicQuestion} handleRemoveLogic={this.handleRemoveLogic} handleChangeLogicAnswer={this.handleChangeLogicAnswer} handleRemoveQuestion={this.handleRemoveQuestion} handleAddParam={this.handleAddParam} handleChangeParam={this.handleChangeParam} handleRemoveParam={this.handleRemoveParam} showSystemMessage={this.props.showSystemMessage} />
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="rounded p-2">
                                    <Survey key={this.state.step} survey={this.state.survey} maxStep={this.state.survey.length} step={this.state.step} handleChange={this.handleChange} handleAddMultipleChoice={this.handleAddMultipleChoice} handleRemoveMultipleChoice={this.handleRemoveMultipleChoice} handleChangeMultipleChoice={this.handleChangeMultipleChoice} handleChangePlaceholder={this.handleChangePlaceholder} handleChangeDisclaimer={this.handleChangeDisclaimer} handleChangeCallToAction={this.handleChangeCallToAction} handleChangeNegCallToAction={this.handleChangeNegCallToAction} handleChangeCharLimit={this.handleChangeCharLimit} handleCustomEventMap={this.handleCustomEventMap} handleChangeRedirectList={this.handleChangeRedirectList} handleRemoveImage={this.handleRemoveImage} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h1 className="mb-h1-dark">Events</h1>
                                        <div>
                                            <Link to={`/avatar/survey/${this.props.surveyId}/eventOverrides`} className="btn btn-secondary btn-sm mr-2">
                                                Overrides
                                            </Link>
                                            <button className="btn btn-warning btn-sm" onClick={() => this.toggleAddEventModal(0)}>
                                                + New
                                            </button>
                                        </div>
                                        <AddEventModal open={this.state.addEventModalOpen === 0} closeModal={() => this.toggleAddEventModal(false)} editEvent={this.handleEditEvent} conditions={[]} questionMap={[]} fraudOverrides={[]} survey={this.state.survey} events={this.state.events} />
                                    </div>
                                    <div className="card-body avatar-survey-side-panel">
                                        {this.state.events.map((event) => {
                                            return <SubmitEvent key={event.eventId} eventId={event.eventId} dbEventId={event.dbEventId} events={this.state.events} action={event.action} conditions={event.conditions} questionMap={event.questionMap} fraudOverrides={event.fraudOverrides} rateName={event.rateName} payout={event.payout} trackingType={event.trackingType} trafficType={event.trafficType} merchantId={event.merchantId} url={event.url} postType={event.postType} postResponseType={event.postResponseType} postJsonKey={event.postJsonKey} postJsonValue={event.postJsonValue} postTextValue={event.postTextValue} postSaleKey={event.postSaleKey} postDataKey={event.postDataKey} eventCompleteMsg={event.eventCompleteMsg} listArrayKey={event.listArrayKey} listTitleKey={event.listTitleKey} listParagraphKey={event.listParagraphKey} listUrlKey={event.listUrlKey} listImageKey={event.listImageKey} listButtonText={event.listButtonText} eventName={event.eventName} mode={event.mode} addEventModalOpen={this.state.addEventModalOpen} toggleAddEventModal={this.toggleAddEventModal} editEvent={this.handleEditEvent} deleteEvent={this.handleDeleteEvent} survey={this.state.survey} prepop={event.prepop} />
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default AvatarSurvey

// this.setState({
//     ...this.state,
//     maxStep: 7, // Initialize it as the length of the survey
//     draggedQuestionId: 0,
//     selectedQuestionOrderNumber: 1,
//     surveyDescription: 'Insurance Survey A',
//     survey:[
//         {
//             questionId: 1,
//             dbQuestionId: 45,
//             order: 1,
//             type: 'YN', // yes/no
//             question: 'Have you ever been in a car accident?',
//             conditions: [],
//             options: [
//                 {
//                     value: 'No'
//                 },
//                 {
//                     value: 'Yes'
//                 }
//             ],
//             response: []
//         },
//         {
//             questionId: 2,
//             dbQuestionId: 49,
//             order: 2,
//             type: 'TI', // text short
//             placeholder: 'Example: 1000',
//             question: 'What was the value of the damage?',
//             conditions: [ // This is the condition for which we will show this question. Only if questionId:1 was Y. Otherwise, skip.
//                 [
//                     {
//                         conditionId: 1,
//                         questionId: 1,
//                         value: 'No',
//                         operator: ''
//                     }
//                 ]
//             ],
//             response: []
//         },
//         {
//             questionId: 3,
//             dbQuestionId: 51,
//             order: 3,
//             type: 'MC',// multiple choice
//             question: 'What color is your car?',
//             conditions: [],
//             options: [ // Certain questions won't need options in the DB... YN is always yes/no. These values can be generated in React                ]
//                 {
//                     optionId:4,
//                     value: 'White'
//                 },
//                 {
//                     optionId:5,
//                     value: 'Black'
//                 },
//                 {
//                     optionId:6,
//                     value: 'Red'
//                 },
//                 {
//                     optionId:7,
//                     value: 'Silver'
//                 }
//             ],
//             response: []
//         },
//         {
//             questionId: 4,
//             dbQuestionId: 79,
//             order: 4,
//             type: 'YN', // Yes No
//             question: 'Do you think having a red/silver car made you more likely to crash?',
//             conditions: [ // An array of arrays. Each array seperate array is an OR condition, and within each array, are AND conditions. [[{} AND {}] OR [{}]]
//                     [ // Show if question 1 was Y AND question 3 was red or silver
//                         {
//                             conditionId:2,
//                             questionId:1,
//                             value: 'Yes',
//                             operator: ''
//                         },
//                         {
//                             conditionId:3,
//                             questionId:3,
//                             value: 'Red',
//                             operator: ''
//                         }
//                         /*  If a question can be gotten to by multiple different responses from the same question,
//                             we can have the same questionId in the conditions
//                             more than once. */
//                     ],
//                     // OR
//                     [
//                         {
//                             conditionId:2,
//                             questionId:1,
//                             value: 'Yes',
//                             operator: ''
//                         },
//                         {
//                             conditionId:3,
//                             questionId:3,
//                             value: 'Silver',
//                             operator: ''
//                         }
//                     ],
//                     // OR
//                     [
//                         {
//                             conditionId:4,
//                             questionId:2,
//                             value: 100,
//                             operator: 'GT'
//                         },
//                     ]
//                 ],
//             response: []
//         },
//         {
//             questionId: 5,
//             dbQuestionId: 94,
//             order: 5,
//             type: 'MCM',// multiple choice
//             question: 'What makes are you interest in?',
//             conditions: [],
//             options: [ // Certain questions won't need options in the DB... YN is always yes/no. These values can be generated in React                ]
//                 {
//                     optionId:4,
//                     value: 'Volkswagen'
//                 },
//                 {
//                     optionId:5,
//                     value: 'Audi'
//                 },
//                 {
//                     optionId:6,
//                     value: 'Mercedes-Benz'
//                 },
//                 {
//                     optionId:7,
//                     value: 'BMW'
//                 }
//             ],
//             response: []
//         },
//         {
//             questionId: 6,
//             dbQuestionId: 67,
//             order: 6,
//             type: 'TL', // text short
//             placeholder: 'Describe your accident.',
//             question: 'Please describe your accident in full detail.',
//             conditions: [
//                 [
//                     {
//                         conditionId: 4,
//                         questionId: 5,
//                         value: 'Audi', // This means, only if they select Audi AND BMW or Audi AND Mercedes-Benz
//                         operator: ''
//                     },
//                     {
//                         conditionId: 6,
//                         questionId: 5,
//                         value: 'BMW', // This means, only if they select Audi AND BMW or Audi AND Mercedes-Benz
//                         operator: ''
//                     }
//                 ],
//                 [
//                     {
//                         conditionId: 4,
//                         questionId: 7,
//                         value: 'Audi', // This means, only if they select Audi AND BMW or Audi AND Mercedes-Benz
//                         operator: ''
//                     },
//                     {
//                         conditionId: 4,
//                         questionId: 8,
//                         value: 'Mercedes-Benz', // This means, only if they select Audi AND BMW or Audi AND Mercedes-Benz
//                         operator: ''
//                     }
//                 ]
//             ],
//             response: []
//         },
//         {
//             questionId: 7,
//             dbQuestionId: 351,
//             order: 7,
//             type: 'CD', // text short
//             placeholder: '',
//             question: 'What country do you live in?',
//             conditions: [],
//             response: []
//         },
//         {
//             questionId: 8,
//             dbQuestionId: 419,
//             order: 8,
//             type: 'TS', // text short
//             placeholder: 'test@test.com',
//             question: 'What is your email?',
//             conditions: [
//                 [
//                     {
//                         conditionId: 9,
//                         questionId: 7,
//                         value: 'CA',
//                         operator: ''
//                     }
//                 ]
//             ],
//             response: []
//         },
//         {
//             questionId: 9,
//             dbQuestionId: 77,
//             order: 9,
//             type: 'SFN', // Short Form Name
//             placeholder: '',
//             question: 'Short Form - Name',
//             conditions: [],
//             response: []
//         },
//         {
//             questionId: 10,
//             dbQuestionId: 44,
//             order: 10,
//             type: 'SFNE', // Short Form Name Email
//             placeholder: '',
//             question: 'Short Form - Name/Email',
//             conditions: [],
//             response: []
//         },
//         {
//             questionId: 11,
//             dbQuestionId: 66,
//             order: 11,
//             type: 'SFA', // Short Form Name Email
//             placeholder: '',
//             question: 'Short Form - Address',
//             conditions: [],
//             response: []
//         }
//     ],
//     events: [
//         {
//             eventId: 1,
//             dbEventId: 1,
//             mode: 1,
//             action: 'POST',
//             url: 'https://ptsv2.com/t/wlbcf-1614872007/post',
//             eventCompleteMsg: 'Thank you for filling out this questionnaire!',
//             conditions: []
//         },
//         {
//             eventId: 2,
//             dbEventId: 2,
//             mode: 2,
//             action: 'POST',
//             url: 'https://ptsv2.com/t/wlbcf-1614872007/post',
//             eventCompleteMsg: 'Thank you for filling out this questionnaire!',
//             conditions: [
//                 [
//                     {
//                         conditionId: 1,
//                         questionId: 1,
//                         value: 'Yes',
//                         operator: ''
//                     },
//                     {
//                         conditionId: 2,
//                         questionId: 2,
//                         value: 100,
//                         operator: 'GT'
//                     }
//                 ],
//                 [
//                     {
//                         conditionId: 3,
//                         questionId: 1,
//                         value: 'No',
//                         operator: ''
//                     }
//                 ],
//                 [
//                     {
//                         conditionId: 4,
//                         questionId: 6,
//                         value: 'Lorem',
//                         operator: ''
//                     },
//                     {
//                         conditionId: 5,
//                         questionId: 8,
//                         value: 'merrickchun@gmail.com',
//                         operator: ''
//                     }
//                 ],
//                 [
//                     {
//                         conditionId:6,
//                         questionId:3,
//                         value: 'Red',
//                         operator: ''
//                     }
//                 ],
//                 [
//                     {
//                         conditionId: 7,
//                         questionId: 5,
//                         value: 'Audi',
//                         operator: ''
//                     },
//                     {
//                         conditionId: 8,
//                         questionId: 5,
//                         value: 'Mercedes-Benz',
//                         operator: ''
//                     }
//                 ],
//                 [
//                     {
//                         conditionId: 9,
//                         questionId: 7,
//                         value: 'CA',
//                         operator: ''
//                     }
//                 ]
//             ]
//         }
//     ]
// })
