import React from 'react'

import CallbackType from '../../../../templates/FormElements/CallbackType'

const SiteTrafficInfo = ({ affiliate, setIsDisabled }) => {
    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Site / Traffic Information</div>
            </div>

            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="text-right border-right">
                                Website URL
                            </td>
                            <td colSpan="3">
                                <input name="website_url" className="form-control" type="text" defaultValue={affiliate.registration.website} onChange={inputChangeHandler} required></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Experience Description</td>
                            <td colSpan="3">
                                <textarea name="experience_desc" className="form-control" type="text" defaultValue={affiliate.registration.experience_description} onChange={inputChangeHandler} maxLength="200"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Promotion Description</td>
                            <td colSpan="3">
                                <textarea name="promotion_desc" className="form-control" type="text" defaultValue={affiliate.registration.promotion_description} onChange={inputChangeHandler} required></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Heard From</td>
                            <td colSpan="3" className="border-right">
                                {affiliate.registration.heard_from.source} {affiliate.registration.heard_from.source === 'other' && '-'} {affiliate.registration.heard_from.details}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Run incentive sites?</td>
                            <td className="border-right">
                                <select name="incentive_flag" defaultValue={affiliate.traffic.incentive.uses_incentive ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler}>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                            <td className="text-right border-right">Incentive Description</td>
                            <td>
                                <input name="incentive_desc" className="form-control" type="text" defaultValue={affiliate.traffic.incentive.description} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Global Callback Type</td>
                            <td>
                                <select name="callback_type" defaultValue={affiliate.tracking.global_callback.type} className="form-control" onChange={inputChangeHandler} required>
                                    <CallbackType />
                                </select>
                            </td>
                            <td colSpan="2"></td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Global Callback Code</td>
                            <td colSpan="3">
                                <textarea name="callback_code" className="form-control" type="text" defaultValue={affiliate.tracking.global_callback.code} onChange={inputChangeHandler}></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Is this another network?</td>
                            <td>
                                <select name="network_flag" defaultValue={affiliate.traffic.network_affiliate ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                            <td colSpan="2"></td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Campaign Cancellation Callback URL</td>
                            <td className="border-right" colSpan="3">
                                <input name="cancelled_campaign_callback" className="form-control" type="text" defaultValue={affiliate.traffic.cancelled_campaign_callback} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Campaign Rate Change Callback URL</td>
                            <td className="border-right" colSpan="3">
                                <input name="campaign_ratechange" className="form-control" type="text" defaultValue={affiliate.traffic.campaign_ratechange_callback} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SiteTrafficInfo
