import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import Loading from '../../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import MarginSummary from '../MarginSummary/MarginSummary'
import AffiliateHealthSummary from '../AffiliateHealthSummary/AffiliateHealthSummary'
import OpenIssues from '../OpenIssues/OpenIssues'
import AffiliateTeam from '../AffiliateTeam/AffiliateTeam'
import AdvertiserTeam from '../AdvertiserTeam/AdvertiserTeam'
import BizdevTeam from '../BizdevTeam/BizdevTeam'
import ComplianceTeam from '../ComplianceTeam/ComplianceTeam'
import TopAffiliates from '../TopAffiliates/TopAffiliates'
import TopCampaignsOfTheWeek from '../TopCampaignsOfTheWeek/TopCampaignsOfTheWeek'
import queryString from 'query-string'
import CustomDashboardLinks from '../CustomDashboardLinks/CustomDashboardLinks'
import callAPI from '../../../../helpers/callAPI'
import { Summary } from '../../../System/mbStatus/Summary'

export default function Administrator({ manager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'cto_dashboard')

    const history = useHistory()    
    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    const [dataLoaded, setDataLoaded] = useState(true)
    const [option, setOption] = useState(urlParams.view ? urlParams.view : 'operations')

    const setView = (option) => {
        setOption(option)
        history.push(`/dashboard?view=${option}`)
    }
    
    useEffect(()=>{
        let urlParams = queryString.parse(location.search)
        setOption(urlParams.view ? urlParams.view : 'operations')
    },[location])

    let statLabelClasses = 'btn btn-secondary'

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1 d-flex align-items-center">
                            <div>Administrator Dashboard</div>
                            <div className="d-flex justify-content-center">
                                <div className="mx-2 btn-group btn-group-sm btn-group-toggle w-75" data-toggle="buttons" role="group">
                                    <label className={option === 'operations' ? statLabelClasses + ' active' : statLabelClasses}>
                                        <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="operations" onClick={(e) => setView(e.target.value)} /> Operations
                                    </label>
                                    <label className={option === 'teams' ? statLabelClasses + ' active' : statLabelClasses}>
                                        <input type="radio" name="statOptions" id="option2" className="change-graph-option" autoComplete="off" value="teams" onClick={(e) => setView(e.target.value)} /> Teams
                                    </label>
                                </div>
                            </div>
                        </h1>
                    </div>
                    {option === 'operations' ? (
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <MarginSummary manager={manager} showSystemMessage={showSystemMessage} />
                                        <TopAffiliates />
                                    </div>
                                    <div className="col-lg-6">
                                        <AffiliateHealthSummary manager={manager} showSystemMessage={showSystemMessage} />
                                        <TopCampaignsOfTheWeek showSystemMessage={showSystemMessage} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <Summary dashboard />
                                <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-lg-8">
                                <AffiliateTeam showSystemMessage={showSystemMessage} mode="all" />
                                <AdvertiserTeam showSystemMessage={showSystemMessage} mode="all" />
                                <BizdevTeam showSystemMessage={showSystemMessage} mode="all" />
                                <ComplianceTeam showSystemMessage={showSystemMessage} />                                
                            </div>
                            <div className="col-lg-4">
                                <CustomDashboardLinks />
                            </div>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
