import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function CampaignTrafficType({ name, allowed }) {
    let classes = allowed ? 'mb-traffic allowed' : 'mb-traffic not-allowed'

    let icon = <span></span>

    switch (name) {
        case 'Search':
            icon = <FontAwesomeIcon icon="search" />
            break
        case 'Email':
            icon = <FontAwesomeIcon icon="envelope-square" />
            break
        case 'Desktop':
            icon = <FontAwesomeIcon icon="desktop" />
            break
        case 'Incentive':
            icon = <FontAwesomeIcon icon="money-bill-wave" />
            break
        case 'Contextual':
            icon = <FontAwesomeIcon icon="crosshairs" />
            break
        case 'Social':
            icon = <FontAwesomeIcon icon={['fab', 'facebook']} />
            break
        case 'Mobile Ads':
            icon = <FontAwesomeIcon icon="mobile-alt" />
            break
        case 'Display':
            icon = <FontAwesomeIcon icon="desktop" />
            break
        case 'Native Ads':
            icon = <FontAwesomeIcon icon="images" />
            break
        case 'Brand Bidding':
            icon = <FontAwesomeIcon icon="gavel" />
            break
        default:
            icon = <span></span>
            break
    }

    return (
        <div className={classes}>
            {icon} {name}
        </div>
    )
}
