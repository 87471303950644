import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import callAPI from '../../helpers/callAPI'

export default function AdminPicker({ adminId, setAdminId, role, defaultSelf, required }) {
    /* PLEASE USE THIS ONE INSTEAD OF EmployeePicker! */

    const [admins, setAdmins] = useState([])
    const auth = useAuth()

    useEffect(() => {
        let url = `admin/get-admins`

        // The role prop can either be a single string, or an array.
        if (role) {
            if (Array.isArray(role)) {
                role.forEach((adminRole, index) => {
                    if (index === 0) {
                        url = url + `?role[]=${adminRole}`
                    } else {
                        url = url + `&role[]=${adminRole}`
                    }
                })
            } else {
                url = url + `?role=${role}`
            }
        }

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAdmins(result.admins)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching admins: ${error}`)
            })
    }, [])
    return (
        <select className="form-control form-control-sm" style={{ minWidth: '150px' }} onChange={(e) => setAdminId(e.target.value)} value={adminId > 0 && adminId} required={required ? required : false}>
            <option value=""></option>
            {defaultSelf && (
                <option key={auth.user.id} value={auth.user.id}>
                    {auth.user.name}
                </option>
            )}
            {admins.map((admin) => {
                return (
                    <option key={admin.id} value={admin.id}>
                        {admin.name}
                    </option>
                )
            })}
        </select>
    )
}
