import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth, useProtectedContent } from "../../../../context/AuthContext";

export default function CustomDashboardLinks(){

    const auth = useAuth()
    const showCEO = useProtectedContent(auth.permissions, 'ceo_dashboard')

    return (
        <div className="card mb-3 overflow-auto">
            <div className="card-header">Custom Dashboards</div>
            <div className={`card-body p-0`} style={{ fontSize: '.9rem' }}>                
                <table className="table table-responsive-md m-0">
                    <thead>
                        <tr className="text-muted">
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        { showCEO && 
                            <tr>                      
                                <td><Link to={`/dashboard/CEO`}>CEO</Link></td>                          
                            </tr>
                        }
                        <tr>
                            <td><Link to={`/dashboard/AdvertiserDirector?adminId=48`}>Advertiser Director</Link></td>
                        </tr>
                        <tr>
                            <td><Link to={`/dashboard/AffiliateDirector?adminId=16`}>Affiliate Director</Link></td>      
                        </tr>
                        {/* <tr>
                            <td><Link to={`/dashboard/Administrator`}>Administrator</Link></td>
                        </tr> */}
                        <tr>
                            <td><Link to={`/dashboard/Marketing`}>Marketing</Link></td>
                        </tr>
                        <tr>
                            <td><Link to={`/dashboard/Accounting`}>Accounting</Link></td>
                        </tr>
                        <tr>                      
                            <td><Link to={`/dashboard/it`}>Developer</Link></td>
                        </tr>
                    </tbody>
                </table>                
            </div>            
        </div>
    )
}