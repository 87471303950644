import React, { Component } from 'react'
import callAPI from '../../helpers/callAPI'

class SecurityQuestion extends Component {
    state = {
        qList: [],
    }

    async componentDidMount() {
        try {
            let questions = await this.getSecurityQuestions()
            this.setState({
                qList: questions,
            })
        } catch (error) {
            console.log('Error in getSecurityQuestions: ', error)
        }
    }

    getSecurityQuestions = async () => {
        const result = await callAPI(`securityQuestions`, 'GET')
        if (result['success'] === true) {
            return result['securityQuestions']
        } else if (result['success'] === false) {
            return false
        } else {
            return false
        }
    }

    render() {
        const questionGroup = parseInt(this.props.questionGroup)
        const questionList = this.state.qList

        return (
            <React.Fragment>
                {questionList
                    .filter((group) => group.question_group === questionGroup)
                    .map((q) => {
                        if (parseInt(q.id) === parseInt(this.props.selectedValue)) {
                            return (
                                <option key={q.id} value={q.id} selected>
                                    {q.question}
                                </option>
                            )
                        } else {
                            return (
                                <option key={q.id} value={q.id}>
                                    {q.question}
                                </option>
                            )
                        }
                    })}
            </React.Fragment>
        )
    }
}

export default SecurityQuestion
