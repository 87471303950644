import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Link } from 'react-router-dom'

import Loading from '../../../templates/UI/Loading/Loading'
import Checkbox from './../Filters/Checkbox/Checkbox'
import callAPI from '../../../helpers/callAPI'
import { Modal } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'

const ReportAffPayTotalsDetails = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [weekly, setWeekly] = useState([])
    const [monthly, setMonthly] = useState([])
    const [belowMin, setBelowMin] = useState([])
    const [notPaid, setNotPaid] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)
    const [checkedItems, setCheckedItems] = useState([])
    const [reviewedItems, setReviewedItems] = useState([])

    const history = useHistory()

    let grandTotal = 0

    // http://vdcott.maxbounty.com/affpaymenttotals_details.cfm?platform_name=TIPALTI&payment_method=N&payment_mode=WEEKLY

    let platformName = props.platform,
        paymentMethod = props.paymentMethod,
        paymentMode = props.paymentMode

    let isModal = true

    if (!props.platform || !props.paymentMethod || !props.paymentMode) {
        const urlParams = queryString.parse(history.location.search)

        platformName = urlParams.platform_name ? urlParams.platform_name.trim() : 'none'
        paymentMethod = typeof urlParams.payment_method !== undefined ? urlParams.payment_method.trim() : 'none'
        paymentMode = urlParams.payment_mode ? urlParams.payment_mode.trim() : 'none'

        isModal = false
    }

    const { showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            let fetchUrl = `reports/affiliates/payment_totals_details?platform=${platformName}&pay_method=${paymentMethod}`

            const result = await callAPI(fetchUrl, 'GET')
            if (result['success'] === true) {
                setWeekly(result.weekly_list)
                setMonthly(result.monthly_list)
                setBelowMin(result.below_min_list)
                setNotPaid(result.not_paid_list)
                setDataLoaded(true)
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }, [paymentMethod, platformName, reviewedItems])

    const getPaymentMethodName = (paymentMethod) => {
        switch (paymentMethod) {
            case 'N':
                return 'Payment method not set (N)'
            case 'P':
                return 'Paypal (P)'
            case 'W':
                return 'WIRE (W)'
            case 'R':
                return 'Intercash (R)'
            case 'A':
                return 'ACH (A)'
            case 'C':
                return 'Check (C)'
            case 'E':
                return 'Local Bank Transfer (E)'
            case 'B':
                return 'Bitcoin (B)'
            case 'M':
                return 'MaxMoney'
            default:
                return `Unknown (${paymentMethod})`
        }
    }

    const getAffPriority = (pri) => {
        if (pri === 1) {
            return 'HIGH'
        } else if (pri === 2) {
            return 'MEDIUM'
        } else if (pri === 3) {
            return 'NEW'
        } else if (pri === 4) {
            return 'LOW'
        } else if (pri === 5) {
            return 'VERY LOW'
        }
    }

    const handleChecked = (e) => {
        if (e.target.checked) {
            let newItems = [...checkedItems, parseInt(e.target.id)]
            setCheckedItems(newItems)
        } else {
            let newItems = checkedItems.filter((id) => id !== parseInt(e.target.id))
            setCheckedItems(newItems)
        }
    }

    const getListType = () => {
        let list, paymentKey

        if (paymentMode === 'WEEKLY') {
            list = weekly
            paymentKey = 'weekly_payment'
        } else if (paymentMode === 'MONTHLY') {
            list = monthly
            paymentKey = 'monthly_payment'
        } else if (paymentMode === 'BELOW_MIN') {
            list = belowMin
            paymentKey = 'below_min_payment'
        } else if (paymentMode === 'NOT_PAID') {
            list = notPaid
            paymentKey = 'not_paid_payment'
        }

        return { list, paymentKey }
    }

    const getList = () => {
        let numberOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        let { list, paymentKey } = getListType()

        return list.map((row) => {
            grandTotal += row[paymentKey]

            let rowStyle = {}

            if (row.pay_reviewed === 'Y') {
                rowStyle = { backgroundColor: 'yellow' }
            }

            return (
                <tr className="text-left" key={row.affiliate_id} style={rowStyle}>
                    <td style={{ width: '20%' }}>{row.affiliate_id}</td>
                    <td style={{ width: '40%' }}>
                        <Affiliate aid={row.affiliate_id} name={row.name} priority={row.aff_priority} status={row.status} />
                    </td>
                    <td style={{ width: '17%' }}>{getAffPriority(row.aff_priority)}</td>
                    <td style={{ width: '17%' }} className="text-right">
                        ${Intl.NumberFormat('en-GB', numberOptions).format(row[paymentKey])}
                    </td>
                    <td style={{ width: '6%' }} className="text-right">
                        {row.pay_reviewed !== 'Y' && <Checkbox id={row.affiliate_id} name="" isChecked={checkedAll ? checkedAll : checkedItems.includes(row.affiliate_id)} checkboxChanged={handleChecked} />}
                    </td>
                </tr>
            )
        })
    }

    const handleReviewSubmit = async (e) => {
        // submit the ids to be updated through POST endpoint
        e.preventDefault()

        const result = await callAPI(`reports/affiliates/payment_totals_details`, 'POST', { reviewedItems: checkedItems })

        if (result['success'] === true) {
            if (!isModal) {
                setReviewedItems(checkedItems)
                showSystemMessage('success', 'Success')
                return
            } else {
                showSystemMessage('success', 'Success')
                props.handleClose(true)
            }
        } else if (result['success'] === false) {
            this.props.showSystemMessage('error', result.errors.message)
        }
    }

    const handleCheckAll = (e) => {
        // e.preventDefault();
        let newCheckedAll = !checkedAll
        let { list } = getListType()
        let selectAllItems = []

        if (!checkedAll) {
            list.forEach((row) => {
                if (row.pay_reviewed !== 'Y' && !checkedItems.includes(row.affiliate_id)) {
                    selectAllItems = [...selectAllItems, parseInt(row.affiliate_id)]
                }
            })

            setCheckedItems([...checkedItems, ...selectAllItems])
        } else {
            selectAllItems = []
            setCheckedItems(selectAllItems)
        }
        setCheckedAll(newCheckedAll)
    }

    const getReportOutput = () => {
        return (
            <form method="POST" onSubmit={handleReviewSubmit}>
                <table className="table table-striped table-card table-hover text-small mb-0">
                    <tbody>
                        {platformName !== 'none' && (
                            <tr>
                                <td colSpan="5">
                                    <b>Payment Platform: </b>
                                    <span className="text-uppercase">{platformName}</span>
                                </td>
                            </tr>
                        )}
                        {paymentMethod !== 'none' && (
                            <tr>
                                <td colSpan="5">
                                    <b>Payment method: </b>
                                    {getPaymentMethodName(paymentMethod)}
                                </td>
                            </tr>
                        )}
                        {paymentMode !== 'none' && (
                            <tr>
                                <td colSpan="5">
                                    <b>Payment Mode: </b>
                                    {paymentMode}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="100%" className="text-right">
                                <input className="btn btn-primary" type="submit" value="Mark as reviewed" />
                                &nbsp; &nbsp;
                                <span className="align-items-center">
                                    <input type="checkbox" name="check_all" onClick={handleCheckAll} defaultChecked={checkedAll} /> Check All
                                </span>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Affiliate ID</b>
                            </td>
                            <td>
                                <b>Name</b>
                            </td>
                            <td>
                                <b>Priority</b>
                            </td>
                            <td className="text-right">
                                <b>Balance Owing</b>
                            </td>
                            <td className="text-right">
                                <b>Review</b>
                            </td>
                        </tr>
                        {getList()}
                        <tr className="font-weight-bold text-left text-regular">
                            <td colSpan="3">Total: </td>
                            <td className="text-right text-regular">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(grandTotal)}</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </form>
        )
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Affiliate Payment Total Details</Modal.Title>
            </Modal.Header>
            <div className="card">
                {/* <div className='card-header'>Affiliate Payment Totals Details</div> */}
                {/* <div className='card-body p-0'> */}
                <div className="m-3">{dataLoaded ? getReportOutput() : <Loading></Loading>}</div>
                {/* </div> */}
            </div>
        </React.Fragment>
    )
}

export default ReportAffPayTotalsDetails
