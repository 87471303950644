import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Loading from '../../../templates/UI/Loading/Loading'
import JobsModal from './Components/JobsModal'
import ScheduledJobs from './Components/ScheduledJobs'

export default function Jobs({ campaignId, showSystemMessage }) {

    document.title = 'Scheduled Jobs'

    const [isCreate, setIsCreate] = useState(false)
    const [jobId, setJobId] = useState(24849)
    const [jobIsOpen, setJobIsOpen] = useState(false)
    const [campaignsDefault, setCampaignsDefault] = useState([])
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()

    const { dataLoaded, data, setData, refetch } = useCallApi(`${selectedStartDate ? `admin/jobs/?startDate=${selectedStartDate}&endDate=${selectedEndDate}` : `admin/jobs?startDate=${initStartDate}&endDate=${initEndDate}`}`, 'GET', {}, [], showSystemMessage)

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Admin</h1>
                <div className="ml-5"></div>
            </div>
            <div className="row Affiliate">
                <Modal show={jobIsOpen} onHide={() => setJobIsOpen(false)} size="lg">
                    <JobsModal isCreate={isCreate} showSystemMessage={showSystemMessage} refetch={refetch} campaignsDefault={campaignsDefault} jobId={jobId} setJobId={setJobId} setJobIsOpen={setJobIsOpen} />
                </Modal>
                <div className="col-12">
                    <ScheduledJobs showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} setJobIsOpen={setJobIsOpen} refetch={refetch} setJobId={setJobId} jobId={jobId} setIsCreate={setIsCreate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} quickDate={quickDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                </div>
            </div>
        </React.Fragment>
    )
}
