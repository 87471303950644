import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { Power, User } from 'react-feather'

const ProfileDropdown = ({ mgrName, mgrId, logout, logoutFromAllDevices }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const toggleAndLogout = () => {
        toggle()
        logout()
    }

    const toggleAndLogoutFromAllDevices = () => {
        toggle()
        logoutFromAllDevices()
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link nav-link-profile dropdown-toggle">
                <span className="circle pt-1 text-white">
                    <User className="text-white" size="14"></User>
                </span>{' '}
                {mgrName}
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark dropdown-menu-right">
                <Link className="dropdown-item" to={`/vacation`} onClick={toggle}>
                    Vacation
                </Link>
                {/* <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/" onClick={toggle}>
                    Settings
                </Link> */}
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to={`/user/${mgrId}/changePassword`} onClick={toggle}>
                    Change Password
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/" onClick={toggleAndLogout}>
                    <Power className="text-danger" size="14"></Power> Logout
                </Link>
            </DropdownMenu>
        </Dropdown>
    )
}

export default ProfileDropdown
