import React, { Component } from 'react'
import callAPI from '../../../../../helpers/callAPI'

class MakeSelect extends Component {
    state = {
        dataLoaded: false,
        year: this.props.year,
        makes: [],
    }

    componentDidMount() {
        const fetchData = async () => {
            // Fetch the years
            const result = await callAPI(`vehicleInfo/getMakes?year=${this.state.year}`, 'GET')

            if (result['success'] === true) {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    makes: result.makes,
                })
            } else if (result['success'] === false) {
                console.log('Error fetching vehicle makes.')
            } else {
                console.log('Error fetching vehicle makes.')
            }
        }
        fetchData()
    }

    render() {
        return this.state.dataLoaded ? (
            <select className="form-control" onChange={(e) => this.props.handleChangeMake(e)}>
                <option></option>
                {this.state.makes.map((make) => {
                    return <option value={make}>{make}</option>
                })}
            </select>
        ) : (
            <select className="form-control">
                <option> - </option>
            </select>
        )
    }
}

export default MakeSelect
