export default {
    advertiserStatuses: [
        {
            value: 'A',
            text: 'Active',
        },
        {
            value: 'N',
            text: 'New Lead',
        },
        {
            value: 'D',
            text: 'Discussion',
        },
        {
            value: 'G',
            text: 'Negotiation',
        },
        {
            value: 'C',
            text: 'Cancelled',
        },
    ],
}
