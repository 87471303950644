import { Card, Form, Table } from 'react-bootstrap'
import CampaignApprovalQuestionsSelect from '../../../../../components/CampaignApprovalQuestionsSelect/CampaignApprovalQuestionsSelect'

const CampaignApprovalInformation = ({ data, setData, setIsDisabled }) => {
    const setQuestion1 = (questionId) => {
        setIsDisabled(false)
        setData({ ...data, campaign_approval: { ...data.campaign_approval, approval_question_1: questionId } })
    }

    const setQuestion2 = (questionId) => {
        setIsDisabled(false)
        setData({ ...data, campaign_approval: { ...data.campaign_approval, approval_question_2: questionId } })
    }

    const setQuestion3 = (questionId) => {
        setIsDisabled(false)
        setData({ ...data, campaign_approval: { ...data.campaign_approval, approval_question_3: questionId } })
    }

    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Campaign Approval Information</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Preface to affiliate</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.campaign_approval.approval_preface}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, campaign_approval: { ...data.campaign_approval, approval_preface: e.target.value } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Custom Question 1</span>
                        </td>
                        <td>
                            <CampaignApprovalQuestionsSelect questionId={data.approval_question_1} setQuestionId={setQuestion1} />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Custom Question 2</span>
                        </td>
                        <td>
                            <CampaignApprovalQuestionsSelect questionId={data.approval_question_2} setQuestionId={setQuestion2} />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Custom Question 3</span>
                        </td>
                        <td>
                            <CampaignApprovalQuestionsSelect questionId={data.approval_question_3} setQuestionId={setQuestion3} />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Approval Criteria</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.campaign_approval.approval_criteria}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, campaign_approval: { ...data.campaign_approval, approval_criteria: e.target.value } })
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}

export default CampaignApprovalInformation
