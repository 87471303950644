import React, { useState } from 'react'
import moment from 'moment'

const SotuMargin = (props) => {
    const [open, setOpen] = useState(false)

    const { quarter, margin, detailed } = props.quarter

    const nextIndex = props.index + 1

    let delta = 0
    if (props.report[nextIndex]) {
        delta = margin - props.report[nextIndex].margin
    }

    const getClass = (delta) => {
        return Math.sign(delta) !== -1 ? 'mb-text-sm text-success' : 'mb-text-sm text-danger'
    }

    return (
        <React.Fragment>
            <tr>
                <td className="font-weight-bold">{quarter}</td>
                <td className="text-right">
                    ${Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(margin)}
                    <div className={getClass(delta)}>
                        ({Math.sign(delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(delta)})
                    </div>
                </td>
                <td>
                    <span className="badge badge-primary" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                        {open ? '-' : '+'}
                    </span>
                </td>
            </tr>
            {open &&
                detailed.map((record, subIndex) => {
                    let subDelta = 0
                    const subNextIndex = subIndex + 1

                    if (detailed[subNextIndex]) {
                        subDelta = record.margin - detailed[subNextIndex].margin
                    } else {
                        // If there is none, let's jump to the first of the LAST QUARTER
                        if (props.report[nextIndex]) {
                            if (props.report[nextIndex].detailed[0]) {
                                subDelta = record.margin - props.report[nextIndex].detailed[0].margin
                            }
                        }
                    }

                    return (
                        <tr className="text-secondary">
                            <td>
                                <div className="pl-3">{moment(record.date).utc().format('YYYY-MM')}</div>
                            </td>
                            <td className="text-right">
                                ${Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(record.margin)}
                                <div className={getClass(subDelta)}>
                                    ({Math.sign(subDelta) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(subDelta)})
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    )
                })}
        </React.Fragment>
    )
}

export default SotuMargin
