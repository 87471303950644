import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import moment from 'moment'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import '../../../templates/ReportTable/ReportTable.css'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportAppliedFilters from '../../../templates/ReportAppliedFilters/ReportAppliedFilters'
import Campaign from '../../../templates/Campaign/Campaign'

export default function CallbackHistory({ affiliateId, showSystemMessage }) {
    const [dataLoaded, setDataLoaded] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [report, setReport] = useState([])
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { buildUrl } = useQueryParams()

    if (affiliate.id){
        document.title = `${affiliate.name}: Callback History`
    } else if (campaign.id){
        document.title = `${campaign.name}: Callback History`
    } else {
        document.title = `Callback History`
    }

    useEffect(() => {
        getReport()
    }, [location])

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = buildUrl(`/affiliate/${affiliateId}/callbackHistory?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`affiliate/${affiliateId}/callbackHistory?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliate(result.affiliate)
                    setReport(result.report)
                    if (result.campaign) {
                        setCampaign(result.campaign)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching affiliate callback history: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching callback history')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'campaign.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign name={tableData.data[tableData.row.index].campaign.name} oid={tableData.data[tableData.row.index].campaign.id} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: 'Click ID',
                accessor: 'click_action_id',
                Cell: ({ cell: { value } }) => {
                    return <Link to={`/searchId?actionId=${value}`}>{value}</Link>
                },
            },
            {
                Header: 'Callback',
                accessor: 'callback',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY/MM/DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Response',
                accessor: 'response',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'StatusCode: 200' ? <div className="text-success font-weight-bold">{value}</div> : value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const CSVHeaders = ['Campaign ID', 'Campaign', 'Click ID', 'Callback', 'Date', 'Status', 'Response']
    const CSVDataLabels = ['campaign.id', 'campaign.name', 'click_action_id', 'callback', 'date', 'status', 'response']

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Callback History</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">
                                            <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority ? affiliate.priority.value : ''} status={affiliate.status ? affiliate.status.value : ''} />
                                        </h1>
                                    </div>
                                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                    <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                        Refresh Report
                                    </button>
                                </div>
                                <div>
                                    <DownloadCSV filename={`Click Referrers - ${affiliate.name} (${affiliateId}) - ${initStartDate}-${initEndDate}`} headerLabels={CSVHeaders} data={report} dataLabels={CSVDataLabels} />
                                </div>
                            </div>
                            {dataLoaded && <ReportAppliedFilters campaign={campaign} />}
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
