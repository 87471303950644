import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import getAffiliateStatus from '../../../helpers/getAffiliateStatus'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'

const PasswordCheck = ({ data, dataLoaded }) => {
    return (
        <Card className="mb-4">
            <Card.Header>
                <div>Password Check (Encrypted in DB)</div>
            </Card.Header>
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                <div className="m-2 font-weight-bold">Other affiliates (up to 10) with this same password: </div>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Affiliate ID</th>
                            <th>Affiliate</th>
                            <th>Status</th>
                            <th>Contact</th>
                        </tr>
                    </thead>
                    {dataLoaded ? (
                        <>
                            {data.success && data.info.matchingPasswords.length > 0 ? (
                                <tbody>
                                    {data.info.matchingPasswords.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.affiliate_id}</td>
                                                <td>
                                                    <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                                </td>
                                                <td>{item.aff_status}</td>
                                                <td>{item.contact}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="4">No data found</td>
                                    </tr>
                                </tbody>
                            )}
                        </>
                    ) : (
                        <Loading />
                    )}
                </Table>
            </div>
        </Card>
    )
}

export default PasswordCheck
