import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import linkifyHtml from 'linkify-html'
import { useState } from 'react'

import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const CompleteInvoiceModal = ({ isOpen, status, advertiserId, periodType, handleClose, date, showSystemMessage }) => {
    const { dataLoaded, data, refetch } = useCallApi(`advertiser/${advertiserId}/invoice/${date}/${periodType}`, 'GET', {}, [advertiserId])

    let invoiceInfo = dataLoaded && data.invoice_info
    let linkifyStatsNotes = dataLoaded && linkifyHtml(invoiceInfo.stats_details ? invoiceInfo.stats_details : '', {})

    const [invoicedAmount, setInvoicedAmount] = useState(0)
    const [invoiceNumber, setInvoiceNumber] = useState(0)
    const [stripeLink, setStripeLink] = useState('')
    const [submitting, setSubmitting] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const getInvoiceType = (value) => {
        if (value === 10) {
            return 'Pre-Payment'
        } else if (value === 20) {
            return 'Weekly'
        } else if (value === 30) {
            return 'Semi-Monthly'
        } else {
            return 'Monthly'
        }
    }

    const getReversalsSource = (value) => {
        if (value === 'U') {
            return 'Unknown'
        } else if (value === 'N') {
            return 'No Reversals'
        } else if (value === 'L') {
            return 'Login for Reversals'
        } else if (value === 'M') {
            return 'Get Reversals from Advertiser'
        } else if (value === 'F') {
            return 'Fraud Pubs Only. Provided Ad-Hoc'
        }
    }

    const getStatsSource = (value) => {
        if (value === 'U') {
            return 'Unknown'
        } else if (value === 'O') {
            return 'Use Our Stats'
        } else if (value === 'L') {
            return 'Login for Stats'
        } else if (value === 'M') {
            return 'Get Stats from Advertiser'
        }
    }

    const getStripeLink = (e) => {
        e.preventDefault()

        const postBody = {
            amount: invoicedAmount,
            invoice: invoiceNumber,
        }

        callAPI(`advertiser/${advertiserId}/stripe`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setStripeLink(result.stripe_link)
                } else {
                    showSystemMessage('error', 'An error occurred while getting Stripe link')
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while getting Stripe link')
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // check if invoiced amount is less than our stats, Pelase describe why in the notes field
        let validated = validate(e)

        if (!validated) {
            e.target.reportValidity()
            return false
        }

        setSubmitting(true)

        const putBody = {
            amount: invoicedAmount,
            invoice_number: invoiceNumber,
            notes: e.target.notes.value,
            qb_invoice_link: e.target.qb_invoice_link.value,
            period_type: periodType,
            invoice_date: date,
            invoice_status: status,
        }

        if (e.target.reversals_done) {
            putBody['reversals_done'] = e.target.reversals_done.checked
        }

        callAPI(`advertiser/${advertiserId}/completeInvoice`, 'PUT', putBody).then((result) => {
            if (result['success'] === true) {
                showSystemMessage('success', result.message)
                handleClose(true)
            } else if (result['success'] === false) {
                showSystemMessage('error', result.errors.message)
                setSubmitting(false)
            } else {
                showSystemMessage('error', 'Error while completing invoice')
                setSubmitting(false)
            }
        })
    }

    const validate = (e) => {
        if (invoicedAmount < invoiceInfo.total_amount && e.target.notes.value.length === 0) {
            e.target.notes.setCustomValidity('Invoiced amount is lower than our stats. Please describe why in the notes field.')
            return false
        }

        if (periodType !== 10 && invoiceInfo.limited_credit_flag === 'Y') {
            let checked = e.target.qb_date.checked

            if (!checked) {
                e.target.setCustomValidity('Invoice date in QuickBooks must be updated.')
                return false
            }
        }

        if ((invoiceInfo.reversals_flag === 'L' || invoiceInfo.reversals_flag === 'P') && periodType !== 10) {
            let revDone = e.target.reversals_done.checked

            if (!revDone) {
                e.target.reversals_done.setCustomValidity('Reversals have not been done.')
                return false
            }
        }
        return true
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Complete Invoice for {dataLoaded ? (data.invoice_info ? data.invoice_info.name : '') : spinner}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <form onSubmit={handleSubmit}>
                        <table className="table table-borderless m-0">
                            <tbody>
                                {Object.keys(data.invoice_info).length !== 0 && (
                                    <>
                                        <tr>
                                            <td>Invoice Date:</td>
                                            <td>{date}</td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Type:</td>
                                            <td>{getInvoiceType(periodType)}</td>
                                        </tr>
                                        <tr>
                                            <td>Requested Amount:</td>
                                            <td>
                                                <Dollars value={invoiceInfo.amount} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Period:</td>
                                            <td>
                                                {moment(invoiceInfo.period_start).utc().format('YYYY/MM/DD')} - {moment(invoiceInfo.period_end).utc().format('YYYY/MM/DD')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Terms:</td>
                                            <td>{invoiceInfo.payment_terms > 0 ? `Net ${invoiceInfo.payment_terms}` : 'Pre-Payment'}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Our Stats:</td>
                                            <td>
                                                <Dollars value={invoiceInfo.total_amount} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Stats Source:</td>
                                            <td>{getStatsSource(invoiceInfo.stats_flag)}</td>
                                        </tr>
                                        <tr>
                                            <td>Stats Notes:</td>
                                            <td>
                                                <span dangerouslySetInnerHTML={{ __html: linkifyStatsNotes }}></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reversals Source:</td>
                                            <td>{getReversalsSource(invoiceInfo.reversals_flag)}</td>
                                        </tr>
                                        <tr>
                                            <td>Reversals Notes:</td>
                                            <td>{invoiceInfo.reversals_details}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <td>Invoiced Amount:</td>
                                    <td>
                                        <input name="invoiced_amount" className="form-control" defaultValue="" maxLength="10" required onChange={(e) => setInvoicedAmount(e.target.value)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Number:</td>
                                    <td>
                                        <input name="invoice_number" className="form-control" defaultValue="" maxLength="10" required onChange={(e) => setInvoiceNumber(e.target.value)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>QB Invoice Link:</td>
                                    <td>
                                        <input name="qb_invoice_link" className="form-control" defaultValue="" maxLength="256" size="40" />
                                    </td>
                                </tr>
                                {periodType !== 10 && invoiceInfo.limited_credit_flag === 'Y' && (
                                    <tr>
                                        <td>Updated QB Invoice Date?</td>
                                        <td>
                                            <input type="checkbox" name="qb_date" />
                                        </td>
                                    </tr>
                                )}

                                {(invoiceInfo.reversals_flag === 'L' || invoiceInfo.reversals_flag === 'M') && periodType !== 10 && (
                                    <tr>
                                        <td>Were Reversals Done?</td>
                                        <td className="pt-2">
                                            <input type="checkbox" name="reversals_done" onChange={(e) => e.target.setCustomValidity('')} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>Notes:</td>
                                    <td>
                                        <textarea name="notes" className="form-control" defaultValue="" cols="40" rows="5" onChange={(e) => e.target.setCustomValidity('')} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Stripe Link:</td>
                                    <td>
                                        <button type="button" className="btn btn-sm btn-warning" onClick={getStripeLink}>
                                            Generate Payment Link
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <textarea cols="40" rows="3" className="form-control" defaultValue={stripeLink} />
                                        {invoiceInfo.include_cc_payment === 'Y' && <span className="text-danger">*THIS ADVERTISER HAS REQUESTED A CREDIT CARD PAYMENT LINK BE SENT</span>}
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="text-right">
                                        <CustomButton name="Save" variant="primary" type="submit" spin={submitting} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
        </Modal>
    )
}

export default CompleteInvoiceModal
