const convertUrl = (url) => {
    let finalUrl = url

    let urlValid = checkUrl(url)

    if (!urlValid) {
        finalUrl = `https://${url}`
    }

    return finalUrl
}

const checkUrl = (string) => {
    try {
        const url = new URL(string)        
        return url.protocol === 'http:' || url.protocol === 'https:'
    } catch (err) {
        return false
    }
}

module.exports = convertUrl
