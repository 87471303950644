import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import Loading from '../../../../templates/UI/Loading/Loading'

const NewestAffiliates = ({ manager, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await callAPI(`affiliates/newest`, 'GET')
            
            if (result.success) {
                setData(result)
            } else {
                showSystemMessage(result.errors.message)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchData()
    }, [manager])

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="font-weight-bold">Newest Active Affiliates</div>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th className="text-right">Month Earnings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.affiliates.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.affiliates.slice(0, 15).map((item, index) => {
                                            if (index < 15) {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.affiliate.id}</td>
                                                        <td>
                                                            <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} status={`${item.affiliate.status}`} priority={`${item.affiliate.priority}`} />
                                                        </td>
                                                        <td align="right">
                                                            <Dollars value={`${item.earnings}`} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default NewestAffiliates
