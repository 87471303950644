import React, { useState, useEffect } from 'react'
import linkifyHtml from 'linkify-html'
import callAPI from '../../../helpers/callAPI'

const AccountNotes = (props) => {
    const [editing, setEditing] = useState(false)
    const [extraInfo, setExtraInfo] = useState(props.notes)
    const [tempExtraInfo, setTempExtraInfo] = useState(props.notes)

    const notes = extraInfo.replace(/(\r\n|\n|\r)/gm, '<br />')
    const linkedNotes = linkifyHtml(notes, {})

    const { affiliateId, showSystemMessage } = props

    const submitNotes = (event) => {
        event.preventDefault()
        let url = `affiliate/${affiliateId}/accountNotes`

        callAPI(url, 'POST', { extraInfo: tempExtraInfo })
            .then((result) => {
                if (result['success'] === true) {
                    setExtraInfo(tempExtraInfo)
                    showSystemMessage('success', result.message)
                    setEditing(false)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while saving traffic notes: ', error)
                showSystemMessage('error', 'An error occurred while updating account notes')
            })
    }

    const handleInput = (event) => {
        const text = event.target.value
        setTempExtraInfo(text)
    }

    return (
        <div className="card mb-2">
            <div className="d-flex justify-content-between align-items-center p-2 card-header">
                <div className="font-weight-bold">Account Notes</div>
                <div>
                    {!editing ? (
                        <button className="btn btn-sm btn-warning" onClick={() => setEditing(true)}>
                            Edit
                        </button>
                    ) : (
                        <div>
                            <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={() => setEditing(false)}>
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-sm" type="submit" onClick={submitNotes}>
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="p-2">{!editing ? <div className="overflow-auto p-2 border min-vh-50" style={{ maxHeight: '350px', minHeight: '250px' }} dangerouslySetInnerHTML={{ __html: linkedNotes }} name="notes" defaultValue={linkedNotes}></div> : <textarea name="account-notes" className="form-control" defaultValue={extraInfo} onChange={handleInput} style={{ maxHeight: '350px', minHeight: '200px' }} maxLength="1024" />}</div>
        </div>
    )
}

export default AccountNotes
