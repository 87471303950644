import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Click extends Component {
    state = {}
    render() {
        /*
            Allowed props:
                status: highlight, active, cancelled
        */

        let classes = this.props.status ? `mb-element ${this.props.status}` : 'mb-element'
        let url = `/reports/clicks?startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        if (this.props.oid > 0) {
            url = url + `&oid=${this.props.oid}`
        }

        if (this.props.aid > 0) {
            url = url + `&aid=${this.props.aid}`
        }

        if (this.props.cid > 0) {
            url = url + `&cid=${this.props.cid}`
        }
        if (this.props.lpid > 0) {
            url = url + `&lpid=${this.props.lpid}`
        }

        return (
            <Link target="_blank" className={classes} to={{ pathname: url }}>
                <span className="name">{this.props.value}</span>
            </Link>
        )
    }
}

export default Click
