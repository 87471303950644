import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import AdminPicker from '../../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const TransferAffiliateModal = ({ affTransferModalData, setAffTransferModalData, showSystemMessage, refetch }) => {
    const [newManagerId, setNewManagerId] = useState(0) // using newManagerId instead the usual [adminId] for AdminPicker to indicate the new manager for affiliate
    const [dataLoaded, setDataLoaded] = useState(true)
    let [reason, setReason] = useState('Medium/High')
    const [extraDetails, setExtraDetails] = useState('')

    let selectReason = (event) => {
        setReason(event.target.value)
    }

    const transferAffiliate = async (e) => {
        e.preventDefault()
        e.stopPropagation() // This is needed to prevent submitting the parent form

        setDataLoaded(false)

        let data = await callAPI(`affiliate/${affTransferModalData.affiliate.id}/transfer`, 'POST', {
            newManager: { id: newManagerId },
            oldManager: { id: affTransferModalData.adminId, name: affTransferModalData.adminName },
            affiliate: {
                id: affTransferModalData.affiliate.id,
                priority: affTransferModalData.affiliate.priority,
            },
            reason: reason,
            extraDetails: extraDetails,
        })
        if (data.success) {
            showSystemMessage('success', 'Affiliate successfully transferred')
            setAffTransferModalData({ ...affTransferModalData, isOpen: false })
            if (refetch) {
                refetch()
            }
        } else {
            showSystemMessage('error', 'Error transferring affiliate')
            setAffTransferModalData({ ...affTransferModalData, isOpen: false })
        }
        setDataLoaded(true)
    }

    return (
        <Modal show={affTransferModalData.isOpen} onHide={() => setAffTransferModalData((prevState) => ({ ...prevState, isOpen: false }))}>
            <form onSubmit={transferAffiliate}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer Affiliate: {affTransferModalData.affiliate.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex m-2">
                        <div style={{ width: '10rem' }} className="d-flex justify-content-end">
                            Affiliate Manager:{' '}
                        </div>
                        <span className="ml-2">
                            <AdminPicker adminId={newManagerId} setAdminId={setNewManagerId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD', 'AFFILIATE_DIRECTOR']} required={true} />
                        </span>
                    </div>
                    <div className="d-flex m-2">
                        <div style={{ width: '10rem' }} className="d-flex justify-content-end">
                            Reason for Transfer:{' '}
                        </div>
                        <span className="ml-2">
                            <Form.Control className="w-100" as="select" size="sm" onChange={(event) => selectReason(event)}>
                                <option value="Medium/High">Transfer from Jr.AM to Senior AM</option>
                                <option value="Staffing_Change">AM is no longer with company</option>
                                <option value="Aff_Request">Affiliate requested new AM</option>
                                <option value="Mgmt_Decision">Management Decision</option>
                            </Form.Control>
                        </span>
                    </div>
                    <div className="d-flex m-2">
                        <div style={{ width: '10rem' }} className="d-flex justify-content-end">
                            Extra Details:{' '}
                        </div>
                        <div className="ml-2 flex-grow-1">
                            <textarea className="form-control w-100" onChange={(e) => setExtraDetails(e.target.value)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="primary" type="submit" name="Transfer" spin={!dataLoaded} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}
export default TransferAffiliateModal
