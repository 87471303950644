import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'

import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const ApprovalQuestionModal = ({ isCreate, question, isOpen, handleClose, showSystemMessage }) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        setSubmitting(true)

        let body = {
            question: e.target.question_text.value,
        }

        let result

        if (isCreate) {
            result = await callAPI(`campaigns/approvalQuestions`, 'POST', body)
        } else {
            result = await callAPI(`campaigns/${question.id}/approvalQuestions`, 'PUT', body)
        }

        if (result.success) {
            setSubmitting(false)
            handleClose(true)
            showSystemMessage('success', result.message)
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
            setSubmitting(false)
        } else {
            showSystemMessage('error', `Error while ${isCreate ? 'creating' : 'editing'} approval question.`)
            setSubmitting(false)
        }
    }

    return (
        <Modal show={isOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Campaign Approval Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="20%">Question Text:</td>
                                <td>
                                    <input name="question_text" defaultValue={question.text} className="form-control" placeholder="Enter question" required />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <CustomButton name={isCreate ? 'Create' : 'Save'} variant="primary" spin={submitting} type="submit" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ApprovalQuestionModal
