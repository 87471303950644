import moment from 'moment'
import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { MoreVertical } from 'react-feather'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import FraudWatchModal from '../../../../components/FraudWatchModal/FraudWatchModal'
import callAPI from '../../../../helpers/callAPI'
import useCallApi from '../../../../hooks/useCallApi'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'
import styles from './FraudWatchCampaigns.module.scss'

const initItem = {
    id: '',
    campaign: {
        id: '',
        name: '',
        status: '',
    },
    reason: '',
    frequency: '',
    frequency_start_date: moment().format('YYYY-MM-DD'),
    notes: '',
}

const FraudWatchCampaigns = ({ manager, showSystemMessage }) => {
    const [modalData, setModalData] = useState({
        isOpen: false,
        isCreate: false,
    })
    const { dataLoaded, data, refetch } = useCallApi(`fraudWatch/campaigns`, 'GET', {}, [manager])
    const [dropDown, setDropdown] = useState(false)

    const updateLastCheckedDate = async (id) => {
        await callAPI(`fraudWatch/campaign/${id}/check`, 'PUT', {})
        refetch()
    }

    const getNextCheckTime = (nextCheckDate) => {
        let today = moment().startOf('day').format('YYYY-MM-DD')
        let next = moment(nextCheckDate).utc().startOf('day').format('YYYY-MM-DD')

        let difference = moment(next).diff(moment(today), 'days', true)

        return difference
    }

    return (
        <Card className="mb-3">
            <FraudWatchModal refetch={refetch} modalData={modalData} setModalData={setModalData} isCampaign showSystemMessage={showSystemMessage} />
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="font-weight-bold">Fraud Watch Campaigns</div>
                <span className="align-items-center">
                    <button className="btn btn-sm btn-warning" onClick={() => setModalData({ isCreate: true, item: initItem, isOpen: true })}>
                        Create New
                    </button>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                    <Table striped hover className="w-100 m-0">
                        <thead>
                            <tr className="text-secondary">
                                <th>ID</th>
                                <th>Campaign</th>
                                <th>Reason</th>
                                <th>Check</th>
                                <th>Note</th>
                                <th>Admin</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded ? (
                                <>
                                    {!data || data.campaigns.length === 0 ? (
                                        <tr>
                                            <td colSpan={7}>No data.</td>
                                        </tr>
                                    ) : (
                                        <>
                                            {data.campaigns.map((item, index) => {
                                                return (
                                                    <tr key={item.id} className={`${getNextCheckTime(item.next_check_date) === 0 ? styles.lightGreen : getNextCheckTime(item.next_check_date) < 0 ? styles.lightRed : ''}`}>
                                                        <td>{item.campaign.id}</td>
                                                        <td>
                                                            <Campaign oid={item.campaign.id} name={item.campaign.name} status={item.campaign.status} />
                                                        </td>
                                                        <td dangerouslySetInnerHTML={{ __html: item.reason }} />
                                                        <td>{getNextCheckTime(item.next_check_date)} days</td>
                                                        <td dangerouslySetInnerHTML={{ __html: item.notes }} />
                                                        <td>{item.admin.name}</td>
                                                        <td align="right">
                                                            <Dropdown
                                                                isOpen={dropDown === item.id}
                                                                toggle={() => {
                                                                    setDropdown(!dropDown ? item.id : false)
                                                                }}
                                                                direction="left"
                                                            >
                                                                <DropdownToggle tag="a" onClick={() => setDropdown(item.id)} data-toggle="dropdown" aria-expanded={dropDown === item.id} className="report-more-toggle">
                                                                    <MoreVertical size={20} />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {getNextCheckTime(item.next_check_date) <= 0 && (
                                                                        <>
                                                                            <button
                                                                                className="dropdown-item btn btn-link"
                                                                                onClick={() => {
                                                                                    updateLastCheckedDate(item.id)
                                                                                }}
                                                                            >
                                                                                Mark as checked
                                                                            </button>
                                                                            <DropdownItem divider className="m-0" />
                                                                        </>
                                                                    )}
                                                                    <button
                                                                        className="dropdown-item btn btn-link"
                                                                        onClick={() => {
                                                                            setModalData({
                                                                                item: item,
                                                                                isCreate: false,
                                                                                isOpen: true,
                                                                            })
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={7} align="center">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    )
}

export default FraudWatchCampaigns
