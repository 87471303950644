import React from 'react'
import { HelpCircle } from 'react-feather'
import ReactTooltip from 'react-tooltip'

import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const TrackingSettings = ({ affiliate, setIsDisabled }) => {
    const auth = useAuth()
    const showTrafficBlocked = useProtectedContent(auth.permissions, 'affiliate_traffic_blocked')
    const showGenerateToken = useProtectedContent(auth.permissions, 'affiliate_tracking_token')
    const showGlobalFilterOverride = useProtectedContent(auth.permissions, 'affiliate_filter_overrides_global')

    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    return (
        <div className="card">
            <ReactTooltip />
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Tracking Settings</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td className="border-right text-right">
                                Track Token <HelpCircle className="text-primary" size={14} data-tip="The token used to validate this affiliate's tracking links." />
                            </td>
                            <td className="border-right">
                                <span className="badge badge-light border">{affiliate.tracking.token}</span>
                            </td>
                            <td className="border-right text-right">
                                Generate New Token <HelpCircle className="text-primary" size={14} data-tip="If an affiliate's link is hijacked, you can generate a new token. Doing this will invalidate ALL of their old links and the affiliate will have to pull new links." />
                            </td>
                            <td>
                                {showGenerateToken ? (
                                    <select name="trkToken" defaultValue="N" className="form-control" onChange={inputChangeHandler} required>
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                ) : (
                                    <div>
                                        No
                                        <input type="hidden" name="trkToken" value="N" />
                                    </div>
                                )}
                            </td>
                        </tr>

                        <tr>
                            <td className="border-right text-right">Traffic</td>
                            <td className="border-right">
                                {showTrafficBlocked ? (
                                    <select name="traffic" defaultValue={affiliate.tracking.traffic_blocked ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                        <option value="N">Not Blocked</option>
                                        <option value="Y">Blocked</option>
                                    </select>
                                ) : affiliate.tracking.traffic_blocked ? (
                                    <div>Blocked<input type="hidden" name="traffic" value={affiliate.tracking.traffic_blocked ? 'Y' : 'N'}></input></div>
                                ) : (
                                    <div>Not Blocked<input type="hidden" name="traffic" value={affiliate.tracking.traffic_blocked ? 'Y' : 'N'}></input></div>
                                )}
                            </td>
                            <td className="border-right text-right">
                                Brand Bid Count <HelpCircle className="text-primary" size={14} data-tip="Count is increased by compliance per violation of brand-bidding rules. If it is set to 3, this affiliate will not be allowed to run campaigns that do not allow brand-bidding." />
                            </td>
                            <td>
                                <select name="brandBidCount" defaultValue={affiliate.tracking.brand_bid_count} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            {/* <td className="border-right text-right">
                                Disable Redirects? <HelpCircle className="text-primary" size={14} data-tip="Disable if the affiliate does not want their links to redirect to another campaign if it gets cancelled, hits cap, etc." />
                            </td>
                            <td className="border-right">
                                <select name="disableRedirects" defaultValue={affiliate.tracking.disable_redirects ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td> */}
                            <td className="border-right text-right">
                                Disable sub-ID Reporting? <HelpCircle className="text-primary" size={14} data-tip="If affiliate is using sub id 1 improperly (ie. sending unique values every time), disable subid reporting." />
                            </td>
                            <td colSpan={3}>
                                <select name="disableSubId" defaultValue={affiliate.account.disable_subid_reporting ? 'Y' : 'N'} className="form-control w-25" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td className="border-right text-right">Ignore Stats for EPC</td>
                            <td className="border-right">
                                <select name="ignoreStatsForEpc" defaultValue={affiliate.account.ignore_stats_for_epc === true ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                            <td className="border-right text-right">
                                Global Filter Override <HelpCircle className="text-primary" size={14} data-tip="If enabled, tracking will not filter any of these affiliate's clicks (IP, Device, etc.)" />
                            </td>
                            <td className="border-right">
                                {showGlobalFilterOverride ? (
                                    <select name="globalFilterOverride" defaultValue={affiliate.tracking.global_filter_override === true ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                ) : 
                                    <div>
                                        { affiliate.tracking.global_filter_override === false  }
                                        <input type="hidden" name="globalFilterOverride" value={affiliate.tracking.global_filter_override === true ? 'Y' : 'N'} />
                                    </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TrackingSettings
