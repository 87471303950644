import moment from 'moment'
import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import callAPI from '../../../../helpers/callAPI'
import getJobName from '../../../../helpers/getJobName'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Campaign from '../../../../templates/Campaign/Campaign'
import ReportTable from '../../../../templates/ReportTable/ReportTable'
import Loading from '../../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../../Reports/Filters/DateRangeFilter/DateRangeFilter'

export default function ScheduledJobs({ showSystemMessage, setIsCreate, setJobId, setJobIsOpen, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, refetch, dataLoaded, data }) {

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'scheduled_jobs')

    const history = useHistory()
    const [deleteIndex, setDeleteIndex] = useState(-1)

    const dateChange = () => {
        history.push(`/admin/jobs?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        refetch()
    }
    const deleteJob = async (id, jobName, campaignId) => {
        const res = await callAPI(`admin/job/${id}`, 'DELETE', {jobName: jobName, campaignId: campaignId})
        if (res.success) {
            showSystemMessage('success', 'Job deleted')
            refetch()
            setDeleteIndex(-1)
        } else {
            showSystemMessage('error', res.errors.message)
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date/Time',
                accessor: 'job_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('MM/DD/YYYY hh:mm A')}</>
                }
            },
            {
                Header: 'Job',
                accessor: 'job_name',
                Cell: ({ cell: { value } }) => {
                    return <>{getJobName(value)}</>
                }
            },
            {
                Header: 'Campaign',
                accessor: 'offer_name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].offer_name} status={tableData.data[tableData.row.index].status_value} />
                }
            },
            {
                Header: 'Advertiser',
                accessor: 'merchant_name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].merchant_id} name={tableData.data[tableData.row.index].merchant_name} status={tableData.data[tableData.row.index].merchant_status_value} />
                }
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate_name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].affiliate_name} status={tableData.data[tableData.row.index].aff_status_value} priority={tableData.data[tableData.row.index].aff_priority_value} />
                }
            },
            {
                Header: 'Params',
                accessor: 'param',
                Cell: (tableData) => {

                    let param1 = tableData.data[tableData.row.index].param1
                    let param2 = tableData.data[tableData.row.index].param2
                    let param3 = tableData.data[tableData.row.index].param3

                    const getStatus = (value => {
                        if (value === 'A') {
                            return 'Active'
                        } else if (value === 'B') {
                            return 'By Request'
                        } else if (value === 'H') {
                            return 'Hidden'
                        } else {
                            return value
                        }
                    })

                    return <div>
                        {param1 && <div>{getStatus(param1)}</div>}
                        {param2 && <div>{getStatus(param2)}</div>}
                        {param3 && <div>{getStatus(param3)}</div>}
                    </div>
                },
            },
            {
                Header: 'Status',
                accessor: 'job_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'P' ? 'Pending' : 'Done'}</>
                }
            },
            {
                Header: 'Edit',
                accessor: 'job_id',
                Cell: (tableData) => {

                    let jobStatus = tableData.data[tableData.row.index].job_status
                    let index = tableData.data[tableData.row.index]
                    let jobId = tableData.data[tableData.row.index].job_id
                    let jobName = tableData.data[tableData.row.index].job_name
                    let campaignId = tableData.data[tableData.row.index].offer_id

                    return <>
                        {jobStatus === 'P' ?
                            <>
                                <button className='btn btn-sm btn-warning mr-2' onClick={(e) => {
                                    e.preventDefault()
                                    setIsCreate(false)
                                    setJobId(jobId)
                                    setJobIsOpen(true)
                                }}>Edit</button>
                                {deleteIndex === index ? (
                                    <button className='btn btn-danger btn-sm' onClick={() => deleteJob(jobId, jobName, campaignId)}>
                                        Confirm Delete
                                    </button>
                                ) : <button className='btn btn-outline-danger btn-sm' onClick={() => setDeleteIndex(index)}>
                                    Delete
                                </button>}
                            </> : <></>}
                    </>
                },
            }
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.jobs}
                    columns={columns}
                />
            </React.Fragment>
        )
    }

    return (
        <Card className="mb-3 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center flex-row">
                <span className="d-flex align-items-center">
                    <span className="mr-2">Scheduled Jobs</span>
                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                    <Button variant="primary" size="sm" onClick={dateChange}>
                        Refresh
                    </Button>
                </span>
                <Link
                    to="/admin/job/create"
                    className="btn btn-sm btn-warning"
                    onClick={(event) => {
                        event.preventDefault()
                        setIsCreate(true)
                        setJobIsOpen(true)
                    }}
                >
                    Create Job
                </Link>
            </Card.Header>
            <div>
                {dataLoaded ? data.jobs.length === 0 ? <div className='p-3'>No data for the current parameters.</div> : getReportOutput() : <Loading />}</div>
        </Card>
    )
}