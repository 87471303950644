import { Card, Table } from "react-bootstrap";
import useCallApi from "../../../hooks/useCallApi";
import moment from "moment";
import { colors } from '../../../theme/variables'
import Loading from '../../../templates/UI/Loading/Loading'
import { DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dollars from "../../../templates/Dollars/Dollars";
import { useEffect, useState } from "react";
import callAPI from "../../../helpers/callAPI";

const Redirectors = () => {
    const [init, setInit] = useState(true)

    const [data, setData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)

    const fetchData = async () => {
        setDataLoaded(false)
        let result = await callAPI(`mbStatus/monitoring/redirectors`, 'GET')
        if (result.success) {
            setData(result)
        }
        setDataLoaded(true)
    }

    useEffect(() => {
        if (init) {
            fetchData()
            setInit(false)
        }
        const interval = setInterval(() => {
            fetchData()
        }, 60000)
        return () => clearInterval(interval)
    }, [])
    return (
        <Card className="mb-3">
            <Card.Header>Top 3 Re-directors For Today [{dataLoaded && data && data.details.redirectedClicksToday}]</Card.Header>
            <Card.Body>
                {dataLoaded && data ? (
                <Table className="m-0">
                    <thead>
                        <tr>
                            <th>Aff. ID</th>
                            <th>Aff.Mgr.</th>
                            <th>Clicks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.details.redirectedClicks.map((server, index) => {
                            return (
                                <tr key={index}>
                                    <td>{server.affiliate_id}</td>
                                    <td>{server.AM_Name}</td>
                                    <td className='text-right'>{server.redirected_clicks_cnt} [{Math.round(server.redirected_clicks_cnt / data.details.redirectedClicksToday * 100)}%]</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                ) : (
                    <Loading />
                )}
            </Card.Body>
        </Card>
    )
}

export { Redirectors }