import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'

const BlacklistModal = ({ isOpen, setIsOpen, showSystemMessage, defaultType, refetch }) => {
    const [type, setType] = useState('email')
    const [value, setValue] = useState('')
    const [notes, setNotes] = useState('')

    useEffect(() => {
        setType(defaultType)
    }, [defaultType])

    const createBlacklist = async (e) => {
        e.preventDefault()

        const result = await callAPI('compliance/blacklist', 'POST', { type, value, notes })
        if (result.success) {
            showSystemMessage('success', 'Blacklist created successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <form onSubmit={createBlacklist}>
                <Modal.Header closeButton>Create Blacklist</Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Keyword: </span>
                        <div className="w-75">
                            <Form.Control as="select" value={type} onChange={(e) => setType(e.target.value)}>
                                <option value="email">Email</option>
                                <option value="ip">IP</option>
                                <option value="redirect">Redirect Affiliate ID</option>
                            </Form.Control>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Blacklisted Value: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={value} onChange={(e) => setValue(e.target.value)} required />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Notes: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={notes} onChange={(e) => setNotes(e.target.value)} required />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default BlacklistModal
