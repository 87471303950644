import moment from 'moment'
import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'

import useCallApi from '../../../../hooks/useCallApi'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import FraudPullDropdown from './FraudPullDropdown/FraudPullDropdown'

const FraudPulls = ({ manager, showSystemMessage }) => {
    let apiUrl = (manager && manager.id) > 0 ? `compliance/fraudPulls?adminId=${manager.id}` : `compliance/fraudPulls`

    const { dataLoaded, data, refetch } = useCallApi(apiUrl, 'GET', {}, [manager])

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="font-weight-bold">Fraud Pulls</div>
                <span>
                    <Link to={`/compliance/fraudPullReport`}>Full Report</Link>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto table-container-scroll">
                <div>
                    <Table striped hover className="w-100 m-0">
                        <thead>
                            <tr className="text-secondary">
                                <th>Aff ID</th>
                                <th>Affiliate</th>
                                <th>Campaign</th>
                                <th>Advertiser</th>
                                <th width="120px">Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded ? (
                                <>
                                    {!data || data.fraud_pulls.length === 0 ? (
                                        <tr>
                                            <td colSpan={6}>No data.</td>
                                        </tr>
                                    ) : (
                                        <>
                                            {data.fraud_pulls.map((item, index) => {
                                                if (index < 15) {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.affiliate.id}</td>
                                                            <td>
                                                                <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} status={`${item.affiliate.status.value}`} priority={`${item.affiliate.priority.value}`} />
                                                            </td>
                                                            <td>
                                                                <Campaign oid={item.campaign.id} name={item.campaign.name} status={item.campaign.status.value} />
                                                            </td>
                                                            <td>
                                                                <Advertiser id={item.advertiser.id} name={item.advertiser.name} status={item.advertiser.status.value} />
                                                            </td>
                                                            <td>{moment(item.date).utc().format('MM/DD/YYYY HH:mm')}</td>
                                                            <td>
                                                                <FraudPullDropdown item={item} showSystemMessage={showSystemMessage} refetch={refetch} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={6} align="center">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    )
}

export default FraudPulls
