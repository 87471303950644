import React, { createContext, useRef, useState } from "react";

const SystemMessageContext = createContext();

const SystemMessageProvider = ({ children }) => {
    //const { showSystemMessage, hideSystemMessage, systemMessage, setSystemMessage } = useContext(SystemMessageContext)
    //()=> showSystemMessage('success', 'This is a success message')
    //()=> showSystemMessage('error', 'This is an error message')
    //The array of messages will reset after 10 seconds of no new messages
    //pressing the X button will remove the most recent message

    const [systemMessage, setSystemMessage] = useState([]);
    const timerRef = useRef(null);
    const showSystemMessage = (type, message) => {
        clearTimeout(timerRef.current);
        setSystemMessage([{ type: type, message: message }, ...systemMessage]);
        timerRef.current = setTimeout(() => {
            setSystemMessage([]);
        }, 10000);
    }

    const hideSystemMessage = () => {
        if (systemMessage.length > 0) {
            setSystemMessage(systemMessage.slice(1));
        } else {
            setSystemMessage([]);
            clearTimeout(timerRef.current);

        }
    }

    return (
        <SystemMessageContext.Provider
            value={{
                systemMessage,
                setSystemMessage,
                showSystemMessage,
                hideSystemMessage,
            }}
        >
            {children}
        </SystemMessageContext.Provider>
    );
};

export { SystemMessageProvider, SystemMessageContext };
