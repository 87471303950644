import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import queryString from 'query-string'

import JuniorAffiliateTeamLead from './components/AffiliateDivision/JuniorAffiliateTeamLead'
import SeniorAffiliateManager from './components/AffiliateDivision/SeniorAffiliateManager'
import JuniorAffiliateManager from './components/AffiliateDivision/JuniorAffiliateManager'
import AffiliateDirector from './components/AffiliateDivision/AffiliateDirector'
import AdvertiserManager from './components/AdvertiserDivision/AdvertiserManager'
import AdvertiserDirector from './components/AdvertiserDivision/AdvertiserDirector'
import ComplianceManager from './components/ComplianceDivision/ComplianceManager'
import ComplianceDirector from './components/ComplianceDivision/ComplianceDirector'
import { useLocation } from 'react-router-dom'
import callAPI from '../../helpers/callAPI'
import Loading from '../../templates/UI/Loading/Loading'
import MarketingManager from './components/MarketingDivision/MarketingManager'
import BizdevManager from './components/BizdevDivision/BizdevManager'
import AdopsTeamlead from './components/AdopsDivision/AdopsTeamlead'
import AdopsManager from './components/AdopsDivision/AdopsManager'
import CEO from './components/CEO/CEO'
import CTO from './components/CTO/CTO'
import Administrator from './components/Administrator/Administrator'
import Owner from './components/Owner/Owner'
import Accounting from './Accounting/Accounting'
import ITDashboard from './components/ITDashboard/ITDashboard'

const Dashboard = ({ showSystemMessage, role }) => {
    document.title = 'Dashboard'

    const auth = useAuth()
    const location = useLocation()

    let dashboard = <div>Undefined Role</div>

    const [manager, setManager] = useState(false)

    const viewRole = role ? role : auth.user.role

    useEffect(async () => {
        let urlParams = queryString.parse(location.search)
        let urlManagerId = urlParams.adminId ? urlParams.adminId : false
        let urlManager = false

        if (urlManagerId > 0) {
            // Get the manager's information
            let urlManagerResult = await callAPI(`admin/${urlManagerId}`, 'GET')
            urlManager = { id: urlManagerId, name: urlManagerResult.admin.full_name.split(' ')[0] }
        }

        // If there is an adminId in the urlparams, we need to use that instead of the logged in user.

        setManager(urlManager ? urlManager : auth.user)
    }, [location])

    switch (viewRole) {
        case 'CEO':
            dashboard = <CEO manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'CTO':
            dashboard = <CTO manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'ADMIN':
            dashboard = <Administrator manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'OWNER':
            dashboard = <Owner manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'AFFILIATE_DIRECTOR':
            dashboard = <AffiliateDirector manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'AM_JR_TEAMLEAD':
            dashboard = <JuniorAffiliateTeamLead manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'AM_SENIOR':
            dashboard = <SeniorAffiliateManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'AM_JUNIOR':
            dashboard = <JuniorAffiliateManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'ADVERTISER_DIRECTOR':
            dashboard = <AdvertiserDirector manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'MERMGR':
            dashboard = <AdvertiserManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'COMPLIANCE':
            dashboard = <ComplianceManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'COMPLIANCE_DIRECTOR':
            dashboard = <ComplianceDirector manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'MARKETING_MANAGER':
            dashboard = <MarketingManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'BIZDEV':
            dashboard = <BizdevManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'BIZDEV_DIRECTOR':
            dashboard = <BizdevManager manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'ADOPS_TEAMLEAD':
            dashboard = <AdopsTeamlead manager={manager} showSystemMessage={showSystemMessage} />
            break
        case 'ADOPS_MANAGER':
            dashboard = <AdopsManager manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'ACCOUNTING':
            dashboard = <Accounting manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
            break
        case 'IT':
            dashboard = <ITDashboard manager={manager} showSystemMessage={showSystemMessage} />
            break
        default:
            dashboard = <div>Undefined Role</div>
    }

    return manager ? (
        dashboard
    ) : (
        <div>
            <Loading />
        </div>
    )
}

export default Dashboard
