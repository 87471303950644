import { Card, Table, Modal } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import ReviewRequest from '../../../../components/ReviewRequest/ReviewRequest'
import Campaign from '../../../../templates/Campaign/Campaign'

const CampaignRequests = ({ manager, mode, showSystemMessage }) => {
    
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [role, setRole] = useState(false)
    const [requestId, setRequestId] = useState()
    const [affiliateId, setAffiliateId] = useState()
    const [offerId, setOfferId] = useState()
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [flow, setFlow] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(false)
    const [selectedRequestStatus, setSelectedRequestStatus] = useState()

    useEffect(() => {
        setDataLoaded(false)
        fetchCampaignRequests()
    }, [manager])

    const fetchCampaignRequests = async () => {
        let apiUrl = `campaignRequests/${manager.id}`
        if (mode === 'all') { apiUrl += `?mode=all` }
        const data = await callAPI(apiUrl, 'GET', null)
        if (data.success) {
            const result = data.campaign_requests.slice(0, 20)
            result.inQueue = data.campaign_requests.length
            setData(result)
            setRole(data.role)
        }
        setDataLoaded(true)
    }

    const handleReviewClick = (index, affiliateId, requestId, offerId, requestStatus, e) => {
        e.preventDefault()
        // selectedIndex is to set the current modal, so that we can go to the next one easily if flow mode is on
        setSelectedIndex(index)
        // Set the information for the modal
        setRequestId(requestId)
        setAffiliateId(affiliateId)
        setOfferId(offerId)
        setSelectedRequestStatus(requestStatus)
        setShowReviewModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowReviewModal(false)

        if (isEdited || flow) {
            fetchCampaignRequests()
        }
    }

    const handleCloseFlow = (isEdited = false) => {
        setShowReviewModal(false)

        if (flow) {
            //open the next one of the same campaign if Ad team, open next one if anything else
            let nextIndex =  (role === 'MERMGR' || role === 'ADOPS_MANAGER' || role === 'ADOPS_TEAMLEAD') ?
                                data.findIndex((request) => request.campaign.id === offerId && request.request_id !== requestId)
                            :
                                data.findIndex((request) => request.request_id !== requestId)

            if (nextIndex >= 0){
                const nextItemInList = data[nextIndex]
                setSelectedIndex(nextIndex)
                setRequestId(nextItemInList.request_id)
                setAffiliateId(nextItemInList.affiliate.id)
                setOfferId(nextItemInList.campaign.id)
                setShowReviewModal(true)
                fetchCampaignRequests()
            } else {
                setShowReviewModal(false)
                fetchCampaignRequests()
            }

        }
    }

    return (
        <Card className="mb-3">
            <Modal show={showReviewModal} onHide={handleClose} size="xl">
                <ReviewRequest affiliateId={affiliateId} requestId={requestId} campaignId={offerId} handleClose={handleClose} handleCloseFlow={handleCloseFlow} flow={flow} showSystemMessage={showSystemMessage} requestStatus={selectedRequestStatus} />
            </Modal>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Campaign Requests</span>
                </span>
                <span className="d-flex align-items-center">
                    <div className="alert alert-secondary m-0 py-0 px-1 mr-2">
                        <div className="custom-control custom-switch">                            
                            <input type="checkbox" className="custom-control-input" id="filterSwitch" onClick={() => setFlow(!flow)} defaultChecked={flow} />
                            <label className="custom-control-label" htmlFor="filterSwitch" data-tip="If flow mode is on, processing a request will automatically open the next relevant request.">
                                Flow Mode
                            </label>                            
                        </div>
                    </div>
                    <div>All Requests <Link to={`/campaigns/approvalRequests?adminId=${mode === 'all' ? 0 : manager.id}`}>{data.inQueue}</Link></div>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0 nowrap">
                    <thead>
                        <tr className="text-secondary">
                            <th width="9%">ID</th>
                            <th width="31%">Affiliate</th>
                            <th width="10%">Priority</th>
                            <th width="40%">Campaign</th>
                            <th width="10%">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.map((item, index) => {
                                            let affPriority = ''
                                            switch (item.affiliate.priority) {
                                                case 1:
                                                    affPriority = 'High'
                                                    break
                                                case 2:
                                                    affPriority = 'Medium'
                                                    break
                                                case 3:
                                                    affPriority = 'New'
                                                    break
                                                case 4:
                                                    affPriority = 'Low'
                                                    break
                                                case 5:
                                                    affPriority = 'Very Low'
                                                    break
                                                default:
                                                    affPriority = 'Unknown'
                                                    break
                                            }
                                            return (
                                                <tr key={`${item.campaign.id}-${index}`}>
                                                    <td xs={3}>{item.affiliate.id}</td>
                                                    <td xs={6}>
                                                        <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} status={`${item.affiliate.status}`} priority={`${item.affiliate.priority}`} />
                                                    </td>
                                                    <td xs={6}>
                                                        <span className="text-dark">{affPriority}</span>
                                                    </td>
                                                    <td xs={3}>
                                                        <Campaign oid={item.campaign.id} name={item.campaign.name} status={item.campaign.status} />
                                                    </td>
                                                    <td xs={3}>
                                                        <a href={`/campaigns/approvalRequests/${item.request_id}?affiliateId=${item.affiliate.id}&campaignId=${item.campaign.id}`} onClick={(e) => handleReviewClick(index, item.affiliate.id, item.request_id, item.campaign.id, item.status, e)}>
                                                            {item.status}
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default CampaignRequests
