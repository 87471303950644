import { useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'

const HostAndPost = ({ isOpen, setIsOpen, campaignId, landingPages, campaignData, dataLoaded, showSystemMessage, refetch }) => {
    const [data, setData] = useState(landingPages[0])

    const generateRow = (string) => {
        //if string starts with 'sub'
        let label = string
        if (string.startsWith('sub')) {
            label += '_label'
        }
        if (dataLoaded && landingPages[0]) {
            return (
                <tr>
                    <td>{string}</td>
                    <td>
                        <Form.Control type="text" placeholder="Advertiser label" value={landingPages[0] ? data[label] : ''} onChange={(e) => setData({ ...data, [label]: e.target.value })} />
                    </td>
                    <td>
                        <Form.Control type="text" placeholder="Description" value={landingPages[0] ? data[`${string}_descr`] : ''} onChange={(e) => setData({ ...data, [`${string}_descr`]: e.target.value })} />
                    </td>
                    <td>
                        <Form.Control type="text" placeholder="Hardcoded value" value={landingPages[0] ? data[`${string}_value`] : ''} onChange={(e) => setData({ ...data, [`${string}_value`]: e.target.value })} />
                    </td>
                    <td>
                        <Form.Control type="text" placeholder="Example" value={landingPages[0] ? data[`${string}_example`] : ''} onChange={(e) => setData({ ...data, [`${string}_example`]: e.target.value })} />
                    </td>
                </tr>
            )
        }
    }

    const save = async () => {
        const tempData = data
        //replace all null values with empty strings
        for (const [key, value] of Object.entries(landingPages[0])) {
            if (value === null) {
                console.log(tempData[key], key)
                tempData[key] = ''
            }
        }        
        setData(tempData)
        const result = await callAPI(`campaign/${campaignId}/landingPages/hp`, 'POST', data)
        if (result.success) {
            showSystemMessage('success', 'Updated settings successfully')
            // refetch()
            //setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Post settings for {campaignData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>MaxBounty Label</th>
                            <th>Advertiser Label</th>
                            <th>Description</th>
                            <th>Hardcoded Value</th>
                            <th>Example</th>
                        </tr>
                    </thead>
                    <tbody>
                        {generateRow('name')}
                        {generateRow('first_name')}
                        {generateRow('last_name')}
                        {generateRow('phone')}
                        {generateRow('e_mail')}
                        {generateRow('address1')}
                        {generateRow('address2')}
                        {generateRow('city')}
                        {generateRow('country_code')}
                        {generateRow('state')}
                        {generateRow('zipcode')}
                        {generateRow('remote_host')}
                        {generateRow('sub1')}
                        {generateRow('sub2')}
                        {generateRow('sub3')}
                        {generateRow('sub4')}
                        {generateRow('sub5')}
                        {generateRow('sub6')}
                        {generateRow('sub7')}
                        {generateRow('sub8')}
                        {generateRow('sub9')}
                        {generateRow('sub10')}
                        {generateRow('sub11')}
                        {generateRow('sub12')}
                        {generateRow('sub13')}
                        {generateRow('sub14')}
                        {generateRow('sub15')}
                        {generateRow('sub16')}
                        {generateRow('sub17')}
                        {generateRow('sub18')}
                        {generateRow('sub19')}
                        {generateRow('sub20')}
                        {generateRow('sub21')}
                        {generateRow('sub22')}
                        {generateRow('sub23')}
                        {generateRow('sub24')}
                        {generateRow('sub25')}
                        {generateRow('sub26')}
                        {generateRow('sub27')}
                        {generateRow('sub28')}
                        {generateRow('sub29')}
                        {generateRow('sub30')}
                        {generateRow('sub31')}
                        {generateRow('sub32')}
                        {generateRow('sub33')}
                        {generateRow('sub34')}
                        {generateRow('sub35')}
                        {generateRow('sub36')}
                        {generateRow('sub37')}
                        {generateRow('sub38')}
                        {generateRow('sub39')}
                        {generateRow('sub40')}
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="border-0">
                                <div className="font-weight-bold">Available Macros (Use in hardcoded values):</div>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr align="left" width="100%">
                                            <td>Click ID</td>
                                            <td>#ACID#</td>
                                        </tr>
                                        <tr align="left" width="100%">
                                            <td>Affiliate ID</td>
                                            <td>#MBID#</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="sm" onClick={save}>
                    Save
                </Button>
            </Modal.Footer>
        </>
    )
}

export default HostAndPost
