import React, { Component } from 'react'
import QuestionDisclaimer from '../templates/QuestionDisclaimer'
import RedirectMacros from '../templates/RedirectMacros'

class Redirect extends Component {
    state = {}

    render() {
        let { question, disclaimer, placeholder, order, questionId, callToAction, negCallToAction } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Redirect</div>
                <h3>
                    <textarea maxLength={600} cols="50" className="form-control d-inline-block" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-3">
                    <input className="form-control" type="text" defaultValue={placeholder} placeholder="Add URL Here." onChange={this.props.handleChangePlaceholder(questionId)} />
                    <div className="m-3 text-center">
                        <input className="form-control mb-button-input-text" type="text" defaultValue={callToAction} placeholder="Custom Call to Action" onChange={this.props.handleChangeCallToAction(questionId)} />
                    </div>
                    <div className="m-3 text-center">
                        <input className="form-control text-center" type="text" defaultValue={negCallToAction} placeholder="No Thanks!" onChange={this.props.handleChangeNegCallToAction(questionId)} />
                    </div>
                    <QuestionDisclaimer disclaimer={disclaimer} handleChangeDisclaimer={this.props.handleChangeDisclaimer} questionId={questionId} />
                    <RedirectMacros />
                </div>
            </div>
        )
    }
}

export default Redirect
