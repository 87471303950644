import React from 'react'
import useCallApi from '../../hooks/useCallApi'

export default function AdminRolePicker({ isCreate, role, formData, setFormData, parentData, setParentData, required, disabled }) {
    const { dataLoaded, data, refetch, setData } = useCallApi(`admins/roles?status=A`, 'GET', {}, [])

    return (
        <>
            <select className="form-control" value={role} onChange={(e) => (isCreate ? setFormData({ ...formData, role_id: e.target.value }) : setParentData({ ...parentData, admin: { ...parentData.admin, role_id: e.target.value } }))} required={required ? required : false} disabled={disabled ? disabled : false}>
                <option value="">Not Set</option>
                {dataLoaded &&
                    data.roles.map((role) => {
                        return <option value={role.id} key={role.id}>{role.role}</option>
                    })}
            </select>
        </>
    )
}
