import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'

export default function MarketingPlatformModal({ showSystemMessage, setShowModal, refetch }) {
    const [name, setName] = useState('')

    const submitForm = (e) => {
        e.preventDefault()

        callAPI('marketing/platform', 'POST', { name: name })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Marketing platform saved.')
                    refetch()
                    setShowModal(false)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while saving marketing platform: ${error.message}`)
                showSystemMessage('error', 'Error saving marketing platform.')
            })
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Create Marketing Platform</Modal.Title>
            </Modal.Header>
            <div className="card">
                <div className="card-body">
                    <form
                        onSubmit={(e) => {
                            submitForm(e)
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <div>Name:</div>
                            <div className="w-100 ml-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-right mt-3">
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
