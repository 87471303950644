import moment from 'moment'
import React from 'react'
import { Card, Table } from 'react-bootstrap'
import useCallApi from '../../hooks/useCallApi'
import Campaign from '../../templates/Campaign/Campaign'
import Dollars from '../../templates/Dollars/Dollars'
import DownloadCSV from '../../templates/DownloadCSV/DownloadCSV'
import Loading from '../../templates/UI/Loading/Loading'

const TransactionDetailsContent = ({ showSystemMessage, transactionId }) => {
    const { data, dataLoaded } = useCallApi(`affiliate/transaction/details?transactionId=${transactionId}`, 'GET', {}, [transactionId], showSystemMessage)

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between">
                <div>Transaction History</div>
                <div>{dataLoaded && data.success && transactionId && <DownloadCSV filename={`Transaction Details - ID: ${transactionId}`} headerLabels={['Lead ID', 'Lead Date', 'Offer ID', 'Offer Name', 'Network Rate', 'Affiliate Rate', 'Subid1', 'Subid2']} data={data.transactionDetails} dataLabels={['lead_id', 'db_date', 'offer_id', 'offer_name', 'network_rate', 'affiliate_rate', 'subcode1', 'subcode2']} />}</div>
            </Card.Header>
            <Card.Body>
                <Table striped hover className="m-0">
                    <thead>
                        <tr>
                            <td>Lead ID</td>
                            <td>Lead Date</td>
                            <td>Offer ID</td>
                            <td>Offer Name</td>
                            <td>Network Rate</td>
                            <td>Affiliate Rate</td>
                            <td>Subcode1</td>
                            <td>Subcode2</td>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {data.transactionDetails?.length === 0 ? (
                                    <tr>
                                        <td colSpan={8}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.transactionDetails?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.lead_id}</td>
                                                    <td>{moment(item.db_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                    <td>{item.offer_id}</td>
                                                    <td>
                                                        <Campaign oid={item.offer_id} name={item.offer_name} />
                                                    </td>
                                                    <td>
                                                        <Dollars value={item.network_rate} />
                                                    </td>
                                                    <td>
                                                        <Dollars value={item.affiliate_rate} />
                                                    </td>
                                                    <td>{item.subcode1}</td>
                                                    <td>{item.subcode2}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={5} align="right">
                                                <strong>Total:</strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    <Dollars value={data.transactionDetails.reduce((a, b) => a + (b['affiliate_rate'] || 0), 0)} />
                                                </strong>
                                            </td>
                                            <td colSpan={2}></td>
                                        </tr>
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={8} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default TransactionDetailsContent
