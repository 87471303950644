import useCallApi from '../../../../hooks/useCallApi'
import moment from 'moment'
import Loading from '../../../../templates/UI/Loading/Loading'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function RejectedMarketingApplicationsSummary(showSystemMessage) {
    const { data, dataLoaded, setDataLoaded, refetch } = useCallApi(`affiliates/rejectedMarketingApps?startDate=${moment().add(-30, 'days').format('YYYY-MM-DD')}&endDate=${moment().add(1, 'days').format('YYYY-MM-DD')}`, 'GET', {}, [], showSystemMessage)

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Rejected Marketing Applications (last 30 days)</div>
                <Link to={'/affiliates/rejectedMarketingApps'}>View Report</Link>
            </div>
            <div className="table-container-scroll">
                {dataLoaded ? (
                    <table className="table hover striped mb-0 pb-0" style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Affiliate</th>
                                <th>Reg Date</th>
                                <th width="150px">Last Followup</th>
                                <th width="200px">Experience</th>
                            </tr>
                        </thead>
                        <tbody className="m-0">
                            {data.rejectedMarketingApps.length > 0 ? (
                                data.rejectedMarketingApps.map((app) => {
                                    return (
                                        <tr>
                                            <td>{app.affiliate_id}</td>
                                            <td>
                                                <Affiliate aid={app.affiliate_id} status={app.aff_status} name={app.name} />
                                            </td>
                                            <td>{moment(app.registration_date).format('YYYY-MM-DD')}</td>
                                            <td>{app.last_followup_note}</td>
                                            <td style={{ wordWrap: 'break-word' }}>{app.experience_description}</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>No records.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}
