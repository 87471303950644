import React, { useState } from 'react'
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table'
import './TableContainer.css'

import { Row, Col, Button, Input, CustomInput } from 'reactstrap'

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center">
            <div className="mr-2">Filter:</div>
            <input
                className="form-control"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder={`${count} records...`}
            />
        </div>
    )
}

export default function TableContainer({ data, columns, totals, getRowProps = () => ({}) }) {
    let {
        getTableProps,
        getTableBodyProps,
        headers,
        //rows,
        page,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            totals,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 20,
                hiddenColumns: columns.map((column) => (column.hide ? column.accessor : column.id)),
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    // Also have to hide headers. Not sure why the above initialState hiddenColumns wouldn't hide the header, but this did the trick.
    headers = headers.filter((header) => header.hide !== true)

    return (
        <>
            <table className="table table-striped table-card table-hover mb-0 text-small" {...getTableProps()}>
                <thead>
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        </th>
                    </tr>
                    <tr>
                        {headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <span className="float-right">{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* {rows.map((row, i) => { */}
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
                {totals && (
                    <tfoot>
                        <tr className="font-weight-bold">
                            <td>Totals</td>
                            {totals.map((total) => {
                                if (total.type === 'number' || total.type === 'int') {
                                    return <td align="right">{Intl.NumberFormat('en-GB').format(total.value)}</td>
                                } else if (total.type === 'dollar' || total.type === 'dollars') {
                                    return <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(total.value)}</td>
                                } else {
                                    return <td align="right">{total.value}</td>
                                }
                            })}
                        </tr>
                    </tfoot>
                )}
            </table>

            {/* // Pagination CSS */}
            <Row className="my-3" style={{ width: 1000, maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
                <Col md={3}>
                    <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="mx-1">
                        {'<<'}
                    </Button>
                    <Button color="primary" onClick={previousPage} disabled={!canPreviousPage} className="mx-1">
                        {'<'}
                    </Button>
                </Col>
                <Col md={2} style={{ marginTop: 7 }}>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Col>
                <Col md={2}>
                    <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                </Col>
                <Col md={2}>
                    <CustomInput type="select" id={pageSize} value={pageSize} onChange={onChangeInSelect}>
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </CustomInput>
                </Col>
                <Col md={3}>
                    <Button color="primary" onClick={nextPage} disabled={!canNextPage} className="mx-1">
                        {'>'}
                    </Button>
                    <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="mx-1">
                        {'>>'}
                    </Button>
                </Col>
            </Row>
        </>
    )
}
