import React, { Component } from 'react'
import callAPI from '../../../../../helpers/callAPI'

class YearSelect extends Component {
    state = {
        dataLoaded: false,
        years: [],
    }

    componentDidMount() {
        const fetchData = async () => {
            // Fetch the years
            const result = callAPI(`vehicleInfo/getYears`, 'GET')
            if (result['success'] === true) {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    years: result.years,
                })
            } else if (result['success'] === false) {
                console.log('Error fetching vehicle years.')
            } else {
                console.log('Error fetching vehicle years.')
            }
        }
        fetchData()
    }

    render() {
        return this.state.dataLoaded ? (
            <select className="form-control" onChange={(e) => this.props.handleChangeYear(e)}>
                <option></option>
                {this.state.years.map((year) => {
                    return <option value={year}>{year}</option>
                })}
            </select>
        ) : (
            <select className="form-control">
                <option> - </option>
            </select>
        )
    }
}

export default YearSelect
