import React, { useEffect, useState } from 'react'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../helpers/callAPI'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'
import { colors } from '../../../../theme/variables'
import EditPopularCampaign from './EditPopularCampaign/EditPopularCampaign'

const PopularCampaigns = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [campaigns, setCampaigns] = useState([])
    const [showCampaignModal, setShowCampaignModal] = useState(false)
    const [campaignId, setCampaignId] = useState()

    const { showSystemMessage } = props

    useEffect(() => {
        fetchCampaigns()
    }, [])

    const fetchCampaigns = async () => {
        setDataLoaded(false)

        let url = `campaigns/popular`

        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', 'There was an error while fetching popular campaigns. Please try again.')
        }

        setCampaigns(result.campaigns)
        setDataLoaded(true)
    }

    const handleClose = (isEdited = false) => {
        setShowCampaignModal(false)

        if (isEdited) {
            fetchCampaigns()
        }
    }

    const handleClick = (campaignId) => {
        setCampaignId(campaignId)
        setShowCampaignModal(true)
    }

    return (
        <div className="card mb-3">
            <Modal show={showCampaignModal} size="md" onHide={handleClose}>
                <EditPopularCampaign campaignId={campaignId} showSystemMessage={showSystemMessage} handleClose={handleClose} />
            </Modal>
            <div className="card-header">
                <div className="font-weight-bold float-left">Popular Campaigns</div>
                <div className="float-right">
                    <button type="button" className="btn btn-sm btn-warning" onClick={() => handleClick(0)}>
                        Add Campaign
                    </button>
                </div>
            </div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    campaigns.length === 0 ? (
                        <div className="p-2 border-top">No stats available </div>
                    ) : (
                        <table className="table table-striped table-card m-0">
                            <thead>
                                <tr className="text-secondary">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Active</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaigns.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.campaign.id}</td>
                                            <td>
                                                <Campaign oid={item.campaign.id} name={item.campaign.name} />
                                            </td>
                                            <td className="text-center">
                                                {
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={item.status === 'Active' ? colors.success : colors.danger} className="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg>
                                                }
                                            </td>
                                            <td>
                                                <button style={{ color: 'orange', marginRight: '15px' }} className="btn btn-sm btn-lnk" onClick={() => handleClick(item.campaign.id)}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default PopularCampaigns
