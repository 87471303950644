import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import Affiliate from '../../templates/Affiliate/Affiliate'
import useCallApi from '../../hooks/useCallApi'
import callAPI from '../../helpers/callAPI'
import Loading from '../../templates/UI/Loading/Loading'
import moment from 'moment'
import PaymentDelayOverridesModal from './PaymentDelayOverridesModal/PaymentDelayOverridesModal'
import { Form } from 'react-bootstrap'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Campaign from '../../templates/Campaign/Campaign'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'

const PaymentDelayOverrides = ({ affiliateId, campaignId, showSystemMessage }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [focusId, setFocusId] = useState(0)

    let apiUrl = affiliateId ? `affiliate/${affiliateId}/paymentDelayOverrides` : `campaign/${campaignId}/paymentDelayOverrides`

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [affiliateId, campaignId])

    document.title = `${dataLoaded ? `${affiliateId ? data.affiliate.name : data.campaign.name}: ` : ''}Payment Delay Overrides`

    const updatepaymentDelay = async (index) => {
        const res = await callAPI(apiUrl, 'PUT', { ...data.payment_delay_list[index] })
        if (res.success) {
            showSystemMessage('success', 'Payment Delay Updated')
            refetch()
        } else {
            showSystemMessage('error', res.message)
        }
    }

    const deletepaymentDelay = async (index) => {
        const res = await callAPI(apiUrl, 'DELETE', { ...data.payment_delay_list[index] })
        if (res.success) {
            showSystemMessage('success', 'Payment Delay Deleted')
            refetch()
        } else {
            showSystemMessage('error', res.message)
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Manager',
                accessor: 'admin.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Override Date',
                accessor: 'db_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('MM/DD/YYYY')}</>
                },
            },
            {
                Header: 'Payment Delay',
                accessor: 'payment_delay',
                Cell: (tableData) => {
                    return (
                        <>
                            <Form.Control
                                size="sm"
                                type="number"
                                defaultValue={tableData.data[tableData.row.index].payment_delay}
                                autoFocus={(tableData.data[tableData.row.index].affiliate ? tableData.data[tableData.row.index].affiliate.id : tableData.data[tableData.row.index].campaign.id) === focusId ? true : false}
                                onChange={(e) => {
                                    setFocusId(tableData.data[tableData.row.index].affiliate ? tableData.data[tableData.row.index].affiliate.id : tableData.data[tableData.row.index].campaign.id)
                                    setData({ ...data, payment_delay_list: data.payment_delay_list.map((item, i) => (i === tableData.row.index ? { ...item, payment_delay: e.target.value } : item)) })
                                }}
                            />
                        </>
                    )
                },
            },
            {
                Header: 'Mode',
                accessor: 'payment_delay_mode',
                Cell: (tableData) => {
                    return (
                        <>
                            <Form.Control
                                as="select"
                                size="sm"
                                className="ml-2"
                                value={tableData.data[tableData.row.index].payment_delay_mode === 'LEAD_DATE' ? 'LEAD_DATE' : 'end of month'}
                                onChange={(e) => {
                                    setData({ ...data, payment_delay_list: data.payment_delay_list.map((item, i) => (i === tableData.row.index ? { ...item, payment_delay_mode: e.target.value } : item)) })
                                }}
                            >
                                <option value="end of month">From End of Month</option>
                                <option value="LEAD_DATE">From Lead Date</option>
                            </Form.Control>
                        </>
                    )
                },
            },
            {
                Header: ' ',
                Cell: (tableData) => {
                    return (
                        <>
                            <Button
                                size="sm"
                                className="ml-2"
                                onClick={(e) => {
                                    updatepaymentDelay(tableData.row.index)
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                size="sm"
                                className="ml-2"
                                variant="danger"
                                onClick={(e) => {
                                    deletepaymentDelay(tableData.row.index)
                                }}
                            >
                                Delete
                            </Button>
                        </>
                    )
                },
            },
        ]

        if (affiliateId) {
            columns.unshift(
                {
                    Header: 'ID',
                    accessor: 'campaign.id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    }, //,
                },
                {
                    Header: 'Campaign',
                    accessor: 'campaign.name',
                    Cell: (tableData) => {
                        return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                    },
                },
                {
                    Header: 'Campaign Status',
                    accessor: 'campaign.status.name',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                }
            )
        }

        if (campaignId) {
            columns.unshift(
                {
                    Header: 'ID',
                    accessor: 'affiliate.id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    }, //,
                },
                {
                    Header: 'Affiliate',
                    accessor: 'affiliate.name',
                    Cell: (tableData) => {
                        return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status.value} priority={tableData.data[tableData.row.index].affiliate.priority.value} />
                    },
                },
                {
                    Header: 'Affiliate Status',
                    accessor: 'affiliate.status.name',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                }
            )
        }

        return (
            <React.Fragment>
                <ReportTable data={data.payment_delay_list} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Payment Delay Overrides</h1>
                <div className="ml-5">
                    {affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : null}
                    {campaignId ? <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} /> : null}
                </div>
            </div>
            <Card>
                <PaymentDelayOverridesModal refetch={refetch} dataLoaded={dataLoaded} affiliate={data.affiliate ? data.affiliate : null} campaign={data.campaign ? data.campaign : null} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} />
                <Card.Body className="p-0">
                    <div className="d-flex justify-content-between p-3 align-items-center">
                        <span className="mb-h1-dark mr-2">{dataLoaded && data.affiliate ? <Affiliate aid={data.affiliate.id} name={data.affiliate.name} priority={data.affiliate.priority ? data.affiliate.priority.value : 0} status={data.affiliate.status ? data.affiliate.status.value : 'A'} /> : dataLoaded && data.campaign ? <Campaign oid={data.campaign.id} name={data.campaign.name} status={data.campaign.status.value} /> : <Loading />}</span>
                        <button onClick={() => setIsOpen(!isOpen)} className="btn btn-sm btn-warning">
                            Add Override
                        </button>
                    </div>
                    {dataLoaded ? data.payment_delay_list.length === 0 ? <div className="p-3">No results.</div> : getReportOutput() : <Loading />}
                </Card.Body>
            </Card>
        </div>
    )
}

export default PaymentDelayOverrides
