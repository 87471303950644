import { useState } from 'react'

const AdvertiserNotes = ({ notes, setIsDisabled }) => {
    return (
        <div className="card">
            <div className="card-header d-flex align-items-center">
                <div>Notes</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="20%" className="border-right text-right">
                                Notes
                            </td>
                            <td className="border-right" colSpan={3}>
                                <textarea name="notes" cols={80} rows={8} className="form-control" defaultValue={notes} onChange={() => setIsDisabled(false)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdvertiserNotes
