import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import AffiliateSearch from '../../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../../CampaignSearch/CampaignSearch'

const AddOverride = ({ affiliateId, campaignId, aff, campaign, showSystemMessage, handleClose, isOpen }) => {
    const [offer, setOffer] = useState({ id: -1, name: '' })
    const [affiliate, setAffiliate] = useState({ id: -1, name: '' })

    const addOverride = (e) => {
        e.preventDefault()

        const postBody = {
            override_status: e.target.override_status.value,
            item_id: affiliateId ? offer.id : affiliate.id,
        }

        let url = affiliateId ? `affiliate/${affiliateId}/addOverride` : `campaign/${campaignId}/addOverride`

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => showSystemMessage('error', 'Error while adding campaign override'))
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            {isOpen && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Campaign Override for: {affiliateId ? <Affiliate aid={affiliateId} name={aff.name} status={aff.status.value} priority={aff.priority.value} /> : <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} />}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="card"> */}
                        <form onSubmit={addOverride} method="POST" className="form-group">
                            <table className="table table-card table-borderless w-100 m-0">
                                <tbody>
                                    <tr className="align-items-center">
                                        <td width="30%">{affiliateId ? 'Campaign:' : 'Affiliate:'}</td>
                                        <td className="pt-3 align-items-center">{affiliateId ? <CampaignSearch setCampaign={setOffer} /> : <AffiliateSearch setAffiliate={setAffiliate} />}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>
                                            <select name="override_status" defaultValue="A" className="form-control">
                                                <option value="A">Approved</option>
                                                <option value="R">Rejected</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <button type="submit" name="submit" className="btn btn-primary">
                                                Create
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </Modal.Body>
                </>
            )}
        </Modal>
    )
}

export default AddOverride
