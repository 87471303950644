import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../helpers/callAPI'
import Advertiser from '../../../templates/Advertiser/Advertiser'

import Loading from '../../../templates/UI/Loading/Loading'
import ReportsMore from '../ReportsMore/ReportsMore'

import TableContainer from '../TableContainer'

class ReportMerchantMargin extends Component {
    state = {
        dataLoaded: true,
        report: [],
        totals: {},
    }

    componentDidMount() {
        this._isMounted = true

        this.setState({
            dataLoaded: false,
        })
        const fetchData = async () => {
            /* Fetch Affiliate Applications Stats from DB and populate the reports table and charts */

            let url = `reports/merchants/margin?startDate=${this.props.startDate}&endDate=${this.props.endDate}`

            if (this.props.mgrid > 0) {
                url = url + `&mgrid=${this.props.mgrid}`
            }

            if (this.props.oid > 0) {
                url = url + `&oid=${this.props.oid}`
            }
            const result = await callAPI(url, 'GET')

            if (result['success'] === true) {
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true,
                        report: result.report,
                        totals: result.totals,
                    })
                }
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    this.props.showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }

    getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    reportType="merchant margin"
                    data={this.state.report}
                    totals={this.state.totals}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'merchant_id',
                            width: 80,
                        },
                        {
                            Header: 'Merchant',
                            accessor: 'name',
                            Cell: ({ cell: { value } }) => {
                                return <>{<Advertiser name={value} status="highlight" />}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Network Earnings</div>,
                            accessor: 'network_earnings',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate Earnings</div>,
                            accessor: 'affiliate_earnings',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin</div>,
                            accessor: 'margin',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin %</div>,
                            accessor: 'pct_margin',
                            className: 'text-right',
                            // To customize rendering according to the row object using other fields from the original object
                            //   Cell: ({ row }) => {
                            //       //console.log(row.original.pct_margin)
                            //       return (<>{parseFloat(row.original.margin ? (row.original.margin/row.original.network_earnings)*100 : 0).toFixed(2)}%</>);
                            Cell: ({ cell: { value } }) => {
                                return <>{(parseFloat(value) * 100).toFixed(2)}%</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'reports_more',
                            className: 'text-right',
                            Cell: ({ row }) => {
                                return <>{this.props.oid ? <ReportsMore subids creatives landingPages refClicks aid={row.original.publisher_id} oid={this.props.oid} startDate={this.props.startDate} endDate={this.props.endDate} /> : <ReportsMore campaigns aid={row.original.publisher_id} startDate={this.props.startDate} endDate={this.props.endDate} />}</>
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <hr className="mt-0 mb-3"></hr>
                <div className="d-flex mx-2"></div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.report.length === 0 ? (
                            <table className="table table-striped table-card table-hover mb-0">
                                <tbody>
                                    <tr>
                                        <td colSpan="7">No stats for this date range.</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <React.Fragment>{this.getReportOutput()}</React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <table className="table table-striped table-card table-hover mb-0">
                        <tbody>
                            <tr>
                                <td colSpan="7">
                                    <Loading />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </React.Fragment>
        )
    }
}

export default ReportMerchantMargin
