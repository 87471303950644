import { Form } from 'react-bootstrap'

const TimeScalePicker = ({ timeScale, setTimeScale, options }) => {
    return (
        <Form.Control className="w-70" as="select" size="sm" onChange={(event) => setTimeScale(event.target.value)} defaultValue={timeScale}>
            {options.map((time, index) => {
                return (
                    <option key={index} value={time}>
                        {time}
                    </option>
                )
            })}
        </Form.Control>
    )
}
export default TimeScalePicker
