import React, { useCallback, useState } from 'react'
import { Button, Card, Form, Modal, Table } from 'react-bootstrap'
import callApi from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import Loading from '../../templates/UI/Loading/Loading'
import ReportTable from '../../templates/ReportTable/ReportTable'

import getDecimalNumber from '../../helpers/getDecimalNumber'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import SpecialRatesModal from '../SpecialRatesModal/SpecialRatesModal'
import { useAuth, useProtectedPage } from '../../context/AuthContext'
import { useRef } from 'react'

const SpecialRates = ({ affiliateId, campaignId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'special_rates')

    const affiliateRateRef = useRef([])
    const networkRateRef = useRef([])
    const displayRateRef = useRef([])
    const displayRateTypeRef = useRef([])
    const overrideUrlRef = useRef([])
    const hideSaleAmountRef = useRef([])

    const [isOpen, setIsOpen] = useState(false)

    const { dataLoaded, data, setData, refetch } = useCallApi(affiliateId ? `affiliate/${affiliateId}/specialRates` : `campaign/${campaignId}/specialRates`, 'GET', {}, [affiliateId, campaignId])

    if (dataLoaded && affiliateId) {
        document.title = `${data.affiliate.name}: Special Rates`
    } else if (dataLoaded && campaignId) {
        document.title = `${data.campaign.name}: Special Rates`
    } else {
        document.title = `Special Rates`
    }

    const updateRate = useCallback(
        async (index, affiliateId, campaignId, rateName) => {
            let body = {
                affiliateId: affiliateId,
                campaignId: campaignId,
                rateName: rateName,
                affiliateRate: affiliateRateRef.current[index].value,
                networkRate: networkRateRef.current[index].value,
                displayRate: displayRateRef.current[index].value,
                displayRateType: displayRateTypeRef.current[index].value,
                hideSaleAmount: hideSaleAmountRef.current[index].checked,
                overrideUrl: overrideUrlRef.current[index].value,
            }

            const res = await callApi(affiliateId ? `affiliate/${affiliateId}/specialRates` : `campaign/${campaignId}/specialRates`, 'PUT', body)
            if (res.success) {
                showSystemMessage('success', 'Rate updated.')
                refetch()
            } else {
                showSystemMessage('error', res.errors.message)
            }
        },
        [affiliateId, campaignId, refetch, showSystemMessage, data]
    )

    const deleteRate = useCallback(
        async (index) => {
            const form = { ...data.rates[index], affiliate: data.affiliate, campaign: data.campaign }
            const res = await callApi(affiliateId ? `affiliate/${affiliateId}/specialRates` : `campaign/${campaignId}/specialRates`, 'DELETE', {
                form,
            })
            if (res.success) {
                showSystemMessage('success', 'Rate deleted.')
                refetch()
            } else {
                showSystemMessage('error', res.message)
            }
        },
        [affiliateId, campaignId, refetch, showSystemMessage, data]
    )

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: affiliateId ? 'offer.name' : 'affiliate.name',
                Cell: (tableData) => {
                    return <>{affiliateId ? <Campaign oid={tableData.data[tableData.row.index].offer.id} name={tableData.data[tableData.row.index].offer.name} status={tableData.data[tableData.row.index].offer.status} /> : <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.priority} />}</>
                },
            },
            {
                Header: 'Status',
                accessor: affiliateId ? 'offer.status' : 'affiliate.status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Manager',
                accessor: 'manager.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Rate Name',
                accessor: 'rate.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Aff Rate',
                accessor: 'rate.spc_aff_rate',
                Cell: (tableData) => {
                    let spcAffRate = tableData.data[tableData.row.index].rate.spc_aff_rate
                    return (
                        <div className="d-flex align-items-center">
                            <Form.Control type="text" size="sm" ref={(el) => (affiliateRateRef.current[tableData.row.index] = el)} className="w-50" defaultValue={(spcAffRate && spcAffRate > 0) ? spcAffRate : ''} />
                            &nbsp;({tableData.data[tableData.row.index].rate.offer_type === 'F' ? `$${getDecimalNumber(tableData.data[tableData.row.index].rate.off_aff_rate)}` : `${getDecimalNumber(tableData.data[tableData.row.index].rate.off_aff_rate)}%`})
                        </div>
                    )
                },
            },
            {
                Header: 'Net Rate',
                accessor: 'rate.spc_net_rate',
                Cell: (tableData) => {
                    let spcNetRate = tableData.data[tableData.row.index].rate.spc_net_rate
                    return (
                        <div className="d-flex align-items-center">
                            <Form.Control type="text" size="sm" className="w-50" ref={(el) => (networkRateRef.current[tableData.row.index] = el)} defaultValue={(spcNetRate && spcNetRate >= 0) ? spcNetRate : ''} />
                            &nbsp;(
                            {tableData.data[tableData.row.index].rate.offer_type === 'F' ? `$${getDecimalNumber(tableData.data[tableData.row.index].rate.off_net_rate)}` : `${getDecimalNumber(tableData.data[tableData.row.index].rate.off_net_rate)}%`})
                        </div>
                    )
                },
            },
            {
                Header: 'Display Rate',
                accessor: 'rate.display_rate',
                Cell: (tableData) => {
                    let displayRate = tableData.data[tableData.row.index].rate.display_rate
                    return (
                        <div className="d-flex align-items-center">
                            <Form.Control type="text" size="sm" className="w-50" ref={(el) => (displayRateRef.current[tableData.row.index] = el)} defaultValue={displayRate ? displayRate : ''} />
                        </div>
                    )
                },
            },
            {
                Header: 'Display Rate Type',
                accessor: 'rate.display_rate_type',
                Cell: (tableData) => {
                    let displayRateType = tableData.data[tableData.row.index].rate.display_rate_type
                    return (
                        <Form.Control as="select" ref={(el) => (displayRateTypeRef.current[tableData.row.index] = el)} defaultValue={displayRateType ? displayRateType : ''}>
                            <option value=""></option>
                            <option value="F">$ per lead</option>
                            <option value="P">% of sale</option>
                        </Form.Control>
                    )
                },
            },
            {
                Header: <div style={{ textDecoration: 'line-through' }}>Sale</div>,
                accessor: 'rate.hide_sale_amount',
                Cell: (tableData) => {
                    let hideSaleAmount = tableData.data[tableData.row.index].rate.hide_sale_amount

                    return <Form.Check type="checkbox" ref={(el) => (hideSaleAmountRef.current[tableData.row.index] = el)} defaultChecked={hideSaleAmount} />
                },
            },
            {
                Header: 'Override URL',
                accessor: 'rate.override_url',
                Cell: (tableData) => {
                    let overrideUrl = tableData.data[tableData.row.index].override_url
                    return <Form.Control type="text" size="sm" ref={(el) => (overrideUrlRef.current[tableData.row.index] = el)} defaultValue={overrideUrl || ''} />
                },
            },
            {
                Header: '',
                accessor: 'rate',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex">
                            <Button className="m-1" size="sm" onClick={() => updateRate(tableData.row.index, affiliateId ? affiliateId : tableData.data[tableData.row.index].affiliate.id, campaignId ? campaignId : tableData.data[tableData.row.index].offer.id, tableData.data[tableData.row.index].rate.name)}>
                                Save
                            </Button>
                            <Button variant="danger" size="sm" onClick={() => deleteRate(tableData.row.index)} className="m-1">
                                Delete
                            </Button>
                        </div>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.rates}
                    columns={columns}
                    getRowProps={(row) => {
                        let classes = ``

                        if (parseFloat(row.original.rate.spc_net_rate) > 0) {
                            if (parseFloat(row.original.rate.spc_aff_rate) > parseFloat(row.original.rate.spc_net_rate)) {
                                if (row.original.rate.actual_offer_type !== 'A') {
                                    classes = 'bgDanger25'
                                }
                            }
                        } else {
                            if (parseFloat(row.original.rate.spc_aff_rate) > parseFloat(row.original.rate.off_net_rate)) {
                                if (row.original.rate.actual_offer_type !== 'A') {
                                    classes = 'bgDanger25'
                                }
                            }
                        }
                        return {
                            className: classes,
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Special Rates</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Card>
                        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                            <SpecialRatesModal setIsOpen={setIsOpen} affiliate={{ ...data.affiliate, dataLoaded }} campaign={{ ...data.campaign, dataLoaded }} refetch={refetch} showSystemMessage={showSystemMessage} />
                        </Modal>
                        <Card.Body className="p-0">
                            <div className="d-flex justify-content-between p-3 align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : <Campaign oid={campaignId} name={data.campaign.name} /> : null}</h1>
                                <Button variant="warning" size="sm" onClick={() => setIsOpen(true)}>
                                    Add Special Rate
                                </Button>
                            </div>
                            {dataLoaded ? data.rates.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default SpecialRates
