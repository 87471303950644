const getAffiliateStatus = (statusLetter) => {
    let status = 'Unknown'

    if (statusLetter == 'A') {
        status = 'Active'
    } else if (statusLetter == 'V') {
        status = 'Validating'
    } else if (statusLetter == 'P') {
        status = 'Pending'
    } else if (statusLetter == 'S') {
        status = 'Suspended'
    } else if (statusLetter == 'R') {
        status = 'Rejected'
    } else if (statusLetter == 'L') {
        status = 'Closed'
    } else if (statusLetter == 'D') {
        status = 'Duplicate'
    } else if (statusLetter == 'C') {
        status = 'Cancelled'
    }

    return status
}

module.exports = getAffiliateStatus
