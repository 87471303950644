import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../templates/UI/Loading/Loading'
import { colors } from '../../../../theme/variables'
import Dollars from '../../../../templates/Dollars/Dollars'

const RecentlyReceivedPayments = ({ manager }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)

    useEffect(() => {
        const fetchCampaigns = async () => {
            const data = await callAPI(`affiliates/received_payment/${manager.id}`, 'GET', null)
            if (data.success) {
                setData(data)
            } else {
                console.log(data.message)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchCampaigns()
    }, [manager])
    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Recently Received Payment (30 days)</span>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th className="text-right">Earnings</th>
                            <th className="text-right">Margin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {!data || data.affiliates.length === 0 ? (
                                            <tr>
                                                <td colSpan={4}>No data.</td>
                                            </tr>
                                        ) : (
                                            <>
                                                {data.affiliates.map((record) => (
                                                    <tr key={record.affiliate.id}>
                                                        <td xs={3}>{record.affiliate.id}</td>
                                                        <td xs={6}>
                                                            {record.affiliate.active ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} class="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                                    <circle cx="8" cy="8" r="8" />
                                                                </svg>
                                                            ) : null}
                                                            <Link to="/affiliate" className="text-primary">
                                                                {record.affiliate.active ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} class="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                                        <circle cx="8" cy="8" r="8" />
                                                                    </svg>
                                                                ) : null}
                                                                <Affiliate aid={`${record.affiliate.id}`} name={`${record.affiliate.name}`} status={`${record.affiliate.status}`} priority={`${record.affiliate.priority}`} />
                                                            </Link>
                                                        </td>
                                                        <td className="text-right" xs={6}>                                                            
                                                            <Dollars value={record.earnings} />
                                                        </td>
                                                        <td className="text-right" xs={3}>
                                                            <Link to={`/reports/stats/campaigns?affiliateId=${record.affiliate.id}&startDate=${moment().add(-30, 'days').format('YYYY-MM-DD')}&withAvatar=true`} className="text-primary font-weight-bold">
                                                                <Dollars value={record.margin} />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default RecentlyReceivedPayments
