import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useCallApi from '../../../hooks/useCallApi'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import StatusCircle from '../../../templates/StatusCircle/StatusCircle'
import Loading from '../../../templates/UI/Loading/Loading'
import AdminsModal from './AdminsModal'

const ListAdmins = ({ showSystemMessage }) => {

    document.title = 'Manage Administrators'

    const [mode, setMode] = useState('A')
    const [isCreate, setIsCreate] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [adminId, setAdminId] = useState(67)
    const { data, dataLoaded, refetch } = useCallApi(`admin/listadmins?mode=${mode}`, 'GET', {}, [mode], showSystemMessage)

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'manage_administrators')

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Name',
                accessor: 'full_name',
                Cell: (tableData) => {
                    return (
                        <button
                            className="btn btn-sm btn-link"
                            onClick={() => {
                                setIsCreate(false)
                                setAdminId(tableData.data[tableData.row.index].admin_id)
                                setIsOpen(true)
                            }}
                        >
                            {tableData.data[tableData.row.index].full_name}
                        </button>
                    )
                },
            },
            {
                Header: 'ID',
                accessor: 'admin_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Email',
                accessor: 'e_mail',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? value : <span className="text-danger">ROLE NOT SET</span>}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'A' ? 'Active' : 'Cancelled'}</>
                },
            },
            {
                Header: 'Out of office',
                accessor: 'out_of_office',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'Y' ? <div className="ml-2" data-tip="Out of office"><StatusCircle type="danger" /></div> : ''}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.adminsList}
                    columns={columns}
                    getRowProps={(row) => {
                        if (row.original.status === 'C') {
                            return {
                                className: `bgDanger25`,
                            }
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    return (
        <div className="row">
            <div className="col-lg-6">
                <Card>
                    {isOpen && <AdminsModal refetch={refetch} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} isCreate={isCreate} adminId={adminId} setAdminId={setAdminId} />}
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div>Manage Administrators</div>
                        <div className="d-flex align-items-center">
                            <div className="custom-control custom-switch mr-2">
                                <input type="checkbox" className="custom-control-input" id="filterSwitch" onClick={() => setMode(mode === 'A' ? 'C' : 'A')} defaultChecked={mode === 'A' ? false : true} />
                                <label className="custom-control-label" htmlFor="filterSwitch">
                                    Show Cancelled
                                </label>
                            </div>

                            <Button
                                className="ml-2"
                                onClick={() => {
                                    setIsCreate(true)
                                    setIsOpen(true)
                                    setAdminId(67)
                                }}
                                variant="primary"
                                size="sm"
                            >
                                Create Admin
                            </Button>
                        </div>
                    </Card.Header>
                    <div className="card-body m-0 p-0">
                        <ReactTooltip />
                        {dataLoaded ? data.adminsList.length === 0 ? <div className="p-3">No admins found.</div> : getReportOutput() : <Loading></Loading>}
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default ListAdmins
