import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useAuth, useProtectedPage } from '../../context/AuthContext'
import callAPI from '../../helpers/callAPI'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Loading from '../../templates/UI/Loading/Loading'
import AddOverride from './AddOverride/AddOverride'

const CampaignApprovals = ({ affiliateId, campaignId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_approvals')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [approvalList, setApprovalList] = useState([])
    const [showOverrideModal, setShowOverrideModal] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        getReport()
    }, [affiliateId])

    const getReport = () => {
        setDataLoaded(false)

        let url

        url = affiliateId ? `affiliate/${affiliateId}/campaignApprovals` : `campaign/${campaignId}/campaignApprovals`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    affiliateId ? setAffiliate(result.affiliate) : setCampaign(result.campaign)
                    setApprovalList(result.approval_list)
                    document.title = `${affiliateId ? result.affiliate.name : result.campaign.name}: Campaign Approvals`
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    document.title = 'Campaign Approvals'
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching campaign approvals: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching campaign approvals')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'item_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign oid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].item_status} /> : <Affiliate aid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].item_status} priority={tableData.data[tableData.row.index].priority} />
                },
            },
            {
                Header: affiliateId ? 'Campaign Status' : 'Affiliate Status',
                accessor: 'item_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Advertiser Manager' : 'Affiliate Manager',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Override Date',
                accessor: 'status_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'Override Status',
                accessor: 'offers',
                Cell: (tableData) => {
                    return (
                        <form className="d-flex align-items-center">
                            <select name="override_status" className="form-control form-control-sm" defaultValue={tableData.data[tableData.row.index].override_status} onChange={(e) => saveAction(tableData.data[tableData.row.index].item_id, e)}>
                                <option value="A">Approved</option>
                                <option value="R">Rejected</option>
                            </select>{' '}
                            &nbsp; &nbsp; &nbsp;
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteAction(tableData.data[tableData.row.index].item_id)}>
                                Delete
                            </button>
                        </form>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={approvalList} columns={columns} />
            </React.Fragment>
        )
    }

    const handleClose = (isEdited = false) => {
        setShowOverrideModal(false)

        if (isEdited) {
            getReport()
        }
    }

    const saveAction = (itemId, e) => {
        e.preventDefault()

        const putBody = {
            item_id: itemId,
            override_status: e.target.value,
        }

        let url = affiliateId ? `affiliate/${affiliateId}/addOverride` : `campaign/${campaignId}/addOverride`

        callAPI(url, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    let tempList = approvalList
                    let index = tempList.findIndex((item) => item.item_id === itemId)
                    tempList[index].override_status = e.target.value

                    setApprovalList(tempList)
                    showSystemMessage('success', result.message)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while updating campaign approvals: ${error}`)
                showSystemMessage('error', 'An error occurred while updating campaign approvals')
            })
    }

    const deleteAction = (itemId) => {
        const deleteBody = {
            item_id: itemId,
        }

        let url = affiliateId ? `affiliate/${affiliateId}/deleteOverride` : `campaign/${campaignId}/deleteOverride`

        callAPI(url, 'DELETE', deleteBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    let tempList = approvalList.filter((item) => item.item_id !== itemId)

                    setApprovalList(tempList)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while deleting campaign approvals: ${error}`)
                showSystemMessage('error', 'An error occurred while deleting campaign approvals')
            })
    }

    return (
        <div>
            <AddOverride affiliateId={affiliateId} campaignId={campaignId} aff={affiliate} campaign={campaign} showSystemMessage={showSystemMessage} handleClose={handleClose} isOpen={showOverrideModal} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Approvals</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="card">
                <div className="d-flex justify-content-between p-3 align-items-center">
                    <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                    <button type="button" className="btn btn-sm btn-warning" onClick={() => setShowOverrideModal(true)}>
                        Add Override
                    </button>
                </div>
                {dataLoaded ? approvalList.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
            </div>
        </div>
    )
}

export default CampaignApprovals
