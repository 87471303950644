import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import callAPI from '../../../helpers/callAPI'
import AffiliateSearch from '../../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../../CampaignSearch/CampaignSearch'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'

const AddDailyCap = ({ affiliate, campaign, showSystemMessage, handleClose, showAddCapModal }) => {
    const [offer, setOffer] = useState({ id: -1, name: '' })
    const [selectedAffiliate, setSelectedAffiliate] = useState({ id: -1, name: '' })
    const [saving, setSaving] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const addCap = (e) => {
        e.preventDefault()
        setSaving(true)

        const postBody = {
            cap_amount: e.target.cap_amount.value,
            item_id: affiliate ? offer.id : selectedAffiliate.id,
        }

        let url = affiliate ? `affiliate/${affiliate.id}/campaignCaps` : `campaign/${campaign.id}/campaignCaps`

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setSaving(false)
                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSaving(false)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while adding campaign cap')
                setSaving(false)
            })
    }

    return (
        <Modal show={showAddCapModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create Campaign Cap for: {affiliate ? <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : null}
                    {campaign ? <Campaign oid={campaign.id} name={campaign.name} status={campaign.status.value} /> : null}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={addCap} method="POST" className="form-group modal-body">
                <table className="table table-card table-borderless w-100 m-0">
                    <tbody>
                        <tr>
                            <td>{affiliate ? 'Affiliate:' : 'Campaign:'}</td>
                            <td>{affiliate ? affiliate.id : campaign.id}</td>
                        </tr>
                        <tr className="align-items-center">
                            <td width="30%">{affiliate ? 'Campaign:' : 'Affiliate:'}</td>
                            <td className="pt-3 align-items-center">{affiliate ? <CampaignSearch setCampaign={setOffer} /> : <AffiliateSearch setAffiliate={setSelectedAffiliate} />}</td>
                        </tr>
                        <tr>
                            <td>Cap amount:</td>
                            <td>
                                <input type="number" name="cap_amount" className="form-control" maxLength={10} autoComplete="off" required />
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <CustomButton type="submit" variant="primary" spin={saving} name="Create" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </Modal>
    )
}

export default AddDailyCap
