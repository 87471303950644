import React, { useState, useEffect } from 'react'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import CustomFlags from '../../../components/CustomFlags/CustomFlags'
import Mailer from '../../../components/Mailer/Mailer'
import { Modal } from 'react-bootstrap'
import MailerRecipientListAdvertisers from '../../../components/Mailer/MailerRecipientListAdvertisers'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'

export default function AdvertiserMailer({ showSystemMessage }) {
    document.title = 'Advertiser Mailer'

    const auth = useAuth()
    const showSenders = useProtectedContent(auth.permissions, 'mailer_sender_unrestricted')

    // Filters
    const [advertiserManager, setAdvertiserManager] = useState( ['MERMGR', 'BIZDEV', 'BIZDEV_DIRECTOR'].includes(auth.user.role) ?  auth.user.id : {} )
    const [flag, setFlag] = useState(false)
    const [excludedAdvertisers, setExcludedAdvertisers] = useState('')
    const [showModal, setShowModal] = useState(false)

    const handlePreview = async () => {
        setShowModal(true)
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Mailer</h1>
            </div>
            <div className="row">
                <div className="col-xl-3">
                    <div className="card mb-3">
                        <div className="p-2 d-flex justify-content-between align-items-center font-weight-bold border-bottom">
                            Recipient Filters
                            <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
                                <MailerRecipientListAdvertisers filters={{ advertiserManager, flag, excludedAdvertisers }} showSystemMessage={showSystemMessage} />
                            </Modal>
                        </div>
                        <table className="table table-striped table-card m-0">
                            <tbody>
                                <tr>
                                    <td>Advertiser Manager</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            {
                                                ['MERMGR', 'BIZDEV', 'BIZDEV_DIRECTOR'].includes(auth.user.role) ?
                                                    <div>{auth.user ? auth.user.name : 'You'}</div>
                                                    
                                                :
                                                <AdminPicker adminId={advertiserManager} setAdminId={setAdvertiserManager} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />
                                            }
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>With Flag</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select
                                                className="form-control form-control-sm"
                                                defaultValue={flag ? flag : ''}
                                                onChange={(e) => {
                                                    setFlag(e.target.value)
                                                }}
                                            >
                                                <option value=""></option>
                                                <CustomFlags target="advertiser" showSystemMessage={showSystemMessage} />
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Advertiser ID's to exclude</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                placeholder="Separate with commas. Ex: 123456, 98765"
                                                onChange={(e) => {
                                                    setExcludedAdvertisers(e.target.value)
                                                }}
                                            ></input>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <div className="p-2">
                                            <button className="btn btn-warning" onClick={handlePreview}>
                                                Preview Recipients
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-9">
                    <Mailer type={'advertiser'} filters={{ advertiserManager, flag, excludedAdvertisers }} showSystemMessage={showSystemMessage} />
                </div>
            </div>
        </React.Fragment>
    )
}
