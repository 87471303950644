import { Modal, Table } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'

import moment from 'moment'
import Dollars from '../../../templates/Dollars/Dollars'

const ViewModal = ({ isOpen, setIsOpen, advertiser, showSystemMessage }) => {
    const { data, dataLoaded } = useCallApi(`advertiser/${advertiser.id}/invoices`, 'GET', {}, [advertiser], showSystemMessage)
    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header>
                <div className="mb-h1-dark">Last 20 Invoices for {advertiser.name}</div>
            </Modal.Header>
            <Table striped bordered hover>
                {/* amount: nullinvoice_date: "2023-04-01T00:00:00.000Z"invoice_num: nullnotes: nullpayment_date: nullsent_date: nullstatus: "P" */}
                <thead>
                    <tr>
                        <th>Invoice Date</th>
                        <th>Send Date</th>
                        <th>Paid Date</th>
                        <th>Inv #</th>
                        <th>Status</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        <>
                            {data.invoices.map((invoice) => {
                                return (
                                    <tr>
                                        <td>{moment(invoice.invoice_date).format('YYYY-MM-DD')}</td>
                                        <td>{invoice.sent_date !== null ? moment(invoice.sent_date).format('YYYY-MM-DD') : ''}</td>
                                        <td>{invoice.payment_date !== null ? moment(invoice.payment_date).format('YYYY-MM-DD') : ''}</td>
                                        <td>{invoice.invoice_num}</td>
                                        <td>{invoice.status}</td>
                                        <td>{invoice.amount !== null ? <Dollars value={invoice.amount} /> : ''}</td>
                                    </tr>
                                )
                            })}
                        </>
                    ) : (
                        <Loading />
                    )}
                </tbody>
            </Table>
        </Modal>
    )
}

export default ViewModal
