import React, { useState, useEffect } from 'react'

import Loading from '../../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import MarginSummary from '../MarginSummary/MarginSummary'
import AffiliateHealthSummary from '../AffiliateHealthSummary/AffiliateHealthSummary'
import CampaignSliders from '../CampaignSliders/CampaignSliders'
import Applications from '../Applications/Applications'
import RejectedMarketingApplicationsSummary from '../RejectedMarketingApplicationsSummary/RejectedMarketingApplicationsSummary'
import MarketingCampaignSummary from '../MarketingCampaignSummary/MarketingCampaignSummary'
import MarketingAdvertiserApplications from '../MarketingAdvertiserApplications/MarketingAdvertiserApplications'

export default function MarketingManager({ manager, showSystemMessage }) {
    // const auth = useAuth()
    // useProtectedPage(auth.permissions, 'marketing_manager_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Marketing Manager</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <MarginSummary showSystemMessage={showSystemMessage} manager={manager} />
                            <AffiliateHealthSummary showSystemMessage={showSystemMessage} />
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-6">
                                    <MarketingCampaignSummary />
                                    <Applications manager={manager} showSystemMessage={showSystemMessage} mode="marketing" />
                                    <MarketingAdvertiserApplications />
                                </div>
                                <div className="col-lg-6">
                                    <CampaignSliders showSystemMessage={showSystemMessage} />
                                    <RejectedMarketingApplicationsSummary />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
