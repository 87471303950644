import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import FraudPullDropdown from '../../Dashboard/components/FraudPulls/FraudPullDropdown/FraudPullDropdown'

const FraudPullReport = ({ showSystemMessage }) => {
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { mode, setMode, buildUrl } = useQueryParams()

    let apiUrl = buildUrl(`compliance/fraudPulls?z=1`)

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [mode], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate name={tableData.data[tableData.row.index].affiliate.name} aid={tableData.data[tableData.row.index].affiliate.id} status={tableData.data[tableData.row.index].affiliate.status.value} priority={tableData.data[tableData.row.index].affiliate.priority.value} />
                },
            },
            {
                Header: 'ID',
                accessor: 'campaign.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign name={tableData.data[tableData.row.index].campaign.name} oid={tableData.data[tableData.row.index].campaign.id} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: 'ID',
                accessor: 'advertiser.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return <Advertiser name={tableData.data[tableData.row.index].advertiser.name} id={tableData.data[tableData.row.index].advertiser.id} status={tableData.data[tableData.row.index].advertiser.status.value} />
                },
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm')}</>
                },
            },
            {
                Header: ' ',
                accessor: 'dropdown',
                Cell: (tableData) => {
                    return <FraudPullDropdown item={tableData.data[tableData.row.index]} showSystemMessage={showSystemMessage} refetch={refetch} />
                },
            },
        ]

        if (mode === 'reviewed') {
            columns = [
                {
                    Header: 'ID',
                    accessor: 'affiliate.id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
                {
                    Header: 'Affiliate',
                    accessor: 'affiliate.name',
                    Cell: (tableData) => {
                        return <Affiliate name={tableData.data[tableData.row.index].affiliate.name} aid={tableData.data[tableData.row.index].affiliate.id} status={tableData.data[tableData.row.index].affiliate.status.value} priority={tableData.data[tableData.row.index].affiliate.priority.value} />
                    },
                },
                {
                    Header: 'ID',
                    accessor: 'campaign.id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
                {
                    Header: 'Campaign',
                    accessor: 'campaign.name',
                    Cell: (tableData) => {
                        return <Campaign name={tableData.data[tableData.row.index].campaign.name} oid={tableData.data[tableData.row.index].campaign.id} status={tableData.data[tableData.row.index].campaign.status.value} />
                    },
                },
                {
                    Header: 'ID',
                    accessor: 'advertiser.id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
                {
                    Header: 'Advertiser',
                    accessor: 'advertiser.name',
                    Cell: (tableData) => {
                        return <Advertiser name={tableData.data[tableData.row.index].advertiser.name} id={tableData.data[tableData.row.index].advertiser.id} status={tableData.data[tableData.row.index].advertiser.status.value} />
                    },
                },
                {
                    Header: 'Date',
                    accessor: 'date',
                    Cell: ({ cell: { value } }) => {
                        return <>{moment(value).utc().format('YYYY-MM-DD HH:mm')}</>
                    },
                },
                {
                    Header: 'Reviewer',
                    accessor: 'reviewer',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
                {
                    Header: 'Review Date',
                    accessor: 'review_date',
                    Cell: ({ cell: { value } }) => {
                        return <>{moment(value).utc().format('YYYY-MM-DD HH:mm')}</>
                    },
                },
                {
                    Header: 'Notes',
                    accessor: 'notes',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
            ]
        }

        return <ReportTable data={data.fraud_pulls} columns={columns} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Fraud Pulls</h1>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="card">
                        <div className="d-flex p-3 align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="mr-3">
                                    <select className="form-control form-control-sm" defaultValue={mode} onChange={(e) => setMode(e.target.value)}>
                                        <option value="unreviewed">Unreviewed</option>
                                        <option value="reviewed">Reviewed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {dataLoaded ? data.fraud_pulls.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FraudPullReport
