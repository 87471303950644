import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const SystemDropdown = (props) => {
    const auth = useAuth()
    const showScheduledJobs = useProtectedContent(auth.permissions, 'system')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right">
                System
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                {/* <Link className="dropdown-item" to="/system/settings" onClick={toggle}>
                    Server Settings
                </Link>                
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/system/mail" onClick={toggle}>
                    Mail
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" /> */}
                <Link className="dropdown-item" to="/system/mbStatus" onClick={toggle}>
                    MB Status
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/system/jobs" onClick={toggle}>
                    System Jobs
                </Link>
                {/* <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/system/settings-summary" onClick={toggle}>
                </Link> */}
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/system/jobHistory" onClick={toggle}>
                    System Jobs Log
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />                
                <Link className="dropdown-item" to="/system/api-logs" onClick={toggle}>
                    API Logs
                </Link>
            </DropdownMenu>
        </Dropdown>
    )
}

export default SystemDropdown
