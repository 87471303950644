import React, { useEffect, useState } from 'react'
import useCallApi from '../../../hooks/useCallApi'
import { Button, Card, Table } from 'react-bootstrap'
import ParamModal from './Modal'

const MobileParameters = ({ showSystemMessage }) => {

    document.title = 'Mobile Parameters'

    const { dataLoaded, data, refetch } = useCallApi(`campaign/mobileParameters`, 'GET', {}, [])
    const [modal, setModal] = useState({
        open: false,
        isCreate: false,
        data: {
            id: 0,
            name: '',
            description: '',
        },
    })

    return (
        <Card className="mt-3">
            <ParamModal showSystemMessage={showSystemMessage} isOpen={modal.open} setIsOpen={(x) => setModal({ ...modal, open: x })} data={modal.data} isCreate={modal.isCreate} refetch={refetch} />
            <Card.Header>
                <div className="d-flex justify-content-between">
                    Mobile Parameters
                    <div>
                        <Button variant="primary" size="sm" onClick={() => setModal({ ...modal, open: true, isCreate: true, data: { id: 0, name: '', description: '' } })}>
                            Add Parameter
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Description</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded &&
                        data.parameters.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.param_name}</td>
                                    <td>{item.param_description}</td>
                                    <td>
                                        <Button variant="warning" size="sm" onClick={() => setModal({ ...modal, open: true, isCreate: false, data: item })}>
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
        </Card>
    )
}

export default MobileParameters
