import React, { useEffect, useState } from 'react'

import EarningSummary from '../../components/EarningSummary/EarningSummary'
import Flags from '../../components/Flags/Flags'
import Followups from '../../components/Followups/Followups'
import Loading from '../../templates/UI/Loading/Loading'
import AccountNotes from './AccountNotes/AccountNotes'
import AffiliateInfo from './AffiliateInfo/AffiliateInfo'
import CampaignPitches from './CampaignPitches/CampaignPitches'
import ClickReferrersSummary from './ClickReferrersSummary/ClickReferrersSummary'
import OpenCampaignPromises from './OpenCampaignPromises/OpenCampaignPromises'
import RecentActivity from './RecentActivity/RecentActivity'
import TargetCountries from './TargetCountries/TargetCountries'
import AffiliateNavbar from './Templates/AffiliateNavbar/AffiliateNavbar'
import TrafficNotes from './TrafficNotes/TrafficNotes'

import FollowupList from '../../components/FollowupList/FollowupList'
import useCallApi from '../../hooks/useCallApi'
import './Affiliate.css'
import CustomDomains from './CustomDomains/CustomDomains'

const Affiliate = ({ affiliateId, showSystemMessage }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { data, dataLoaded, refetch } = useCallApi(`affiliate/${affiliateId}/summary`, 'GET', {}, [affiliateId])

    useEffect(() => {
        if (dataLoaded && data.success) {
            document.title = data.affiliate_details.name
        } else {
            document.title = 'Affiliate'
        }
    }, [data, dataLoaded])

    return (
        <React.Fragment>
            {dataLoaded ? (
                data.success ?
                    <React.Fragment>
                        <FollowupList affiliateId={affiliateId} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} />
                        <div className="page-header d-flex align-items-center">
                            <h1 className="mb-h1">Manage Affiliate</h1>
                            <div className="ml-5">
                                <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                            </div>
                        </div>
                        <div className="row Affiliate">
                            <div className="col-xl-4">
                                <AffiliateInfo affiliateInfo={data.affiliate_details} showSystemMessage={showSystemMessage} setIsOpen={setIsOpen} refetch={refetch} />
                                <OpenCampaignPromises showSystemMessage={showSystemMessage} affiliateId={affiliateId} />
                                <CampaignPitches affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                                <AccountNotes notes={data.affiliate_details.extra_info} affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                                <TrafficNotes trafficNotes={data.affiliate_details.traffic_type_notes} trafficTypeNotesDate={data.affiliate_details.traffic_type_notes_date} affiliateId={affiliateId} showSystemMessage={showSystemMessage} priority={data.affiliate_details.aff_priority_value} refetch={refetch} />
                            </div>
                            <div className="col-xl-4 pb-0">
                                <EarningSummary showSystemMessage={showSystemMessage} affiliateId={affiliateId} target="affiliate" />
                                <ClickReferrersSummary showSystemMessage={showSystemMessage} affiliateId={affiliateId} />
                                {/* <TopTrafficTypes /> */}
                                <RecentActivity affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                                <Flags flags={data.flags} showSystemMessage={showSystemMessage} affiliateId={affiliateId} refetch={refetch} />
                            </div>
                            <div className="col-xl-4">
                                <Followups id={data.affiliate_details.affiliate_id} showSystemMessage={showSystemMessage} managerName={data.affiliate_details.manager_name} managerId={data.affiliate_details.manager_id} notes={data.affiliate_details.notes} type="affiliate" setIsOpen={setIsOpen} />
                                <TargetCountries targetCountries={data.target_countries} />
                                {data.affiliate_details.custom_domains.length > 0 && <CustomDomains custom_domains={data.affiliate_details.custom_domains} />}
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="page-header d-flex align-items-center">
                            <h1 className="mb-h1">Affiliate does not exist</h1>
                        </div>
                        <div className="row"></div>
                    </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}

export default Affiliate
