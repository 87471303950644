import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { Card } from "react-bootstrap"
import { Bar } from "react-chartjs-2"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import callAPI from "../../../helpers/callAPI"
import AffiliateNavbar from "../Templates/AffiliateNavbar/AffiliateNavbar"

const LifetimeEarningsGraph = ({ affiliateId, showSystemMessage }) => {

    const [report, setReport] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const chartRef = useRef()
    const history = useHistory()

    useEffect(() => {
        fetchData()
    }, [affiliateId])

    const fetchData = () => {
        callAPI(`affiliate/${affiliateId}/overallEarnings`, 'GET')
            .then(result => {
                if (result["success"] === true) {
                    setReport(result.report)
                } else if (result["success"] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            }).catch((error) => {
                showSystemMessage('error', `An error occurred while affiliate lifetime earnings.`)
                setDataLoaded(true)
            })
    }

    let labels = []
    let stats = []
    let months = []

    report.forEach(item => {
        months.push(moment(item.stats_month).utc().format('YYYY-MM-DD'))
        labels.push(moment(item.stats_month).utc().format('MMM/YY'))
        stats.push(item.earnings.toFixed(2))
    })

    const handleClick = (element) => {
        history.push(`/reports/stats/campaigns?affiliateId=${affiliateId}&withAvatar=true&navigation=affiliate&startDate=${moment(months[element[0]._index]).format('YYYY-MM-DD')}&endDate=${moment(months[element[0]._index]).endOf('month').format('YYYY-MM-DD')}`)
    }

    const options = {
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        display: true,
                        autoSkip: true,                        
                        maxTicksLimit: 10,
                        callback: function(value, index, values) {                           
                            return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)                                                   
                        }
                    },
                },
            ]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {                    
                    return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tooltipItem.yLabel)                   
                }
            }
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Lifetime Earnings</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <Card>
                <div className="chart-container">
                    <h3 className="trendGraphTitle">Lifetime Earnings Graph</h3>
                    <article className="canvas-container">
                        <Bar
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        label: 'Earnings',
                                        data: stats,
                                        fill: false,
                                        backgroundColor: 'rgba(23,162, 184, 1)',
                                        borderColor: 'rgba(23, 162, 184, 1)',
                                    }
                                ]
                            }}
                            ref={chartRef}
                            height={70}
                            onElementsClick={element => handleClick(element)}
                            options={options}
                        />
                    </article>
                </div>
            </Card>
        </div>
    )
}

export default LifetimeEarningsGraph