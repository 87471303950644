import React from 'react'
import { Modal } from 'react-bootstrap'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'
import PostbackElement from './PostbackElement/PostbackElement'

const GeneratePixelModal = ({ campaignId, campaign, isOpen, setIsOpen }) => {

    const { dataLoaded, data, refetch } = useCallApi(`campaign/mobileParameters`, 'GET', {}, [])

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="lg">
            <Modal.Header closeButton>
                <h1 className="mb-h1-dark">Pixel / Postbacks for {campaign.name ? campaign.name : null}</h1>
            </Modal.Header>
            <Modal.Body>
                {campaign.rates.map((rate, index) => (
                    <div key={index}>
                        <h5>Rate Name: {rate.rate_name}</h5>
                        <PostbackElement campaign={campaign} rate={rate} campaignId={campaignId} data={data} dataLoaded={dataLoaded} />
                    </div>
                ))}
                {
                    campaign.type.value === 'A' &&
                    <div>
                        NOTES:<br/><br/>
                        * Rate will be based on the rate indicated on the Survey Event the token relates to.
                    </div>
                }
                {
                    campaign.tracking.is_mobile_offer &&
                    <table className="table table-striped table-hover mt-3" width="100%" cellspacing="0" cellpadding="2">
                        <tbody>
                            <tr width="100%" align="left">
                                <td className="font-weight-bold" align="left" width="10%">Parameter</td>
                                <td className="font-weight-bold" align="left" width="60%">Description</td>
                            </tr>
                            {
                                dataLoaded ?
                                    data.parameters.map(parameter =>
                                    <tr align="left">
                                        <td align="left">{parameter.param_name}</td>
                                        <td align="left">{parameter.param_description}</td>
                                    </tr>)
                                :
                                        <Loading></Loading>
                            }    
                        </tbody>
                    </table>
                }
            </Modal.Body>
        </Modal>
    )
}

export default GeneratePixelModal
