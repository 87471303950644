import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'

const LoginDetailsModal = ({ modalData, setModalData, id, dataLoaded, manager, refetch, advertiserId, showSystemMessage, setDataLoaded }) => {
    const [formData, setFormData] = useState({ url: '', login: '', password: '', notes: '' })
    const createLogin = async () => {
        console.log(formData)
        console.log(manager)
        const result = await callAPI(`advertisers/${advertiserId}/logindetails`, 'POST', { ...formData, manager })
        if (result.success) {
            setFormData({ url: '', login: '', password: '', notes: '' })
            setModalData({ ...modalData, isOpen: false, isCreate: false })
            showSystemMessage('success', 'Login details created successfully')
            refetch()
        } else {
            console.log(result.message)
            showSystemMessage('error', result.message)
        }
    }
    const updateLogin = async () => {
        const result = await callAPI(`advertisers/${advertiserId}/logindetails`, 'PUT', { ...modalData.loginDetails[id], manager })
        if (result.success) {
            setModalData({ ...modalData, isOpen: false, isCreate: false })
            showSystemMessage('success', 'Login details updated successfully')
            refetch()
        } else {
            console.log(result.message)
            showSystemMessage('error', result.message)
        }
    }
    const deleteLogin = async () => {
        setDataLoaded(false)
        const result = await callAPI(`advertisers/${advertiserId}/logindetails`, 'DELETE', { id: modalData.loginDetails[id].id })
        if (result.success) {
            setModalData({ isOpen: false, isCreate: false })
            showSystemMessage('success', 'Login details deleted successfully')
            refetch()
        } else {
            console.log(result.message)
            showSystemMessage('error', result.message)
        }
    }

    return (
        <Modal show={modalData.isOpen} onHide={() => setModalData({ ...modalData, isOpen: false })}>
            <Modal.Header closeButton>
                <Modal.Title>Platform Logins for Advertiser: {!dataLoaded || modalData.isCreate ? formData.login : modalData.isOpen ? modalData.loginDetails[id].login : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalData.isOpen ? (
                    <>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Login URL:</span>
                            {!dataLoaded || !modalData.isCreate ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    value={modalData.loginDetails[id].url}
                                    onChange={(event) => {
                                        let loginDetails = modalData.loginDetails
                                        loginDetails[id].url = event.target.value
                                        setModalData({ ...modalData, loginDetails: loginDetails })
                                    }}
                                />
                            ) : (
                                <input type="text" className="form-control" value={formData.url} onChange={(event) => setFormData({ ...formData, url: event.target.value })} />
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Username/ email:</span>
                            {!dataLoaded || !modalData.isCreate ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    value={modalData.loginDetails[id].login}
                                    onChange={(event) => {
                                        let loginDetails = modalData.loginDetails
                                        loginDetails[id].login = event.target.value
                                        setModalData({ ...modalData, loginDetails: loginDetails })
                                    }}
                                />
                            ) : (
                                <input type="text" className="form-control" value={formData.login} onChange={(event) => setFormData({ ...formData, login: event.target.value })} />
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Password:</span>
                            {!dataLoaded || !modalData.isCreate ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    value={modalData.loginDetails[id].password}
                                    onChange={(event) => {
                                        let loginDetails = modalData.loginDetails
                                        loginDetails[id].password = event.target.value
                                        setModalData({ ...modalData, loginDetails: loginDetails })
                                    }}
                                />
                            ) : (
                                <input type="text" className="form-control" value={formData.password} onChange={(event) => setFormData({ ...formData, password: event.target.value })} />
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Notes:</span>
                            {!dataLoaded || !modalData.isCreate ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    value={modalData.loginDetails[id].notes}
                                    onChange={(event) => {
                                        let loginDetails = modalData.loginDetails
                                        loginDetails[id].notes = event.target.value
                                        setModalData({ ...modalData, loginDetails: loginDetails })
                                    }}
                                />
                            ) : (
                                <input type="text" className="form-control" value={formData.notes} onChange={(event) => setFormData({ ...formData, notes: event.target.value })} />
                            )}
                        </div>
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                {modalData.isCreate ? (
                    <Button variant="primary" onClick={createLogin}>
                        Create Login
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={updateLogin}>
                            Update Login
                        </Button>
                        <Button variant="danger" onClick={deleteLogin}>
                            Delete Login
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default LoginDetailsModal
