import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import EmployeePicker from '../../../../components/EmployeePicker/EmployeePicker'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import styles from './AamSummary.module.scss'
import moment from 'moment'

import { useAuth, useProtectedPage, useSetManager } from '../../../../context/AuthContext'
import ReactTooltip from 'react-tooltip'
import useCallApi from '../../../../hooks/useCallApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function AamSummary({ manager, setManager, showSetManager }) {
    const auth = useAuth()

    const [dataLoaded, setDataLoaded] = useState(false)

    const [summaryData, setSummaryData] = useState({
        affiliates: {
            high: { affiliate_manager: 0, network: 0 },
            medium: { affiliate_manager: 0, network: 0 },
            new: { affiliate_manager: 0, network: 0 },
            total: { affiliate_manager: 0, network: 0 },
        },
        followupsToday: 0,
        margin: 0.0,
        applications: { approved: 0, processed: 0 },
    })

    const [todayMargin, setTodayMargin] = useState(0)
    const [monthMargin, setMonthMargin] = useState(0)

    let { data: advertiserCount, dataLoaded: advertiserCountDataLoaded } = useCallApi(`advertisers?status=A`, 'GET', {}, [])
    let { data: advertiserWithCampaignsCount, dataLoaded: advertiserWithCampaignsCountDataLoaded } = useCallApi(`advertisers?status=A&withCampaigns=true`, 'GET', {}, [])
    let { data: campaignCount, dataLoaded: campaignCountDataLoaded } = useCallApi(`campaigns/search?status=A,B,H`, 'GET', {}, [])

    useEffect(async () => {
        const fetchSummaryData = async () => {
            const data = await callAPI(`summary/advertiserManager?adminId=${manager.id}`, 'GET', null)
            if (data.success) {
                setSummaryData(data)
            } else {
                console.log(data.message)
            }
        }

        const fetchDayMargin = async () => {
            const todayMargin = await callAPI(`reports/advertisers?adminId=${manager.id}&startDate=${moment().startOf('day').format('YYYY-MM-DD')}&endDate=${moment().add(1, 'day').startOf('day').format('YYYY-MM-DD')}`, 'GET', null)
            if (todayMargin.success) {
                setTodayMargin(todayMargin.totals.find((total) => total.header === 'margin').value)
            }
            return
        }

        const fetchMonthMargin = async () => {
            const monthMargin = await callAPI(`reports/advertisers?adminId=${manager.id}`, 'GET', null)
            if (monthMargin.success) {
                setMonthMargin(monthMargin.totals.find((total) => total.header === 'margin').value)
            }
            return
        }

        setDataLoaded(false)

        await fetchSummaryData()
        await fetchDayMargin()
        await fetchMonthMargin()

        setDataLoaded(true)
    }, [manager])

    return (
        <Card className="mb-3">
            <Card.Title className={`m-3 d-flex ${styles.customFlexDirection}`}>
                <div className="font-weight-bold text-secondary" style={{ fontSize: '1.5rem' }}>
                    Hello <span className="text-dark">{manager.name.split(' ')[0]}</span>
                </div>
                {useSetManager(auth.permissions, 'aam_dashboard') && showSetManager && <EmployeePicker employee={manager} setEmployee={setManager} role={'MERMGR'} />}
            </Card.Title>
            <hr className="my-0" />
            <div>
                {dataLoaded ? (
                    <>
                        <ReactTooltip />
                        <div className="d-flex p-3 justify-content-between border-bottom">
                            <div className="d-flex align-items-center">
                                <span className="font-weight-bold mr-2">Margin</span>
                            </div>
                            <div>
                                Today &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold"><Link to={`/reports/margin?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().endOf('day').format('YYYY-MM-DD')}&mgrid=${manager.id}`}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(todayMargin)}</Link> &nbsp;</span>
                                </span>
                            </div>
                            <div>
                                Month &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold"><Link to={`/reports/margin?startDate=${moment().startOf('month').format('YYYY-MM-DD')}&endDate=${moment().endOf('month').format('YYYY-MM-DD')}&mgrid=${manager.id}`}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(monthMargin)}</Link> &nbsp;</span>
                                </span>
                            </div>
                        </div>                        
                        <div className="d-flex p-3 justify-content-between">
                            <div className="d-flex">
                                Followups Today &nbsp;
                                <Link to={`/advertisers/followupSummaryReport?adminId=${manager.id}&startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`} className="text-primary font-weight-bold">
                                    {summaryData.followups_today}&nbsp;
                                </Link>
                            </div>
                            <div className="d-flex">
                                
                                Your Advertisers &nbsp;
                                <span className="font-weight-bold" data-tip="Active with Campaigns">
                                    {summaryData.advertisers.with_campaigns} &nbsp;
                                </span>
                                <span className="font-weight-bold" data-tip="Active">
                                    / <Link to={`/advertisers/search?adminId=${manager.id}&status=A`}>{summaryData.advertisers.active}</Link>
                                </span>
                            </div>
                            <div className="d-flex">
                                Your Campaigns &nbsp;
                                <Link to={`/campaigns/search?adminId=${manager.id}&status=A,B,H`} className="font-weight-bold">{summaryData.campaigns}</Link>
                            </div>
                        </div>
                        <div className="d-flex border-top">
                            <div className="w-50 p-3 border-right text-center">
                                Active Advertisers &nbsp;
                                <span className="font-weight-bold">
                               
                                    {advertiserCountDataLoaded ? (
                                        <Link to={`/advertisers?status=A`} data-tip="Total">
                                            {advertiserCount.advertisers.length}
                                        </Link>
                                    ) : (
                                        <FontAwesomeIcon icon="spinner" size="1x" spin />
                                    )}
                                    {advertiserWithCampaignsCountDataLoaded ? (
                                        <span>
                                            {' '}
                                            /{' '}
                                            <Link to={`/advertisers?withCampaigns=true&status=A`} data-tip="With Active Campaigns">
                                                {advertiserWithCampaignsCount.advertisers.length}
                                            </Link>
                                        </span>
                                    ) : (
                                        <FontAwesomeIcon icon="spinner" size="1x" spin />
                                    )}
                                </span>
                            </div>
                            <div className="w-50 p-3 text-center">
                                Active Campaigns &nbsp;
                                <span className="font-weight-bold">{campaignCountDataLoaded ? <Link to={`/campaigns/search?z=1&status=A,B,H`}>{Intl.NumberFormat('en-GB').format(campaignCount.search_results.length)}</Link> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className={`d-flex justify-content-center p-2 bg-info ${styles.bgInfo25}`}>
                <Link to={`/admin/AAMPerformanceReport?adminId=${manager.id}`} className="text-primary">
                    {' '}
                    View performance report{' '}
                </Link>
            </div>
        </Card>
    )
}
