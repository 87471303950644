import { useContext, useEffect, useState } from 'react'
import Loading from '../../../../templates/UI/Loading/Loading'
import { SystemMessageContext } from "../../../../context/SystemMessageContext"
import useCallApi from '../../../../hooks/useCallApi'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import callAPI from '../../../../helpers/callAPI'

export default function MarketingAdvertiserApplications({startDate=false, endDate=false}) {

    const { showSystemMessage } = useContext(SystemMessageContext)
    
    let apiUrl = `marketing/advertiserApplications`    
    if (startDate && endDate){
        apiUrl += `?startDate=${startDate}&endDate=${endDate}`
    }

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [], showSystemMessage)

    const buildUrl = (status, source, startDate, endDate) => {
        let url = `/advertisers/applications`
        url += status ? `?status=${status}` : '?status=P'
        url += source ? `&source=${source}` : ''
        url += startDate ? `&startDate=${startDate}` : ''
        url += endDate ? `&endDate=${endDate}` : ''
        return url
    }

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Advertiser Application Source</div>                
            </div>
            <div className="table-container-scroll">
                {dataLoaded ? (
                    <table className="table hover striped mb-0 pb-0" style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr className="text-secondary">
                                <th>Source</th>
                                <th className="text-right">Created</th>
                                <th className="text-right">Pending</th>
                                <th className="text-right">Rejected</th>
                                <th className="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody className="m-0">
                            {data && data.report.length > 0 ? (
                                data.report.map((item) => {
                                    return (
                                        <tr key={item.source}>
                                            <td>{item.source}</td>
                                            <td className="text-right"><Link to={buildUrl('C', item.source, startDate, endDate)}>{item.created}</Link></td>
                                            <td className="text-right"><Link to={buildUrl('P', item.source, startDate, endDate)}>{item.pending}</Link></td>
                                            <td className="text-right"><Link to={buildUrl('R', item.source, startDate, endDate)}>{item.rejected}</Link></td>
                                            <td className="text-right">{item.rejected + item.created + item.pending}</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>No records.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}
