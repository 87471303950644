import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Pill from '../../../../templates/UI/Pill/Pill'

import getAdvertiserFollowupResult from '../../../../helpers/getAdvertiserFollowupResult'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import '../../../../theme/classes.scss'
import { colors } from '../../../../theme/variables'

const AdvertiserFollowupQueue = ({ manager, limit, type }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const data = await callAPI(`advertisers/followups/${manager.id}?type=${type}`, 'GET', null)
            if (data.success) {
                let result = data.advertisers.slice(0, 20)
                if (limit && !isNaN(limit)) {
                    result = data.advertisers.slice(0, limit)
                }
                result.inQueue = data.advertisers.length
                setData(result)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchData()
    }, [manager])

    return (
        <Card className="mb-3 p-0 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">{type === 'adops' ? `AdOps` : `Advertiser`} Followup Queue</span>
                </span>
                <span>
                    In Queue <Link to={`/advertisers/followups?adminId=${manager.id}&startDate=2003-01-01&endDate=${moment().format('YYYY-MM-DD')}&type=${type}`}>{data.inQueue}</Link>
                </span>
            </Card.Header>
            <Card.Body className="p-0">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Advertiser</th>
                            <th>Next</th>
                            <th className="text-right">Last</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.map((row) => {
                                            const pastDate = moment().format('YYYY-MM-DD') > moment(row.followups.next_followup_date).utc().format('YYYY-MM-DD')
                                            return (
                                                <tr key={row.advertiser.id} className={pastDate ? 'bgDanger25' : null}>
                                                    <td xs={3}>{row.advertiser.id}</td>
                                                    <td xs={6}>
                                                        <span className={`mb-element ${row.advertiser.status === 'C' ? 'cancelled' : null} : null}`}>
                                                            <Advertiser id={row.advertiser.id} name={row.advertiser.name} status={row.advertiser.status} />
                                                        </span>
                                                    </td>
                                                    <td xs={3}>
                                                        <span to="/followup">{moment(row.followups.next_followup_date).utc().format('YYYY-MM-DD')}</span>
                                                    </td>
                                                    <td className="text-right" xs={3}>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="bottom"
                                                            overlay={
                                                                <Popover id="popover-positioned-bottom" arrowprops={{ style: { display: 'none' } }} style={{ minWidth: '300px', maxWidth: '500px' }}>
                                                                    <Popover.Title className="d-flex justify-content-between" style={{ fontSize: '14px' }}>
                                                                        <div>
                                                                            <div className="mr-2" style={{ width: 'max-content' }}>
                                                                                {moment(row.followups.last_followup.followup_date).utc().format('YYYY-MM-DD HH:mm')}
                                                                            </div>
                                                                            <div className="mr-2 text-muted" style={{ width: 'max-content' }}>
                                                                                {row.followups.last_followup.admin.name}
                                                                            </div>
                                                                        </div>
                                                                        <span>
                                                                            <Pill text={getAdvertiserFollowupResult(row.followups.last_followup.result)} borderColor={colors.success} />
                                                                        </span>
                                                                    </Popover.Title>
                                                                    <Popover.Content>{row.followups.last_followup.note}</Popover.Content>
                                                                </Popover>
                                                            }
                                                        >
                                                            <Link to={`/advertiser/${row.advertiser.id}`}>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                        </OverlayTrigger>
                                                        {/*  */}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default AdvertiserFollowupQueue
