import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Campaign from '../../../templates/Campaign/Campaign'
import AddHighlightedCampaignModal from './AddHighlightedCampaignModal/AddHighlightedCampaignModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const Highlighted = ({ showSystemMessage }) => {
    document.title = 'Highlighted Campaigns'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_highlight')

    const { adminId, setAdminId } = useQueryParams()
    const history = useHistory()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOffers, setSelectedOffers] = useState([])

    useEffect(() => {
        getReport()
    }, [])

    const getReport = () => {
        setDataLoaded(false)

        let url = `campaigns/highlighted`

        if (adminId > 0) {
            url = url + `?mgrId=${adminId}`
        }

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log('Error while fetching highlighted campaigns: ', error)
                showSystemMessage('error', 'An error occurred while fetching highlighted campaigns')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Campaign ID',
                accessor: 'offer_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: 'Select',
                accessor: '',
                Cell: (tableData) => {
                    return <input type="checkbox" value={tableData.data[tableData.row.index].offer_id} onChange={(e) => handleCheckBoxChange(e, tableData.data[tableData.row.index].offer_id)} checked={selectedOffers.includes(tableData.data[tableData.row.index].offer_id)} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const handleCheckBoxChange = (e, offer_id) => {
        e.preventDefault()

        if (e.target.checked) {
            setSelectedOffers([...selectedOffers, offer_id])
        } else {
            setSelectedOffers(selectedOffers.filter((offer) => offer !== offer_id))
        }
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = `/campaigns/highlighted`

        if (adminId > 0) {
            url = url + `?adminId=${adminId}`
        }

        history.push(url)
    }

    const handleClick = (e) => {
        e.preventDefault()
        setIsOpen(true)
    }

    const handleClose = (isEdited = false) => {
        setIsOpen(false)

        if (isEdited) {
            getReport()
        }
    }

    const handleHighlightRemoval = () => {
        let deleteBody = {
            offers: selectedOffers,
        }

        callAPI(`campaigns/highlighted`, 'DELETE', deleteBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Highlight successfully removed')
                    setSelectedOffers([])
                    getReport()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    return
                }
            })
            .catch((error) => {
                console.log(`Error while removing highlighted campaigns: ${error}`)
                showSystemMessage('error', 'An error occurred while removing highlighted campaigns.')
            })
    }

    return (
        <div>
            <AddHighlightedCampaignModal showSystemMessage={showSystemMessage} isOpen={isOpen} handleClose={handleClose} />
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3 justify-content-between">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">Highlighted Campaigns for:</h1>
                            </div>
                            <div className="mr-4">
                                <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />
                            </div>
                            <div className="d-flex align-items-center">
                                <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                    Refresh Report
                                </button>
                            </div>
                        </div>
                        <div className="d-flex">
                            <button className="btn btn-sm btn-danger" onClick={handleHighlightRemoval} disabled={selectedOffers.length === 0}>
                                Remove highlight from selected campaigns
                            </button>
                            &nbsp;&nbsp;
                            <button className="btn btn-sm btn-warning" onClick={handleClick}>
                                Add Highlighted Campaign
                            </button>
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default Highlighted
