import { Modal } from 'react-bootstrap'

import { useState } from 'react'
import callAPI from '../../../../helpers/callAPI'
import useCallApi from '../../../../hooks/useCallApi'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'
import Loading from '../../../../templates/UI/Loading/Loading'

const CreateCategoryModal = ({ isOpen, handleClose, showSystemMessage, selectedCategory, categoryType, isCreate }) => {
    const { dataLoaded, data, refetch } = useCallApi(`campaigns/categories`, 'GET', {}, [])

    const [selectedParentCategoryId, setSelectedParentCategoryId] = useState(selectedCategory.parent_category_id ? selectedCategory.parent_category_id : 0)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let parentCategoryId = 0

        if (isCreate) {
            parentCategoryId = e.target.category_id ? e.target.category_id.value : 0
        } else if (!isCreate) {
            if (categoryType === 'main') {
                parentCategoryId = 0
            } else if (categoryType === 'sub') {
                parentCategoryId = selectedParentCategoryId
            }
        }

        const body = {
            parent_category_id: parentCategoryId,
            name: e.target.name.value,
            enabled: e.target.enabled.value,
        }

        let result

        if (isCreate) {
            result = await callAPI(`campaigns/category`, 'POST', body)
        } else {
            result = await callAPI(`campaigns/category/${selectedCategory.category_id}`, 'PUT', body)
        }

        if (result.success) {
            setSubmitting(false)
            handleClose(true)
            showSystemMessage('success', result.message)
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
            setSubmitting(false)
        } else {
            showSystemMessage('error', `Error while ${isCreate ? 'creating' : 'editing'} category`)
            setSubmitting(false)
        }
    }

    const changeParentCategory = (e) => {
        e.preventDefault()
        setSelectedParentCategoryId(e.target.value)
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">{isCreate ? 'Create New Category' : 'Edit Category'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="id" value={selectedCategory.category_id} />
                        <table className="table table-borderless m-0">
                            <tbody>
                                {/* If there are no subcategories, we are editing a main category. We do not need the dropdown */}
                                {(isCreate || (!isCreate && selectedCategory.parent_category_id > 0)) && (
                                    <tr>
                                        <td>Select Main Category</td>
                                        <td>
                                            <select className="form-control" defaultValue={selectedCategory.parent_category_id} name="category_id" required={selectedParentCategoryId > 0 ? true : false} onChange={changeParentCategory}>
                                                <option value={0}>None - Create a new main category</option>
                                                {data.categories.map((category) => {
                                                    return (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td>New Category Name</td>
                                    <td>
                                        <input type="text" defaultValue={selectedCategory.name} className="form-control" name="name" required />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Enabled</td>
                                    <td>
                                        <select className="form-control w-25" defaultValue={!selectedCategory.enabled ? 'N' : 'Y'} name="enabled" required>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <CustomButton variant="primary" type="submit" name={selectedCategory.category_id === 0 ? 'Create Category' : 'Save Changes'} spin={submitting} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
        </Modal>
    )
}

export default CreateCategoryModal
