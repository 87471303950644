import { Card, Table } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import moment from 'moment'

const ListVerifications = ({ showSystemMessage }) => {
    const { data, dataLoaded, refetch } = useCallApi(`compliance/applications`, 'GET', {}, [], showSystemMessage)

    const calcExpereince = (exp) => {
        switch (exp) {
            case '1':
                return 'Little Exp'
            case '2':
                return 'Medium Exp'
            case '3':
                return 'Very Expereinced'
            default:
                return 'Unknown'
        }
    }

    const calcTimeString = (time, timeToCall) => {
        let timeString = time.split(',')
        if (timeString[1] !== undefined) {
            return `${moment().add(timeString[1], 'hours').format('hh:mm A')} / ${moment().add(timeToCall, 'hours').format('hh:mm A')}`
        }
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="mb-h1-dark">Rejected Affiliate Applications</h1>
            </Card.Header>
            <Table striped hover className="w-100 m-0">
                <thead>
                    <tr className="text-secondary">
                        <th>ID</th>
                        <th>Affiliate</th>
                        <th>Experience</th>
                        <th>Country</th>
                        <th>Aff Time / Time To Call</th>
                        <th>App Date</th>
                    </tr>
                </thead>
                {dataLoaded ? (
                    <tbody>
                        {data.success ? (
                            <>
                                {data.applications.map((item, index) => {
                                    return (
                                        <tr key={item.affiliate_id}>
                                            <td>{item.affiliate_id}</td>
                                            <td>
                                                <Affiliate aid={`${item.affiliate_id}`} name={`${item.name}`} />
                                            </td>
                                            <td>{calcExpereince(item.experience)}</td>
                                            <td>{item.country}</td>
                                            <td>{calcTimeString(item.time_zone, item.best_time_to_call)}</td>
                                            <td>{moment(item.application_date).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    )
                                })}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={6}>No data.</td>
                            </tr>
                        )}
                    </tbody>
                ) : (
                    <tr>
                        <td colSpan={6} align="center">
                            <Loading />
                        </td>
                    </tr>
                )}
            </Table>
        </Card>
    )
}

export default ListVerifications
