import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'
import MaxMoneyAffs from './Components/MaxMoneyAffs'
import MaxMoneyModal from './Components/MaxMoneyModal'
import PromotionsTable from './Components/PromotionsTable'

export default function MaxMoney({ campaignId, showSystemMessage }) {
    document.title = 'MaxMoney'

    const { dataLoaded, data, setData, refetch } = useCallApi(`maxmoney`, 'GET', {}, [], showSystemMessage)
    const [modalId, setModalId] = useState(0)
    const [isCreate, setIsCreate] = useState(false)
    const [affId, setAffId] = useState(0)
    const [campaignsDefault, setCampaignsDefault] = useState([])
    const [defaultType, setDefaultType] = useState('email')

    return (
        <React.Fragment>
            <Modal show={Boolean(affId > 0)} onHide={() => setAffId(0)} size="xl">
                <MaxMoneyAffs showSystemMessage={showSystemMessage} promoId={affId} isModal={true} handleClose={() => setAffId(0)}/>
            </Modal>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage MaxMoney Promotions</h1>
                <div className="ml-5"></div>
            </div>

            <div className="row">
                <Modal show={Boolean(modalId > 0)} onHide={() => setModalId(0)} size="xl">
                    <MaxMoneyModal isCreate={isCreate} promoId={modalId} setPromoId={setModalId} showSystemMessage={showSystemMessage} refetch={refetch} campaignsDefault={campaignsDefault} />
                </Modal>
                {dataLoaded ? (
                    <div className="col-lg-12">
                        <PromotionsTable createButton type="ongoing" showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} refetch={refetch} setModalId={setModalId} setAffId={setAffId} setIsCreate={setIsCreate} setCampaignsDefault={setCampaignsDefault} />
                        <PromotionsTable type="future" showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} refetch={refetch} setModalId={setModalId} setAffId={setAffId} setIsCreate={setIsCreate} setCampaignsDefault={setCampaignsDefault} />
                        <PromotionsTable type="past" showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} refetch={refetch} setModalId={setModalId} setAffId={setAffId} setIsCreate={setIsCreate} setCampaignsDefault={setCampaignsDefault} />
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </React.Fragment>
    )
}
