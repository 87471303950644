import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import questionTypes from '../Survey/data/avatarQuestionTypes'

class LogicModal extends Component {
    state = {}

    getConditionQuestionOptions = (questionType, options, condition, outerIndex, innerIndex) => {
        let conditionValue = condition.value
        let questionOptions = <div>Question type {questionType} does not exist.</div>

        let definedType = questionTypes.find((type) => {
            return type.type === questionType
        })

        if (definedType !== undefined) {
            questionOptions = definedType.formOptions(this.props.questionId, outerIndex, innerIndex, options, condition, conditionValue, this.props.handleChangeLogicAnswer)
        } else {
            questionOptions = <div>Question type {questionType} does not exist.</div>
        }

        return questionOptions
    }

    getConditionableQuestions = () => {
        // We want to only display options in the select for question where conditionable is true.

        let conditionableTypes = questionTypes.filter((questionType) => {
            return questionType.conditionable === true
        })

        let options = this.props.survey.map((question) => {
            let conditionalCheck = conditionableTypes.find((questionType) => {
                return questionType.type === question.type
            })

            if (conditionalCheck !== undefined) {
                if (question.order < this.props.order) {
                    return (
                        <option key={question.questionId} value={question.questionId}>
                            Q{question.order}: {question.question} ({question.type})
                        </option>
                    )
                }
            }

            return false
        })

        return options
    }

    render() {
        return (
            <Modal isOpen={this.props.logicModalOpen} toggle={this.props.toggleLogicModal} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title">Q{this.props.order} Condition Logic</h5>
                    <button type="button" className="close" onClick={this.props.toggleLogicModal}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    {this.props.conditions.map((condition, outerIndex) => {
                        return (
                            <div key={outerIndex}>
                                <div className="border rounded my-3 p-3">
                                    {condition.map((singleCondition, innerIndex) => {
                                        const conditionQuestion = this.props.survey.find((question) => question.questionId === singleCondition.questionId)
                                        const { questionId, options = '', type } = conditionQuestion
                                        return (
                                            <div key={innerIndex}>
                                                <div className="alert alert-primary" key={singleCondition.questionId}>
                                                    <div className="form-group">
                                                        <select className="form-control" defaultValue={questionId} onChange={(e) => this.props.handleChangeLogicQuestion(e, this.props.questionId, outerIndex, innerIndex)}>
                                                            {this.getConditionableQuestions()}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        {
                                                            // We need to get the question type, and then get the values
                                                            this.getConditionQuestionOptions(type, options, singleCondition, outerIndex, innerIndex)
                                                        }
                                                        <div className="text-right mt-2">
                                                            <button className="btn btn-danger btn-sm" onClick={() => this.props.handleRemoveLogic(this.props.questionId, outerIndex, innerIndex)}>
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {condition.length - 1 !== innerIndex && (
                                                    <h5 className="text-muted text-center">
                                                        <span>And</span>
                                                    </h5>
                                                )}
                                            </div>
                                        )
                                    })}
                                    <div className="text-center">
                                        <button className="btn btn-warning" onClick={() => this.props.handleAddInnerLogic(this.props.questionId, outerIndex)}>
                                            + AND Condition
                                        </button>
                                    </div>
                                </div>
                                {this.props.conditions.length - 1 > outerIndex && (
                                    <h5 className="text-muted text-center">
                                        <span>Or</span>
                                    </h5>
                                )}
                            </div>
                        )
                    })}
                    <div className="text-center">
                        {this.getConditionableQuestions().every((e) => e === false) ? (
                            <div className="alert alert-info">There are no conditionable questions.</div>
                        ) : (
                            <button className="btn btn-warning" onClick={() => this.props.handleAddLogic(this.props.questionId)}>
                                + Condition
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        )
    }
}

export default LogicModal
