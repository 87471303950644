import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const AffiliateTipModal = ({ isOpen, handleClose, showSystemMessage }) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting(true)

        const postBody = {
            tip_sequence: e.target.tip_sequence.value,
            tip_text: e.target.text.value,
        }

        callAPI(`admin/affiliateTips`, 'POST', postBody)
            .then((result) => {
                if (result.success) {
                    showSystemMessage('success', result.message)
                    setSubmitting(false)
                    handleClose(true)
                } else if (!result.success) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while creating affiliate tip')
                setSubmitting(false)
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create New Affiliate Tip</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="20%">Tip Sequence:</td>
                                <td>
                                    <input name="tip_sequence" className="form-control w-25" placeholder="Enter tip sequence" required />
                                </td>
                            </tr>
                            <tr>
                                <td>Tip Text:</td>
                                <td>
                                    <input name="text" className="form-control" placeholder="Enter tip text" required />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <CustomButton name="Create Tip" variant="primary" type="submit" spin={submitting} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AffiliateTipModal
