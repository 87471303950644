const getAdvertiserFollowupResult = (result) => {
    if (result === 'PM') {
        return 'Publisher Mgmt'
    } else if (result === 'IO') {
        return 'IO Negotiation'
    } else if (result === 'DC') {
        return 'Drop Campaign'
    } else if (result === 'PI') {
        return 'Pixel Issue'
    } else if (result === 'CI') {
        return 'Campaign Issue'
    } else if (result === 'EM') {
        return 'Sent E-Mail'
    } else if (result === 'LM') {
        return 'Called NA/LM'
    } else if (result === 'CS') {
        return 'Called Spoke'
    } else if (result === 'SC') {
        return 'Status Call'
    } else if (result === 'FW') {
        return 'Forwarded'
    } else if (result === 'OT') {
        return 'Other'
    } else {
        return `Unknown - ${result}`
    }
}

module.exports = getAdvertiserFollowupResult
