import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import Loading from '../../../../../templates/UI/Loading/Loading'

const TriggerModal = ({ mode, events, campaignId, showSystemMessage, isOpen, closeModal, data, isCreate, refetch, kpiId, rateName, trigger_id }) => {
    // TODO: RENAME THIS COMPONENT TO TriggerModal, modify backend to accept mode for kpi vs rate
    const [formData, setFormData] = useState({ event_id: 486, trigger_period: 0, monetary_value: 0 })
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        if (isCreate) {
            setDataLoaded(true)
            return
        }
        getTriggerInfo()
    }, [data])

    const getTriggerInfo = () => {

        let url = `campaign/${campaignId}/trigger/${mode}/${trigger_id}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setFormData({
                        event_id: result.trigger.event_id,
                        monetary_value: result.trigger.monetary_value,
                        event_mode: result.trigger.event_mode,
                        related_event_id: result.trigger.related_event_id,
                        trigger_period: result.trigger.related_event_period,
                        delete_status: result.trigger.delete_status
                    })
                    setDataLoaded(true)

                } else {
                    showSystemMessage('error', result.errors.message)
                }
                setDataLoaded(true)
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching trigger info.')
            })
    }

    const validate = () => {
        if (!(formData.event_id > 0)) {
            showSystemMessage('error', 'Event is required')
            return false
        }

        if (!formData.trigger_period && isNaN(formData.trigger_period)) {
            showSystemMessage('error', 'Trigger Period must be a number')
            return false
        }

        if (!formData.monetary_value && isNaN(formData.monetary_value)) {
            showSystemMessage('error', 'Monetary Value must be a number')
            return false
        }

        if (formData.monetary_value && !(formData.event_mode.length > 0)) {
            showSystemMessage('error', 'You must select an event mode if you are expecting a monetary value')
            return false
        }

        return true
    }

    const save = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/trigger/${mode}/${mode === 'kpi' ? data.kpi_trigger_id : trigger_id}`, 'PUT', formData)
            if (result.success) {
                showSystemMessage('success', 'Saved Mobile Event')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const create = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/trigger/${mode}?id=${mode === 'kpi' ? kpiId : rateName}`, 'POST', formData)
            if (result.success) {
                showSystemMessage('success', 'Created Trigger')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const deleteEvent = async () => {
        //save data to db
        const result = await callAPI(`campaign/${campaignId}/trigger/${mode}/${mode === 'kpi' ? data.kpi_trigger_id : trigger_id}`, 'DELETE', {})
        if (result.success) {
            showSystemMessage('success', 'Deleted Trigger')
            refetch()
            closeModal()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isCreate ? 'Create' : 'Edit'} {kpiId > 0 ? 'KPI' : 'Rate'} Trigger
                </Modal.Title>
            </Modal.Header>
            {dataLoaded ?
                <>
                    <Modal.Body>
                        <div className="d-flex align-items-center m-2">
                            <span className="w-25">Event</span>
                            <Form.Control as="select" defaultValue={formData.event_id} onChange={(e) => setFormData({ ...formData, event_id: e.target.value })} className="ml-2" disabled={!isCreate}>
                                {events?.length > 0 &&
                                    events.map((event, index) => (
                                        <option key={index} value={event.id}>
                                            {event.name}
                                        </option>
                                    ))}
                            </Form.Control>
                        </div>
                        <div className="d-flex align-items-center m-2">
                            <span className="w-25">Within (Hours)</span>
                            <Form.Control type="number" defaultValue={formData.trigger_period} onChange={(e) => setFormData({ ...formData, trigger_period: e.target.value })} className="ml-2" />
                        </div>
                        <div className="d-flex align-items-center m-2">
                            <span className="w-25">Of Event</span>
                            <Form.Control as="select" defaultValue={formData.related_event_id} onChange={(e) => setFormData({ ...formData, related_event_id: e.target.value })} className="ml-2">
                                <option value=""></option>
                                {events?.length > 0 &&
                                    events.map((event, index) => (
                                        <option key={index} value={event.id}>
                                            {event.name}
                                        </option>
                                    ))}
                            </Form.Control>
                        </div>
                        <div className="d-flex align-items-center m-2">
                            <span className="w-25">Monetary Value</span>
                            <Form.Control type="number" defaultValue={formData.monetary_value} onChange={(e) => setFormData({ ...formData, monetary_value: e.target.value })} className="ml-2" />
                        </div>
                        <div className="d-flex align-items-center m-2">
                            <span className="w-25">Mode</span>
                            <Form.Control as="select" defaultValue={formData.event_mode} onChange={(e) => setFormData({ ...formData, event_mode: e.target.value })} className="ml-2">
                                <option value=""></option>
                                <option value="SUM">Sum values of all subsequent events</option>
                                <option value="IND">One time value</option>
                            </Form.Control>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {isCreate ? (
                            <Button variant="primary" onClick={create}>
                                Create
                            </Button>
                        ) : (
                            <>
                                <Button variant="primary" onClick={save}>
                                    Save
                                </Button>
                                {formData.delete_status === 'Y' && (
                                    <Button variant="danger" onClick={deleteEvent}>
                                        Delete
                                    </Button>
                                )}
                            </>
                        )}
                    </Modal.Footer>
                </> : <Loading />}
        </Modal>
    )
}

export default TriggerModal
