import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Loading from '../../../../templates/UI/Loading/Loading'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import AddEventModal from '../Modals/AddEventModal'
import SurveyConditionDisplay from '../Templates/SurveyConditionDisplay/SurveyConditionDisplay'
import callAPI from '../../../../helpers/callAPI'

class AvatarSurveyEventOverrides extends Component {
    state = {
        dataLoaded: false,
        surveyId: 0,
        surveyDescription: '',
        survey: [],
        events: [],
        normalEvents: [],
        addEventModalOpen: false,
    }

    componentDidMount() {
        const fetchData = async () => {
            let surveyId = !isNaN(this.props.surveyId) ? this.props.surveyId : 0

            if (surveyId > 0) {
                // If > 0 , it's editing an existing site. Use the PUT url
                // Fetch the info for this site and set the defaults!

                const authtoken = localStorage.getItem('adm-auth-token')

                const result = await callAPI(`avatar/survey/${surveyId}/eventOverrides`, 'GET')

                if (result['success'] === true) {
                    this.setState({
                        ...this.state,
                        dataLoaded: true,
                        surveyId: result.surveyId,
                        surveyDescription: result.surveyDescription,
                        survey: result.survey,
                        events: result.events,
                        normalEvents: result.normalEvents,
                    })
                } else if (result['success'] === false) {
                    this.props.showSystemMessage('error', result.errors.message)
                } else {
                    this.props.showSystemMessage('error', 'Error fetching event overrides.')
                }
            } else {
                // If 0, it's a new site. Use the POST url
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                })
            }
        }
        fetchData()
    }

    toggleAddEventModal = (eventId) => {
        this.setState({
            ...this.state,
            addEventModalOpen: eventId,
        })
    }

    saveEvent = (eventId, dbEventId, eventName, mode, action, url, postType, postResponseType, postJsonKey, postJsonValue, postTextValue, postSaleKey, postDataKey, eventCompleteMsg, listArrayKey, listTitleKey, listParagraphKey, listUrlKey, listImageKey, listButtonText, conditions, questionMap, fraudOverrides, rateName, payout, trackingType, trafficType, merchantId, override, prepop, editing) => {
        const fetchData = async () => {
            const fetchUrl = `avatar/survey/${this.props.surveyId}/eventOverrides`

            this.setState({
                ...this.state,
                dataLoaded: false,
            })

            let eventObject = {
                eventId: eventId,
                dbEventId: dbEventId,
                eventName: eventName,
                mode: parseInt(mode),
                action: action,
                url: url,
                postType: postType,
                postResponseType: postResponseType,
                postJsonKey: postJsonKey,
                postJsonValue: postJsonValue,
                postTextValue: postTextValue,
                postSaleKey: postSaleKey,
                postDataKey: postDataKey,
                eventCompleteMsg: eventCompleteMsg,
                listArrayKey: listArrayKey,
                listTitleKey: listTitleKey,
                listParagraphKey: listParagraphKey,
                listUrlKey: listUrlKey,
                listImageKey: listImageKey,
                listButtonText: listButtonText,
                conditions: conditions,
                questionMap: questionMap,
                fraudOverrides: fraudOverrides,
                rateName: rateName,
                payout: payout,
                trackingType: trackingType,
                trafficType: trafficType,
                merchantId: merchantId,
                override: override,
                prepop: prepop,
            }

            let formData = {
                surveyId: this.state.surveyId,
                survey: this.state.survey,
                event: eventObject,
            }
            const result = await callAPI(fetchUrl, 'POST', formData)
            if (result['success'] === true) {
                // Redirect to the editing page
                this.props.showSystemMessage('success', 'Override saved.')
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    addEventModalOpen: false,
                    surveyId: result.surveyId,
                    surveyDescription: result.surveyDescription,
                    survey: result.survey,
                    events: result.events,
                })
            } else {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                })
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }

    deleteEvent = (eventId) => {
        const fetchData = async () => {
            const fetchUrl = `avatar/survey/${this.props.surveyId}/eventOverrides/${eventId}`

            this.setState({
                ...this.state,
                dataLoaded: false,
            })

            const result = await callAPI(fetchUrl, 'DELETE')
            if (result['success'] === true) {
                // Redirect to the editing page
                this.props.showSystemMessage('success', 'Override deleted.')
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    addEventModalOpen: false,
                    surveyId: result.surveyId,
                    surveyDescription: result.surveyDescription,
                    survey: result.survey,
                    events: result.events,
                })
            } else {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                })
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Avatar Survey Builder</h1>
                </div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        <div className="mb-2">
                            <div className="card">
                                <div className="p-3 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className="mb-h1-dark mr-2">Event overrides for Survey:</div>
                                        <div className="text-light">
                                            <Link to={`/avatar/survey/${this.state.surveyId}`}>{this.state.surveyDescription}</Link>
                                        </div>
                                    </div>
                                    <button className="btn btn-warning btn-sm" onClick={() => this.toggleAddEventModal(0)}>
                                        + New Override
                                    </button>
                                    <AddEventModal open={this.state.addEventModalOpen === 0} closeModal={() => this.toggleAddEventModal(false)} conditions={[]} questionMap={[]} fraudOverrides={[]} override={true} survey={this.state.survey} events={this.state.events} normalEvents={this.state.normalEvents} editEvent={this.saveEvent} />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-0">
                                <table className="table table-card table-striped table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="border-top-0">
                                                ID
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Affiliate
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Overridden Event
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Trigger
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Event Action
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Rate Name
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Payout Type
                                            </th>
                                            <th scope="col" className="border-top-0">
                                                Conditions
                                            </th>
                                            <th scope="col" className="border-top-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.events.map((event) => {
                                            return (
                                                <React.Fragment>
                                                    <AddEventModal eventId={event.eventId} dbEventId={event.dbEventId} editing={true} events={this.state.events} open={this.state.addEventModalOpen === event.eventId} closeModal={() => this.toggleAddEventModal(false)} action={event.action} url={event.url} postType={event.postType} postResponseType={event.postResponseType} postJsonKey={event.postJsonKey} postJsonValue={event.postJsonValue} postTextValue={event.postTextValue} postSaleKey={event.postSaleKey} eventCompleteMsg={event.eventCompleteMsg} listArrayKey={event.listArrayKey} listTitleKey={event.listTitleKey} listParagraphKey={event.listParagraphKey} listUrlKey={event.listUrlKey} listImageKey={event.listImageKey} listButtonText={event.listButtonText} eventName={event.eventName} mode={event.mode} conditions={event.conditions} questionMap={event.questionMap} fraudOverrides={event.fraudOverrides} rateName={event.rateName} payout={event.payout} trackingType={event.trackingType} trafficType={event.trafficType} merchantId={event.merchantId} override={event.override} prepop={event.prepop} editEvent={this.saveEvent} deleteEvent={this.deleteEvent} survey={this.state.survey} />
                                                    <tr key={event.eventId}>
                                                        <td width="85px">{event.override.affiliateId}</td>
                                                        <td>
                                                            <Affiliate aid={event.override.affiliateId} name={event.override.contact} status={event.override.status} priority={event.override.priority} />
                                                        </td>
                                                        <td>
                                                            <h5 className="m-0">
                                                                <span className="badge badge-info rounded">{event.override.eventName}</span>
                                                            </h5>
                                                        </td>
                                                        <td>{event.mode === '1' ? 'On Completion' : 'On Condition'}</td>
                                                        <td>{event.action}</td>
                                                        <td>{event.rateName}</td>
                                                        <td>{event.payout === 'A' ? 'Affiliate' : 'Network'}</td>
                                                        <td>
                                                            {event.conditions.length > 0 ? (
                                                                event.conditions.map((outerCondition, outerIndex) => {
                                                                    //Now map through inner conditions
                                                                    return <SurveyConditionDisplay conditions={event.conditions} outerCondition={outerCondition} outerIndex={outerIndex} />
                                                                })
                                                            ) : (
                                                                <div className="alert alert-secondary m-0 p-1 text-small">No conditions.</div>
                                                            )}
                                                        </td>
                                                        <td align="right">
                                                            <button className="btn btn-secondary btn-sm" onClick={() => this.toggleAddEventModal(event.eventId)}>
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default AvatarSurveyEventOverrides
