import { Button, Form, Modal } from 'react-bootstrap'

import moment from 'moment'
import { useState } from 'react'
import callAPI from '../../../helpers/callAPI'
import DatePicker from 'react-datepicker'
import { Calendar } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UpdateBalancesModal = ({ showSystemMessage, isOpen, setIsOpen, refetch }) => {

    const [balances, setBalances] = useState('')
    const [date, setDate] = useState('')
    const [submitting, setSubmitting] = useState(false)

    const updateBalances = async (e) => {
        e.preventDefault();
        setSubmitting(true)
        const result = await callAPI(`advertisers/balances`, 'POST', { balances: balances, date: date })
        if (result.success) {
            showSystemMessage('success', 'Balances updated successfully')
            setIsOpen(false)
            setDate('')
            setBalances('')
            refetch()
        } else {
            showSystemMessage('error', result.errors.message ? result.errors.message : 'There was an error updating the balances')
        }
        setSubmitting(false)
    }

    const handleDateChange = (date) => {
        if (date){
            setDate(moment(date).toDate())
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header className="d-flex justify-content-start align-items-start flex-column">
                <Modal.Title>Reconcile Merchant Balances</Modal.Title>                
            </Modal.Header>
            <form onSubmit={(e)=>updateBalances(e)}>
                <Modal.Body>                
                    <div className="alert alert-info">⚠️ Input the <strong>EXACT DATE</strong> used on the QuickBooks Customer Balance Summary Report. ⚠️</div>                    
                    <div className="d-flex align-items-center mt-2">
                        <DatePicker placeholderText="YYYY-MM-DD" className="form-control form-control-sm" required selected={date} onChange={(date) => handleDateChange(date)} dateFormat="yyyy-MM-dd" />
                        <Calendar size={18} color="#007bff" />
                    </div>        
                    <div className="mt-2"><i>Balance, Merchant ID, Balance date</i></div>
                    <Form.Control as="textarea" rows={10} value={balances} onChange={(e) => setBalances(e.target.value)} required />
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary btn-sm" disabled={submitting}>
                        {submitting && <FontAwesomeIcon icon="spinner" spin />} Update Balances
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default UpdateBalancesModal
