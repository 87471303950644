import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import callAPI from '../../helpers/callAPI'

const ForgotPassword = ({ showSystemMessage }) => {
    const [username, setUsername] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleChange = (e) => {
        setUsername(e.target.value)
    }

    const handleFormSubmit = async () => {
        setLoading(true)
        let data = await callAPI(`admin/forgotPassword`, 'POST', { username: username })
        if (data['success'] == true) {
            showSystemMessage('success', 'You will be receiving an email with instructions on how to reset your password within a few minutes')
            setSuccess(true)
        } else {
            showSystemMessage('error', 'Invalid Username')
        }
        setLoading(false)
    }

    return (
        <div className="login-background d-flex">
            <div className="login-content rounded">
                <div className="text-white">
                    <img className="mb-4 rounded login-logo" src={maxbountyLogoSquare} alt="MaxBounty" width="80px" height="80px" />
                    <p>Password Reset</p>
                    <input
                        value={username}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleFormSubmit()
                            }
                        }}
                        onChange={handleChange}
                        className="form-control login-input-text mb-2"
                        type="text"
                        placeholder="Username"
                        name="username"
                        disabled={success}
                    />
                    {loading ? (
                        <button className="btn btn-primary mt-4 mb-2 w-100" disabled>
                            <FontAwesomeIcon icon="spinner" spin />
                        </button>
                    ) : (
                        <button onClick={handleFormSubmit} className="btn btn-primary mt-4 mb-2 w-100" disabled={success}>
                            Submit
                        </button>
                    )}
                    <Link className="text-secondary" to="/">
                        Back to login page
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
