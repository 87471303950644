import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import AdvertiserSearch from '../../../../components/AdvertiserSearch/AdvertiserSearch'
import callAPI from '../../../../helpers/callAPI'

const CustomStripeLink = ({ isOpen, handleClose, showSystemMessage }) => {
    const [advertiser, setAdvertiser] = useState({})
    const [invoicedAmount, setInvoicedAmount] = useState()
    const [invoiceNumber, setInvoiceNumber] = useState()
    const [stripeLink, setStripeLink] = useState('')
    const [copied, setCopied] = useState(false)

    const getStripeLink = (e) => {
        e.preventDefault()

        const postBody = {
            amount: invoicedAmount,
            invoice: invoiceNumber,
        }

        callAPI(`advertiser/${advertiser.id}/stripe`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setStripeLink(result.stripe_link)
                } else {
                    showSystemMessage('error', 'An error occurred while getting Stripe link')
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while getting Stripe link')
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Stripe Payment Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="25%">Advertiser:</td>
                                <td>
                                    <AdvertiserSearch setAdvertiser={setAdvertiser} />
                                </td>
                            </tr>
                            <tr>
                                <td>Invoiced Amount:</td>
                                <td>
                                    <input name="invoiced_amount" className="form-control" defaultValue="" maxLength="10" required onChange={(e) => setInvoicedAmount(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Invoice Number:</td>
                                <td>
                                    <input name="invoice_number" className="form-control" defaultValue="" maxLength="10" required onChange={(e) => setInvoiceNumber(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Stripe Link:</td>
                                <td>
                                    <button className="btn btn-warning" onClick={getStripeLink}>
                                        Generate Payment Link
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <textarea cols="40" rows="3" className="form-control" defaultValue={stripeLink} />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td align="right">
                                    <CopyToClipboard text={stripeLink} onCopy={() => setCopied(true)}>
                                        <button type="button" className="btn btn-outline-primary" size="sm">
                                            <FontAwesomeIcon icon="copy" />
                                        </button>
                                    </CopyToClipboard>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CustomStripeLink
