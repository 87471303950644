import React, { useState, useEffect } from 'react'
import callAPI from '../../helpers/callAPI'
import Loading from '../../templates/UI/Loading/Loading'
import Advertiser from '../../templates/Advertiser/Advertiser'

const MailerRecipientListAdvertisers = ({ filters, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [advertisers, setAdvertisers] = useState([])

    useEffect(async () => {
        let formattedFilters = { filters: filters }

        let recipientList = await callAPI('batchmail/advertisers/preview', 'POST', formattedFilters)

        if (!recipientList.success) {
            showSystemMessage('error', recipientList.errors.message)
        }

        setDataLoaded(true)
        setAdvertisers(recipientList.advertisers)

        return
    }, [])

    return (
        <div className="card">
            <div className="card-header">Recipient List {dataLoaded && advertisers.length > 0 && `(${advertisers.length} advertisers)`}</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    advertisers.length > 0 ? (
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Advertiser</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {advertisers.map((advertiser) => (
                                    <tr>
                                        <td>{advertiser.id}</td>
                                        <td>
                                            <div className="pl-2">
                                                <Advertiser id={advertiser.id} name={advertiser.name} />
                                            </div>
                                        </td>
                                        <td>{advertiser.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>No advertisers</div>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default MailerRecipientListAdvertisers
