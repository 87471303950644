import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import CampaignCapWatchModal from '../../../components/CampaignCapWatchModal/CampaignCapWatchModal'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import getJobName from '../../../helpers/getJobName'
import useCallApi from '../../../hooks/useCallApi'
import { FaSkype, FaExternalLinkAlt } from 'react-icons/fa'

import Advertiser from '../../../templates/Advertiser/Advertiser'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const CampaignInfo = ({ campaign, refetch, showSystemMessage }) => {
    const auth = useAuth()

    const [modal, setModal] = useState({ open: false, id: 0, campaign: false, type: false, cap: false })
    const auth_addCapWatch = useProtectedContent(auth.permissions, 'add_cap_watch')
    const showCampaignSettings = useProtectedContent(auth.permissions, 'campaign_settings')
    const showEditJob = useProtectedContent(auth.permissions, 'scheduled_jobs')
    const [manager, setManager] = useState(auth.user)
    const [deleting, setDeleting] = useState(false)

    const { data: capwatchData, dataLoaded: capwatchDataLoaded } = useCallApi(`campaigns/capWatch/${manager.id}`, 'GET', {}, [manager.id])

    const handleCloseModal = () => {
        setModal({ open: false, id: 0, campaign: false, type: false, cap: false })
    }

    const deleteWatch = (capWatchId) => {
        setDeleting(true)

        let url = `campaigns/capWatch/${manager.id}`

        callAPI(url, 'DELETE', { id: capWatchId })
            .then((result) => {
                if (result['success'] === true) {
                    refetch()
                    showSystemMessage('success', 'Cap watch deleted.')
                } else if (result['success'] === false) {
                    refetch()
                    setDeleting(false)
                    showSystemMessage('error', 'An error occurred while deleting cap watch.')
                }
            })
            .catch((error) => {
                console.log(`Error while deleting cap watch: ${error}`)
                showSystemMessage('error', 'An error occurred while deleting cap watch.')
                setDeleting(false)
            })
    }

    const getCopyDetailsImage = () => {
        let text = campaign.name + ' - ID: ' + campaign.id + '\nLanding Page Preview:\n'
        let rateText = ''
        
        let campaignPreviewImage = `https://maxbounty.com/getlp.asp?m=${campaign.advertiser_id}&o=${campaign.id}&l=${campaign.landing_pages[0]?.lp_record_id}&s=full`

        text += campaignPreviewImage + '\nCommission Details:\n'

        rateText = campaign.rates.map((rate) => { if (rate.status === 'A') {return rateText + rate.description + ' : ' + (rate.display_rate_text !== '' ? rate.display_rate_text : rate.affiliate_rate_text) + '\n'} else {return ''} })
        text += rateText.toString().replace(/,/g, '')
        text += '\nFull details found here: ' + 'https://affiliates.maxbounty.com/campaign/' + campaign.id
        return text
    }

    const getCopyDetailsUrl = () => {
        let text = campaign.name + ' - ID: ' + campaign.id + '\nDefault Landing Page:\n'
        let rateText = ''
        let defaultLandingPageUrl = ''
        // get default landing page link with filter function
        if (campaign.visible_url) {
            defaultLandingPageUrl = campaign.visible_url
        } else {
            let theDefault = campaign.landing_pages.filter((lp) => lp.default_flag === 'Y')
            defaultLandingPageUrl = theDefault[0] ? theDefault[0].landing_url : ''
        }

        text += defaultLandingPageUrl + '\n\nCommission Details:\n'

        rateText = campaign.rates.map((rate) => { if (rate.status === 'A') {return rateText + rate.description + ' : ' + (rate.display_rate_text !== '' ? rate.display_rate_text : rate.affiliate_rate_text) + '\n'} else {return ''} })
        text += rateText.toString().replace(/,/g, '')

        text += '\nFull details found here: ' + 'https://affiliates.maxbounty.com/campaign/' + campaign.id
        return text
    }

    return (
        <div className="card pt-2 mb-2">
            <CampaignCapWatchModal key={modal.id} manager={manager} campaign={{ id: campaign.id, name: campaign.name }} open={modal.open} close={handleCloseModal} refetch={refetch} showSystemMessage={showSystemMessage} />
            <div className="d-flex justify-content-between border-bottom p-2 align-items-center">
                <div>
                    <div>
                        <h5 className="font-weight-bold">
                            {
                                showCampaignSettings ?
                                    <Link className={`${campaign.status.name === 'Cancelled' ? 'text-danger' : ''}`} to={`/campaign/${campaign.id}/settings`}>
                                        {campaign.name} ({campaign.id})
                                    </Link>
                                    :
                                    <>{campaign.name} ({campaign.id})</>
                            }
                        </h5>
                    </div>                    
                </div>
                {
                    auth_addCapWatch ?
                        auth_addCapWatch && capwatchDataLoaded && capwatchData.campaigns.findIndex((capwatch) => parseInt(capwatch.campaign.id) === parseInt(campaign.id)) === -1 ? (
                            <div>
                                <button className="btn btn-warning btn-sm" onClick={() => setModal({ open: true, id: 0, campaign: { id: campaign.id, name: campaign.name } })}>
                                    Watch Cap
                                </button>
                            </div>
                        ) : !deleting ? (
                            <div>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => {
                                        if (capwatchDataLoaded) {
                                            deleteWatch(capwatchData.campaigns[capwatchData.campaigns.findIndex((capwatch) => parseInt(capwatch.campaign.id) === parseInt(campaign.id))].id)
                                        }
                                    }}
                                >
                                    Remove Cap Watch
                                </button>
                            </div>
                        ) : (
                            <div>
                                <button className="btn btn-secondary btn-sm" disabled>
                                    {' '}
                                    Remove Cap Watch
                                </button>
                            </div>
                        )
                        :
                        ''
                }
            </div>
            <div className="p-2 border-bottom">
                Advertiser: <Advertiser id={campaign.advertiser.id} name={campaign.advertiser.name} status={campaign.advertiser.status.value} />
                <span className="text-secondary ml-2"> / Manager: {campaign.advertiser.manager.name} / Bizdev: {campaign.advertiser.bizdev_manager ? campaign.advertiser.bizdev_manager.name : ''}</span>
            </div>
            <div className="p-2 border-bottom d-flex justify-content-between">
                <div>
                    <span className={`mb-pill ${campaign.status.name === 'Cancelled' ? 'text-danger' : campaign.status.name === 'Active' ? 'text-success' : ''}`}>{campaign.status.name}</span>
                    <span className="mb-pill">{moment.utc(campaign.expiry_date).year() === 2099 ? `Doesn't Expire` : `Expires on ${moment.utc(campaign.expiry_date).format('YYYY-MM-DD HH:mm:ss')}`}</span>
                    <span className="mb-pill">{campaign.tracking.type.name} Tracking</span>
                </div>
                <div>
                    {campaign.type.name === 'Avatar' && <span className="mb-pill">Avatar</span>}
                    {campaign.tracking.is_mobile_offer && <span className="mb-pill">📱 Mobile Campaign</span>}
                    {campaign.mobile_optimized && <span className="mb-pill">📱 Mobile Optimized</span>}
                </div>
            </div>
            {
                campaign.scheduled_jobs.length > 0 &&
                    <div className="p-2 border-bottom">
                        {
                            campaign.scheduled_jobs.map( job => {
                                return <div><span className="text-secondary"><FontAwesomeIcon icon="calendar-alt" /></span> Scheduled to {getJobName(job.name)} on {moment(job.date).utc().format('YYYY-MM-DD HH:mm:ss')} {showEditJob && <Link to='/admin/jobs'><FontAwesomeIcon icon="pencil-alt" /></Link>}</div>})
                        }                        
                    </div>
            }            
            <div className="p-2 border-bottom d-flex justify-content-between align-items-center">
                <div>
                    <CopyToClipboard text={getCopyDetailsImage()}>
                        <button type="button" className="btn btn-sm btn-outline-primary mr-3">
                            <FontAwesomeIcon icon="copy" /> Copy (Image)
                        </button>
                    </CopyToClipboard>

                    <CopyToClipboard text={getCopyDetailsUrl()}>
                        <button type="button" className="btn btn-sm btn-outline-primary">
                            <FontAwesomeIcon icon="copy" /> Copy (URL)
                        </button>
                    </CopyToClipboard>
                </div>
                <div>
                    <a target="_blank" href={campaign.visible_url}>Preview Lander <FaExternalLinkAlt color="#007bff" /></a>
                </div>                
            </div>
            <div className="p-2 border-bottom" dangerouslySetInnerHTML={{ __html: campaign.description }} />
            {campaign.notes.general && <div className="p-2" dangerouslySetInnerHTML={{ __html: campaign.notes.general }}></div>}
        </div>
    )
}

export default CampaignInfo
