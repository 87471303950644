import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import useCallApi from '../../hooks/useCallApi'
import EarningsTable from './EarningsTable/EarningsTable'
import Loading from '../../templates/UI/Loading/Loading'
import TrailingTwelveMonthGraph from '../TrailingTwelveMonthGraph/TrailingTwelveMonthGraph'

const EarningSummary = ({ affiliateId, advertiserId, showSystemMessage, statPeriod = 'month', target }) => {
    const [selectedStatPeriod, setSelectedStatPeriod] = useState(statPeriod)

    let startDate = moment().startOf('month').format('YYYY-MM-DD')
    let endDate = moment().endOf('month').format('YYYY-MM-DD')

    switch (selectedStatPeriod) {
        case 'today':
            startDate = moment().startOf('day').format('YYYY-MM-DD')
            endDate = moment().endOf('day').format('YYYY-MM-DD')
            break
        case 'yesterday':
            startDate = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
            endDate = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
            break
        case 'month':
            startDate = moment().startOf('month').format('YYYY-MM-DD')
            endDate = moment().endOf('month').format('YYYY-MM-DD')
            break
        case 'last_month':
            startDate = moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD')
            endDate = moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
            break
        case 'year':
            startDate = moment().startOf('year').format('YYYY-MM-DD')
            endDate = moment().endOf('year').format('YYYY-MM-DD')
            break
    }

    let apiUrl = affiliateId ? `reports/campaigns?affiliateId=${affiliateId}&startDate=${startDate}&endDate=${endDate}&withAvatar=true` : `reports/campaigns?advertiserId=${advertiserId}&startDate=${startDate}&endDate=${endDate}&withAvatar=true`

    const { data, dataLoaded } = useCallApi(apiUrl, 'GET', {}, [affiliateId, advertiserId, selectedStatPeriod])

    let statLabelClasses = 'btn btn-secondary'

    const handleStatTimeChange = (event) => {
        event.preventDefault()
        setSelectedStatPeriod(event.target.value)
    }

    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Earnings Summary</div>
                <div className="float-right">
                    <Link to={target === 'affiliate' ? `/reports/stats/campaigns?affiliateId=${affiliateId}&startDate=${startDate}&endDate=${endDate}&navigation=affiliate&withAvatar=true` : `/reports/stats/campaigns?advertiserId=${advertiserId}&startDate=${startDate}&endDate=${endDate}&navigation=advertiser&withAvatar=true`} className="text-primary">
                        Full Report
                    </Link>
                </div>
            </div>

            {affiliateId && <TrailingTwelveMonthGraph affiliateId={affiliateId} advertiserId={advertiserId} showSystemMessage={showSystemMessage} />}

            <div className="m-2 btn-group btn-group-sm btn-group-toggle" data-toggle="buttons" role="group">
                <label className={selectedStatPeriod === 'today' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="today" onClick={handleStatTimeChange} /> Today
                </label>
                <label className={selectedStatPeriod === 'yesterday' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="yesterday" onClick={handleStatTimeChange} /> Yesterday
                </label>
                <label className={selectedStatPeriod === 'month' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="statOptions" id="option2" className="change-graph-option" autoComplete="off" value="month" onClick={handleStatTimeChange} /> This Month
                </label>
                <label className={selectedStatPeriod === 'last_month' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="statOptions" id="option3" className="change-graph-option" autoComplete="off" value="last_month" onClick={handleStatTimeChange} /> Last Month
                </label>
                <label className={selectedStatPeriod === 'year' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="statOptions" id="option3" className="change-graph-option" autoComplete="off" value="year" onClick={handleStatTimeChange} /> Year
                </label>
            </div>

            {dataLoaded ? <EarningsTable advertiserId={advertiserId} affiliateId={affiliateId} target={target} stats={data.report} startDate={startDate} endDate={endDate} /> : <Loading></Loading>}
        </div>
    )
}

export default EarningSummary
