import { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ReversalStatusReport = ({ showSystemMessage }) => {
    document.title = 'Advertiser Reversal Status Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_reversal_status')

    const [adminId, setAdminId] = useState('')
    const { data, dataLoaded, refetch } = useCallApi(`advertisers/reversalStatusReport?mgrId=${adminId}`, 'GET', {}, [adminId], showSystemMessage)

    const updateReversalStatus = async (advertiserId, reversalType) => {
        const result = await callAPI(`advertisers/reversalStatusReport?advertiserId=${advertiserId}&mode=${reversalType}`, 'PUT')
        if (result.success) {
            showSystemMessage('success', 'Reversal Status Updated')
            refetch()
        } else {
            showSystemMessage('error', 'Error Updating Reversal Status')
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Advertiser ID',
                accessor: 'advertiser.id',
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: ({ cell: { value, row } }) => {
                    return <Advertiser name={value} id={row.original.advertiser.id} />
                },
            },
            {
                Header: 'Manager',
                accessor: 'manager.name',
            },
            {
                Header: 'Reversal Requested',
                accessor: 'reversals_requested_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== 'Invalid date' ? value : ''}</>
                },
            },
            {
                Header: 'Reversal Received',
                accessor: 'reversals_received_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== 'Invalid date' ? value : ''}</>
                },
            },
            {
                Header: 'Reversal Processed',
                accessor: 'reversals_processed_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== 'Invalid date' ? value : ''}</>
                },
            },
            {
                Header: 'Updated By',
                accessor: 'reversals_updated_by',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== 'Invalid date' ? value : ''}</>
                },
            },
            {
                Header: '',
                accessor: 'advertiser',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex justify-content-start">
                            <Button className="mr-2" variant="warning" size="sm" onClick={() => updateReversalStatus(tableData.value.id, 'requested')}>
                                Requested
                            </Button>
                            <Button className="mr-2" variant="primary" size="sm" onClick={() => updateReversalStatus(tableData.value.id, 'received')}>
                                Received
                            </Button>
                            <Button className="mr-2" variant="success" size="sm" onClick={() => updateReversalStatus(tableData.value.id, 'processed')}>
                                Processed
                            </Button>
                            <Button variant="danger" size="sm" onClick={() => updateReversalStatus(tableData.value.id, 'reset')}>
                                Reset Dates
                            </Button>
                        </div>
                    )
                },
            },
        ]

        return (
            <>
                <ReportTable data={data.report} columns={columns} />
            </>
        )
    }

    return (
        <Card>
            <Card.Header className="d-flex align-items-center">
                <h1 className="mb-h1-dark mr-2">Advertisers Reversal Status Report</h1>
                <div>
                    <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />
                </div>
            </Card.Header>
            {dataLoaded ? data.report.length === 0 ? <div className="p-3">No missing invoice information found.</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default ReversalStatusReport
