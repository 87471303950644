import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Calendar } from 'react-feather'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import callAPI from '../../../helpers/callAPI'
import FollowupSchedule from './FollowupSchedule/FollowupSchedule'
import Checkbox from '../../../pages/Reports/Filters/Checkbox/Checkbox'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'
import styles from './FollowupForm.module.scss'
import { useAuth } from '../../../context/AuthContext'
import addWeekdays from '../../../helpers/addWeekdays'

const FollowupForm = (props) => {
    const auth = useAuth()

    const [submitting, setSubmitting] = useState(false)
    const [date, setDate] = useState('')
    const [results, setResults] = useState('')
    const [followupNote, setFollowupNote] = useState('')
    const [preserveFU, setPreserveFU] = useState(props.preserveFollowups)
    const [showScheduleModal, setShowScheduleModal] = useState(false)

    const { showSystemMessage, affiliateId } = props

    const saveFollowupNote = (event) => {
        event.preventDefault()
        setSubmitting(true)
        let url = `affiliate/${affiliateId}/followups`

        callAPI(url, 'POST', { followupDate: moment(date).format('YYYY-MM-DD HH:mm:ss'), results: results, followupNote: followupNote, preserveFollowups: preserveFU })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setDate('')
                    setFollowupNote('')
                    setResults('')
                    setSubmitting(false)
                    props.updateList()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while saving affiliate followup notes: ', error)
                showSystemMessage('error', 'An error occurred while saving affiliate followup notes')
            })
    }

    const handleSchedule = () => {
        setShowScheduleModal(true)
    }

    const toggle = () => {
        setShowScheduleModal(false)
    }

    const handleDateChange = (date) => {
        let newDate = addWeekdays(date)
        setDate(moment(newDate).toDate())
    }

    const handleNoteInput = (event) => {
        setFollowupNote(event.target.value)
    }

    const handleResult = (event) => {
        setResults(event.target.value)
    }

    const checkboxChangeHandler = (event) => {
        setPreserveFU(!preserveFU)
    }

    return (
        <div className="pt-2">
            <Modal show={showScheduleModal} onHide={toggle} size="sm">
                <FollowupSchedule followupSchedule={props.followupSchedule} managerName={props.managerName} managerId={props.managerId} followupType={props.followupType} />
            </Modal>
            <form className="form-group" onSubmit={saveFollowupNote} method="POST">
                <div className="d-flex p-2 align-items-center">
                    <div style={{ width: '40%' }} className="pr-2" name="followup_results">
                        <select className="form-control form-control-sm" onChange={handleResult} value={results} required>
                            <option value="">Select Results</option>
                            {/* <option value="PT">Promise to Run</option> */}
                            <option value="SC">Status Call</option>
                            <option value="EM">Sent E - Mail</option>
                            <option value="CN">Called NA / LM</option>
                            <option value="CS">Called Spoke</option>
                            <option value="IN">IMed NA</option>
                            <option value="IC">IMed Chatted</option>
                            <option value="FW">Forwarded</option>
                            <option value="OT">Other</option>
                            <option value="RQ">Compliance Request</option>
                            {/* <CFIF listFindNoCase("G,N,C,P", mgr_role) > */}
                            {auth.user.role !== 'AM_JUNIOR' && auth.user.role !== 'AM_SENIOR' && auth.user.role !== 'AM_JR_TEAMLEAD' && auth.user.role !== 'AFFILIATE_DIRECTOR' && (
                                <>
                                    <option value="CM"> Compliance</option>
                                    <option value="QC"> Quality Check</option>
                                    <option value="NP"> Never Paid</option>
                                    <option value="TM"> Traffic Methods</option>
                                    <option value="PM"> Promotional Method</option>
                                    <option value="AM"> AM information</option>
                                    <option value="CH"> Checked</option>
                                    <option value="TR"> Transfer to Senior AM</option>
                                </>
                            )}
                            {/* </CFIF > */}
                        </select>
                    </div>
                    <div className="px-3">
                        <Checkbox id="preserve" name="Preserve F/U" checkboxChanged={checkboxChangeHandler} isChecked={preserveFU} />
                    </div>
                </div>
                <div className="d-flex p-2 align-items-center">
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().startOf('day').add(36, 'hours').format('YYYY/MM/DD HH:mm:ss')))}>
                        One Day
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().startOf('day').add(60, 'hours').format('YYYY/MM/DD HH:mm:ss')))}>
                        Two Days
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().startOf('day').add(1, 'week').add(12, 'hours').format('YYYY/MM/DD HH:mm:ss')))}>
                        Next Week
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().startOf('day').add(2, 'weeks').add(12, 'hours').format('YYYY/MM/DD HH:mm:ss')))}>
                        Two Weeks
                    </button>
                    <div className="d-flex align-items-center">
                        <DatePicker placeholderText="Next Followup" className="form-control form-control-sm" selected={date} showTimeSelect onChange={(date) => handleDateChange(date)} dateFormat="yyyy/MM/dd hh:mm:ss" popperClassName={styles.popper} />
                        <Calendar size={18} color="#007bff" />
                    </div>
                </div>
                <div className="d-flex pl-2 pt-1">
                    <div style={{ width: '85%' }}>
                        <textarea placeholder="Add followup note..." className="form-control" onChange={handleNoteInput} value={followupNote} required={results === 'FW' ? false : true} />
                    </div>
                    <div className="text-center pt-2 pl-2">
                        <CustomButton variant="primary" type="submit" name="Save" spin={submitting} size="sm" />
                        <button type="button" className="btn btn-sm btn-link" onClick={handleSchedule}>
                            Schedule
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FollowupForm