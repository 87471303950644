import React, { Component } from 'react'
import States from '../templates/States'

class StateDropdown extends Component {
    render() {
        let { question, order, questionId } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Country Dropdown</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-3">
                    {
                        <select className="form-control" defaultValue="NY">
                            <States />
                        </select>
                    }
                </div>
            </div>
        )
    }
}

export default StateDropdown
