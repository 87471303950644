import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'

import { Link } from 'react-router-dom/cjs/react-router-dom'
import callAPI from '../../../helpers/callAPI'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import MerchantCampaignPullsFull from './MerchantCampaignPullsFull/MerchantCampaignPullsFull'

const MerchantCampaignPulls = ({ affiliateId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [pulls, setPulls] = useState([])
    const [showListAllModal, setShowListAllModal] = useState(false)

    useEffect(() => {
        getMerchantPulls()
    }, [affiliateId])

    const getMerchantPulls = () => {
        let url = `affiliate/merchantPulls/${affiliateId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setPulls(result.pulls)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching advertiser campaign pulls')
                setDataLoaded(true)
            })
    }

    const getFilteredArray = () => {
        let newArray = pulls.filter(item => moment(item.status_date).isAfter(moment().subtract(12, 'month')))
        return newArray
    }

    const handleClose = () => {
        setShowListAllModal(false)
    }

    const handleClick = (e) => {
        e.preventDefault()
        setShowListAllModal(true)
    }

    return (
        <div className="card mb-3">            
            <Modal isOpen={showListAllModal} toggle={handleClose} className="modal-lg">
                <MerchantCampaignPullsFull isOpen={showListAllModal} handleClose={handleClose} affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
            </Modal>
            <div className="card-header d-flex justify-content-between align-items-center">
                <span>Recent Advertiser/Campaign Pulls (Last 12 Months)</span>
                <a href={`${process.env.REACT_APP_PORTAL_URL}/affiliate/${affiliateId}/advertiserCampaignPulls`} className='shadow-none' variant='link' target="_blank" rel="noopener noreferrer" onClick={(e) => handleClick(e)}>List All</a>
            </div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    getFilteredArray().length === 0 ? (
                        <div className="p-3">No stats available.</div>
                    ) : (
                        <table className="table table-striped table-card m-0">
                            <tbody>
                                {getFilteredArray().map((item) => {
                                    return (
                                        <tr key={item.name}>
                                            <td>{item.record_type === 'O' ? 'Campaign' : 'Advertiser'}</td>
                                            <td>{item.record_type === 'O' ? <Campaign oid={item.id} name={item.name} status={item.status} /> : <Advertiser id={item.id} name={item.name} status={item.status} />}</td>
                                            <td className="text-right">{moment(item.status_date).format('YYYY/MM/DD')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default MerchantCampaignPulls
