import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import '../../../templates/ReportTable/ReportTable.css'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportAppliedFilters from '../../../templates/ReportAppliedFilters/ReportAppliedFilters'
import Campaign from '../../../templates/Campaign/Campaign'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Dollars from '../../../templates/Dollars/Dollars'
import useMaxbountyEntities from '../../../hooks/useMaxbountyEntities'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export default function AdvertiserInvoiceReport({ showSystemMessage }) {
    document.title = 'Advertiser Invoice Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_invoices')

    const [dataLoaded, setDataLoaded] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { advertiser, setAdvertiser } = useMaxbountyEntities()
    const { buildUrl } = useQueryParams()

    useEffect(() => {
        // Because it loads the same route (withLeads), there is odd functionality where the URL is built before withLeads is actually set.
        // To overcome this, we added props.location.search to the Route in App.js. By updating the key, we force a reload of the component.
        getReport()
    }, [location])

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = buildUrl(`/advertisers/invoices/report?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`advertisers/invoices/report?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    setTotals(result.totals)
                    if (result.advertiser) {
                        setAdvertiser(result.advertiser)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching invoice report: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching invoice report')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Adv ID',
                accessor: 'advertiser.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].advertiser.id} name={tableData.data[tableData.row.index].advertiser.name} status={tableData.data[tableData.row.index].advertiser.status.value} />
                },
            },
            {
                Header: 'Cam ID',
                accessor: 'campaign.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return tableData.data[tableData.row.index].override_affiliate_id > 0 ? (
                        <span>
                            <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} /> (AFF: {tableData.data[tableData.row.index].override_affiliate_id})
                        </span>
                    ) : (
                        <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                    )
                },
            },
            {
                Header: 'Rev Criteria',
                accessor: 'reversal_criteria.name',
                Cell: (tableData) => {
                    return tableData.data[tableData.row.index].reversal_criteria.name === 'Unknown' ? <span className="text-danger">{tableData.data[tableData.row.index].reversal_criteria.name}</span> : tableData.data[tableData.row.index].reversal_criteria.name
                },
            },
            {
                Header: 'Contact',
                accessor: 'advertiser.contact',
                Cell: (tableData) => {
                    return <a href={`mailto:${tableData.data[tableData.row.index].advertiser.email}`}>{tableData.data[tableData.row.index].advertiser.contact}</a>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sales',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Leads</div>,
                accessor: 'leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Cost</div>,
                accessor: 'cost',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Invoice Report</h1>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">Report Title</h1>
                                    </div>
                                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                    <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                        Refresh Report
                                    </button>
                                </div>
                            </div>
                            {dataLoaded && <ReportAppliedFilters advertiser={advertiser} />}
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
