import { useState } from 'react'

import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const AffiliateHoldPaymentAnalyzer = ({ showSystemMessage }) => {

    document.title = 'Hold Payment Analyzer'

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const [affiliateList, setAffiliateList] = useState('')
    const [analysisResult, setAnalysisResult] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        getAnalysis()
    }

    const getAnalysis = () => {
        setDataLoaded(false)
        let finalList = []

        if (affiliateList.trim() === '') {
            return
        }

        if (affiliateList.includes(',')) {
            finalList = affiliateList.split(',')
        } else {
            finalList = affiliateList.split(' ')
        }

        let invalidInput = false

        finalList = finalList.map((item) => {
            invalidInput = false
            item = item.trim()

            let isNum = /^\d+$/.test(item)

            if (item !== '' && !isNum) {
                invalidInput = true
                showSystemMessage('error', 'Affiliate IDs should be number')
                return
            }

            return item
        })

        if (invalidInput) {
            return
        }

        let postBody = {
            affiliate_list: finalList,
        }

        let url = `holdPaymentAnalyzer?startDate=${selectedStartDate}`

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setAnalysisResult(result.analysis)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error in payment hold analyzer: ${error}`)
                showSystemMessage('error', 'An error occurred n payment hold analyzer')
            })
    }

    const handleChange = (e) => {
        e.preventDefault()
        setAffiliateList(e.target.value)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Hold Payment Analyzer</h1>
            </div>

            <div className="card">
                <div className="card-body p-0">
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between p-3">
                            <div className="d-flex align-items-center">
                                <div className="mr-2">Date:</div>
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleQuickDate={handleQuickDate} singleDate />
                            </div>
                        </div>

                        <table className="table table-borderless table-card">
                            <tbody>
                                <tr>
                                    <td>Copy and paste the affiliate IDs you wish to analyze in this box</td>
                                    <td>Results of Analysis</td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <textarea cols={40} rows={15} name="affiliates_to_analyze" className="form-control" onChange={handleChange} defaultValue={affiliateList} />
                                    </td>
                                    <td className="align-self-start d-flex">
                                        {dataLoaded ? (
                                            <ul>
                                                {analysisResult.map((item) => {
                                                    if (item.returnCode === '1') {
                                                        return (
                                                            <li className="text-danger">
                                                                {item.affiliate_id} Hold Payment - {item.returnMsg}
                                                            </li>
                                                        )
                                                    } else {
                                                        return <li className="text-success">{item.affiliate_id} OK</li>
                                                    }
                                                })}
                                            </ul>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="submit" className="btn btn-primary">
                                            Analyze
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AffiliateHoldPaymentAnalyzer
