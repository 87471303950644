import React, { Component } from 'react'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Click from '../../../templates/Click/Click'
import ReactTooltip from 'react-tooltip'

import Loading from '../../../templates/UI/Loading/Loading'
import ReportsMore from '../ReportsMore/ReportsMore'
import TableContainer from '../TableContainer'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'
import callAPI from '../../../helpers/callAPI'

class ReportAffiliateEarnings extends Component {
    state = {
        dataLoaded: true,
        report: [],
        totals: {},
    }

    componentDidMount() {
        const fetchData = async () => {
            this._isMounted = true

            this.setState({
                dataLoaded: false,
            })

            const mbToken = localStorage.getItem('adm-auth-token')

            /* Fetch Affiliate Applications Stats from DB and populate the reports table and charts */

            let url = `reports/affiliates/earnings?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=all`

            if (this.props.mgrid > 0) {
                url = url + `&mgrid=${this.props.mgrid}`
            }

            if (this.props.oid > 0) {
                url = url + `&oid=${this.props.oid}`
            }

            if (this.props.filterStartDate && this.props.filterStartDate !== '') {
                url = url + `&filterStartDate=${this.props.filterStartDate}`
            }

            if (this.props.filterEndDate && this.props.filterEndDate !== '') {
                url = url + `&filterEndDate=${this.props.filterEndDate}`
            }

            if (this.props.ap > 0) {
                url = url + `&ap=${this.props.ap}`
            }

            if (this.props.as && this.props.as !== '') {
                url = url + `&as=${this.props.as}`
            }

            if (this.props.ta && this.props.ta === 'true') {
                url = url + `&ta=${this.props.ta}`
            }

            //console.log('TA:'+this.props.ta);
            const result = await callAPI(url, 'GET')

            if (result['success'] === true) {
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true,
                        report: result.report,
                        totals: result.totals,
                    })
                }
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    this.props.showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }

    getAffPriorityText = (pri) => {
        switch (pri) {
            case 1:
                return 'High'
            case 2:
                return 'Med'
            case 3:
                return 'New'
            case 4:
                return 'Low'
            case 5:
                return 'Very low'
            default:
        }
    }

    getAffiliatePriorityChange = (newPri, oldPri) => {
        if (oldPri === 3) {
            return
        }

        if (newPri > oldPri) {
            return (
                <span className="text-danger ml-2 text-small">
                    <FaCaretDown size={20} data-tip={`${this.getAffPriorityText(oldPri)} - ${this.getAffPriorityText(newPri)}`} />
                </span>
            )
        } else {
            return (
                <span className="text-success ml-2 text-small">
                    <FaCaretUp size={20} data-tip={`${this.getAffPriorityText(oldPri)} - ${this.getAffPriorityText(newPri)}`} />
                </span>
            )
        }
    }

    getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={this.state.report}
                    totals={this.state.totals}
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'publisher_id',
                            width: 80,
                        },
                        {
                            Header: 'Name',
                            accessor: 'affiliate_name',
                            Cell: ({ row }) => {
                                return (
                                    <>
                                        {<Affiliate aid={row.original.publisher_id} name={row.original.affiliate_name} priority={row.original.aff_priority} status={row.original.status} />} {this.props.ap > 0 && this.getAffiliatePriorityChange(row.original.filter_new_priority, row.original.filter_old_priority)}
                                    </>
                                )
                            },
                        },
                        {
                            Header: () => <div className="float-right">Clicks</div>,
                            accessor: 'clicks',
                            className: 'text-right',
                            Cell: ({ row }) => {
                                return (
                                    <>
                                        <Click aid={row.original.publisher_id} oid={this.props.oid} startDate={this.props.startDate} endDate={this.props.endDate} value={Intl.NumberFormat('en-GB').format(row.original.clicks)} />
                                    </>
                                )
                            },
                        },
                        {
                            Header: () => <div className="float-right">Leads</div>,
                            accessor: 'leads',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Sales</div>,
                            accessor: 'sales',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Network Earnings</div>,
                            accessor: 'network_earnings',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate Earnings</div>,
                            accessor: 'affiliate_earnings',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin</div>,
                            accessor: 'margin',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin %</div>,
                            accessor: 'pct_margin',
                            className: 'text-right',
                            // To customize rendering according to the row object using other fields from the original object
                            Cell: ({ row }) => {
                                //console.log(row.original.pct_margin)
                                return <>{parseFloat(row.original.margin ? (row.original.margin / row.original.network_earnings) * 100 : 0).toFixed(2)}%</>
                                //Cell: ({ cell: { value } }) => {
                                // return (<>{parseFloat(value).toFixed(2)}%</>);
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate EPC</div>,
                            accessor: 'affiliate_epc',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'reports_more',
                            className: 'text-right',
                            Cell: ({ row }) => {
                                return <>{this.props.oid ? <ReportsMore subids creatives landingPages refClicks aid={row.original.publisher_id} oid={this.props.oid} startDate={this.props.startDate} endDate={this.props.endDate} /> : <ReportsMore campaigns aid={row.original.publisher_id} startDate={this.props.startDate} endDate={this.props.endDate} />}</>
                            },
                        },
                    ]}
                />
                <ReactTooltip />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.report.length === 0 ? (
                            <table className="table table-striped table-card table-hover mb-0">
                                <tr>
                                    <td colSpan="11">No stats for this date range.</td>
                                </tr>
                            </table>
                        ) : (
                            <React.Fragment>
                                {this.props.oid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Affiliate Stats for Offer: {this.props.oid}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {this.getReportOutput()}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <table className="table table-striped table-card table-hover mb-0">
                        <tr>
                            <td colSpan="11">
                                <Loading />
                            </td>
                        </tr>
                    </table>
                )}
            </React.Fragment>
        )
    }
}

export default ReportAffiliateEarnings
