import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom"

import useCallApi from "../../hooks/useCallApi"
import useQueryParams from "../../hooks/useQueryParams"
import useDateRangeFilter from "../../hooks/useDateRangeFilter"
import AffiliateNavbar from "../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar"
import CampaignNavbar from "../../pages/Campaign/CampaignNavBar/CampaignNavBar"
import DateRangeFilter from "../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter"
import Affiliate from "../../templates/Affiliate/Affiliate"
import Campaign from "../../templates/Campaign/Campaign"
import Loading from "../../templates/UI/Loading/Loading"
import ByCountry from "./ByCountry/ByCountry"
import ByRegion from "./ByRegion/ByRegion"
import ByCity from "./ByCity/ByCity"
import ByDeviceOS from "./ByDeviceOS/ByDeviceOS"
import ByCreative from "./ByCreative/ByCreative"
import ByLandingPage from "./ByLandingPage/ByLandingPage"
import ByUserAgent from "./ByUserAgent/ByUserAgent"
import ByTrafficTypes from "./ByTrafficTypes/ByTrafficTypes"
import ByTimeOfDay from "./ByTimeOfDay/ByTimeOfDay"

const Analytics = ({ affiliateId, campaignId, showSystemMessage }) => {
    const location = useLocation()
    const history = useHistory()

    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const {affiliateId:queryAffiliateId, campaignId:queryCampaignId} = useQueryParams()

    let apiUrl = `reports/custom/trafficAnalytics?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

    const buildUrl = (url) => {        
        let finalUrl = url
        if (queryAffiliateId > 0){
            finalUrl += `&affiliateId=${queryAffiliateId}`
        }
        if (affiliateId > 0){
            finalUrl += `&affiliateId=${affiliateId}`
        }
        if (queryCampaignId > 0){
            finalUrl += `&campaignId=${queryCampaignId}`
        }        
        if (campaignId > 0){
            finalUrl += `&campaignId=${campaignId}`
        }        
        return finalUrl
    }

    const { dataLoaded, data, refetch } = useCallApi(buildUrl(apiUrl), 'GET', {}, [])

    if (dataLoaded) {
        document.title = `${dataLoaded && (affiliateId ? data.affiliate.name : data.campaign.name)}: Traffic Analytics`
    } else {
        document.title = `Traffic Analytics`
    }

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = affiliateId ? `/affiliate/${affiliateId}/analytics?startDate=${selectedStartDate}&endDate=${selectedEndDate}` : `/campaign/${campaignId}/analytics?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
        history.push(url)
    }

    useEffect(() => {
        refetch()
    }, [location])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Traffic Analytics</h1>
                <div className="ml-5">
                    {affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}
                </div>
            </div>
            <div>
                {dataLoaded &&
                    <div className="card mb-2">
                        <div className="d-flex p-3 align-items-center">
                            <h1 className="mb-h1-dark mr-2">{affiliateId ? <Affiliate aid={affiliateId} name={data.affiliate.name} status={data.affiliate.status.value} priority={data.affiliate.priority.value} /> : <Campaign oid={campaignId} name={data.campaign.name} status={data.campaign.status.value} />}</h1>                            
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                        { affiliateId ?
                            (queryCampaignId > 0 && <div className="d-flex px-3"><div className="alert alert-info">{data.campaign && <Campaign oid={data.campaign.id} name={data.campaign.name} status={data.campaign.status.value} />}</div></div>)
                        : campaignId ?  
                            (queryAffiliateId > 0 && <div className="d-flex px-3"><div className="alert alert-info">{data.affiliate && <Affiliate aid={data.affiliate.id} name={data.affiliate.name} status={data.affiliate.status.value} priority={data.affiliate.priority.value} />}</div></div>)
                        : <></>
                        }
                    </div>
                }                
                {dataLoaded ? (
                    <div className="row Affiliate">
                        <div className="col-xl-6">
                            <ByCountry geographic={data.geographic} totalLeads={data.total_leads} />
                            <ByRegion region={data.region} totalLeads={data.total_leads} />
                            <ByCity city={data.city} totalLeads={data.total_leads} />
                            <ByDeviceOS deviceOS={data.device_os} totalLeads={data.total_leads} />
                        </div>
                        <div className="col-xl-6 pb-0">
                            <ByCreative creatives={data.creatives} totalLeadsFromStats={data.total_leads_from_stats} />
                            <ByLandingPage landingPages={data.landing_pages} totalLeadsFromLpStats={data.total_leads_from_lp_stats} />
                            <ByUserAgent userAgents={data.user_agent} totalLeads={data.total_leads} />
                            <ByTrafficTypes trafficTypes={data.traffic_types} />
                            <ByTimeOfDay timeOfDay={data.time_of_day} totalLeads={data.total_leads} />
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default Analytics