import React, { Component } from 'react'
import Loading from '../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom'
import callAPI from '../../../helpers/callAPI'

class AvatarSiteManager extends Component {    

    state = {
        dataLoaded: false,
    }

    componentDidMount() {

        document.title = 'Avatar Site Manager'

        const fetchData = async () => {
            const result = await callAPI(`avatar/sites`, 'GET')

            if (result['success'] === true) {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    sites: result.sites,
                })
            } else if (result['success'] === false) {
                this.setState({
                    dataLoaded: true,
                })
            } else {
                this.props.showSystemMessage('error', result.errors.message)
                this.setState({
                    dataLoaded: true,
                })
            }
        }
        fetchData()
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Avatar Site Design Manager</h1>
                </div>
                {this.state.dataLoaded ? (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h1 className="mb-h1-dark">Site Designs</h1>
                                    <Link to="/avatar/site/new" className="btn btn-sm btn-warning" onClick={this.closePropertyModal}>
                                        + New Site Design
                                    </Link>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Site Title</th>
                                                <th scope="col">Header</th>
                                                <th scope="col">Content</th>
                                                <th scope="col">Active on</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.sites.map((site) => {
                                                return (
                                                    <tr key={site.site_id}>
                                                        <td>{site.meta_title}</td>
                                                        <td>{site.content_header}</td>
                                                        <td>{site.content_description}</td>
                                                        <td>
                                                            {site.active_on_properties.map((property) => {
                                                                return (
                                                                    <div>
                                                                        <div className="badge badge-light text-left border">
                                                                            <Link to={`/avatar/property/${property.property_id}`}>Property {property.property_id}</Link> {property.domain}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </td>
                                                        <td align="right">
                                                            <Link to={`/avatar/site/${site.site_id}`} className="btn btn-sm btn-warning">
                                                                Edit
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default AvatarSiteManager
