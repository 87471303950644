import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import queryString from 'query-string'
import { Line } from 'react-chartjs-2'

import Loading from '../../../templates/UI/Loading/Loading'
import Campaign from '../../../templates/Campaign/Campaign'
import Affiliate from '../../../templates/Affiliate/Affiliate'

const ReportTrendDetail = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [dayTrend, setDayTrend] = useState([])
    const [weekTrend, setWeekTrend] = useState([])
    const [monthTrend, setMonthTrend] = useState([])
    const [yearTrend, setYearTrend] = useState([])
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})

    const { showSystemMessage } = props
    const history = useHistory()

    const urlParams = history.location.search ? queryString.parse(history.location.search) : ''

    const offerId = urlParams.oid ? parseInt(urlParams.oid.trim()) : 0
    const affiliateId = urlParams.aid ? parseInt(urlParams.aid.trim()) : 0

    useEffect(() => {
        const mbToken = localStorage.getItem('adm-auth-token')

        setDataLoaded(false)
        let url = props.type === 'affiliate' ? `${process.env.REACT_APP_API_URL}/reports/offers/trend_detail?aid=${affiliateId}` : `${process.env.REACT_APP_API_URL}/reports/offers/trend_detail?oid=${offerId}`

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    if (props.type === 'affiliate') {
                        affiliateId !== 0 ? setAffiliate(result.affiliate) : setAffiliate({ name: 'MaxBounty' })
                    } else if (props.type === 'campaign') {
                        offerId !== 0 ? setCampaign(result.campaign) : setCampaign({ name: 'MaxBounty' })
                    }

                    setDayTrend(result.day_trend)
                    setWeekTrend(result.week_trend)
                    setMonthTrend(result.month_trend)
                    setYearTrend(result.year_trend)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                    return
                } else {
                    showSystemMessage('error', result.errors.message)
                    return
                }
            })
            .catch((error) => {
                console.log('Error in ReportCampaignTrendDetail: ', error)
                showSystemMessage('error', 'Connection Error')
                return
            })
    }, [])

    const formattedNumber = (number) => Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)

    const deltaSign = (value) => {
        let delta
        if (value < 0) {
            delta = <span style={{ color: 'red', fontSize: '10px' }}>&nbsp;&#9660;</span>
        } else if (value > 0) {
            delta = <span style={{ color: 'green', fontSize: '10px' }}>&nbsp;&#9650;</span>
        } else {
            delta = ''
        }
        return delta
    }

    const getClasses = (value) => {
        let classes = ``

        if (value < 0) {
            classes = `text-danger`
        } else if (value > 0) {
            classes = `text-success`
        }

        return classes
    }

    const plotChart = (dataType) => {
        let chartLabels, chartData

        switch (dataType) {
            case 'dayTrend':
                chartLabels = Object.keys(dayTrend).reverse()
                chartData = Object.values(dayTrend).reverse()
                break
            case 'weekTrend':
                chartLabels = Object.keys(weekTrend).reverse()
                chartData = Object.values(weekTrend).reverse()
                break
            case 'monthTrend':
                chartLabels = Object.keys(monthTrend).reverse()
                chartData = Object.values(monthTrend).reverse()
                break
            case 'yearTrend':
                chartLabels = Object.keys(yearTrend).reverse()
                chartData = Object.values(yearTrend).reverse()
                break
            default:
        }

        return (
            <Line
                data={{
                    labels: chartLabels,
                    datasets: [
                        {
                            label: '',
                            data: chartData,
                            backgroundColor: 'rgba(23,162, 184, 0.1)',
                            borderColor: 'rgba(23, 162, 184, 1)',
                            fill: false,
                        },
                    ],
                }}
                options={{
                    maintainAspectRatio: false,
                    legend: false,
                }}
                width={320}
                height={100}
            />
        )
    }

    const getName = () => {
        if (props.type === 'affiliate') {
            if (affiliateId !== 0) {
                return (
                    <h5>
                        <Affiliate aid={affiliateId} name={affiliate.name} status={affiliate.status.value} />
                    </h5>
                )
            } else {
                return <h5>{affiliate.name}</h5>
            }
        } else if (props.type === 'campaign') {
            if (offerId !== 0) {
                return (
                    <h5>
                        <Campaign oid={offerId} name={campaign.name} status={campaign.status.value} />
                    </h5>
                )
            } else {
                return <h5>{campaign.name}</h5>
            }
        }
    }

    const getReportOutput = () => {
        let { D0, D1, D2, D3, D4, D5, D6, D7 } = dayTrend
        let { W4, W3, W2, W1, W0 } = weekTrend
        let { M3, M2, M1, M0 } = monthTrend
        let { Y3, Y2, Y1, Y0 } = yearTrend

        let d6_delta, d5_delta, d4_delta, d3_delta, d2_delta, d1_delta, w3_delta, w2_delta, w1_delta, m2_delta, m1_delta, y2_delta, y1_delta

        d6_delta = D7 === 0 ? 0 : ((D6 - D7) / D7) * 100
        d5_delta = D6 === 0 ? 0 : ((D5 - D6) / D6) * 100
        d4_delta = D5 === 0 ? 0 : ((D4 - D5) / D5) * 100
        d3_delta = D4 === 0 ? 0 : ((D3 - D4) / D4) * 100
        d2_delta = D3 === 0 ? 0 : ((D2 - D3) / D3) * 100
        d1_delta = D2 === 0 ? 0 : ((D1 - D2) / D2) * 100
        w3_delta = W4 === 0 ? 0 : ((W3 - W4) / W4) * 100
        w2_delta = W3 === 0 ? 0 : ((W2 - W3) / W3) * 100
        w1_delta = W2 === 0 ? 0 : ((W1 - W2) / W2) * 100
        m2_delta = M3 === 0 ? 0 : ((M2 - M3) / M3) * 100
        m1_delta = M2 === 0 ? 0 : ((M1 - M2) / M2) * 100
        y2_delta = Y3 === 0 ? 0 : ((Y2 - Y3) / Y3) * 100
        y1_delta = Y2 === 0 ? 0 : ((Y1 - Y2) / Y2) * 100

        return (
            <React.Fragment>
                <table cellPadding="2" className="ml-3 table-card mb-0 table-responsive">
                    <tbody>
                        <tr>
                            <td className="d-flex">Margin Trend Report for: &nbsp;{getName()}</td>
                        </tr>

                        {/* Last 7 Days */}
                        <tr>
                            <td>
                                <br /> Last 7 days:
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table className="table-bordered text-small" cellPadding="2">
                                    <tbody>
                                        <tr valign="top" align="left" style={{ backgroundColor: 'lightgray' }} className="font-weight-bold card-body">
                                            <td>T-7</td>
                                            <td>T-6</td>
                                            <td>T-5</td>
                                            <td>T-4</td>
                                            <td>T-3</td>
                                            <td>T-2</td>
                                            <td>Yesterday</td>
                                            <td>Today</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>${formattedNumber(D7)}</td>
                                            <td>
                                                ${formattedNumber(D6)}&nbsp;
                                                <span className={getClasses(d6_delta)}>
                                                    ({formattedNumber(d6_delta)}%{deltaSign(d6_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(D5)}&nbsp;
                                                <span className={getClasses(d5_delta)}>
                                                    ({formattedNumber(d5_delta)}%{deltaSign(d5_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(D4)}&nbsp;
                                                <span className={getClasses(d4_delta)}>
                                                    ({formattedNumber(d4_delta)}%{deltaSign(d4_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(D3)}&nbsp;
                                                <span className={getClasses(d3_delta)}>
                                                    ({formattedNumber(d3_delta)}%{deltaSign(d3_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(D2)}&nbsp;
                                                <span className={getClasses(d2_delta)}>
                                                    ({formattedNumber(d2_delta)}%{deltaSign(d2_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(D1)}&nbsp;
                                                <span className={getClasses(d1_delta)}>
                                                    ({formattedNumber(d1_delta)}%{deltaSign(d1_delta)})
                                                </span>
                                            </td>
                                            <td>${formattedNumber(D0)}</td>
                                            <td>{plotChart('dayTrend')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        {/* Last 5 Weeks */}
                        <tr>
                            <td>
                                <br /> Last 5 weeks:
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table className="table-bordered text-small" cellPadding="2">
                                    <tbody>
                                        <tr valign="top" align="left" style={{ backgroundColor: 'lightgray' }} className="font-weight-bold">
                                            <td>T-4</td>
                                            <td>T-3</td>
                                            <td>T-2</td>
                                            <td>Last Week</td>
                                            <td>This Week</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>${formattedNumber(W4)}</td>
                                            <td>
                                                ${formattedNumber(W3)}&nbsp;
                                                <span className={getClasses(w3_delta)}>
                                                    ({formattedNumber(w3_delta)}%{deltaSign(w3_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(W2)}&nbsp;
                                                <span className={getClasses(w2_delta)}>
                                                    ({formattedNumber(w2_delta)}%{deltaSign(w2_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(W1)}&nbsp;
                                                <span className={getClasses(w1_delta)}>
                                                    ({formattedNumber(w1_delta)}%{deltaSign(w1_delta)})
                                                </span>
                                            </td>
                                            <td>${formattedNumber(W0)}</td>
                                            <td>{plotChart('weekTrend')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        {/* Last 4 Months */}
                        <tr>
                            <td>
                                <br /> Last 4 months:
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table className="table-bordered text-small" cellPadding="2" width="60%">
                                    <tbody>
                                        <tr valign="top" align="left" style={{ backgroundColor: 'lightgray' }} className="font-weight-bold">
                                            <td>T-3</td>
                                            <td>T-2</td>
                                            <td>Last Month</td>
                                            <td>This Month</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>${formattedNumber(M3)}</td>
                                            <td>
                                                ${formattedNumber(W2)}&nbsp;
                                                <span className={getClasses(m2_delta)}>
                                                    ({formattedNumber(m2_delta)}%{deltaSign(m2_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(M1)}&nbsp;
                                                <span className={getClasses(m1_delta)}>
                                                    ({formattedNumber(m1_delta)}%{deltaSign(m1_delta)})
                                                </span>
                                            </td>
                                            <td>${formattedNumber(M0)}</td>
                                            <td width="10%">{plotChart('monthTrend')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        {/* Last 4 Years */}
                        <tr>
                            <td>
                                <br /> Last 4 years:
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table className="table-bordered text-small" cellPadding="2">
                                    <tbody>
                                        <tr valign="top" align="left" style={{ backgroundColor: 'lightgray' }} className="font-weight-bold">
                                            <td>T-3</td>
                                            <td>T-2</td>
                                            <td>Last Year</td>
                                            <td>This Year</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>${formattedNumber(Y3)}</td>
                                            <td>
                                                ${formattedNumber(Y2)}&nbsp;
                                                <span className={getClasses(y2_delta)}>
                                                    ({formattedNumber(y2_delta)}%{deltaSign(y2_delta)})
                                                </span>
                                            </td>
                                            <td>
                                                ${formattedNumber(Y1)}&nbsp;
                                                <span className={getClasses(y1_delta)}>
                                                    ({formattedNumber(y1_delta)}%{deltaSign(y1_delta)})
                                                </span>
                                            </td>
                                            <td>${formattedNumber(Y0)}</td>
                                            <td>{plotChart('yearTrend')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <hr className="mt-0 mb-3"></hr>
            {dataLoaded ? getReportOutput() : <Loading></Loading>}
        </React.Fragment>
    )
}

export default ReportTrendDetail
