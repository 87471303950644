import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'

import useCallApi from '../../../../../hooks/useCallApi'
import Campaign from '../../../../../templates/Campaign/Campaign'
import Loading from '../../../../../templates/UI/Loading/Loading'
import CampaignWorksheetQuestions from '../CampaignWorksheetQuestions/CampaignWorksheetQuestions'

const EditCampaignPhase = ({ selectedCampaignId, handleClose, showSystemMessage }) => {
    const { dataLoaded, data, refetch } = useCallApi(`campaign/${selectedCampaignId}/phase`, 'GET', {}, [selectedCampaignId])
    const [showQuestionsModal, setShowQuestionsModal] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const handleSubmit = (e) => {
        e.preventDefault()

        let putBody = {
            phase: e.target.phase.value,
        }

        callAPI(`campaign/${selectedCampaignId}/phase`, 'PUT', putBody)
            .then((result) => {
                if (result.success) {
                    showSystemMessage('success', result.message)
                    setSubmitting(false)
                    refetch()
                    handleClose(true)
                } else if (!result.success) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while updating campaign phase')
                setSubmitting(false)
            })
    }

    const handleQuestionsClose = (edited = false) => {
        setShowQuestionsModal(false)
        if (edited) {
            refetch()
        }
    }

    return (
        <Card>
            <Modal show={showQuestionsModal} onHide={handleQuestionsClose} size="lg">
                <CampaignWorksheetQuestions selectedCampaignId={selectedCampaignId} handleClose={handleQuestionsClose} showSystemMessage={showSystemMessage} />
            </Modal>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Modify Worksheet Phase for {dataLoaded ? <Campaign oid={data.campaign.id} name={data.campaign.name} status={data.campaign.status.value} /> : spinner}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <form onSubmit={handleSubmit}>
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Phase</td>
                                    <td>
                                        <select name="phase" className="form-control" defaultValue={data.phase}>
                                            <option value={10}>IO</option>
                                            {data.phase >= 20 || (data.phase >= 10 && data.campaign_questions === data.phase_questions && selectedCampaignId > 0) ? <option value={20}>Setup</option> : null}
                                            {data.phase >= 30 || (data.phase >= 20 && data.campaign_questions === data.phase_questions && selectedCampaignId > 0) ? <option value={30}>Ready for Launch</option> : null}
                                            {data.phase >= 40 || (data.phase >= 30 && data.campaign_questions === data.phase_questions && selectedCampaignId > 0) ? <option value={40}>Launched</option> : null}
                                            {data.phase >= 50 || (data.phase >= 40 && data.campaign_questions === data.phase_questions && selectedCampaignId > 0) ? <option value={50}>Complete</option> : null}
                                        </select>
                                    </td>
                                    <td className="align-items-center">
                                        <button className="btn btn-link" onClick={() => setShowQuestionsModal(true)} type="button">
                                            {data.campaign_questions} of {data.phase_questions}
                                        </button>{' '}
                                        questions completed
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <button type="submit" className="btn btn-primary">
                                            {submitting ? spinner : 'Save'}
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
        </Card>
    )
}

export default EditCampaignPhase
