import MultipleChoice from '../MultipleChoice/MultipleChoice'
import MultipleChoiceMulti from '../MultipleChoiceMulti/MultipleChoiceMulti'
import TextShort from '../TextShort/TextShort'
import TextLong from '../TextLong/TextLong'
import TextInt from '../TextInt/TextInt'
import YesNo from '../YesNo/YesNo'
import CountryDropdown from '../CountryDropdown/CountryDropdown'
import ShortFormName from '../ShortFormName/ShortFormName'
import ShortFormNameEmail from '../ShortFormNameEmail/ShortFormNameEmail'
import ShortFormAddress from '../ShortFormAddress/ShortFormAddress'
import ShortFormAddressPhone from '../ShortFormAddressPhone/ShortFormAddressPhone'
import Countries from '../templates/Countries'
import Message from '../Message/Message'
import Dropdown from '../Dropdown/Dropdown'
import ShortFormContact from '../ShortFormContact/ShortFormContact'
import ShortFormVehicle from '../ShortFormVehicle/ShortFormVehicle'
import DateSelector from '../DateSelector/DateSelector'
import StateDropdown from '../StateDropdown/StateDropdown'
import Redirect from '../Redirect/Redirect'
import States from '../templates/States'
import RedirectList from '../RedirectList/RedirectList'

const questionTypes = [
    {
        name: 'Yes/No',
        type: 'YN',
        options: [
            {
                optionId: 1,
                value: 'Yes',
            },
            {
                optionId: 2,
                value: 'No',
            },
        ],
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer) {
            return <YesNo data={question} handleChange={handleChange} handleChangeDisclaimer={handleChangeDisclaimer} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control" value={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            )
        },
    },
    {
        name: 'Text Short',
        type: 'TS',
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit) {
            return <TextShort data={question} placeholder={placeholder} handleChange={handleChange} handleChangePlaceholder={handleChangePlaceholder} handleChangeCharLimit={handleChangeCharLimit} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return <input className="form-control" defaultValue={conditionValue} type="text" onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)} />
        },
    },
    {
        name: 'Text Long',
        type: 'TL',
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit) {
            return <TextLong data={question} placeholder={placeholder} handleChange={handleChange} handleChangePlaceholder={handleChangePlaceholder} handleChangeCharLimit={handleChangeCharLimit} handleChangeCallToAction={handleChangeCallToAction} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return <input className="form-control" defaultValue={conditionValue} type="text" onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)} />
        },
    },
    {
        name: 'Number',
        type: 'TI',
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit) {
            return <TextInt data={question} placeholder={placeholder} handleChange={handleChange} handleChangePlaceholder={handleChangePlaceholder} handleChangeCharLimit={handleChangeCharLimit} handleChangeCallToAction={handleChangeCallToAction} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <div className="row">
                    <div className="col">
                        <select className="form-control" defaultValue={condition.operator} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'operator', outerIndex, innerIndex)}>
                            <option value="">Anything</option>
                            <option value="GT">Greater Than</option>
                            <option value="LT">Less Than</option>
                            <option value="EQ">Equal To</option>
                        </select>
                    </div>
                    <div className="col">
                        <input type="text" className="form-control" defaultValue={condition.value} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)} />
                    </div>
                </div>
            )
        },
    },
    {
        name: 'Multiple Choice',
        type: 'MC',
        options: [
            {
                optionId: 1,
                value: 'Option 1',
            },
        ],
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit, handleAddMultipleChoice, handleChangeMultipleChoice, handleRemoveMultipleChoice) {
            return <MultipleChoice data={question} handleChange={handleChange} handleAddMultipleChoice={handleAddMultipleChoice} handleChangeMultipleChoice={handleChangeMultipleChoice} handleRemoveMultipleChoice={handleRemoveMultipleChoice} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control mt-2" defaultValue={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>
                                {option.value}
                            </option>
                        )
                    })}
                </select>
            )
        },
    },
    {
        name: 'Multiple Choice Multi-select',
        type: 'MCM',
        options: [
            {
                optionId: 1,
                value: 'Option 1',
            },
        ],
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit, handleAddMultipleChoice, handleChangeMultipleChoice, handleRemoveMultipleChoice) {
            return <MultipleChoiceMulti data={question} handleChange={handleChange} handleAddMultipleChoice={handleAddMultipleChoice} handleChangeMultipleChoice={handleChangeMultipleChoice} handleRemoveMultipleChoice={handleRemoveMultipleChoice} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control mt-2" defaultValue={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>
                                {option.value}
                            </option>
                        )
                    })}
                </select>
            )
        },
    },
    {
        name: 'Dropdown',
        type: 'DD',
        options: [
            {
                optionId: 1,
                value: 'Option 1',
            },
        ],
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit, handleAddMultipleChoice, handleChangeMultipleChoice, handleRemoveMultipleChoice) {
            return <Dropdown data={question} handleChange={handleChange} handleAddMultipleChoice={handleAddMultipleChoice} handleChangeMultipleChoice={handleChangeMultipleChoice} handleRemoveMultipleChoice={handleRemoveMultipleChoice} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control mt-2" defaultValue={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>
                                {option.value}
                            </option>
                        )
                    })}
                </select>
            )
        },
    },
    {
        name: 'Country Dropdown',
        type: 'CD',
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer) {
            return <CountryDropdown data={question} handleChange={handleChange} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control" defaultValue={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    <Countries />
                </select>
            )
        },
    },
    {
        name: 'State Dropdown',
        type: 'SD',
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer) {
            return <StateDropdown data={question} handleChange={handleChange} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control" defaultValue={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    <States />
                </select>
            )
        },
    },
    {
        name: 'Short Form - Name',
        type: 'SFN',
        fields: ['firstName', 'lastName'],
        requiredFields: ['firstName', 'lastName'],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder) {
            return <ShortFormName data={question} handleChange={handleChange} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} handleChangePlaceholder={handleChangePlaceholder} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Short Form - Name / Email',
        type: 'SFNE',
        fields: ['firstName', 'lastName', 'email'],
        requiredFields: ['firstName', 'lastName', 'email'],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder) {
            return <ShortFormNameEmail data={question} handleChange={handleChange} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} handleChangePlaceholder={handleChangePlaceholder} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Short Form - Contact',
        type: 'SFC',
        fields: ['firstName', 'lastName', 'email', 'phone'],
        requiredFields: ['firstName', 'lastName', 'email', 'phone'],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder) {
            return <ShortFormContact data={question} handleChange={handleChange} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} handleChangePlaceholder={handleChangePlaceholder} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Short Form - Address',
        type: 'SFA',
        fields: ['address1', 'address2', 'city', 'state', 'country', 'zipcode'],
        requiredFields: ['address1', 'city', 'state', 'country', 'zipcode'],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder) {
            return <ShortFormAddress data={question} handleChange={handleChange} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} handleChangePlaceholder={handleChangePlaceholder} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Short Form - Address / Phone',
        type: 'SFAP',
        fields: ['address', 'phone'],
        requiredFields: ['address', 'phone'],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder) {
            return <ShortFormAddressPhone data={question} handleChange={handleChange} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} handleChangePlaceholder={handleChangePlaceholder} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Short Form - Vehicle',
        type: 'SFV',
        fields: ['year', 'make', 'model', 'trim'],
        requiredFields: ['year', 'make', 'model', 'trim'],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer) {
            return <ShortFormVehicle data={question} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Message',
        type: 'MSG',
        requiredFields: [],
        conditionable: false,
        paramaterable: false,
        mappable: false,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction) {
            return <Message data={question} handleChange={handleChange} handleChangeCallToAction={handleChangeCallToAction} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Date Selector',
        type: 'DS',
        requiredFields: [],
        conditionable: true,
        paramaterable: false,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit, handleAddMultipleChoice, handleChangeMultipleChoice, handleRemoveMultipleChoice, handleCustomEventMap) {
            return <DateSelector data={question} handleChange={handleChange} handleAddMultipleChoice={handleAddMultipleChoice} handleChangeMultipleChoice={handleChangeMultipleChoice} handleRemoveMultipleChoice={handleRemoveMultipleChoice} handleCustomEventMap={handleCustomEventMap} handleChangeCallToAction={handleChangeCallToAction} />
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return <input className="form-control" defaultValue={conditionValue} type="text" onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)} />
        },
    },
    {
        name: 'Redirect',
        type: 'RD',
        requiredFields: [],
        conditionable: false,
        paramaterable: false,
        mappable: false,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder) {
            return <Redirect data={question} handleChange={handleChange} handleChangePlaceholder={handleChangePlaceholder} handleChangeDisclaimer={handleChangeDisclaimer} handleChangeCallToAction={handleChangeCallToAction} handleChangeNegCallToAction={handleChangeNegCallToAction} />
        },
        formOptions: function () {
            return false
        },
    },
    {
        name: 'Redirect List',
        type: 'RDL',
        options: [
            {
                optionId: 1,
                value: '',
                url: '' //THIS IS A NEW FIELD WE'LL NEED TO ADD TO DB
            },
        ],
        requiredFields: [],
        conditionable: true,
        paramaterable: true,
        mappable: true,
        component: function (question, placeholder, handleChange, handleChangeDisclaimer, handleChangeCallToAction, handleChangeNegCallToAction, handleChangePlaceholder, handleChangeCharLimit, handleAddMultipleChoice, handleChangeMultipleChoice, handleRemoveMultipleChoice, handleChangeRedirectList, handleChangeMultipleChoiceRedirectList, handleRemoveImage) {
            return <RedirectList data={question} handleChange={handleChange} handleAddMultipleChoice={handleAddMultipleChoice} handleChangeMultipleChoice={handleChangeMultipleChoice} handleRemoveMultipleChoice={handleRemoveMultipleChoice} handleChangeRedirectList={handleChangeMultipleChoiceRedirectList} handleRemoveImage={handleRemoveImage} handleChangeNegCallToAction={handleChangeNegCallToAction}/>
        },
        formOptions: function (questionId, outerIndex, innerIndex, options, condition, conditionValue, handleChangeLogicAnswer) {
            return (
                <select className="form-control mt-2" defaultValue={conditionValue} onChange={(e) => handleChangeLogicAnswer(e, questionId, 'value', outerIndex, innerIndex)}>
                    <option value="">Anything</option>
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>
                                {option.value}
                            </option>
                        )
                    })}
                </select>
            )
        },
    },
]

export default questionTypes
