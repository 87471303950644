export default {
    types: [
        {
            value: 'C',
            text: 'Normal',
        },
        {
            value: 'H',
            text: 'Host & Post',
        },
        {
            value: 'A',
            text: 'Avatar',
        },
    ],
}
