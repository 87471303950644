import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png'
import callAPI from '../../helpers/callAPI'

const Login = ({ checkAuthentication, getAdmin, showSystemMessage }) => {
    document.title = 'Login'

    let [loginFormState, setLoginFormState] = useState({ username: '', password: '', token: '' })
    let [loading, setLoading] = useState(false)

    const handleFormChange = (e, field) => {
        setLoginFormState({ ...loginFormState, [field]: e.target.value })
    }

    const handleFormSubmit = async () => {
        setLoading(true)
        let data = await callAPI(`authentication`, 'POST', loginFormState)
        if (data['success'] == true) {
            const admAppToken = data['adm-auth-token']
            localStorage.setItem('adm-auth-token', admAppToken)
            await getAdmin()
        } else {
            showSystemMessage('error', data.errors.message)
        }
        setLoading(false)
        await checkAuthentication()
    }

    return (
        <div className="login-background d-flex">
            <div className="login-content rounded">
                <img className="mb-4 rounded login-logo fadein-1s" src={maxbountyLogoSquare} alt="MaxBounty" width="80px" height="80px" />
                <div className="text-white">
                    <input
                        onChange={(e) => handleFormChange(e, 'username')}
                        value={loginFormState.username}
                        className="form-control login-input-text mb-2"
                        type="text"
                        placeholder="Username"
                        name="username"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleFormSubmit()
                            }
                        }}
                    />
                    <input
                        onChange={(e) => handleFormChange(e, 'password')}
                        value={loginFormState.password}
                        className="form-control login-input-text mb-2"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleFormSubmit()
                            }
                        }}
                    />
                    <input
                        onChange={(e) => handleFormChange(e, 'token')}
                        value={loginFormState.token}
                        className="form-control login-input-text login-input-text-short mb-2"
                        type="text"
                        placeholder="Auth Token"
                        name="token"
                        maxLength="6"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleFormSubmit()
                            }
                        }}
                    />
                    {loading ? (
                        <button className="btn btn-primary mt-4 mb-2 w-100" disabled>
                            <FontAwesomeIcon icon="spinner" spin />
                        </button>
                    ) : (
                        <button onClick={handleFormSubmit} className="btn btn-primary mt-4 mb-2 w-100">
                            Login
                        </button>
                    )}
                    <Link className="text-secondary" to="/forgotPassword">
                        Forgot Password
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Login
