import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import questionTypes from '../Survey/data/avatarQuestionTypes'

class AddQuestionModal extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
    }

    state = {}

    saveQuestion = (e) => {
        e.preventDefault()

        let question = e.target.question.value
        let questionType = e.target.questionType.value

        if (question.length === 0) {
            // Get the default question type name
            let questionTypeObj = questionTypes.find((findQuestionType) => {
                return findQuestionType.type === questionType
            })
            question = questionTypeObj.name
        }

        this.props.handleAddQuestion(question, questionType)
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.closeModal} className="modal-md">
                <div className="modal-header">
                    <h5 className="modal-title">Add a new question</h5>
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form ref={this.form} onSubmit={this.saveQuestion}>
                        <div className="form-group">
                            <label htmlFor="question">Question:</label>
                            <textarea maxLength={600} className="form-control" type="text" name="question" placeholder="Question text..."></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="question_type">Question Type:</label>
                            <select className="form-control" name="questionType">
                                {questionTypes.map((questionType) => {
                                    return (
                                        <option key={questionType.type} value={questionType.type}>
                                            {questionType.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary">Add Question</button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default AddQuestionModal
