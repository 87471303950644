import { useEffect, useState } from 'react'

import { Form, Modal } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../CampaignSearch/CampaignSearch'

const SpecialRatesModal = ({ setIsOpen, refetch, affiliate, campaign, showSystemMessage }) => {
    const [formData, setFormData] = useState({
        offer: {
            name: '',
            id: 0
        },
        campaign: {
            name: '',
            id: campaign.id ? campaign.id : 0,
            type: campaign.id ? campaign.type.value : ''
        },
        spc_aff_rate: 0.00,
        spc_net_rate: "",
        display_rate: "",
        display_rate_type: '',
        hide_sale_amount: false,
        override_url: '',
        rate_names: [],
        affiliate: {
            id: affiliate.id ? affiliate.id : 0,
        },
    })

    const { data, dataLoaded } = useCallApi(affiliate.id ? `affiliate/${affiliate.id || 0}/${formData.campaign.id}/specialRates/rateNames` : `affiliate/${formData.affiliate.id}/${campaign.id || 0}/specialRates/rateNames`, 'GET', {}, [formData.campaign, formData.affiliate])

    const [submitting, setSubmitting] = useState(false)

    const createRate = async (e) => {
        setSubmitting(true)
        e.preventDefault()

        if (formData.rate_names.length < 1) {
            showSystemMessage('error', 'Rate name must be selected')
            setSubmitting(false)
            return
        }

        const res = await callAPI(affiliate.id ? `affiliate/${affiliate.id}/specialRates` : `campaign/${campaign.id}/specialRates`, 'POST', {
            form: formData,
        })
        if (res.success) {
            setSubmitting(false)
            setIsOpen(false)
            showSystemMessage('success', 'Special rate created.')
            refetch()
        } else if (!res.success) {
            setSubmitting(false)
            showSystemMessage('error', res.errors.message)
        } else {
            showSystemMessage('error', res.errors.message)
            setSubmitting(false)
        }
    }

    useEffect(() => {
        if (formData.rate_names === '' && formData.campaign.id !== 0) {
            setFormData({
                ...formData,
                rate_names: data.rateNames,
            })
        }
    }, [data])

    return (
        <>
            <Modal.Header closeButton>
                <h1 className="mb-h1-dark">Add Special Rate for: {affiliate.id ? <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaign.id} name={campaign.name} />}</h1>
            </Modal.Header>
            <Form onSubmit={createRate}>
                <Modal.Body>
                    {affiliate.dataLoaded ? (
                        <>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>{affiliate.id ? 'Affiliate:' : 'Campaign:'} </span>
                                <span className="w-75">{affiliate.id ? affiliate.id : campaign.id}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>{affiliate.id ? 'Campaign:' : 'Affiliate:'} </span>
                                <div className="w-75">{affiliate.id ? <CampaignSearch setFormData={setFormData} /> : <AffiliateSearch showSystemMessage setFormData={setFormData} />}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Special Aff Rate: </span>
                                <div className="w-75">
                                    <Form.Control
                                        type="text"
                                        defaultValue={formData.spc_aff_rate}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                spc_aff_rate: e.target.value,
                                            })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Special Net Rate: </span>
                                <div className="w-75">
                                    <Form.Control
                                        type="text"
                                        defaultValue={formData.spc_net_rate}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                spc_net_rate: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Override URL: </span>
                                <div className="w-75">
                                    <Form.Control
                                        type="text"
                                        value={formData.override_url}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                override_url: String(e.target.value),
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Rate Name: </span>
                                <div className="w-75">
                                    {dataLoaded
                                        ?
                                        data.rateNames.length === 0 ?
                                            'Affiliate already has special rates for all available rates.'
                                            :
                                            data.rateNames.map((rateName) => {
                                                if (rateName.status === 'A') {
                                                    return <Form.Check
                                                        type="checkbox"
                                                        name="rate_names"
                                                        value={rateName.name}
                                                        onChange={() => {
                                                            if (formData.rate_names.findIndex(e => e.name === rateName.name) > -1) {
                                                                setFormData({
                                                                    ...formData,
                                                                    rate_names: formData.rate_names.filter((item) => item.name !== rateName.name),
                                                                })
                                                            } else {
                                                                setFormData({
                                                                    ...formData,
                                                                    rate_names: [...formData.rate_names, rateName.name],
                                                                })
                                                            }
                                                        }}
                                                        label={<Form.Check.Label htmlFor="rate_names">{rateName.name} - ({rateName.affiliate_rate} vs {rateName.network_rate})</Form.Check.Label>}
                                                        checked={formData.rate_names.includes(rateName.name)}
                                                    />
                                                }
                                            })
                                        : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span style={{ width: '20%' }}>Hide Sale Amount: </span>
                                <div className="w-75">
                                    <Form.Check type="checkbox" checked={formData.hide_sale_amount} onChange={(e) => setFormData({ ...formData, hide_sale_amount: e.target.checked })} />
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">Affiliate Portal Displayed Rate Override</div>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                        <span>Rate: </span>
                                        <div className="w-75">
                                            <Form.Control type="text" value={formData.display_rate} onChange={(e) => setFormData({ ...formData, display_rate: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                        <span style={{ width: '20%' }}>Rate Type: </span>
                                        <div className="w-75">
                                            <Form.Control as="select" value={formData.display_rate_type} onChange={(e) => setFormData({ ...formData, display_rate_type: e.target.value })}>
                                                <option value=""></option>
                                                <option value="F">$ per lead</option>
                                                <option value="P">% of sale</option>
                                                {/* {campaign.type.value === 'A' && (
                                                    <>
                                                        <option value="M">$ aff / % net</option>
                                                        <option value="C">$ per click</option>
                                                    </>
                                                )} */}
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="primary" type="submit" name="Create" spin={submitting} />
                </Modal.Footer>
            </Form>
        </>
    )
}

export default SpecialRatesModal
