import { useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../helpers/callAPI'

const ScheduleCancellation = ({ campaignId, name, setShowScheduleCancellationModal, showSystemMessage, redirects, refetch = () => { return } }) => {
    const [selectedDate, setSelectedDate] = useState(null)
    const [redirectType, setRedirectType] = useState('')
    const [selectedCampaign, setSelectedCampaign] = useState({ id: redirects.expired_redirect_id ? redirects.expired_redirect_id : 0, name: redirects.expired_redirect_name ? redirects.expired_redirect_name : 'No campaign selected.' })
    const [reason_for_pull, setReasonForPull] = useState('')
    const [notes, setNotes] = useState('')

    const submitHandler = async (e) => {
        e.preventDefault()

        let url = `campaign/${campaignId}/scheduleCancellation`

        callAPI(url, 'POST', { date: selectedDate, selectedCampaign, campaignName: name, reason_for_pull, notes })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Campaign Cancellation Scheduled')
                    setShowScheduleCancellationModal(false)
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', error.message)
            })
    }

    const changeRedirectType = (type) => {
        setRedirectType(type)
        if (type === 'custom') {
            setSelectedCampaign({ id: 0, name: 'No campaign selected.' })
        } else {
            setSelectedCampaign({ id: redirects.expired_redirect_id ? redirects.expired_redirect_id : 0, name: redirects.expired_redirect_name ? redirects.expired_redirect_name : 'No campaign selected.' })
        }
    }

    return (
        <Card>
            <form onSubmit={submitHandler}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div className="mb-h1-dark">Schedule Cancellation for: {name}</div>
                </Card.Header>
                <Card.Body>
                    <div>
                        <div className="w-100">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Reason for Pull:</div>
                                <Form.Control required as="select" value={reason_for_pull} onChange={(e) => setReasonForPull(e.target.value)}>
                                    <option value="">Select Reason</option>
                                    <option value="PAUSE_MONTHLY_CAP">Paused for Monthly Cap</option>
                                    <option value="PAUSE_TOTAL_CAP">Paused for Total Cap</option>
                                    <option value="PAUSE_TECH_ISSUES">Paused for Technical Issues</option>
                                    <option value="ADV_CANCEL_PROGRAM">Advertiser Cancelling from Program</option>
                                    <option value="OTHER">Other</option>
                                </Form.Control>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div required className="w-25 text-right pr-2">
                                    Redirect:
                                </div>
                                <Form.Control as="select" onChange={(e) => changeRedirectType(e.target.value)}>
                                    <option value={'current'}>Use Current Cancelled Redirect Campaign</option>
                                    <option value={'custom'}>Specify Campaign</option>
                                </Form.Control>
                            </div>
                            {redirectType === 'custom' && (
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="w-25 text-right pr-2">Redirect Campaign:</div>
                                    <div className="w-100">
                                        <CampaignSearch setCampaign={setSelectedCampaign} />
                                    </div>
                                </div>
                            )}
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Scheduled Date:</div>
                                <DatePicker placeholderText="Scheduled Date" className="form-control form-control" selected={selectedDate} showTimeSelect onChange={(date) => setSelectedDate(date)} dateFormat="yyyy/MM/dd hh:mm:ss" />
                                {/* <input required className="form-control" type="datetime-local" onChange={(e) => setSelectedDate(e.target.value)}></input>                     */}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Notes:</div>
                                <Form.Control as="textarea" value={notes} onChange={(e) => setNotes(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                    <input type="submit" className="btn btn-primary btn-sm" value="Schedule Cancellation" />
                </Card.Footer>
            </form>
        </Card>
    )
}

export default ScheduleCancellation
