import moment from 'moment'
import { Button, Card, Form, FormCheck, Table } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Dollars from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import { useEffect, useState } from 'react'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const RejectedMarketingApps = ({ showSystemMessage }) => {

    document.title = 'Rejected Marketing Apps'

    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, setDataLoaded, refetch } = useCallApi(`affiliates/rejectedMarketingApps?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ cell }) => <Affiliate aid={cell.row.original.affiliate_id} status={cell.row.original.aff_status} name={cell.row.original.name} />,
            },
            {
                Header: 'Campaign',
                accessor: 'marketing_campaign_name',
            },
            {
                Header: 'Platform',
                accessor: 'marketing_platform_name',
            },
            {
                Header: 'Registration Date',
                accessor: 'registration_date',
                Cell: ({ cell }) => moment(cell.row.original.registration_date).format('YYYY-MM-DD'),
            },
            {
                Header: 'Reject Date',
                accessor: 'reject_date',
                Cell: ({ cell }) => moment(cell.row.original.reject_date).format('YYYY-MM-DD'),
            },
            {
                Header: 'Last Followup Note',
                accessor: 'last_followup_note',
            },
            {
                Header: 'Experience',
                accessor: 'experience_description',
                className: 'mb-maxw-500',
            },
        ]

        return (
            <>
                <ReportTable data={data.rejectedMarketingApps} columns={columns} />
            </>
        )
    }

    return (
        <Card>
            <Card.Body>
                <span className="d-flex align-items-center flex-wrap">
                    <span className="d-flex mr-2">Rejected Marketing Apps</span>
                    <span className="d-flex ml-2 flex-wrap align-items-center">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />

                        <Button
                            variant="warning"
                            size="sm"
                            onClick={() => {
                                refetch()
                                history.push(`/affiliates/rejectedmarketingapps?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
                            }}
                        >
                            Refresh
                        </Button>
                    </span>
                </span>
                <span className="d-flex ml-2"></span>
            </Card.Body>
            {dataLoaded ? data.rejectedMarketingApps.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default RejectedMarketingApps
