import { Link } from 'react-router-dom';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Play, Pause, Trash2 } from 'react-feather'
import { useContext, useState } from 'react';
import ReportTable from '../../templates/ReportTable/ReportTable'
import useCallApi from '../../hooks/useCallApi';
import moment from 'moment';
import callAPI from '../../helpers/callAPI';
import { SystemMessageContext } from "../../context/SystemMessageContext";
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter';
import useDateRangeFilter from '../../hooks/useDateRangeFilter';
import Loading from '../../templates/UI/Loading/Loading';
import { useAuth, useProtectedPage } from '../../context/AuthContext';

const JobHistory = ({ defaultJobName = '' }) => {

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'system_jobs')

    document.title = 'System Jobs Log'
    const [jobName, setJobName] = useState(defaultJobName);
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter("month")

    const { showSystemMessage } = useContext(SystemMessageContext);
    const { data, dataLoaded, refetch } = useCallApi(`jobHistory?jobName=${jobName}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`, "GET", {}, []);

    const { data: jobListdata, dataLoaded: jobListDataLoaded } = useCallApi("systemJobs", "GET", {}, []);

    const getReportOutput = () => {
        let columns = [

            {
                Header: 'Job ID',
                accessor: 'job_id'
            },
            {
                Header: 'Name',
                accessor: 'job_name'
            },
            {
                Header: 'Date',
                //no accessor so that filter can search job id's
                //accessor: 'db_date'
                Cell: ({ row }) => {
                    return row.original.db_date
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <div className={ value === 'Error' ? 'text-danger' : value === 'Success' ? 'text-success' : '' }>{value}</div>
                }
            },
            {
                Header: 'Message',
                accessor: 'message'
            }
        ];
        return (
            <>
                <ReportTable data={data.history} columns={columns} />
            </>
        )
    }

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">System Jobs</h1>
            </div>
            <Card className="mb-3 col-lg-10 pr-0 pl-0">
                <Card.Header className="d-flex justify-content-start align-items-center">
                    <span className="mr-2">Job History</span>
                    <>
                        <div className="mr-4">
                            { jobListDataLoaded &&
                                <select className='form-control form-control-sm' onChange={(e)=>setJobName(e.target.value)} defaultValue={jobName !== '' && jobName}>
                                    <option value="">All Jobs</option>
                                    {
                                        jobListdata.jobs.map(job => {
                                            return <option value={`${job.job_name}`}>{job.job_name}</option>
                                        })
                                    }
                                </select>  
                            }                                                      
                        </div>
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <div className="d-flex align-items-center">
                            <button onClick={refetch} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                    </>
                </Card.Header>
                <div className="table-responsive">

                    {/* {dataLoaded && data.success && getReportOutput()} */}

                    {dataLoaded ? data.history.length === 0 ? <div className='p-3'>No data for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </Card>
        </>
    );
}

export { JobHistory }