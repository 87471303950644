import { Button, Card, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import useCallApi from '../../../hooks/useCallApi'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const CampaignSearches = ({ affiliateId }) => {

    const history = useHistory()
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const { data, dataLoaded, refetch } = useCallApi(`affiliate/${affiliateId}/campaignSearches?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, [affiliateId])

    if (dataLoaded){
        document.title = `${data.affiliate.name}: Campaign Searches`
    } else {
        document.title = `Campaign Searches`
    }

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const refreshReport = () => {
        refetch()
        history.push(`/affiliate/${affiliateId}/campaignSearches?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Search Pattern',
                accessor: 'pattern',
            },
            {
                Header: ()=><div className="text-center">Times Searched</div>,
                accessor: 'total_count',
                className: 'text-center',
            },
            {
                Header: 'Last Search',
                accessor: 'last_search',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY/MM/DD HH:mm:ss')}</>
                },
            },
        ]

        return (
            <>
                <ReportTable data={data.recent_searches} columns={columns} />
            </>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Searches</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <Card>
                        <Card.Body className="p-0">
                            <div className="d-flex flex-row w-100 align-items-center p-3">
                                <div className="mr-4 d-flex align-items-center">
                                    <h1 className="mb-h1-dark mr-2">{dataLoaded ? <Affiliate aid={affiliateId} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : spinner}</h1>
                                </div>
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                <Button size="sm" onClick={refreshReport}>
                                    Refresh Report
                                </Button>
                            </div>
                            {dataLoaded ? data.recent_searches.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default CampaignSearches
