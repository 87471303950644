import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function MarketingDropdown() {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right">
                Marketing
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                <Link className="dropdown-item" to="/marketing/campaigns" onClick={toggle}>
                    Campaigns
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/marketing/platforms" onClick={toggle}>
                    Platforms
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/marketing/affiliates/mailers" onClick={toggle}>
                    Affiliate Initial Mailers
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/affiliates/rejectedMarketingApps" onClick={toggle}>
                    Rejected Marketing Apps
                </Link>
            </DropdownMenu>
        </Dropdown>
    )
}
