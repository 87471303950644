
import useCallApi from "../../../hooks/useCallApi";
import { Summary } from "./Summary";
import { AvatarProperties } from "./AvatarProperties";
import moment from "moment";
import { Clicks } from "./Clicks";
import { Leads } from "./Leads";
import { Margin } from "./Margin";
import { AvatarNumbers } from "./AvatarNumbers";
import { TrafficSenders } from "./TrafficSenders";
import { Redirectors } from "./Redirectors";
import { useEffect, useState } from "react";
import callAPI from "../../../helpers/callAPI";

const MbStatusPage = () => {
    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1 d-flex align-items-center">
                    <div>MB Master Status Page</div>
                </h1>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <Summary />
                    <Clicks />
                    <Leads  />
                </div>
                <div className="col-lg-4">
                    <Margin />
                    <TrafficSenders />
                    <Redirectors />
                </div>
                <div className="col-lg-4">
                    <AvatarProperties />
                    <AvatarNumbers />
                </ div>
            </div>
        </div>
    );
}

export { MbStatusPage }