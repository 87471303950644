import React, { Component } from 'react'
import QuestionDisclaimer from '../templates/QuestionDisclaimer'
import RedirectSettings from '../templates/RedirectSettings'

class ShortFormNameEmail extends Component {
    state = {}

    render() {
        let { order, disclaimer, question, questionId, callToAction, placeholder } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Short Form - Name / Email</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <h3 className="text-xlarge">First Name</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-xlarge">Last Name</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <h3 className="text-xlarge">Email</h3>
                    <div className="mt-3">
                        <input className="form-control" type="text" />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="mt-3">
                        <input className="form-control mb-button-input-text" type="text" defaultValue={callToAction} placeholder="Custom Call to Action" onChange={this.props.handleChangeCallToAction(questionId)} />
                    </div>
                </div>
                <RedirectSettings questionId={questionId} handleChangePlaceholder={this.props.handleChangePlaceholder} placeholder={placeholder} callToAction={callToAction} />
                <QuestionDisclaimer disclaimer={disclaimer} handleChangeDisclaimer={this.props.handleChangeDisclaimer} questionId={questionId} />
            </div>
        )
    }
}

export default ShortFormNameEmail
