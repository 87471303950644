import moment from 'moment'

import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import Campaign from '../../../../templates/Campaign/Campaign'

const CampaignViews = ({ affiliateId }) => {
    let startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
    let endDate = moment().format('YYYY-MM-DD')
    const { data, dataLoaded } = useCallApi(`affiliate/${affiliateId}/campaignViews?startDate=${startDate}&endDate=${endDate}`, 'GET', {}, [affiliateId])

    return (
        <div>
            {dataLoaded ? (
                data.view_list.length === 0 ? (
                    <div className="p-2">No campaign views available</div>
                ) : (
                    <table className="table table-striped table-card table-hover">
                        <thead>
                            <tr>
                                <th>Campaign</th>
                                <th className="text-right">Frequency</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.view_list.slice(0, 4).map((item, index) => (
                                <tr key={`${item.month}-${index}`}>
                                    <td>
                                        <Campaign oid={item.item_id} name={item.item_name} status={item.item_status} />
                                    </td>
                                    <td align="center">{item.view_count}</td>
                                    <td>{moment(item.view_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            ) : (
                <Loading></Loading>
            )}
        </div>
    )
}

export default CampaignViews
