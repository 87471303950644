import { useEffect, useState } from 'react'

import moment from 'moment'

import FlagSettings from './FlagSettings/FlagSettings'
import callAPI from '../../helpers/callAPI'
import Loading from '../../templates/UI/Loading/Loading'
import { Modal } from 'react-bootstrap'

const Flags = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [user, setUser] = useState({})
    const [existingFlags, setExistingFlags] = useState([])
    const [availableFlags, setAvailableFlags] = useState([])

    const { affiliateId, advertiserId, campaignId, showSystemMessage, refetch } = props

    let id, target

    if (affiliateId) {
        id = affiliateId
        target = 'affiliate'
    } else if (advertiserId) {
        id = advertiserId
        target = 'advertiser'
    } else if (campaignId) {
        id = campaignId
        target = 'campaign'
    }

    useEffect(() => {
        let url

        if (target === 'affiliate') {
            url = `affiliate/${id}/flags`
        } else if (target === 'advertiser') {
            url = `advertiser/${id}/flags`
        } else if (target === 'campaign') {
            url = `campaign/${id}/flags`
        }

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setUser(result[target])
                    setExistingFlags(result.existing_flags)
                    setAvailableFlags(result.available_flags)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', `An error occurred while getting ${target} flags`)
                setDataLoaded(true)
            })
    }, [dataLoaded])

    const handleFlagSettings = (e) => {
        e.preventDefault()
        setShowModal(true)
    }

    const toggleModal = () => {
        setShowModal(false)
    }

    const updateFlagList = () => {
        setDataLoaded(false)
        setDataLoaded(true)
    }

    return (
        <div className="card mt-2 mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Flags</div>
                <div>
                    <button className="btn btn-sm btn-warning" onClick={(e)=>handleFlagSettings(e)}>
                        Settings
                    </button>
                </div>
            </div>
            <Modal show={showModal} size="lg" onHide={toggleModal}>
                <FlagSettings existingFlags={existingFlags} user={user} showSystemMessage={showSystemMessage} id={id} availableFlags={availableFlags} updateFlagList={updateFlagList} target={target} refetch={refetch} />
            </Modal>
            <div className="card-body p-0">
                {dataLoaded ? (
                    existingFlags.length === 0 ? (
                        <div className="p-2">No Flags</div>
                    ) : (
                        <table className="table table-striped table-card m-0 table-hover">
                            <tbody>
                                {existingFlags.map((flag) => (
                                    <tr key={flag.flag_id}>
                                        <td>{flag.flag_name}</td>
                                        <td>{flag.date ? moment(flag.date).format('YYYY-MM-DD') : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </div>
    )
}

export default Flags
