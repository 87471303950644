import moment from 'moment'
import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Modal } from 'reactstrap'
import Loading from '../../../templates/UI/Loading/Loading'
import IpDetailsContent from '../../Affiliate/AffiliateLoginHistory/IpDetails/IpDetailsContent'

const LoginInformation = ({ data, dataLoaded, affiliateId, showSystemMessage }) => {
    const [showIpDetailsModal, setShowIpDetailsModal] = useState(false)
    const [modalIp, setModalIp] = useState('')

    return (
        <Card className="mb-4">
            <Modal isOpen={showIpDetailsModal} size="md" toggle={() => setShowIpDetailsModal(!showIpDetailsModal)}>
                <IpDetailsContent ipAddress={modalIp} handleClose={() => setShowIpDetailsModal(false)} isModal={true} />
            </Modal>
            <Card.Header>
                <div>Login Information</div>
            </Card.Header>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>IP Details</th>
                        <th>Country</th>
                        <th>Login Count</th>
                        <th>Language</th>
                        <th>Affiliate ID</th>
                        <th>User Agent</th>
                    </tr>
                </thead>
                {dataLoaded ? (
                    <>
                        {data.success && data.info.lastLogins.length > 0 ? (
                            <tbody>
                                {data.info.lastLogins.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{moment(item.login_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>
                                                <a
                                                    href={`/ipDetails?ip=${item.remote_host}`}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setShowIpDetailsModal(true)
                                                        setModalIp(item.remote_host)
                                                    }}
                                                >
                                                    {item.remote_host}
                                                </a>
                                            </td>
                                            <td>{item.country}</td>
                                            <td>{item.device_login_count}</td>
                                            <td>{item.accept_language}</td>
                                            <td>{affiliateId === item.original_affiliate_id || item.original_affiliate_id === 0 ? '' : item.original_affiliate_id}</td>
                                            <td>{item.user_agent}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="7">No data found</td>
                                </tr>
                            </tbody>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
            <Table striped bordered className='mt-2'>
                <thead>
                    <tr><th colSpan={3}>Last 10 Unique Countries Used for Login (Last 60 days)</th></tr>
                    <tr>
                        {/* <th>IP Details</th> */}
                        <th>Country</th>
                        {/* <th>Login Date</th> */}
                    </tr>
                </thead>
                {dataLoaded ? (
                    <>
                        {data.success && data.info.uniqueLoginIps.length > 0 ? (
                            <tbody>
                                {data.info.uniqueLoginIps.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* <td>
                                                <a
                                                    href={`/ipDetails?ip=${item.remote_host}`}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setShowIpDetailsModal(true)
                                                        setModalIp(item.remote_host)
                                                    }}
                                                >
                                                    {item.remote_host}
                                                </a>
                                            </td> */}
                                            <td>{item.country}</td>
                                            {/* <td>
                                                {moment(item.login_date).utc().format('YYYY-MM-DD HH:mm:ss')}
                                            </td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4">No data found</td>
                                </tr>
                            </tbody>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}

export default LoginInformation
