import { Card, Table } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'

const PostalAddressCheck = ({ data }) => {
    return (
        <Card className="mb-4">
            <Card.Header>
                <div>Postal Address Check</div>
            </Card.Header>
            <div className="m-2 font-weight-bold">Other affiliates listing this postal address as theirs:</div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Contact</th>
                    </tr>
                </thead>

                {data.success && data.info.matchingNames.length > 0 ? (
                    <tbody>
                        {data.info.matchingNames.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.affiliate_id}</td>
                                    <td>
                                        <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                    </td>
                                    <td>{item.aff_status}</td>
                                    <td>{item.contact}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan="4">No data found</td>
                        </tr>
                    </tbody>
                )}
            </Table>
        </Card>
    )
}

export default PostalAddressCheck
