import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Calendar } from 'react-feather'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import callAPI from '../../../../helpers/callAPI'
import FollowupSchedule from '../../../../components/Followups/FollowupForm/FollowupSchedule/FollowupSchedule'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'
import { useAuth } from '../../../../context/AuthContext'
import addWeekdays from '../../../../helpers/addWeekdays'

const FollowupFormAdvertiser = (props) => {
    const auth = useAuth()
    const [submitting, setSubmitting] = useState(false)
    const [date, setDate] = useState('')
    const [results, setResults] = useState('')
    const [followupNote, setFollowupNote] = useState('')
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [showBizdevScheduleModal, setShowBizdevScheduleModal] = useState(false)

    const { showSystemMessage, advertiserId } = props

    const saveFollowupNote = (event) => {
        event.preventDefault()
        setSubmitting(true)
        let url = `advertiser/${advertiserId}/followups`

        let postBody = { followupDate: date, results: results, followupNote: followupNote, followupType: event.target.followup_type.value }

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setDate('')
                    setFollowupNote('')
                    setResults('')
                    setSubmitting(false)
                    props.updateList()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                console.log('Error while saving affiliate followup notes: ', error)
                showSystemMessage('error', 'An error occurred while saving advertiser followup notes')
                setSubmitting(false)
            })
    }

    const handleSchedule = () => {
        setShowScheduleModal(true)
    }

    const handleBizdevSchedule = () => {
        setShowBizdevScheduleModal(true)
    }

    const toggle = () => {
        setShowScheduleModal(false)
        setShowBizdevScheduleModal(false)
    }

    const handleDateChange = (date) => {
        let newDate = addWeekdays(date)
        setDate(moment(newDate).toDate())
    }

    const handleNoteInput = (event) => {
        setFollowupNote(event.target.value)
    }

    const handleResult = (event) => {
        setResults(event.target.value)
    }

    return (
        <div className="pt-2">
            <Modal show={showScheduleModal} onHide={toggle} size="sm">
                <FollowupSchedule followupSchedule={props.followupSchedule} managerName={props.managerName} managerId={props.managerId} followupType={props.followupType} />
            </Modal>

            <Modal show={showBizdevScheduleModal} onHide={toggle} size="sm">
                <FollowupSchedule followupSchedule={props.bizdevFollowupSchedule} managerName={props.bizdevManagerName} managerId={props.bizdevManagerId} followupType={props.followupType} />
            </Modal>
            <form className="form-group" onSubmit={saveFollowupNote} method="POST">
                <div className="d-flex p-2 align-items-center">
                    <div style={{ width: '70%' }} className="pr-2" name="followup_results">
                        <select className="form-control form-control-sm" onChange={handleResult} value={results} required>
                            <option value="">Select Results</option>
                            <option value="PM">Publisher Mgmt</option>
                            <option value="DC">Drop Campaign</option>
                            <option value="IO">IO Negotiation</option>
                            <option value="PI">Pixel Issue</option>
                            <option value="CI">Campaign Issue</option>
                            <option value="EM">Sent E - Mail</option>
                            <option value="LM">Called NA/LM</option>
                            <option value="CS">Called Spoke</option>
                            <option value="SC">Status Call</option>
                            <option value="FW">Forwarded</option>
                            <option value="OT">Other</option>
                        </select>
                    </div>
                    {/* <div className="d-flex align-items-center">
                        <DatePicker placeholderText="Next Followup" className="form-control form-control-sm" selected={date} onChange={(date) => handleDateChange(date)} dateFormat="yyyy/MM/dd" />
                        <Calendar size={18} color="#007bff" />
                    </div> */}
                </div>
                <div className="d-flex p-2 align-items-center">
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().add(1, 'day').format('YYYY/MM/DD')))}>
                        One Day
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().add(2, 'day').format('YYYY/MM/DD')))}>
                        Two Days
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().add(1, 'week').format('YYYY/MM/DD')))}>
                        Next Week
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={() => handleDateChange(new Date(moment().add(2, 'week').format('YYYY/MM/DD')))}>
                        Two Weeks
                    </button>
                    <div className="d-flex align-items-center">
                        <DatePicker placeholderText="Next Followup" className="form-control form-control-sm" selected={date} showTimeSelect onChange={(date) => handleDateChange(date)} dateFormat="yyyy/MM/dd hh:mm:ss" />
                        <Calendar size={18} color="#007bff" />
                    </div>
                </div>
                <div className="d-flex align-items-center p-2">
                    <div style={{ width: '25%' }}>Set followup for:</div>
                    <select className="form-control form-control-sm w-50" name="followup_type" defaultValue={auth.user.role === 'BIZDEV' ? 'B' : 'M'}>
                        <option value="M">Advertiser Manager</option>
                        <option value="B">Biz Dev</option>
                        <option value="A">AdOps</option>
                    </select>
                </div>
                <div className="d-flex pl-2 pt-1">
                    <div style={{ width: '85%' }}>
                        <textarea placeholder="Add followup note..." className="form-control" onChange={handleNoteInput} value={followupNote} required={results === 'FW' ? false : true} />
                    </div>
                    <div className="text-center pt-2 pl-2">
                        <CustomButton variant="primary" type="submit" name="Save" spin={submitting} />
                    </div>
                </div>

                <div className="d-flex pl-2 pt-1">
                    <button type="button" className="btn btn-sm btn-link" onClick={handleSchedule}>
                        Ad Manager Schedule
                    </button>
                    <button type="button" className="btn btn-sm btn-link" onClick={handleBizdevSchedule}>
                        BizDev Schedule
                    </button>
                </div>
            </form>
        </div>
    )
}

export default FollowupFormAdvertiser