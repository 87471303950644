import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { SystemMessageContext } from '../../../context/SystemMessageContext';
import queryString from 'query-string'
import AdminPicker from '../../../components/AdminPicker/AdminPicker';
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter';
import useDateRangeFilter from '../../../hooks/useDateRangeFilter';
import Checkbox from '../../Reports/Filters/Checkbox/Checkbox';
import useCallApi from '../../../hooks/useCallApi';
import Dollars from '../../../templates/Dollars/Dollars';
import Number from '../../../templates/Number/Number';
import moment from 'moment'
import Loading from '../../../templates/UI/Loading/Loading';
import { useAuth, useProtectedPage } from '../../../context/AuthContext';

export default function MarginReportNew(){
    document.title = 'Margin Report 2'    

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'report_margin_new')

    const { showSystemMessage } = useContext(SystemMessageContext)    

    const history = useHistory()
    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const [adminId, setAdminId] = useState(urlParams.adminId || 0)
    const [payable, setPayable] = useState(urlParams.payable || false)
    const [waiting, setWaiting] = useState(urlParams.waiting || false)
    const [fixed, setFixed] = useState(urlParams.fixed || false)
    const [revshare, setRevshare] = useState(urlParams.revshare || false)
    const [oldBusiness, setOldBusiness] = useState((urlParams.oldBusiness == 'true' ? true : urlParams.oldBusiness == 'false' ? false : false) || false)
    const [newBusiness, setNewBusiness] = useState((urlParams.newBusiness == 'true' ? true : urlParams.newBusiness == 'false' ? false : false) || false)
    const [doubleColumn, setDoubleColumn] = useState(false)
    const [leftHeader, setLeftHeader] = useState(false)
    const [rightHeader, setRightHeader] = useState(false)

    let initUrl = `reports/custom/margin?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

    const buildUrl = (url) => {
        if (adminId > 0){
            url = url + `&adminId=${adminId}`
        }
        if (payable) {
            url = url + `&payable=${payable}`
        }
        if (waiting) {
            url = url + `&waiting=${waiting}`
        }
        if (fixed) {
            url = url + `&fixed=${fixed}`
        }
        if (revshare) {
            url = url + `&revshare=${revshare}`
        }
        if (oldBusiness) {
            url = url + `&oldBusiness=${oldBusiness}`
        }
        if (newBusiness) {
            url = url + `&newBusiness=${newBusiness}`
        }
       
        return url
    }

    const {data, dataLoaded, refetch} = useCallApi(buildUrl(initUrl), 'GET', {}, [], showSystemMessage)

    useEffect(()=>{
        // Formatting
        setDoubleColumn(false)
        if (payable && waiting && !(fixed && revshare) && !(oldBusiness && newBusiness)){ setDoubleColumn(true); setLeftHeader('Payable'); setRightHeader('Waiting') }
        if (fixed && revshare && !(payable && waiting) && !(oldBusiness && newBusiness)){ setDoubleColumn(true); setLeftHeader('Fixed'); setRightHeader('Revshare') }
        if (oldBusiness && newBusiness && !(fixed && revshare) && !(payable && waiting)){ setDoubleColumn(true); setLeftHeader('Old Business'); setRightHeader('New Business') }
    },[])

    const submitHandler = () => {
        // Redirect and get new data
        let url = `/${buildUrl(initUrl)}`
        history.push(url)
    }

    const buildCampaignReportUrl = (url) => {        

        if (oldBusiness && !newBusiness) {
            url = url + `&businessAge=old`
        }
        if (newBusiness && !oldBusiness) {
            url = url + `&businessAge=new`
        }
        if (adminId && adminId > 0){
            url = url + `&adminId=${adminId}`
            url = url + `&withAvatar=true`
        }
        return url
    }

    const getReportOutput = () => {
        return (
            <table className="table table-striped table-bordered table-hover mb-0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th className="text-center" colSpan={doubleColumn ? 2 : 1}>Network Earnings</th>
                        <th className="text-center" colSpan={doubleColumn ? 2 : 1}>Affiliate Earnings</th>
                        <th className="text-center" colSpan={doubleColumn ? 2 : 1}>Margin</th>
                        <th className="text-center" colSpan={doubleColumn ? 2 : 1}>Margin Percentage</th>
                    </tr>
                    {doubleColumn &&
                        <tr>
                            <th className="text-center"></th>
                            <th className="text-center">{leftHeader}</th>
                            <th className="text-center">{rightHeader}</th>
                            <th className="text-center">{leftHeader}</th>
                            <th className="text-center">{rightHeader}</th>
                            <th className="text-center">{leftHeader}</th>
                            <th className="text-center">{rightHeader}</th>
                            <th className="text-center">{leftHeader}</th>
                            <th className="text-center">{rightHeader}</th>               
                        </tr>
                    }
                </thead>
                <tbody>                 
                    {/* Report */}
                    {data.report.map( line => {
                        return (
                            doubleColumn ?
                                <tr>
                                    <td className="text-left"><Link to={buildCampaignReportUrl(`/reports/stats/campaigns?startDate=${moment(line.rev_date).utc().format('YYYY-MM-DD')}&endDate=${moment(line.rev_date).utc().format('YYYY-MM-DD')}`)}>{moment(line.rev_date).utc().format('YYYY-MM-DD')} ({moment(line.rev_date).utc().format('dddd')})</Link></td>
                                    <td className="text-right"><Dollars value={line.left_net_earnings} /></td>
                                    <td className="text-right"><Dollars value={line.right_net_earnings} /></td>
                                    <td className="text-right"><Dollars value={line.left_aff_earnings} /></td>
                                    <td className="text-right"><Dollars value={line.right_aff_earnings} /></td>
                                    <td className="text-right"><Dollars value={line.left_margin} /></td>
                                    <td className="text-right"><Dollars value={line.right_margin} /></td>
                                    <td className="text-right"><Number decimals={0} value={line.left_margin_percentage*100} />%</td>
                                    <td className="text-right"><Number decimals={0} value={line.right_margin_percentage*100} />%</td>               
                                </tr>
                            :
                                <tr>
                                    <td className="text-left"><Link to={buildCampaignReportUrl(`/reports/stats/campaigns?startDate=${moment(line.rev_date).utc().format('YYYY-MM-DD')}&endDate=${moment(line.rev_date).utc().format('YYYY-MM-DD')}`)}>{moment(line.rev_date).utc().format('YYYY-MM-DD')} ({moment(line.rev_date).utc().format('dddd')})</Link></td>
                                    <td className="text-right"><Dollars value={line.net_earnings} /></td>
                                    <td className="text-right"><Dollars value={line.aff_earnings}/></td>
                                    <td className="text-right"><Dollars value={line.margin}/></td>
                                    <td className="text-right"><Number decimals={0} value={line.margin_percentage*100}/>%</td>                                               
                                </tr>
                        )
                    })}
                    {/* totals */}
                    {
                        doubleColumn ?
                            <tr>
                                <td className="text-left"></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_left_net_earnings} /></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_right_net_earnings} /></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_left_aff_earnings} /></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_right_aff_earnings} /></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_left_margin} /></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_right_margin} /></td>
                                <td className="text-right font-weight-bold"><Number decimals={0} value={data.totals[0].total_left_margin_percentage*100} />%</td>
                                <td className="text-right font-weight-bold"><Number decimals={0} value={data.totals[0].total_right_margin_percentage*100} />%</td>               
                            </tr>
                        :
                            <tr>
                                <td className="text-left"></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_net_earnings} /></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_aff_earnings}/></td>
                                <td className="text-right font-weight-bold"><Dollars value={data.totals[0].total_margin}/></td>
                                <td className="text-right font-weight-bold"><Number decimals={0} value={data.totals[0].total_margin_percentage*100}/>%</td>                                               
                            </tr>
                    }
                </tbody>
                
            </table>
        )
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <span className="mb-h1">Reports</span>
                {/* <HeaderStats showSystemMessage={props.showSystemMessage}></HeaderStats> */}
            </div>
            <div className="card pb-0">                                    
                <div className='d-flex align-items-center p-3'>
                    <div className="mr-4 d-flex align-items-center">
                        <h1 className="mb-h1-dark">Margin Report 2</h1>
                    </div>
                    <div>
                        <AdminPicker adminId={adminId} setAdminId={setAdminId} />
                    </div>
                    <div className="ml-2">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                    </div>                        
                    <div className="ml-2 d-flex">
                        <div>
                            <Checkbox id="payable" name="Payable" checkboxChanged={(e)=>setPayable(e.target.checked)} isChecked={payable}/>
                            <Checkbox id="waiting" name="Waiting" checkboxChanged={(e)=>setWaiting(e.target.checked)} isChecked={waiting} />
                        </div>
                        <div>
                            <Checkbox id="perlead" name="Fixed (per lead)" checkboxChanged={(e)=>setFixed(e.target.checked)} isChecked={fixed} />
                            <Checkbox id="revshare" name="Percentage (revshare)" checkboxChanged={(e)=>setRevshare(e.target.checked)} isChecked={revshare} />
                        </div>
                        <div>
                            <Checkbox id="oldMerchants" name="Old Business" checkboxChanged={(e)=>setOldBusiness(e.target.checked)} isChecked={oldBusiness} title="First lead is < year ago"/>
                            <Checkbox id="newMerchants" name="New Business" checkboxChanged={(e)=>setNewBusiness(e.target.checked)} isChecked={newBusiness} title="First lead is > year ago"/>
                        </div>
                    </div>
                    <div className="ml-2">
                        <button className="btn btn-primary" onClick={()=>submitHandler()}>
                            Apply Filters
                        </button>
                    </div>
                </div>
                <div>
                    {dataLoaded ? (data.report ? getReportOutput() : <div>No data.</div>) : <Loading /> }
                </div>                
            </div>
        </React.Fragment>
    )
}