import React, { Component } from 'react'
import AddEventModal from '../Modals/AddEventModal'
import SurveyConditionDisplay from '../Templates/SurveyConditionDisplay/SurveyConditionDisplay'

class SubmitEvent extends Component {
    state = {}

    render() {
        let boxClasses = () => {
            let classes = 'alert alert-info'
            if (this.props.action !== 'NONE' && this.props.url === '') {
                classes = 'alert alert-danger'
            }
            if (this.props.mode === 2 && this.props.conditions.length === 0) {
                classes = 'alert alert-danger'
            }
            return classes
        }

        return (
            <div className={boxClasses()}>
                <AddEventModal eventId={this.props.eventId} dbEventId={this.props.dbEventId} editing={true} events={this.props.events} open={this.props.addEventModalOpen === this.props.eventId} closeModal={() => this.props.toggleAddEventModal(false)} action={this.props.action} url={this.props.url} postType={this.props.postType} postResponseType={this.props.postResponseType} postJsonKey={this.props.postJsonKey} postJsonValue={this.props.postJsonValue} postTextValue={this.props.postTextValue} postSaleKey={this.props.postSaleKey} postDataKey={this.props.postDataKey} eventCompleteMsg={this.props.eventCompleteMsg} listArrayKey={this.props.listArrayKey} listTitleKey={this.props.listTitleKey} listParagraphKey={this.props.listParagraphKey} listUrlKey={this.props.listUrlKey} listImageKey={this.props.listImageKey} listButtonText={this.props.listButtonText} eventName={this.props.eventName} mode={this.props.mode} conditions={this.props.conditions} questionMap={this.props.questionMap} fraudOverrides={this.props.fraudOverrides} rateName={this.props.rateName} payout={this.props.payout} trackingType={this.props.trackingType} trafficType={this.props.trafficType} merchantId={this.props.merchantId} editEvent={this.props.editEvent} deleteEvent={this.props.deleteEvent} survey={this.props.survey} prepop={this.props.prepop} />
                <div className="mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="m-0">
                            <span className="badge badge-info rounded">{this.props.eventName}</span>
                        </h5>
                        <button className="btn btn-secondary btn-sm" onClick={() => this.props.toggleAddEventModal(this.props.eventId)}>
                            Edit
                        </button>
                    </div>
                    <hr></hr>
                    {this.props.conditions.length > 0 ? (
                        this.props.conditions.map((outerCondition, outerIndex) => {
                            //Now map through inner conditions
                            return <SurveyConditionDisplay conditions={this.props.conditions} outerCondition={outerCondition} outerIndex={outerIndex} />
                        })
                    ) : (
                        <div className="alert alert-secondary m-0 p-1 text-small">No conditions.</div>
                    )}
                </div>
                <hr></hr>
                {this.props.mode !== 2 && (
                    <React.Fragment>
                        <div className="text-secondary">
                            <i>"{this.props.eventCompleteMsg}"</i>
                        </div>
                        <hr></hr>
                    </React.Fragment>
                )}
                <div>
                    <table className="table table-sm table-info m-0 text-small table-hover">
                        <tbody>
                            <tr>
                                <td>Trigger</td>
                                <td>{this.props.mode === 1 ? 'On Completion' : 'On Condition'}</td>
                            </tr>
                            <tr>
                                <td>Event action</td>
                                <td>{this.props.action}</td>
                            </tr>
                            <tr>
                                <td>Rate Name</td>
                                <td>{this.props.rateName}</td>
                            </tr>
                            <tr>
                                <td>Payout</td>
                                <td>{this.props.payout === 'N' ? 'Network' : 'Affiliate'}</td>
                            </tr>
                            <tr>
                                <td>Traffic</td>
                                <td>{this.props.trafficType === 'Z' ? 'Any' : this.props.trafficType === 'A' ? 'Affiliate' : 'Organic'}</td>
                            </tr>
                            <tr>
                                <td>Tracking</td>
                                <td>{this.props.trackingType === 'L' ? 'Lead' : this.props.trackingType === 'E' ? 'Event' : this.props.TrackingType}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default SubmitEvent
