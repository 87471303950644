import React, { useState } from 'react'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import Loading from '../../../../templates/UI/Loading/Loading'
import AdvertiserTeam from '../AdvertiserTeam/AdvertiserTeam'
import CampaignCapWatch from '../CampaignCapWatch/CampaignCapWatch'
import CampaignSliders from '../CampaignSliders/CampaignSliders'
import CreditChecks from '../CreditChecks/CreditChecks'
import MarginSummary from '../MarginSummary/MarginSummary'
import OpenIssues from '../OpenIssues/OpenIssues'
import PopularCampaigns from '../PopularCampaigns/PopularCampaigns'
import ThresholdAdvertiserBalances from '../ThresholdAdvertiserBalances/ThresholdAdvertiserBalances'
import TopCampaignsOfTheWeek from '../TopCampaignsOfTheWeek/TopCampaignsOfTheWeek'
import YesterdaysCapHits from '../YesterdaysCapHits/YesterdaysCapHits'

export default function AdvertiserDirector({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_director_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Advertiser Director</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <MarginSummary showSystemMessage={showSystemMessage} manager={manager} mode={'advertiser'} />
                            <ThresholdAdvertiserBalances manager={manager} showSystemMessage={showSystemMessage} noAdmin={true} />
                            <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                        </div>
                        <div className="col-lg-8">
                            <AdvertiserTeam showSystemMessage={showSystemMessage} mode="all" />
                            <div className="row">
                                <div className="col-lg-6">
                                    <CampaignCapWatch mode="admin" manager={manager} showSystemMessage={showSystemMessage} />
                                    <YesterdaysCapHits manager={manager} showSystemMessage={showSystemMessage} />
                                    <CreditChecks manager={manager} showSystemMessage={showSystemMessage} />
                                </div>
                                <div className="col-lg-6">
                                    <TopCampaignsOfTheWeek showSystemMessage={showSystemMessage} />
                                    <CampaignSliders showSystemMessage={showSystemMessage} />
                                    <PopularCampaigns showSystemMessage={showSystemMessage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
