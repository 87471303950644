import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import useCallApi from '../../hooks/useCallApi'

export default function AdvertiserPlatforms() {
    let apiUrl = `advertisers/trackingPlatforms`

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [])

    return dataLoaded && data.platforms.length === 0 ? (
        <FontAwesomeIcon icon="spinner" spin />
    ) : data.platforms ? (
        data.platforms.map((platform) => {
            return <option value={platform.id}>{platform.name}</option>
        })
    ) : (
        <FontAwesomeIcon icon="spinner" spin />
    )
}
