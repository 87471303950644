import React, { useState, useEffect } from 'react'
import Loading from '../../templates/UI/Loading/Loading'
import queryString from 'query-string'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import { Link } from 'react-router-dom'
import callAPI from '../../helpers/callAPI'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import DownloadCSV from '../../templates/DownloadCSV/DownloadCSV'

const SearchId = ({ showSystemMessage }) => {

    document.title = 'ID Search'

    const [dataLoaded, setDataLoaded] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [actionInfo, setActionInfo] = useState({})
    const [leadInfo, setLeadInfo] = useState([])
    const [mode, setMode] = useState('action')

    const location = useLocation()

    useEffect(() => {
        // Get the param from props from URL
        let urlParams = queryString.parse(location.search)
        let actionId = urlParams.actionId ? urlParams.actionId : ''

        setSearchValue(actionId)
        handleSearch(actionId)

        //1296565995
    }, [location, showSystemMessage])

    const handleSearch = (searchValue) => {
        const fetchData = async () => {
            if (searchValue !== '' && parseInt(searchValue) !== 0) {
                setDataLoaded(false)

                let fetchUrl = `searchId?actionId=${searchValue}`

                const result = await callAPI(fetchUrl, 'POST', { actionId: searchValue })

                if (result['success'] === true) {
                    setDataLoaded(true)
                    if (result.actions) {
                        setActionInfo(result.actions)
                    }
                    if (result.lead) {
                        setLeadInfo(result.lead)
                    }
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', result.errors.message)
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                setDataLoaded(true)
            }
        }

        fetchData()
    }

    const CSVHeaders = ['Date', 'Click ID', 'Affiliate Id', 'Affiliate', 'Offer Id', 'Campaign', 'Lead Action ID', 'Type', 'Status', 'Rate', 'Data', 'Subid1', 'Subid2', 'Subid3', 'Subid4', 'Subid5']
    const CSVDataLabels = ['db_date', 'action_id', 'affiliate_id', 'name', 'offer_id', 'offer_name', 'related_action_id', 'action_type', 'status', 'rate_name', 'action_data', 'subcode1', 'subcode2', 'subcode3', 'subcode4', 'subcode5']

    const CSVLeadHeaders = ['Date', 'Lead ID', 'Action ID', 'Click ID', 'Affiliate ID', 'Affiliate', 'Campaign ID', 'Campaign', 'Status', 'Aff Rate', 'Net Rate', 'Sale', 'data', 'country', 'C2L', 'Subid1', 'Subid2', 'Subid3', 'Subid4', 'Subid5']
    const CSVLeadDataLabels = ['db_date', 'lead_id', 'lead_action_id', 'action_id', 'affiliate_id', 'name', 'offer_id', 'offer_name', 'status', 'affiliate_rate', 'network_rate', 'sale_amount', 'lead_data', 'ip_country', 'click_to_lead_seconds', 'subcode1', 'subcode2', 'subcode3', 'subcode4', 'subcode5']

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">ID Search</h1>
            </div>
            <div className="card">
                <div className="card-header">
                    <h1 className="mb-h1-dark">Search Parameters</h1>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="form-group">
                            <textarea name="search_box" className="my-3 form-control" placeholder="Comma separated list of IDs. Example: 123456,987654" onChange={(e) => setSearchValue(e.target.value)} defaultValue={searchValue}></textarea>

                            <button className="btn btn-small btn-primary" onClick={() => handleSearch(searchValue)}>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="card mt-3">
                        <div className="card-header d-flex justify-content-between">
                            <h1 className="mb-h1-dark">Action Information</h1>
                            <div>
                                <DownloadCSV filename={`Action ID Search`} headerLabels={CSVHeaders} data={actionInfo} dataLabels={CSVDataLabels} />
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table table-card table-hover mb-0 text-small">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Click ID</th>
                                        <th scope="col">Affiliate</th>
                                        <th scope="col">Campaign</th>
                                        <th scope="col">Related ID</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Rate</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Subid1</th>
                                        <th scope="col">Subid2</th>
                                        <th scope="col">Subid3</th>
                                        <th scope="col">Subid4</th>
                                        <th scope="col">Subid5</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actionInfo.length > 0 ? (
                                        actionInfo.map((actionInfo) => {
                                            return (
                                                <tr>
                                                    <td>{actionInfo.db_date}</td>
                                                    <td>{actionInfo.action_id}</td>
                                                    <td>
                                                        <Affiliate aid={actionInfo.affiliate_id} name={actionInfo.name} status={actionInfo.aff_status} priority={actionInfo.aff_priority} /> ({actionInfo.affiliate_id})
                                                    </td>
                                                    <td>
                                                        <Campaign oid={actionInfo.offer_id} name={actionInfo.offer_name} status={actionInfo.offer_status} /> ({actionInfo.offer_id})
                                                    </td>
                                                    <td>
                                                        <Link to={`searchId?actionId=${actionInfo.related_action_id}`}>{actionInfo.related_action_id}</Link>
                                                    </td>
                                                    <td>{actionInfo.action_type}</td>
                                                    <td>{actionInfo.status}</td>
                                                    <td>{actionInfo.rate_name}</td>
                                                    <td>
                                                        <input type="text" value={actionInfo.action_data} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={actionInfo.subcode1} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={actionInfo.subcode2} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={actionInfo.subcode3} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={actionInfo.subcode4} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={actionInfo.subcode5} disabled className="form-control form-control-sm" />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={15}>No records found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header d-flex justify-content-between">
                            <h1 className="mb-h1-dark">Lead Information</h1>
                            <div>
                                <DownloadCSV filename={`Action ID Search - Lead Info`} headerLabels={CSVLeadHeaders} data={leadInfo} dataLabels={CSVLeadDataLabels} />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="alert alert-info">If a click action resulted in a conversion, it will also be displayed here.</div>
                            <table className="table table-card table-hover mb-0 text-small">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Lead ID</th>
                                        <th scope="col">Action ID</th>
                                        <th scope="col">Click ID</th>
                                        <th scope="col">Affiliate</th>
                                        <th scope="col">Offer</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Aff Rate</th>
                                        <th scope="col">Net Rate</th>
                                        <th scope="col">Sale</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Country</th>
                                        <th scope="col">C2L</th>
                                        <th scope="col">Subid1</th>
                                        <th scope="col">Subid2</th>
                                        <th scope="col">Subid3</th>
                                        <th scope="col">Subid4</th>
                                        <th scope="col">Subid5</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leadInfo.length > 0 ? (
                                        leadInfo.map((lead) => {
                                            return (
                                                <tr>
                                                    <td>{lead.db_date}</td>
                                                    <td>{lead.lead_id}</td>
                                                    <td>{lead.lead_action_id}</td>
                                                    <td>{lead.action_id}</td>
                                                    <td>
                                                        <Affiliate aid={lead.affiliate_id} name={lead.name} status={lead.aff_status} priority={lead.aff_priority} /> ({lead.affiliate_id})
                                                    </td>
                                                    <td>
                                                        <Campaign oid={lead.offer_id} name={lead.offer_name} status={lead.offer_status} /> ({lead.offer_id})
                                                    </td>
                                                    <td>{lead.status}</td>
                                                    <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(lead.affiliate_rate)}</td>
                                                    <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(lead.network_rate)}</td>
                                                    <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(lead.sale_amount)}</td>
                                                    <td>
                                                        <input type="text" value={lead.lead_data} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>{lead.ip_country}</td>
                                                    <td>{lead.click_to_lead_seconds}</td>
                                                    <td>
                                                        <input type="text" value={lead.subcode1} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={lead.subcode2} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={lead.subcode3} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={lead.subcode4} disabled className="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="text" value={lead.subcode5} disabled className="form-control form-control-sm" />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={18}>No records found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div style={{ marginTop: '4rem' }}>
                    <Loading />
                </div>
            )}
        </React.Fragment>
    )
}

export default SearchId
