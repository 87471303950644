import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

export default function BizdevPerformanceReport({ defaultAdminId, showSystemMessage }) {
    const [adminId, setAdminId] = useState(defaultAdminId)
    const { data, dataLoaded, refetch } = useCallApi(`admin/${adminId}/bizdevperformancereport`, 'GET', {}, [adminId], showSystemMessage)
    const history = useHistory()

    useEffect(() => {
        history.push(`/admin/${adminId}/bizdevperformancereport`)
    }, [adminId, history])

    return (
        <React.Fragment>
            <Card className="mb-3">
                <Card.Header className=" justify-content-between align-items-center">
                    <span className="d-flex align-items-center">
                        <div className="mr-2">Bizdev Performance Report</div>
                        <div>
                            <AdminPicker role={['BIZDEV', 'BIZDEV_DIRECTOR']} setAdminId={setAdminId} adminId={adminId} />
                        </div>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex">
                        <div className="alert alert-success mr-2">
                            MTD Revenue: <span className="font-weight-bold">{dataLoaded && data.success && <Dollars value={data.data.total_margin} />}</span>
                        </div>
                        <div className="alert alert-success">
                            Projected MTD Revenue: <span className="font-weight-bold">{dataLoaded && data.success && <Dollars value={data.data.projected_margin} />}</span>
                        </div>
                    </div>
                    {dataLoaded && data.success ? (
                        <>
                            <h5 className="font-weight-bold">Total Campaign Earnings</h5>
                            <div className="d-flex justify-content-between align-items-center">
                                <Line
                                    data={{
                                        labels: data.data.report_data.map((item) => moment(item.stats_month).utc().format('MMM/YY')),
                                        datasets: [
                                            {
                                                data: data.data.report_data.map((item) => item.monthly_earnings.toFixed(2)),
                                                backgroundColor: 'rgba(23,162, 184, 0.1)',
                                                borderColor: 'rgba(23, 162, 184, 1)',
                                                fill: false,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                    }}
                                    width={320}
                                    height={200}
                                />
                            </div>
                            <h5 className="font-weight-bold mt-5">Total Campaigns with Stats</h5>
                            <div className="d-flex justify-content-between align-items-center">
                                <Line
                                    data={{
                                        labels: data.data.report_data.map((item) => moment(item.stats_month).utc().format('MM/YY')),
                                        datasets: [
                                            {
                                                data: data.data.report_data.map((item) => item.total_offers),
                                                backgroundColor: 'rgba(23,162, 184, 0.1)',
                                                borderColor: 'rgba(23, 162, 184, 1)',
                                                fill: false,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                    }}
                                    width={320}
                                    height={200}
                                />
                            </div>
                        </>
                    ) : (
                        <Loading />
                    )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
