import React, { useState, useEffect } from 'react'
import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import callAPI from '../../../../helpers/callAPI'

export default function MarketingPlatformDropdown({ marketingPlatformId, setMarketingPlatformId, omittedPlatformIdArray = [] }) {
    // Get the marketing campaigns using useCallAPI
    const { data, dataLoaded, refetch } = useCallApi('marketing/platforms', 'GET', {}, [])

    return dataLoaded ? (
        <select
            className="form-control form-control-sm"
            defaultValue={marketingPlatformId ? marketingPlatformId : ''}
            onChange={(e) => {
                setMarketingPlatformId(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)
            }}
        >
            <option value="">Select a marketing platform</option>
            {data.marketing_platforms.active.map((platform) => {
                if (omittedPlatformIdArray.findIndex((item) => item.id === platform.id) < 0) {
                    return <option value={platform.id}>{platform.name}</option>
                }
            })}
        </select>
    ) : (
        <Loading />
    )
}
