import React, { Component } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { MoreVertical } from 'react-feather'
import { Link } from 'react-router-dom'

import './ReportsMore.css'

class ReportsMore extends Component {
    state = {
        dropdownOpen: false,
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    render() {
        let offer_url = `/reports/campaign_earnings?startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        let aff_url = `/reports/affiliate_earnings?startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        let cr_url = `/reports/creatives?startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        let lp_url = `/reports/landing_pages?startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        let ref_clicks_url = `/reports/referring_urls_clicks?startDate=${this.props.startDate}&endDate=${this.props.endDate}`
        let subid_url = `/reports/subids?startDate=${this.props.startDate}&endDate=${this.props.endDate}`

        if (this.props.oid > 0) {
            if (this.props.campaigns) {
                offer_url = offer_url + `&oid=${this.props.oid}`
            }
            if (this.props.affiliates) {
                aff_url = aff_url + `&oid=${this.props.oid}`
            }
            if (this.props.creatives) {
                cr_url = cr_url + `&oid=${this.props.oid}`
            }
            if (this.props.landingPages) {
                lp_url = lp_url + `&oid=${this.props.oid}`
            }
            if (this.props.refClicks) {
                ref_clicks_url = ref_clicks_url + `&oid=${this.props.oid}`
            }
            if (this.props.subids) {
                subid_url = subid_url + `&oid=${this.props.oid}`
            }
        }
        if (this.props.aid > 0) {
            if (this.props.campaigns) {
                offer_url = offer_url + `&aid=${this.props.aid}`
            }
            if (this.props.creatives) {
                cr_url = cr_url + `&aid=${this.props.aid}`
            }
            if (this.props.landingPages) {
                lp_url = lp_url + `&aid=${this.props.aid}`
            }
            if (this.props.refClicks) {
                ref_clicks_url = ref_clicks_url + `&aid=${this.props.aid}`
            }
            if (this.props.subids) {
                subid_url = subid_url + `&aid=${this.props.aid}`
            }
        }

        if (this.props.mid > 0) {
            if (this.props.campaigns) {
                offer_url = offer_url + `&mid=${this.props.mid}`
            }
        }
        if (this.props.cid > 0) {
            if (this.props.refClicks) {
                ref_clicks_url = ref_clicks_url + `&cid=${this.props.cid}`
            }
        }

        if (this.props.lpid > 0) {
            if (this.props.refClicks) {
                ref_clicks_url = ref_clicks_url + `&lpid=${this.props.lpid}`
            }
        }

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction="left">
                <DropdownToggle tag="a" onClick={this.toggle} data-toggle="dropdown" aria-expanded={this.state.dropdownOpen} className="report-more-toggle">
                    <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.conversions && (
                        <>
                            <Link className="dropdown-item" to={`/`} onClick={this.toggle}>
                                Conversions
                            </Link>
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.leads && (
                        <>
                            <Link className="dropdown-item" to={`/`} onClick={this.toggle}>
                                Referring URLS (Leads)
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}

                    {this.props.creatives && (
                        <>
                            <Link className="dropdown-item" to={cr_url} onClick={this.toggle}>
                                Creatives
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.landingPages && (
                        <>
                            <Link className="dropdown-item" to={lp_url} onClick={this.toggle}>
                                Landing Pages
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.campaigns && (
                        <>
                            <Link className="dropdown-item" to={offer_url} onClick={this.toggle}>
                                Campaigns
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.advertisers && (
                        <>
                            <Link className="dropdown-item" to={`/`} onClick={this.toggle}>
                                Advertisers
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.affiliates && (
                        <>
                            <Link className="dropdown-item" to={aff_url} onClick={this.toggle}>
                                Affiliates
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.subids && (
                        <>
                            <Link className="dropdown-item" to={subid_url} onClick={this.toggle}>
                                Sub ID
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {this.props.refClicks && (
                        <>
                            <Link className="dropdown-item" to={ref_clicks_url} onClick={this.toggle}>
                                Referring URLS (Clicks)
                            </Link>{' '}
                            <DropdownItem divider className="m-0" />
                        </>
                    )}

                    {/* <DropdownItem divider className="m-0" />
                    { this.props.trafficTypes && <Link className="dropdown-item" to={`/`} onClick={this.toggle}>Traffic Types</Link> }
                    <DropdownItem divider className="m-0" />
                    { this.props.countries && <Link className="dropdown-item" to={`/`} onClick={this.toggle}>Countries</Link> } */}
                </DropdownMenu>
            </Dropdown>
        )
    }
}

export default ReportsMore
