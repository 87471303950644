import React, { useState } from 'react'
import moment from 'moment'

import BizMonth from './BizMonth'

const BizMonthTotals = (props) => {
    const [openMonth, setOpenMonth] = useState(false)

    const record = props.record

    return (
        <React.Fragment>
            <tr key={record.month} className="text-secondary">
                <td className="text-center">
                    {moment()
                        .month(record.month - 1)
                        .format('MMM')}
                </td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_active_status)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_with_sales)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_new)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_disc)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_neg)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_promoted_to_active)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_old_cancel)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.month_totals.total_new_cancel)}</td>
                <td>
                    <span className="badge badge-secondary" style={{ cursor: 'pointer' }} onClick={() => setOpenMonth(!openMonth)}>
                        {openMonth ? '-' : '+'}
                    </span>
                </td>
            </tr>
            {openMonth && record.report.map((record) => <BizMonth record={record} />)}
        </React.Fragment>
    )
}

export default BizMonthTotals
