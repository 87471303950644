import { useCallback, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import DomainAffiliates from './DomainAffiliates'
import DomainTable from './DomainTable'

const ManageDomains = ({ showSystemMessage }) => {

    document.title = 'Tracking Domains'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'mb_domains')

    const [affiliateData, setAffiliateData] = useState()
    const [affiliateDataLoaded, setAffiliateDataLoaded] = useState(false)
    const [lastAction, setLastAction] = useState('P')

    const [formData, setFormData] = useState({
        pubsUsingDomain: false,
        sendEmail: false,
        confirmAction: false,
        password: '',
        selectedDomain: '',
    })
    const { dataLoaded, data, setData, refetch } = useCallApi(`admin/domains?adminId=${auth.user.id}`, 'GET', {}, [], showSystemMessage)

    const handleChange = useCallback(
        (e) => {
            const { name, type, checked, value } = e.target
            if (name === 'pubsUsingDomain' && checked) {
                setFormData({ ...formData, [name]: checked, sendEmail: false })
            } else if (name === 'sendEmail' && checked) {
                setFormData({ ...formData, [name]: checked, pubsUsingDomain: false })
            } else {
                setFormData({ ...formData, [e.target.name]: type === 'checkbox' ? checked : value })
            }
        },
        [formData]
    )

    const validate = useCallback(() => {
        if (!formData.pubsUsingDomain && !formData.sendEmail) {
            showSystemMessage('error', 'Please select an action')
            return false
        }
        if (formData.sendEmail && formData.password === '') {
            showSystemMessage('error', 'Please enter a password to send Emails')
            return false
        }
        if (formData.confirmAction === false && formData.sendEmail) {
            showSystemMessage('error', 'Please confirm action to send Emails')
            return false
        }
        if (formData.selectedDomain === '') {
            showSystemMessage('error', 'Please select a domain')
            return false
        }
        return true
    }, [formData, showSystemMessage])

    const submitForm = useCallback(
        async (e) => {
            if (validate()) {
                setAffiliateDataLoaded(false)
                if (formData.pubsUsingDomain) {
                    const result = await callAPI(`admin/domain/affiliates?selectedDomain=${formData.selectedDomain}`, 'GET')
                    if (result.success) {
                        showSystemMessage('success', 'Affiliate data loaded')
                        setAffiliateData(result)
                    }
                    setLastAction('P')
                    setAffiliateDataLoaded(true)
                } else if (formData.sendEmail) {
                    const result = await callAPI(`admin/domain/affiliates/sendmail`, 'POST', { selectedDomain: formData.selectedDomain, password: formData.password, adminId: auth.user.id })
                    if (result.success) {
                        showSystemMessage('success', 'Email sent')
                        setAffiliateData(result)
                    }
                    setLastAction('E')
                    setAffiliateDataLoaded(true)
                }
            }
        },
        [formData, showSystemMessage]
    )

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">MB Tracking Domain Manager</h1>
            </div>
            <div className="card mb-3">
                <div className="card-body p-2">
                    <Form.Group controlId="Form" className="d-flex align-items-center mb-0">
                        <Form.Check className="ml-5" style={{ width: 'auto' }}>
                            <Form.Check.Input type="radio" checked={formData.pubsUsingDomain} name="pubsUsingDomain" id="pubsUsingDomain" onChange={handleChange} />
                            <Form.Check.Label className="ml-2" htmlFor="pubsUsingDomain">
                                Pubs Using Domain
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="ml-5" style={{ width: 'auto' }}>
                            <Form.Check.Input type="radio" checked={formData.sendEmail} name="sendEmail" id="sendEmail" onChange={handleChange} />
                            <Form.Check.Label className="ml-2" htmlFor="sendEmail">
                                Send Email
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check className="ml-5" style={{ width: 'auto' }}>
                            <Form.Check.Input checked={formData.confirmAction} name="confirmAction" id="confirmAction" onChange={handleChange} />
                            <Form.Check.Label className="ml-2" htmlFor="confirmAction">
                                Confirm Action
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Control type="password" placeholder="Password" className="ml-5" style={{ width: 'auto' }} name="password" value={formData.password} onChange={handleChange} />
                        <Button variant="primary" className="ml-5" style={{ width: 'auto' }} name="executeAction" onClick={submitForm}>
                            Execute Action
                        </Button>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    {dataLoaded ? <DomainTable domains={data.general_domains} setFormData={setFormData} formData={formData} dataLoaded={dataLoaded} scope="general" /> : <Loading />}
                    <div className='mb-3'></div>
                    {dataLoaded ? <DomainTable domains={data.custom_domains} setFormData={setFormData} formData={formData} dataLoaded={dataLoaded} scope="custom" /> : <Loading />}
                    <div className='mb-3'></div>
                    {dataLoaded ? <DomainTable domains={data.unknown_domains} setFormData={setFormData} formData={formData} dataLoaded={dataLoaded} scope="Unknown" /> : <Loading />}
                </div>
                <div className="col-6">
                    <DomainAffiliates affiliateData={affiliateData} affiliatesLoaded={affiliateDataLoaded} formData={formData} lastAction={lastAction} />
                </div>
            </div>
        </>
    )
}

export default ManageDomains
