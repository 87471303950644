import moment from "moment";
import { useCallback, useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Modal } from 'react-bootstrap'
import callAPI from "../../../helpers/callAPI";
import { SystemMessageContext } from "../../../context/SystemMessageContext";

const TaskModal = ({ setTask, task, visible, handleClose }) => {
    const {showSystemMessage} = useContext(SystemMessageContext);
    const [tempFormData, setTempFormData] = useState({
        job_name: "",
        start_date: "",
        end_date: "",
        interval_value: "",
        interval_type: "years",
        business_days_only: "N",
        job_type: "",
        api_credential1: "",
        api_credential2: "",
        api_credential3: "",
    });

    const updateTask = async () => {
        let result = await callAPI( `systemJob`,"PUT", task);
        console.log(result);
        if (result.success) {
            showSystemMessage("success", "Task updated successfully");
            handleClose();
        } else {
            showSystemMessage("error", result.errors.message);
        }
    }

    const createTask = async () => {
        let result = await callAPI( `systemJob`,"POST", tempFormData);
        console.log(result);
        if (result.success) {
            showSystemMessage("success", "Task created successfully");
            handleClose();
        } else {
            showSystemMessage("error", result.errors.message);
        }
    }



    const inputChangeHandler = (e) => {
        console.log(task.isEdit)
        if ((e.target.name === "start_date"|| e.target.name=== "end_date") && task.isEdit) {
            console.log("1")
            let date = moment(e.target.value).format("YYYY-MM-DDTHH:mm:ss");
            setTask({ ...task, [e.target.name]: date,isEdit: true });
        } else if ((e.target.name === "start_date"|| e.target.name=== "end_date")) {
            console.log("2")
            let date = moment(e.target.value).format("YYYY-MM-DDTHH:mm:ss");
            setTempFormData({ ...tempFormData, [e.target.name]: date });
        } else if (task.isEdit) {
                console.log("3")
            setTask({ ...task, [e.target.name]: e.target.value });
        } else {
            console.log("4")
            setTempFormData({ ...tempFormData, [e.target.name]: e.target.value });
        }
    }

    const IntervalOptions = () => {
        return (
            <>
                <option value="years">Year(s)</option>
                <option value="months">Month(s)</option>
                <option value="weeks">Week(s)</option>
                <option value="days">Day(s)</option>
                <option value="hours">Hour(s)</option>
                <option value="minutes">Minute(s)</option>
            </>
        )
    }

    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{task.isEdit ? 'Edit' : 'Create'} System Job</Modal.Title>
            </Modal.Header>
            <table style={{margin: 20}}>
                <tbody>
                    {task.isEdit ?
                        <tr>
                            <td>Name:</td>
                            <td>
                                <Form.Control name="job_name" disabled defaultValue={task.job_name} className="form-control form-control-sm" onChange={inputChangeHandler} required value={task.name} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>Name:</td>
                            <td>
                                <Form.Control name="job_name" className="form-control form-control-sm" onChange={inputChangeHandler} required value={tempFormData.name} />
                            </td>
                        </tr>
                    }
                    {task.isEdit ?
                        <tr>
                            <td>Group:</td>
                            <td>
                                <Form.Control name="job_type" defaultValue={task.job_type} className="form-control form-control-sm" onChange={inputChangeHandler} required value={task.job_type} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>Group:</td>
                            <td>
                                <Form.Control name="job_type" className="form-control form-control-sm" onChange={inputChangeHandler} required value={tempFormData.job_type} />
                            </td>
                        </tr>
                    }
                    {task.isEdit ?
                        <tr>
                            <td>Start date:</td>
                            <td>
                                <Form.Control name="start_date" type="datetime-local" defaultValue={task.start_date} onChange={inputChangeHandler} required value={task.start_date} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>Start date:</td>
                            <td>
                                <Form.Control name="start_date" type="datetime-local" onChange={inputChangeHandler} required value={tempFormData.start_date} />
                            </td>
                        </tr>
                    }
                    {task.isEdit ?
                        <tr>
                            <td>End date:</td>
                            <td>
                                <Form.Control name="end_date" type="datetime-local" defaultValue={task.end_date} onChange={inputChangeHandler} required value={task.end_date} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>End date:</td>
                            <td>
                                <Form.Control name="end_date" type="datetime-local" onChange={inputChangeHandler} required value={tempFormData.end} />
                            </td>
                        </tr>
                    }


                    {task.isEdit ?
                        <tr>
                            <td>Interval:</td>
                            <td style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                Every
                                <Form.Control type="number" name="interval_value" defaultValue={task.interval_value} onChange={inputChangeHandler} style={{ marginLeft: 10 }} required value={task.interval_value} />
                                
                                <Form.Control name="interval_type" as="select" defaultValue={task.interval_type} onChange={inputChangeHandler} style={{ marginLeft: 10 }} required value={task.interval_type}>
                                    <IntervalOptions />
                                </Form.Control>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                Business Days Only
                                <Form.Control name="business_days_only" as="select" defaultValue={task.business_days_only} onChange={inputChangeHandler} style={{ marginLeft: 10 }} required value={task.business_days_only}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                                </div>
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>Interval:</td>
                            <td style={{ display: "flex", alignItems: "center" }}>
                                Every
                                <Form.Control type="number" name="interval_value" onChange={inputChangeHandler} style={{ marginLeft: 10 }} required value={tempFormData.interval_value} />
                                <Form.Control name="interval_type" as="select" onChange={inputChangeHandler} style={{ marginLeft: 10 }} required value={tempFormData.interval_type}>
                                    <IntervalOptions />
                                </Form.Control>
                            </td>
                        </tr>
                    }
                    {/* {task.isEdit ?
                        <tr>
                            <td>URL:</td>
                            <td>
                                <Form.Control name="url" defaultValue={task.url} className="form-control form-control-sm" onChange={inputChangeHandler} required value={task.url} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>URL:</td>
                            <td>
                                <Form.Control name="url" className="form-control form-control-sm" onChange={inputChangeHandler} required value={tempFormData.url} />
                            </td>
                        </tr>
                    } */}
                    {task.isEdit ?
                        <tr>
                            <td>Detailed Log:</td>
                            <td>
                                <Form.Control name="detailed_log" as="select" defaultValue={task.detailed_log} className="form-control form-control-sm w-25" onChange={inputChangeHandler} required value={task.detailed_log}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>Detailed Log:</td>
                            <td>
                                <Form.Control name="detailed_log" as="select" className="form-control form-control-sm w-25" onChange={inputChangeHandler} required value={tempFormData.detailed_log}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </td>
                        </tr>
                    }
                    {task.isEdit ?
                        <tr>
                            <td>API Credential 1:</td>
                            <td>
                                <Form.Control name="api_credential1" defaultValue={task.api_credential1} className="form-control form-control-sm" onChange={inputChangeHandler} required value={task.api_credential1} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>API Credential 1:</td>
                            <td>
                                <Form.Control name="api_credential1" className="form-control form-control-sm" onChange={inputChangeHandler} required value={tempFormData.api_credential1} />
                            </td>
                        </tr>
                    }
                    {task.isEdit ?
                        <tr>
                            <td>API Credential 2:</td>
                            <td>
                                <Form.Control name="api_credential2" defaultValue={task.api_credential2} className="form-control form-control-sm" onChange={inputChangeHandler} required value={task.api_credential2} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>API Credential 2:</td>
                            <td>
                                <Form.Control name="api_credential2" className="form-control form-control-sm" onChange={inputChangeHandler} required value={tempFormData.api_credential2} />
                            </td>
                        </tr>
                    }
                    {task.isEdit ?
                        <tr>
                            <td>API Credential 3:</td>
                            <td>
                                <Form.Control name="api_credential3" defaultValue={task.api_credential3} className="form-control form-control-sm" onChange={inputChangeHandler} required value={task.api_credential3} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>API Credential 3:</td>
                            <td>
                                <Form.Control name="api_credential3" className="form-control form-control-sm" onChange={inputChangeHandler} required value={tempFormData.api_credential3} />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <Modal.Footer>
                <Button variant="primary" onClick={task.isEdit ? updateTask : () => { createTask() }}>{task.isEdit ? 'Save' : 'Create'}</Button>
                <Button variant="danger" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export { TaskModal }