import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import callAPI from '../../../../helpers/callAPI'

import useCallApi from '../../../../hooks/useCallApi'
import Dollars from '../../../../templates/Dollars/Dollars'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'
import Loading from '../../../../templates/UI/Loading/Loading'

const ReceivePaymentModal = ({ isOpen, status, advertiserId, periodType, handleClose, date, showSystemMessage }) => {
    const { dataLoaded, data, refetch } = useCallApi(`advertiser/${advertiserId}/invoice/${date}/${periodType}`, 'GET', {}, [advertiserId])

    let invoiceInfo = dataLoaded && data.invoice_info

    const [invoicedAmount, setInvoicedAmount] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const getInvoiceType = (value) => {
        if (value === 10) {
            return 'Pre-Payment'
        } else if (value === 20) {
            return 'Weekly'
        } else if (value === 30) {
            return 'Semi-Monthly'
        } else {
            return 'Monthly'
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setSubmitting(true)

        const putBody = {
            amount: invoicedAmount,
            notes: e.target.notes.value,
            period_type: periodType,
            invoice_date: date,
            invoice_status: status,
        }

        if (selectedDate !== ['']) {
            putBody['payment_date'] = selectedDate
        }

        callAPI(`advertiser/${advertiserId}/completeInvoice`, 'PUT', putBody).then((result) => {
            if (result['success'] === true) {
                showSystemMessage('success', result.message)
                handleClose(true)
            } else if (result['success'] === false) {
                showSystemMessage('error', result.errors.message)
                setSubmitting(false)
            } else {
                showSystemMessage('error', 'Error while completing invoice')
                setSubmitting(false)
            }
        })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Receive Payment for {dataLoaded ? (data.invoice_info ? data.invoice_info.name : '') : spinner}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <form onSubmit={handleSubmit}>
                        <table className="table table-borderless m-0">
                            <tbody>
                                {Object.keys(data.invoice_info).length !== 0 && (
                                    <>
                                        <tr>
                                            <td>Invoice Date:</td>
                                            <td>{date}</td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Type:</td>
                                            <td>{getInvoiceType(periodType)}</td>
                                        </tr>
                                        <tr>
                                            <td>Invoiced Amount:</td>
                                            <td>
                                                <Dollars value={invoiceInfo.amount} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Number:</td>
                                            <td>{invoiceInfo.invoice_num}</td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Received Amount:</td>
                                    <td>                                        
                                            {invoiceInfo.status === 'S' && invoiceInfo.period_type !== 10 && invoiceInfo.payment_terms === -1 ?                                                                                                 
                                                <div className="alert alert-warning m-0">
                                                    This is a PRE-PAY advertiser. We do not receive payments against <strong>{invoiceInfo.period_type === 10 ? 'pre-payment' : invoiceInfo.period_type === 20 ? 'weekly' : invoiceInfo.period_type === 30 ? 'semi-monthly' : 'monthly'}</strong> invoices for pre-pay advertisers. If there is still a balance owing - please ask the manager for a new PRE-PAYMENT invoice request.
                                                </div>
                                                
                                            :
                                                <input name="invoiced_amount" className="form-control w-50" defaultValue="" maxLength="10" required onChange={(e) => setInvoicedAmount(e.target.value)} />
                                            }                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Received Date:</td>
                                    <td>
                                        <DatePicker className="form-control" selected={selectedDate ? moment(selectedDate).toDate() : null} onChange={(date) => setSelectedDate(date)} />{' '}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Notes:</td>
                                    <td>
                                        <textarea name="notes" className="form-control" defaultValue="" cols="40" rows="5" onChange={(e) => e.target.setCustomValidity('')} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="text-right">
                                        <CustomButton name="Save" variant="primary" type="submit" spin={submitting} dateFormat="YYYY/MM/DD" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ReceivePaymentModal
