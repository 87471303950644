import ReviewRequest from '../../../../components/ReviewRequest/ReviewRequest'
import useQueryParams from '../../../../hooks/useQueryParams'

const ReviewRequestWrapper = ({ requestId, showSystemMessage }) => {
    const { affiliateId, campaignId } = useQueryParams()

    document.title = "Review Campaign Request"

    const handleClose = () => {}

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Review Request</h1>
            </div>
            {/* <div className="row"> */}
            <div>
                <ReviewRequest requestId={requestId} showSystemMessage={showSystemMessage} applicationId={requestId} handleClose={handleClose} handleCloseFlow={handleClose} affiliateId={affiliateId} campaignId={campaignId} type="page" />
            </div>
            {/* </div> */}
        </div>
    )
}

export default ReviewRequestWrapper
