import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import Loading from '../../templates/UI/Loading/Loading'
import Campaign from '../../templates/Campaign/Campaign'
import callAPI from '../../helpers/callAPI'
import Affiliate from '../../templates/Affiliate/Affiliate'

const ReferrerDetailsContent = ({ affiliateIdParam, campaignIdParam, affiliateId, campaignId, refurl, withLeads, landingPageId, subid1, startDate, endDate }) => {
    const location = useLocation()
    let finalStartDate = startDate
    let finalEndDate = endDate

    // If no startDate and endDate from props, get it from the URL. If none, default to today
    if (!finalStartDate || !finalEndDate) {
        let urlParams = queryString.parse(location.search)
        finalStartDate = urlParams.startDate
        finalEndDate = urlParams.endDate
    }

    if (!finalStartDate || !finalEndDate) {
        finalStartDate = moment().startOf('day').format('YYYY-MM-DD')
        finalEndDate = moment().add(1, 'days').startOf('day').format('YYYY-MM-DD')
    }

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [affiliate, setAffiliate] = useState({
        id: 0,
        name: '',
        status: '',
        priority: '',
    })
    const [campaign, setCampaign] = useState({
        id: 0,
        name: '',
        status: '',
    })

    const buildUrl = (url) => {
        if (affiliateId && affiliateId > 0) {
            url = url + `&affiliateId=${affiliateId}`
        }

        if (campaignId && campaignId > 0) {
            url = url + `&campaignId=${campaignId}`
        }

        if (landingPageId && landingPageId > 0) {
            url = url + `&landingPageId=${landingPageId}`
        }

        if (subid1 !== 'notset') {
            url = url + `&subid1=${subid1}`
        }

        if (withLeads && withLeads == 'true') {
            url = url + `&withLeads=true`
        }

        return url
    }

    useEffect(() => {
        // Get the data
        let url = affiliateIdParam ? buildUrl(`affiliate/${affiliateIdParam}/referrerDetails/?startDate=${finalStartDate}&endDate=${finalEndDate}&refurl=${encodeURIComponent(refurl)}`) : buildUrl(`campaign/${campaignIdParam}/referrerDetails/?startDate=${finalStartDate}&endDate=${finalEndDate}&refurl=${encodeURIComponent(refurl)}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    if (result.affiliate) {
                        setAffiliate(result.affiliate)
                    }
                    if (result.campaign) {
                        setCampaign(result.campaign)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    // showSystemMessage('error', result.errors.message);
                    setDataLoaded(true)
                } else {
                    // showSystemMessage('error', 'Could not load report.');
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching click referrer details: ${error}`)
                // showSystemMessage('error', 'An error occurred while fetching click referrers')
            })
    }, [affiliateIdParam, campaignIdParam, refurl])

    return (
        <div className="card">
            <div className="card-header">Referrer Details</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    <div className="m-3">
                        {affiliateIdParam ? (
                            <span>
                                <span className="font-weight-bold">Affiliate:</span> <Affiliate aid={affiliate.id} name={affiliate.name} status={affiliate.status.value} priority={affiliate.status.value} />
                            </span>
                        ) : (
                            <span>
                                <span className="font-weight-bold">Campaign:</span> <Campaign oid={campaign.id} name={campaign.name} status={campaign.status.value} />
                            </span>
                        )}
                        <br></br>
                        <span className="font-weight-bold">Referring URL:</span>
                        {decodeURIComponent(refurl)}
                        <br></br>
                        <span className="font-weight-bold">Date Range:</span> {finalStartDate} - {finalEndDate}
                        <table className="table table-striped table-hover mt-3">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>{affiliateIdParam ? 'Campaign' : 'Affiliate'}</th>
                                    <th style={{ textAlign: 'right' }}>Click Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {report.length > 0 ? (
                                    report.map((record) => {
                                        return (
                                            <tr key={record.id}>
                                                <td width={60}>{record.id}</td>
                                                <td>{affiliateIdParam ? <Campaign oid={record.id} name={record.name} status={record.status} /> : <Affiliate aid={record.id} name={record.name} priority={record.priority} status={record.status_value} />}</td>
                                                <td align="right">{record.count}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={3}>No data.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default ReferrerDetailsContent
