import React, { useEffect, useState } from 'react'
import Flags from '../../components/Flags/Flags'
import AdvertiserInfo from './AdvertiserInfo/AdvertiserInfo'
import AdvertiserNavbar from './AdvertiserNavBar/AdvertiserNavBar'
import LoginDetails from './components/LoginDetails/LoginDetails'
import { useAuth } from '../../context/AuthContext'
import AccountNotes from './components/AccountNotes/AccountNotes'
import useCallApi from '../../hooks/useCallApi'
import CampaignList from './CampaignList/CampaignList'
import EarningSummary from '../../components/EarningSummary/EarningSummary'
import StatsDetails from './StatsDetails/StatsDetails'
import ReversalDetails from './components/ReversalDetails/ReversalDetails'
import FollowupList from '../../components/FollowupList/FollowupList'
import Loading from '../../templates/UI/Loading/Loading'
import OpenIssues from '../Dashboard/components/OpenIssues/OpenIssues'
import Followups from '../../components/Followups/Followups'
import AdvertiserCapWatch from './components/AdvertiserCapWatch/AdvertiserCapWatch'
import AdvertiserApprovalInfoMainPage from './AdvertiserApprovalInfoMainPage/AdvertiserApprovalInfoMainPage'

export default function Advertiser({ advertiserId, showSystemMessage }) {
    const auth = useAuth()
    const [manager, setManager] = useState(auth.user)
    const [isOpen, setIsOpen] = useState(false)
    const [update, setUpdate] = useState(false)
    const { data, dataLoaded, refetch } = useCallApi(`advertiser/${advertiserId}/summary`, 'GET', {}, [advertiserId, update])
    const { data: advertiserData, dataLoaded: advertiserDataLoaded } = useCallApi(`advertiser/${advertiserId}`, 'GET', {}, [advertiserId])

    useEffect(() => {
        if (advertiserDataLoaded && advertiserData.success) {
            document.title = advertiserData.general_info.name
        } else {
            document.title = 'Advertiser'
        }
    }, [advertiserData, advertiserDataLoaded])

    return (
        <React.Fragment>
            <FollowupList advertiserId={advertiserId} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} />
            {dataLoaded && advertiserDataLoaded ? (
                (data.success && advertiserData.success) ?
                    <React.Fragment>
                        <div className="page-header d-flex align-items-center">
                            <h1 className="mb-h1">{advertiserData.general_info.name}</h1>
                            <div className="ml-5">
                                <AdvertiserNavbar advertiserId={advertiserId} showSystemMessage={showSystemMessage} updateCampaignList={() => setUpdate(true)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <AdvertiserInfo advertiserId={advertiserId} setIsOpen={setIsOpen} showSystemMessage={showSystemMessage} limitedCreditFlag={data.limited_credit_flag} />
                                <AdvertiserApprovalInfoMainPage advertiserId={advertiserId} approvalRequirements={data.placement_approval_requirement} approvalNotes={data.custom_approval_notes} />
                                <AccountNotes advertiserId={advertiserId} showSystemMessage={showSystemMessage} notes={data.extra_info} />
                                <LoginDetails advertiserId={advertiserId} manager={manager} showSystemMessage={showSystemMessage} />
                            </div>
                            <div className="col-xl-4 pb-0">
                                <EarningSummary showSystemMessage={showSystemMessage} advertiserId={advertiserId} />
                                <CampaignList advertiserId={advertiserId} showSystemMessage={showSystemMessage} update={update} />
                                <AdvertiserCapWatch advertiserId={advertiserId} manager={manager} showSystemMessage={showSystemMessage} />
                                <Flags showSystemMessage={showSystemMessage} advertiserId={advertiserId} refetch={refetch} />
                                <OpenIssues manager={manager} advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                            </div>
                            <div className="col-xl-4">
                                <Followups setIsOpen={setIsOpen} id={advertiserId} showSystemMessage={showSystemMessage} type="advertiser" managerName={advertiserData.adv_manager.name} managerId={advertiserData.adv_manager.id} bizdevManagerName={advertiserData.bizdev_manager.name} bizdevManagerId={advertiserData.bizdev_manager.id} notes={data.notes} />
                                <StatsDetails advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                                <ReversalDetails advertiserId={advertiserId} manager={manager} showSystemMessage={showSystemMessage} reversals_flag={data.reversals_flag} />
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="page-header d-flex align-items-center">
                            <h1 className="mb-h1">Advertiser does not exist</h1>
                        </div>
                        <div className="row"></div>
                    </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
