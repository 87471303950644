import React, { Component } from 'react'

class CountryFilter extends Component {
    state = {}

    render() {
        return (
            <div>
                <div className="mr-2">
                    <select className="custom-select custom-select-sm">
                        <option value="">All Countries</option>
                        <option value="CA">Canada</option>
                        <option value="USA">United States</option>
                        <option value="...">...</option>
                    </select>
                </div>
            </div>
        )
    }
}

export default CountryFilter
