import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'

const IpDetailsContent = ({ ipAddress, isModal, handleClose }) => {
    const location = useLocation()
    let ip = ipAddress

    if (!ip) {
        let urlParams = queryString.parse(location.search)
        ip = urlParams.ip
    }

    const { dataLoaded, data } = useCallApi(`ipDetails?ip=${ipAddress}`, 'GET', {}, [])

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <span>IP Details</span>
                {isModal ? (
                    <button className="btn btn-sm font-weight-bold text-decoration-none" onClick={handleClose}>
                        X
                    </button>
                ) : (
                    ''
                )}
            </div>
            <div className="card-body">
                {dataLoaded ? (
                    <table className="table table-borderless table-card">
                        <tbody>
                            <tr>
                                <td>
                                    <b>IP Address: </b>
                                    {ipAddress}
                                    <br />
                                    <b>Country: </b>
                                    {data.ip_details.ip_country}
                                    <br />
                                    <b>Region: </b>
                                    {data.ip_details.ip_region}
                                    <br />
                                    <b>City: </b>
                                    {data.ip_details.ip_city}
                                    <br />
                                    <b>ISP: </b>
                                    {data.ip_details.ip_isp}
                                    <br />
                                    <b>Domain: </b>
                                    {data.ip_details.ip_domain}
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default IpDetailsContent
