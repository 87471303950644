import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Triangle } from 'react-feather'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import useCallApi from '../../../../hooks/useCallApi'
import Campaign from '../../../../templates/Campaign/Campaign'
import Dollars from '../../../../templates/Dollars/Dollars'
import Loading from '../../../../templates/UI/Loading/Loading'

export default function CampaignTrendSummary({ showSystemMessage }) {
    const { data, dataLoaded } = useCallApi(`reports/offers/trend?t=w&filter_trend=b&type=o&s=delta`, 'GET', {}, [], showSystemMessage)

    let date1 = moment().subtract(1, 'days').startOf('day')
    let date2 = moment()
    let timeDiff = date2.diff(date1, 'seconds')
    let timeRatio = 604800 / timeDiff

    const [topArray, setTopArray] = useState([])
    const [bottomArray, setBottomArray] = useState([])

    useEffect(() => {
        if (dataLoaded) {
            setTopArray([...data.report.slice(0, 5)])
            setBottomArray([...data.report.reverse().slice(0, 5)].reverse())
        }
    }, [dataLoaded])

    return (
        <div className="card mb-2">
            <div className="card-header d-flex justify-content-between">
                <div>Campaign Trend Summary (Week) <span style={{fontWeight: 'normal'}} className="ml-2">Last Updated: {dataLoaded ? moment(data.last_updated).utc().format('YYYY-MM-DD HH:mm:ss') : ''}</span></div>
                <Link to={`/reports/campaignTrend?t=w`}>Full Report</Link>
            </div>
            <div className="overflow-auto">
                <table className="table table-striped table-hover mb-1">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Campaign</th>
                            <th className="text-center">Current Margin</th>
                            <th className="text-right">T1</th>
                            <th className="text-right">T2</th>
                            <th className="text-right">
                                <Triangle size={10} /> Margin
                            </th>
                            <th className="text-right">
                                <Triangle size={10} /> Margin %
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded && (
                            <tr>
                                <td className="font-weight-bold">Grand Totals:</td>
                                <td></td>
                                <td className="text-center">
                                    <div className="d-flex justify-content-between">
                                        <span className="text-secondary text-right" style={{ width: '50%' }}>
                                            <Dollars value={timeRatio * data.totals.grand_D0} />
                                        </span>{' '}
                                        <span className="text-right" style={{ width: '50%' }}>
                                            <Dollars value={data.totals.grand_D0} />
                                        </span>
                                    </div>
                                </td>
                                <td className="text-right">{<Dollars value={data.totals.grand_D1} />}</td>
                                <td className="text-right">{<Dollars value={data.totals.grand_D2} />}</td>
                                <td className="text-right">{<Dollars value={data.totals.grand_delta} />}</td>
                                <td className={`text-right ${((data.totals.grand_D1 - data.totals.grand_D2) / data.totals.grand_D2) * 100 < 0 ? 'text-danger' : 'text-success'}`}>
                                    {Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(((data.totals.grand_D1 - data.totals.grand_D2) / data.totals.grand_D2) * 100)} %{((data.totals.grand_D1 - data.totals.grand_D2) / data.totals.grand_D2) * 100 < 0 ? <span style={{ color: 'red', fontSize: '10px' }}>&#9660;</span> : <span style={{ color: 'green', fontSize: '10px' }}>&#9650;</span>}
                                </td>
                            </tr>
                        )}
                        {dataLoaded ? (
                            <>
                                {topArray.map((item, index) => {
                                    let classes = `text-right `
                                    let delta = <span></span>

                                    if (item.deltap < 0) {
                                        delta = <span style={{ color: 'red', fontSize: '10px' }}>&#9660;</span>
                                        classes += `text-danger`
                                    } else if (item.deltap > 0) {
                                        delta = <span style={{ color: 'green', fontSize: '10px' }}>&#9650;</span>
                                        classes += `text-success`
                                    } else {
                                        delta = ''
                                    }

                                    return (
                                        <tr>
                                            <td>{item.rank}</td>
                                            <td>
                                                <Campaign oid={item.offer_id} name={item.name} status={item.status} />
                                            </td>
                                            <td className="text-center">
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-secondary text-right" style={{ width: '50%' }}>
                                                        <Dollars value={item.D0 * timeRatio} />
                                                    </span>{' '}
                                                    <span className="text-right" style={{ width: '50%' }}>
                                                        <Dollars value={item.D0} />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.D1} />
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.D2} />
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.delta} />
                                            </td>
                                            <td className="text-right">
                                                <div className={classes}>
                                                    {Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.deltap)} % {delta}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={7} style={{ borderTop: '2px solid RGBA(0,0,0,0.25)', borderBottom: '2px solid RGBA(0,0,0,0.25)' }}></td>
                                </tr>
                                {bottomArray.map((item, index) => {
                                    let classes = `text-right `
                                    let delta = <span></span>

                                    if (item.deltap < 0) {
                                        delta = <span style={{ color: 'red', fontSize: '10px' }}>&#9660;</span>
                                        classes += `text-danger`
                                    } else if (item.deltap > 0) {
                                        delta = <span style={{ color: 'green', fontSize: '10px' }}>&#9650;</span>
                                        classes += `text-success`
                                    } else {
                                        delta = ''
                                    }

                                    return (
                                        <tr>
                                            <td>{item.rank}</td>
                                            <td>
                                                <Campaign oid={item.offer_id} name={item.name} status={item.status} />
                                            </td>
                                            <td className="text-center">
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-secondary text-right" style={{ width: '50%' }}>
                                                        <Dollars value={item.D0 * timeRatio} />
                                                    </span>{' '}
                                                    <span className="text-right" style={{ width: '50%' }}>
                                                        <Dollars value={item.D0} />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.D1} />
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.D2} />
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.delta} />
                                            </td>
                                            <td className="text-right">
                                                <div className={classes}>
                                                    {Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.deltap)} % {delta}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
