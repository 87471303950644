import React, { Component } from 'react'
import StateList from '../../../../../data/statelist'

class States extends Component {
    state = {
        states: StateList.states,
    }

    render() {
        return (
            <React.Fragment>
                {this.state.states.map((state) =>
                    this.props.selected === state.value ? (
                        <option key={state.value} value={state.value} selected>
                            {state.name}
                        </option>
                    ) : (
                        <option key={state.value} value={state.value}>
                            {state.name}
                        </option>
                    )
                )}
            </React.Fragment>
        )
    }
}

export default States
