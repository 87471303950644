import React, { useEffect, useState } from 'react'

import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import CreateCategoryModal from './CreateCategoryModal/CreateCategoryModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const CampaignCategories = ({ showSystemMessage }) => {
    document.title = 'Campaign Categories'
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_categories')

    const { dataLoaded, data, refetch } = useCallApi(`campaigns/categories`, 'GET', {}, [])

    const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState({})
    const [categoryType, setCategoryType] = useState('')
    const [isCreate, setIsCreate] = useState(false)
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        let newList = []

        if (data.categories && data.sub_categories) {
            data.categories.map((mainCategory) => {
                newList.push({
                    category_id: mainCategory.id,
                    name: mainCategory.name,
                    enabled: mainCategory.enabled,
                })

                let subsOfMainArray = data.sub_categories.filter((subcategory) => subcategory.parent_category_id === mainCategory.id)

                subsOfMainArray.map((subCategory) => {
                    newList.push({
                        category_id: subCategory.id,
                        name: subCategory.name,
                        parent_category_id: mainCategory.id,
                        parent_name: mainCategory.name,
                        enabled: subCategory.enabled,
                    })
                })

                return
            })
        }

        setCategoryList(newList)
    }, [data])

    const handleClick = (category, type) => {
        setSelectedCategory(category)
        setCategoryType(type)
        setIsCreate(false)
        setShowCreateCategoryModal(true)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Main Category',
                accessor: 'name',
                Cell: (tableData) => {
                    let parent_category_id = tableData.data[tableData.row.index].parent_category_id

                    if (!parent_category_id) {
                        return (
                            <>
                                <button className="btn btn-link btn-sm pl-0 shadow-none" onClick={() => handleClick(tableData.data[tableData.row.index], 'main')}>
                                    {tableData.data[tableData.row.index].name}
                                </button>
                            </>
                        )
                    } else {
                        return <>{tableData.data[tableData.row.index].parent_name}</>
                    }
                },
            },
            {
                Header: 'Sub Category',
                accessor: 'subcategory_name',
                Cell: (tableData) => {
                    let parent_category_id = tableData.data[tableData.row.index].parent_category_id

                    if (parent_category_id > 0) {
                        return (
                            <>
                                <button className="btn btn-link btn-sm shadow-none pl-0" onClick={() => handleClick(tableData.data[tableData.row.index], 'sub')}>
                                    {tableData.data[tableData.row.index].name}
                                </button>
                            </>
                        )
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Enabled',
                accessor: 'enabled',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? 'Yes' : 'No'}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={categoryList} columns={columns} />
            </React.Fragment>
        )
    }

    const handleClose = (isEdited = false) => {
        setShowCreateCategoryModal(false)
        setSelectedCategory({})
        setCategoryType('')

        if (isEdited) {
            refetch()
        }
    }

    return (
        <div>
            {showCreateCategoryModal && <CreateCategoryModal isOpen={showCreateCategoryModal} handleClose={handleClose} showSystemMessage={showSystemMessage} refetch={refetch} selectedCategory={selectedCategory} categoryType={categoryType} isCreate={isCreate} />}
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="d-flex justify-content-between p-3 align-items-center">
                            <h1 className='mb-h1-dark mr-2'>Campaign Categories</h1>
                            <button
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    setIsCreate(true)
                                    setShowCreateCategoryModal(true)
                                }}
                            >
                                Create Category
                            </button>
                        </div>
                        {dataLoaded ? data.categories.length === 0 ? <div className="p-3">No categories to show</div> : getReportOutput() : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignCategories
