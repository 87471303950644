import React, { Component } from 'react'

import ReactTooltip from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Power } from 'react-feather'

class PropertySettings extends Component {
    state = {}

    render() {
        let statusDotClass = this.props.status.toUpperCase() === 'ACTIVE' ? 'text-success' : 'text-danger'

        return (
            <div className="card">
                <ReactTooltip />
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <FontAwesomeIcon icon="circle" size="xs" className={statusDotClass} /> <span className="font-weight-bold">Property {this.props.propertyId}</span>
                    </div>
                    <div>
                        {this.props.activateButtonLoaded ? (
                            this.props.status === 'Active' ? (
                                <button onClick={this.props.deactivate} className="btn btn-danger btn-sm d-flex align-items-center">
                                    <Power size={14} /> &nbsp; Deactivate
                                </button>
                            ) : (
                                <button onClick={this.props.activate} className="btn btn-info btn-sm  d-flex align-items-center">
                                    <Power size={14} /> &nbsp; Activate
                                </button>
                            )
                        ) : (
                            <button className="btn btn-secondary btn-sm" disabled>
                                <FontAwesomeIcon icon="spinner" spin />
                            </button>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <div>
                        <label htmlFor="domain">Domain:</label>{' '}
                        <a target="_blank" rel="noreferrer" href={this.props.domain}>
                            Visit site
                        </a>
                        <input name="domain" className="form-control" type="text" value={this.props.domain} onChange={this.props.handleDomainChange} />
                        <label htmlFor="offerId" className="mt-2">
                            MaxBounty Offer ID (for admin stats):
                        </label>
                        <input name="offerId" className="form-control systemId" type="text" defaultValue={this.props.offerId} onChange={this.props.handleOfferIdChange} />
                        {this.props.domainSubmitLoaded ? (
                            <button className="btn btn-primary btn-sm mt-3" onClick={this.props.updateDomain}>
                                Update
                            </button>
                        ) : (
                            <button className="btn btn-secondary btn-sm mt-3" disabled>
                                <FontAwesomeIcon icon="spinner" spin />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default PropertySettings
