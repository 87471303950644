import React, { useState, useEffect } from 'react'

import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import TeamMemberCard from '../../../../templates/TeamMemberCard/TeamMemberCard'
import TeamMemberCardMobile from '../../../../templates/TeamMemberCardMobile/TeamMemberCardMobile'

import styles from './BizdevTeam.module.scss'

export default function BizdevTeam(props) {
    const [managers, setManagers] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    const { showSystemMessage, mode } = props

    useEffect(() => {
        let url = `summary/bizdevTeam`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setManagers(result.managers)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching affiliate team details')
            })
    }, [])

    return (
        <div className="card mb-3 overflow-auto">
            <div className="card-header">Bizdev Team</div>
            <div className={`${styles.desktopOnly} card-body p-0`} style={{ fontSize: '.9rem' }}>
                {dataLoaded ? (
                    <table className="table table-responsive-md m-0">
                        <thead>
                            <tr className="text-muted">
                                <th>Manager</th>
                                <th>Actions</th>
                                <th>Activity</th>
                                <th className="text-center">Top Offs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {managers.map((manager) => (
                                <TeamMemberCard manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="bizdev" />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <Loading></Loading>
                )}
            </div>

            {/* Mobile View */}

            <div className={`${styles.mobileOnly} ${styles.bgGray} card-body p-0`} style={{ fontSize: '.9rem' }}>
                {dataLoaded ? (
                    <div className={`m-0`}>
                        {managers.map((manager) => (
                            <TeamMemberCardMobile manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="bizdev" />
                        ))}
                    </div>
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </div>
    )
}
