import { useState } from 'react'

import ContactTime from '../../../../templates/FormElements/ContactTime'
import ImType from '../../../../templates/FormElements/ImType'
import Timezones from '../../../../templates/FormElements/Timezones'
import Countries from '../../../Avatar/AvatarSurvey/Survey/templates/Countries'
import States from '../../../Avatar/AvatarSurvey/Survey/templates/States'

const ContactInfo = ({ affiliate, setIsDisabled }) => {
    const getNonState = (countryCode) => {
        let state
        if (countryCode !== 'US' && countryCode !== 'CA') {
            state = affiliate.contact.state
        } else {
            state = ''
        }
        return state
    }

    const [countryCode, setCountryCode] = useState(affiliate.contact.country_code)
    const [state, setState] = useState(countryCode === 'US' || countryCode === 'CA' ? affiliate.contact.state : '')
    const [nonState, setNonState] = useState(getNonState(affiliate.contact.state))

    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    const countryChangeHandler = (e) => {
        let country = e.target.value

        setCountryCode(country)

        if (country !== 'CA' && country !== 'US') {
            setState('')
        }
        setIsDisabled(false)
    }

    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Contact Information</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="text-right border-right">
                                E-Mail
                            </td>
                            <td colSpan="3">
                                <input name="email" className="form-control" type="text" defaultValue={affiliate.contact.email} onChange={inputChangeHandler} required></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Contact</td>
                            <td className="border-right">
                                <input name="contact" className="form-control" type="text" defaultValue={affiliate.contact.contact} onChange={inputChangeHandler} required></input>
                            </td>
                            <td className="text-right border-right">Contact Title</td>
                            <td>
                                <input name="title" className="form-control" type="text" defaultValue={affiliate.contact.title} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Street Address</td>
                            <td className="border-right">
                                <input name="address1" className="form-control" type="text" defaultValue={affiliate.contact.street_address1} onChange={inputChangeHandler} placeholder="Street Address 1" required></input>
                                <input name="address2" className="form-control mt-1" type="text" defaultValue={affiliate.contact.street_address2} onChange={inputChangeHandler} placeholder="Street Address 2"></input>
                            </td>
                            <td className="text-right border-right">City</td>
                            <td>
                                <input name="city" className="form-control" type="text" defaultValue={affiliate.contact.city} onChange={inputChangeHandler} required></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">USA State/Canadian Province</td>
                            <td className="border-right">
                                <select
                                    className="form-control"
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value)
                                        setIsDisabled(false)
                                    }}
                                    disabled={countryCode !== 'US' && countryCode !== 'CA'}
                                    name="state"
                                    required={countryCode === 'US' || countryCode === 'CA'}
                                >
                                    <option value=""></option>
                                    <States />
                                </select>
                            </td>
                            <td className="text-right border-right">
                                Non-USA State/
                                <br />
                                Non-Canadian Province
                            </td>
                            <td>
                                <input name="nonState" className="form-control" type="text" defaultValue={nonState} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Country</td>
                            <td className="border-right">
                                <select name="country" className="form-control" defaultValue={countryCode} onChange={countryChangeHandler} required>
                                    <option value=""></option>
                                    <Countries />
                                </select>
                            </td>
                            <td className="text-right border-right">Zip Code</td>
                            <td>
                                <input name="zipcode" className="form-control" type="text" defaultValue={affiliate.contact.zip_code} onChange={inputChangeHandler} required></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Mobile Phone</td>
                            <td className="border-right">
                                <input name="mobile" className="form-control" type="text" defaultValue={affiliate.contact.mobile_phone} onChange={inputChangeHandler} required></input>
                            </td>
                            <td className="text-right border-right">Phone</td>
                            <td>
                                <input name="phone" className="form-control" type="text" defaultValue={affiliate.contact.phone} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Time Zone</td>
                            <td className="border-right" colSpan="3">
                                <select name="timezone" defaultValue={affiliate.contact.timezone} className="form-control" onChange={inputChangeHandler} required>
                                    <Timezones />
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Best Time to Call</td>
                            <td className="border-right" colSpan="3">
                                <select name="bestTimeToCall" defaultValue={affiliate.contact.best_time_to_call} className="form-control" onChange={inputChangeHandler} required>
                                    <ContactTime />
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">IM Type</td>
                            <td className="border-right">
                                <select name="imtype" defaultValue={affiliate.contact.im.type_code} className="form-control" onChange={inputChangeHandler} required>
                                    <ImType />
                                </select>
                            </td>
                            <td className="text-right border-right">IM handle</td>
                            <td>
                                <input name="imhandle" className="form-control" type="text" defaultValue={affiliate.contact.im.handle} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Additional E-mails</td>
                            <td className="border-right" colSpan={3}>
                                <input name="additionalContacts" className="form-control" type="text" defaultValue={affiliate.contact.additional_emails} placeholder="Separate emails with a comma." onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ContactInfo
