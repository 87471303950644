import { useCallback } from 'react'
import { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import useCallApi from '../../../../hooks/useCallApi'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'

const CloneCampaignContent = ({ campaignId, showSystemMessage, setCloneCampaignModalIsOpen }) => {
    const [formData, setFormData] = useState({
        name: '',
        landingPages: true,
        allowedCountries: true,
        rateNames: true,
        campaignOverrides: true,
        campaignCountryOverrides: true,
        campaignQuestions: true,
        operatingSystems: true,
        countryRedirectSets: true,
        keywords: true,
        flags: true,
        affiliatePaymentDelays: true,
        fraudWatch: true,
        mobileSettings: true,
        suppressionURLs: true,
        avatarScrubOverrides: true,
        subcodeLabels: true,
    })

    const { dataLoaded, data, setData, refetch } = useCallApi(`campaign/${campaignId}`, 'GET', {}, [], showSystemMessage)

    const [submitting, setSubmitting] = useState(false)

    const handleChange = useCallback(
        (e) => {
            const { name, type, checked, value } = e.target
            setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value })
        },
        [formData]
    )

    const validate = useCallback(() => {
        if (formData.name === '') {
            showSystemMessage('error', 'Please enter a name for the cloned campaign')
            return false
        }
        return true
    }, [formData])

    const submitForm = useCallback(
        async (e) => {
            setSubmitting(true)
            const response = await callAPI(`campaign/clone/${campaignId}`, 'POST', { ...formData })
            if (response.success) {
                showSystemMessage('success', 'Campaign Cloned Successfully')
                setCloneCampaignModalIsOpen(false)
            } else {
                showSystemMessage('error', 'Error Cloning Campaign')
            }
            setSubmitting(false)
        },
        [formData]
    )

    return (
        <Card>
            <Card.Header>Campaign: {dataLoaded ? <>{data.success ? <Campaign name={data.campaign.name} oid={campaignId} /> : 'Error Finding Campaign'}</> : <Loading />}</Card.Header>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Name of cloned campaign:</span>
                    <div className="w-75">
                        <Form.Control type="text" name="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Clone the following:</span>
                    <div className="w-75">
                        <Form.Check type="checkbox" name="landingPages" id="landingPages" checked={formData.landingPages} onChange={handleChange} label={<Form.Check.Label htmlFor="landingPages">Landing Pages</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="allowedCountries" checked={formData.allowedCountries} onChange={handleChange} id="allowedCountries" label={<Form.Check.Label htmlFor="allowedCountries">Allowed Countries</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="rateNames" checked={formData.rateNames} onChange={handleChange} id="rateNames" label={<Form.Check.Label htmlFor="rateNames">Rate Names</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="campaignOverrides" checked={formData.campaignOverrides} onChange={handleChange} id="campaignOverrides" label={<Form.Check.Label htmlFor="campaignOverrides">Campaign Overrides</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="campaignCountryOverrides" checked={formData.campaignCountryOverrides} onChange={handleChange} id="campaignCountryOverrides" label={<Form.Check.Label htmlFor="campaignCountryOverrides">Campaign Country Overrides</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="campaignQuestions" checked={formData.campaignQuestions} onChange={handleChange} id="campaignQuestions" label={<Form.Check.Label htmlFor="campaignQuestions">Campaign Questions</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="operatingSystems" checked={formData.operatingSystems} onChange={handleChange} id="operatingSystems" label={<Form.Check.Label htmlFor="operatingSystems">Operating Systems</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="countryRedirectSets" checked={formData.countryRedirectSets} onChange={handleChange} id="countryRedirectSets" label={<Form.Check.Label htmlFor="countryRedirectSets">Country Redirect Sets</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="keywords" checked={formData.keywords} onChange={handleChange} id="keywords" label={<Form.Check.Label htmlFor="keywords">Keywords</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="flags" checked={formData.flags} onChange={handleChange} id="flags" label={<Form.Check.Label htmlFor="flags">Flags</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="affiliatePaymentDelays" checked={formData.affiliatePaymentDelays} onChange={handleChange} id="affiliatePaymentDelays" label={<Form.Check.Label htmlFor="affiliatePaymentDelays">Affiliate Payment Delays</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="fraudWatch" checked={formData.fraudWatch} onChange={handleChange} id="fraudWatch" label={<Form.Check.Label htmlFor="fraudWatch">Fraud Watch</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="mobileSettings" checked={formData.mobileSettings} onChange={handleChange} id="mobileSettings" label={<Form.Check.Label htmlFor="mobileSettings">Mobile Settings</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="suppressionURLs" checked={formData.suppressionURLs} onChange={handleChange} id="suppressionURLs" label={<Form.Check.Label htmlFor="suppressionURLs">Suppression URLs</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="avatarScrubOverrides" checked={formData.avatarScrubOverrides} onChange={handleChange} id="avatarScrubOverrides" label={<Form.Check.Label htmlFor="avatarScrubOverrides">Avatar Scrub Overrides</Form.Check.Label>} />
                        <Form.Check type="checkbox" name="subcodeLabels" checked={formData.subcodeLabels} onChange={handleChange} id="subcodeLabels" label={<Form.Check.Label htmlFor="subcodeLabels">Subcode Labels</Form.Check.Label>} />
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <div className="d-flex justify-content-end align-items-center w-100 mb-2">
                    {!submitting ? (
                        <Button variant="primary" size="md" onClick={submitForm}>
                            Clone
                        </Button>
                    ) : (
                        <button disabled className="btn btn-primary">
                            Cloning...
                        </button>
                    )}
                </div>
            </Card.Footer>
        </Card>
    )
}

export default CloneCampaignContent
