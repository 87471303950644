import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Creative extends Component {
    state = {}
    render() {
        /*
            Allowed props:
                status: highlight, active, cancelled
        */

        let classes = this.props.status ? `mb-element ${this.props.status}` : 'mb-element'
        let url = this.props.cr_id ? `https://ott.maxbounty.com/previewcr.cfm?id=${this.props.cr_id}` : ''

        return (
            <Link target="_blank" className={classes} to={{ pathname: url }}>
                <span className="name">{this.props.name}</span>
            </Link>
        )
    }
}

export default Creative
