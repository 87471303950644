import moment from 'moment'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import callAPI from '../../../helpers/callAPI'
import CompliancePaymentInfoCheck from '../../Dashboard/components/CompliancePaymentInfoCheck/CompliancePaymentInfoCheck'
import getDecimalNumber from '../../../helpers/getDecimalNumber'
import styles from '../../../css/backgroundClass.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FraudReportDetailed = ({ showSystemMessage }) => {
    // TODO: Need to review and add some more tooltips
    // TODO: want to make this table bordered (as there are too many columns) while still using ReportTable

    document.title = 'Fraud Report'

    const [mode, setMode] = useState('unreviewed')
    const [modalData, setModalData] = useState({ isOpen: false, affiliate: { id: 0 } })

    const { dataLoaded, data, refetch } = useCallApi(`compliance/fraudReport?mode=${mode}`, 'GET', {}, [mode])

    const [disabledWatchButtonArray, setDisabledWatchButtonArray] = useState([])
    const [watchButtonClicked, setWatchButtonClicked] = useState(false)

    const handleMarkAsReviewed = (affiliateId, sub1, offerId) => {
        const putBody = {
            sub1: sub1,
            offer_id: offerId,
        }

        callAPI(`compliance/fraudReport/${affiliateId}/review`, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    refetch()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while marking affiliate as reviewed: ${error}`)
                showSystemMessage('error', 'An error occurred while marking affiliate as reviewed')
            })
    }

    const handleAddToWatchlist = (affiliateId) => {
        setWatchButtonClicked(affiliateId)

        callAPI(`compliance/fraudReport/${affiliateId}/watchlist`, 'POST', {})
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setDisabledWatchButtonArray((oldArray) => [...oldArray, affiliateId])
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }

                setWatchButtonClicked(false)
            })
            .catch((error) => {
                console.log(`Error while adding affiliate to watchlist: ${error}`)
                showSystemMessage('error', 'An error occurred while adding affiliate to watchlist')
                setWatchButtonClicked(false)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].affiliate.id}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'affiliate.status',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].affiliate.status}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.priority} />
                },
            },
            {
                Header: <div className="text-center">Sub1</div>,
                accessor: 'sub1',
                className: 'text-center',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'IP %',
                accessor: 'ip_concentration',
                conditionalClassName: (value) => (value > 10 ? 'bgDanger25 text-right' : value > 5 ? 'bgWarning25 text-right' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{value}% of this affiliate's leads came from a device with the same IP.</Tooltip>}>
                            <div>{value ? `${value}%` : ''}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: <div className="text-center">AID</div>,
                accessor: 'AID',
                conditionalClassName: (value) => (value > 10 ? 'bgDanger25 text-center' : value > 5 ? 'bgWarning25 text-center' : 'text-center'),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{value} leads came from a device logged in as this affiliate.</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'PERM %', // need to do perm_cookie_fraud_percentage
                accessor: 'PERM.fraud_percentage',
                conditionalClassName: (value, data) => (data.PERM.fraud_percentage > 10 || data.PERM.cookie_leads > 10 ? 'bgDanger25 text-right' : data.PERM.fraud_percentage > 5 || data.PERM.cookie_leads > 10 ? 'bgWarning25 text-right' : 'text-right'),
                Cell: (tableData) => {
                    let value = tableData.data[tableData.row.index].PERM.fraud_percentage
                    let cookieLeads = tableData.data[tableData.row.index].PERM.cookie_leads
                    let permCookieFraud = tableData.data[tableData.row.index].PERM.fraud
                    let leadCount = tableData.data[tableData.row.index].PERM.lead_count

                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{value}% of this affiliate's leads came from a device that has generated a lead in the past.</Tooltip>}>
                            <div>
                                {permCookieFraud && (
                                    <span>
                                        <strong>{value}%</strong>
                                        <br />({cookieLeads} / {leadCount})
                                    </span>
                                )}
                            </div>
                        </OverlayTrigger>
                    )
                },
            },

            // PERM: {
            //     cookie_leads: item.PERM_cookie_leads,
            //     fraud: item.PERM_cookie_fraud === 'Y' ? true : false,
            //     PERM_cookie_fraud_percentage: (item.proxy_lead_count_non_zero / item.lead_count) * 100,
            // },
            {
                Header: 'PERM Shared',
                accessor: 'PERMs',
                conditionalClassName: (value) => (value > 10 ? 'bgDanger25' : value > 5 ? 'bgWarning25' : ''),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{value} PERM cookies are shared with other affiliates.</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'UA %',
                accessor: 'UA.concentration',
                conditionalClassName: (value, data) => (getDecimalNumber(data.UA.concentration) > 50 ? 'bgDanger25 text-right' : getDecimalNumber(data.UA.concentration) > 25 ? 'bgWarning25 text-right' : 'text-right'),
                Cell: (tableData) => {
                    let value = getDecimalNumber(tableData.data[tableData.row.index].UA.concentration)
                    let totalUA = tableData.data[tableData.row.index].UA.total_ua
                    let uniqueUA = tableData.data[tableData.row.index].UA.unique_ua

                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{value}% of this affiliate's leads came from a device the same user agent.</Tooltip>}>
                            <div>
                                {tableData.data[tableData.row.index].UA.fraud ? (
                                    <span>
                                        <strong>{value}%</strong>
                                        <br />({totalUA - uniqueUA} / {totalUA})
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Proxy %',
                accessor: 'proxy',
                conditionalClassName: (value) => (value > 30 ? 'bgDanger25 text-right' : value > 15 ? 'bgWarning25 text-right' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{value}% of this affiliate's leads came from IPs with a proxy score over 50.</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.id',
                className: 'text-center',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'C2L',
                accessor: 'C2L',
                conditionalClassName: (value) => (value > 30 ? 'bgDanger25' : value > 15 ? 'bgWarning' : ''),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Click 2 lead is less than 10 seconds in the last day.</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Pay',
                accessor: 'PAY.payment_held',
                conditionalClassName: (value, data) => (data.pay.payment_held ? 'bgDanger25' : ''),
                Cell: (tableData) => {
                    let paymentHeld = tableData.data[tableData.row.index].pay.payment_held
                    let holdPaymentFlag = tableData.data[tableData.row.index].pay.hold_payment_flag

                    return paymentHeld ? (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{tableData.data[tableData.row.index].pay.reason}</Tooltip>}>
                            <div className={`p-0 m-0 text-center`}>
                                <button className="btn btn-link btn-sm text-center" type="button" onClick={() => setModalData({ isOpen: true, affiliate: { id: tableData.data[tableData.row.index].affiliate.id, name: tableData.data[tableData.row.index].affiliate.name } })}>
                                    Check
                                    {holdPaymentFlag === 77 && <span className="font-weight-bold ml-1">(PAY HELD)</span>}
                                </button>
                            </div>
                        </OverlayTrigger>
                    ) : (
                        ''
                    )
                },
            },
            {
                Header: 'Fast Logins',
                accessor: 'fast_logins',
                conditionalClassName: (value) => {
                    if (value >= 10) {
                        return 'bgDanger25 text-right'
                    } else if (value >= 5) {
                        return 'bgWarning25 text-right'
                    } else {
                        return 'text-right'
                    }
                },
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Consecutive logins less than 60s apart</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Foreign Logins',
                accessor: 'foreign_logins',
                conditionalClassName: (value) => (value >= 5 ? 'bgDanger25 text-right' : value >= 3 ? 'bgWarning25 text-right' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Number of times the affiliate has logged in from countries that aren't their own</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Foreign Unique',
                accessor: 'foreign_unique',
                conditionalClassName: (value) => (value >= 5 ? 'bgDanger25 text-right' : value >= 2 ? 'bgWarning25 text-right' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Unique countries the affiliate logged in from, and isn't their own</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Foreign Watchlist',
                accessor: 'foreign_watchlist',
                conditionalClassName: (value) => (value >= 1 ? 'bgDanger25 text-center' : 'text-center'),
                Cell: ({ cell: { value } }) => {
                    return (
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Unique countries from the watch list the affiliate logged in from, and isn't thier own</Tooltip>}>
                            <div>{value}</div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                Header: 'Removal',
                accessor: 'removal',
                Cell: ({ cell: { value } }) => {
                    return <div className="text-nowrap">{moment(value).format('YYYY-MM-DD')}</div>
                },
            },
            {
                Header: '',
                accessor: 'affiliate',
                Cell: (tableData) => {
                    return (
                        <form>
                            {!tableData.data[tableData.row.index].review.date && (
                                <button className="btn btn-primary btn-sm mr-2" type="button" onClick={() => handleMarkAsReviewed(tableData.data[tableData.row.index].affiliate.id, tableData.data[tableData.row.index].sub1, tableData.data[tableData.row.index].campaign.id)}>
                                    Reviewed
                                </button>
                            )}
                            {!tableData.data[tableData.row.index].watch_date && !disabledWatchButtonArray.includes(tableData.data[tableData.row.index].affiliate.id) && (
                                <button className="btn btn-primary btn-sm" type="button" disabled={watchButtonClicked === tableData.data[tableData.row.index].affiliate.id} onClick={() => handleAddToWatchlist(tableData.data[tableData.row.index].affiliate.id)}>
                                    {watchButtonClicked === tableData.data[tableData.row.index].affiliate.id ? <FontAwesomeIcon icon="spinner" spin /> : 'Watch UA'}
                                </button>
                            )}
                        </form>
                    )
                },
            },
        ]

        return (
            <ReportTable
                data={data.report}
                columns={columns}
                getRowProps={(item) => {
                    if (mode === 'reviewed') {
                        return {
                            className: `${styles.lightGreen}`,
                        }
                    }
                }}
            />
        )
    }

    return (
        <div>
            <CompliancePaymentInfoCheck modalData={modalData} setModalData={setModalData} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Detailed Fraud Report</h1>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="filterSwitch" onClick={() => setMode(mode === 'reviewed' ? 'unreviewed' : 'reviewed')} />
                        <label className="custom-control-label" for="filterSwitch">
                            View Reviewed
                        </label>{' '}
                    </div>
                </div>
                <div className="card-body p-0">{dataLoaded ? data.report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
            </div>
        </div>
    )
}

export default FraudReportDetailed
