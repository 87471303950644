import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo/Logo'
import './Toolbar.css'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { colors } from '../../../theme/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import QuickSearch from './QuickSearch/QuickSearch'

import AdvertiserDropdown from './AdvertiserDropdown/AdvertiserDropdown'
import AffiliateDropdown from './AffiliateDropdown/AffiliateDropdown'
import ProfileDropdown from './ProfileDropdown/ProfileDropdown'
import ReportDropdown from './ReportDropdown/ReportDropdown'
import CampaignDropdown from './CampaignDropdown/CampaignDropdown'
import NotificationDropdown from './NotificationDropdown/NotificationDropdown'
import AvatarDropdown from './AvatarDropdown/AvatarDropdown'
import ToolsDropdown from './ToolsDropdown/ToolsDropdown'
import AdminDropdown from './AdminDropdown/AdminDropdown'

import { useAuth, useProtectedContent } from '../../../context/AuthContext'
import MarketingDropdown from './MarketingDropdown/MarketingDropdown'
import ComplianceDropdown from './ComplianceDropdown/ComplianceDropdown'
import SystemDropdown from './SystemDropdown/SystemDropdown'

const Toolbar = ({ mgrName, mgrId, logout, logoutFromAllDevices, isOpen, setIsOpen }) => {
    const auth = useAuth()
    const showAdminNav = useProtectedContent(auth.permissions, 'nav_admin')
    const showSystemNav = useProtectedContent(auth.permissions, 'nav_system')
    const showAvatarNav = useProtectedContent(auth.permissions, 'nav_avatar')
    const showMarketingNav = useProtectedContent(auth.permissions, 'nav_marketing')
    const showComplianceNav = useProtectedContent(auth.permissions, 'nav_compliance')
    const showAdvertiserNav = useProtectedContent(auth.permissions, 'nav_advertisers')
    const showReportsNav = useProtectedContent(auth.permissions, 'nav_reports')

    const breakpoint = useBreakpoint()

    const toggleMenu = () => {
        if (isOpen.left === '-200px') {
            setIsOpen({ left: '0px' })
        } else {
            setIsOpen({ left: '-200px' })
        }
    }

    if (breakpoint === 'xl') {
        return (
            <div>
                <nav className="Toolbar navbar navbar-expand-lg navbar-dark fixed-top">
                    <Link className="navbar-brand mb-icon d-flex align-items-center justify-content-center" to="/dashboard">
                        {/* MaxBounty Logo */}
                        <Logo />
                    </Link>
                    <div className="w-100 d-flex flex-justify justify-content-between pr-3">
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav w-100 justify-content-around">
                                <li className="nav-item">
                                    <AffiliateDropdown />
                                </li>
                                {showAdvertiserNav && (
                                    <li className="nav-item">
                                        <AdvertiserDropdown />
                                    </li>
                                )}
                                <li className="nav-item">
                                    <CampaignDropdown />
                                </li>
                                {showReportsNav && (
                                    <li className="nav-item">
                                        <ReportDropdown />
                                    </li>
                                )}
                                {showAvatarNav && (
                                    <li className="nav-item">
                                        <AvatarDropdown />
                                    </li>
                                )}
                                <li className="nav-item">
                                    <ToolsDropdown />
                                </li>
                                {showComplianceNav && (
                                    <li className="nav-item">
                                        <ComplianceDropdown />
                                    </li>
                                )}
                                {showMarketingNav && (
                                    <li className="nav-item">
                                        <MarketingDropdown />
                                    </li>
                                )}
                                {showAdminNav && (
                                    <li className="nav-item">
                                        <AdminDropdown />
                                    </li>
                                )}
                                {showSystemNav && (
                                    <li className="nav-item">
                                        <SystemDropdown />
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div>
                            <ul className="navbar-nav navbar-right">
                                <li className="nav-item">
                                    <QuickSearch />
                                </li>
                                {/* <li className="nav-item">
                                    <NotificationDropdown />
                                </li> */}
                                <li className="nav-item">
                                    <ProfileDropdown mgrName={mgrName} logout={logout} logoutFromAllDevices={logoutFromAllDevices} mgrId={mgrId} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="toolbar-stats">Other stuff</div>
            </div>
        )
    } else {
        return (
            <>
                <div className="Toolbar navbar navbar-expand-lg navbar-dark fixed-top justify-content-start d-flex flex-row align-items-center">
                    <div className="nav-item" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} className="text-light nav-icon" />
                    </div>
                    <Link className="navbar-brand mb-icon mr-3 d-flex align-items-center justify-content-center" to="/dashboard">
                        {/* MaxBounty Logo */}
                        <Logo />
                    </Link>
                    <ul className="navbar-nav" style={{ flexDirection: 'row' }}>
                        <li className="nav-item">
                            <QuickSearch />
                        </li>
                        <li className="nav-item">
                            <NotificationDropdown />
                        </li>
                    </ul>
                </div>

                <div className="Toolbar navbar navbar-expand-lg navbar-dark justify-content-start d-flex flex-column side-bar" style={{ position: 'fixed', ...isOpen }}>
                    <ul className="navbar-nav w-100 justify-content-around d-flex flex-column align-items-start">
                        <li className="nav-item">
                            <AffiliateDropdown />
                        </li>
                        <li className="nav-item">
                            <AdvertiserDropdown />
                        </li>
                        <li className="nav-item">
                            <CampaignDropdown />
                        </li>
                        <li className="nav-item">
                            <ReportDropdown />
                        </li>
                        {showAvatarNav && (
                            <li className="nav-item">
                                <AvatarDropdown />
                            </li>
                        )}
                        <li className="nav-item">
                            <ToolsDropdown />
                        </li>
                        {showAdminNav && (
                            <li className="nav-item">
                                <AdminDropdown />
                            </li>
                        )}
                        {showSystemNav && (
                                    <li className="nav-item">
                                        <SystemDropdown />
                                    </li>
                                )}
                        <hr className="border-light w-100" />
                        <li className="nav-item">
                            <ProfileDropdown mgrName={mgrName} logout={logout} logoutFromAllDevices={logoutFromAllDevices} mgrId={mgrId} />
                        </li>
                    </ul>
                </div>
                {/* <div>
                            <ul className="navbar-nav navbar-right">
                                <li className="nav-item">
                                    <QuickSearch />
                                </li>
                                <li className="nav-item">
                                    <NotificationDropdown />
                                </li>
                                <li className="nav-item">
                                    <ProfileDropdown mgrName={mgrName} logout={logout} />
                                </li>
                            </ul>
                        </div> */}
            </>
        )
    }
}

export default Toolbar
