import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import Affiliate from '../../templates/Affiliate/Affiliate'

import Campaign from '../../templates/Campaign/Campaign'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../CampaignSearch/CampaignSearch'

const CallbacksModal = ({ isOpen, setIsOpen, affiliate, campaign, showSystemMessage, refetch }) => {
    const [formData, setFormData] = useState({
        offer: { id: 0, name: 'test' },
        type: 'S',
        code: '',
    })

    const createCallback = async (e) => {
        e.preventDefault()

        const result = await callAPI(affiliate ? `affiliate/${affiliate.id}/callbacks` : `campaign/${campaign.id}/callbacks`, 'POST', formData)
        if (result.success) {
            showSystemMessage('success', 'Callback created')
            setIsOpen(false)
            refetch()
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
        } else {
            showSystemMessage('error', 'Error creating callback')
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            {isOpen && (
                <Form onSubmit={createCallback}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">
                            Add callback for {affiliate ? 'Affiliate' : 'Campaign'}: {affiliate ? <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.name} status={affiliate.status.name} /> : <Campaign oid={campaign.id} name={campaign.name} status={campaign.status.value} />}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center">
                            <span className="w-50">Affiliate:</span>
                            <span className="w-100">{affiliate ? affiliate.id : <AffiliateSearch setFormData={setFormData} />}</span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <span className="w-50">Campaign:</span>
                            <span className="w-100">{affiliate ? <CampaignSearch setFormData={setFormData} /> : campaign.id}</span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <span className="w-50">Type:</span>
                            <Form.Control as="select" className="w-100" value={formData.type} onChange={(event) => setFormData((prev) => ({ ...prev, type: event.target.value }))}>
                                <option value="S">Server (delayed)</option>
                                <option value="L">URL (pixel)</option>
                                <option value="I">HTML/Javascript (iframe)</option>
                            </Form.Control>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="w-50">Code:</span>
                            <Form.Control as="textarea" rows={3} placeholder="Code" className="mt-2" onChange={(event) => setFormData((prev) => ({ ...prev, code: event.target.value }))} value={formData.code} required />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Add Callback
                        </Button>
                    </Modal.Footer>
                </Form>
            )}
        </Modal>
    )
}

export default CallbacksModal
