import React, { Component } from 'react'

class MultipleChoiceMulti extends Component {
    state = {}

    render() {
        let { question, order, options = [], questionId } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Multiple Choice Multi-Select</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="alert alert-info">You may select more than one.</div>
                <div className="mt-3">
                    {options.map((option, index) => {
                        return (
                            <div key={index} className="m-3 d-flex align-items-center">
                                <input type="text" className="btn btn-light border btn-lg d-block w-100 btn-text-input" value={option.value} onChange={this.props.handleChangeMultipleChoice(questionId, option.optionId)} />{' '}
                                {options.length > 1 && (
                                    <button onClick={() => this.props.handleRemoveMultipleChoice(questionId, option.optionId)} className="ml-2 btn btn-danger badge">
                                        X
                                    </button>
                                )}
                            </div>
                        )
                    })}
                    <div className="m-3 text-center">
                        <button className="btn btn-warning" onClick={() => this.props.handleAddMultipleChoice(questionId)}>
                            + Add choice
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default MultipleChoiceMulti
