import { Card, Form, Table } from 'react-bootstrap'

import CampaignTrafficType from '../../../../../components/CampaignTrafficType/CampaignTrafficType'

const TrafficSettings = ({ data, setData, setIsDisabled }) => {
    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Traffic Types</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Contextual" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.contextual_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, contextual_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Display" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.site_traffic_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, site_traffic_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Email" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.solo_emails_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, solo_emails_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Search" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.search_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, search_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Incentive" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.incentive_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, incentive_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Social" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.social_media_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, social_media_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Native Ads" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.native_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, native_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Mobile Ads" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.mobile_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, mobile_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">
                                <CampaignTrafficType allowed={true} name="Brand Bidding" />
                            </span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.brand_bid_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, brand_bid_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                    </tr>
                    {/* <td className='w-25'><span className='d-flex justify-content-end align-items-center'>Desktop Allowed</span></td>
                            <td><Form.Check size="sm" type="checkbox" checked={data.desktop_flag === "Y" ? true : false} onChange={(e) => setData({ ...data, desktop_flag: e.target.checked ? "Y" : "N" })} /></td> */}
                </tbody>
            </Table>
        </Card>
    )
}

export default TrafficSettings
