import React from 'react'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import moment from 'moment'

const SearchResults = ({ searchResults }) => {
    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (tableData) => {

                    let experience = tableData.data[tableData.row.index].experience_level.value
                    let countryTier = tableData.data[tableData.row.index].tier

                    return (
                        <>
                            <Affiliate aid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} priority={tableData.data[tableData.row.index].priority.value} status={tableData.data[tableData.row.index].status.value} />{(experience === 3 || experience === 2) && countryTier === 1 ? <span className='ml-2'>⭐</span> : ''}
                        </>
                    )
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Experience',
                accessor: 'experience_level.name',                
                Cell: (tableData) => {
                    let customClass = ((tableData.data[tableData.row.index].experience_level.value === 2 || tableData.data[tableData.row.index].experience_level.value === 3) && tableData.data[tableData.row.index].tier === 1) ? 'text-success font-weight-bold' : ''

                    return <span className={customClass}>{tableData.data[tableData.row.index].experience_level.name}</span>
                },
            },
            {
                Header: 'Country',
                accessor: 'country_name',
                Cell: (tableData) => {

                    let customClass = tableData.data[tableData.row.index].tier === 1 ? 'text-success font-weight-bold' : ''

                    return <span className={customClass}>{tableData.data[tableData.row.index].country.country_name}</span>
                },
            },
            {
                Header: 'Status',
                accessor: 'status.name',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].status.name}</>
                },
            },            
            {
                Header: 'Priority',
                accessor: 'priority.name',
                conditionalClassName: (value, data) => (data.priority.value < data.prev_priority.value ? 'bgSuccess25' : data.priority.value > data.prev_priority.value ? 'bgDanger25' : ''),
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].priority.name}</>
                },
            },            
            {
                Header: 'Prev Priority',
                accessor: 'prev_priority.name',
                Cell: (tableData) => {
                    return <div className="text-secondary">{tableData.data[tableData.row.index].priority.value !== 3 ? tableData.data[tableData.row.index].prev_priority.name : ''}</div>
                },
            },
            {
                Header: 'Max Priority',
                accessor: 'max_priority.name',
                Cell: (tableData) => {
                    return <div className="text-secondary">{tableData.data[tableData.row.index].priority.value !== 3 ? tableData.data[tableData.row.index].max_priority.name : ''}</div>
                },
            },
            {
                Header: 'Affiliate Manager',
                accessor: 'manager',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Last Followup',
                accessor: 'last_followup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: () => <div className="text-right">This Month</div>,
                accessor: 'this_month',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <>
                            <Dollars value={tableData.data[tableData.row.index].earnings.this_month} />
                        </>
                    )
                },
            },
            {
                Header: () => <div className="text-right">Last Month</div>,
                accessor: 'previous_month',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <>
                            <Dollars value={tableData.data[tableData.row.index].earnings.previous_month} />
                        </>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={searchResults} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div className="card">
            <div className="card-body p-0">{getReportOutput()}</div>
        </div>
    )
}

export default SearchResults
