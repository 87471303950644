import React, { useState, useEffect } from 'react';

export default function TextareaImage({name, required, maxLength, preview, setPreview, setFile, setLength}){
    

    const pasteImage = (e) => {
        let file = e.clipboardData.files[0]
        if (file){
            setPreview(URL.createObjectURL(file))
            setFile(file)
        }
    }    

    const uploadImage = (e) => {
        e.preventDefault();
        let file = e.target.files[0]
        if (file){
            setPreview(URL.createObjectURL(file))
            setFile(file)
        }
    }

    return (
        <>
            {
                preview &&
                    <div className="position-relative">
                        <div className="position-absolute border rounded p-2 bg-white shadow" style={{bottom: 15, right: 0}}>
                            <img style={{ maxHeight: '50px' }} src={preview} alt="Uploaded File." />
                            <button className="btn btn-link" onClick={()=>{setPreview(false); setFile(false)}}>Remove</button>
                        </div>
                    </div>
            }
            <textarea name={name} required={required} maxLength={maxLength} onPaste={(e)=>pasteImage(e)} rows={4} cols={40} className="form-control" placeholder="Notes (supports image pasting)." onChange={(e)=>{setLength(e.target.value.length)}} />
            <input type="file" onChange={(e)=>uploadImage(e)} className="border rounded mt-2" accept="image/*" />
        </>        
    )
}