import { Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import MerModal from './MerModal'
import { useAuth, useProtectedContent, useProtectedPage } from '../../../context/AuthContext'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Advertiser from '../../../templates/Advertiser/Advertiser'

// TODO: Need to work this one out for url as there isnt' a Refresh button

const AdvertiserList = ({ showSystemMessage }) => {
    document.title = 'List Advertisers'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertisers_list')
    const showCreateAdvertiser = useProtectedContent(auth.permissions, 'advertiser_create')

    const [isOpen, setIsOpen] = useState(false)

    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    const [withCampaigns, setWithCampaigns] = useState(urlParams.withCampaigns && urlParams.withCampaigns == 'true' ? 'true' : 'false')

    const { adminId, setAdminId, status, setStatus, buildUrl } = useQueryParams()

    const { data, dataLoaded, refetch } = useCallApi(buildUrl(`advertisers?withCampaigns=${withCampaigns}`), 'GET', {}, [adminId, status, withCampaigns], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status.value} />
                },
            },
            {
                Header: 'Status',
                accessor: 'status.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser Manager',
                accessor: 'mer_mgr_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Bizdev Manager',
                accessor: 'bizdev_mgr_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div className="text-center">Active Campaigns</div>,
                accessor: 'active_campaign_count',
                Cell: ({ cell: { value } }) => {
                    return <div className="text-center">{value}</div>
                },
            },
            {
                Header: ' ',
                Cell: (tableData) => {
                    return (
                        <>
                            <Link to={`/advertiser/${tableData.data[tableData.row.index].id}/campaigns`}>View Campaigns</Link>
                        </>
                    )
                },
            },
        ]

        return <ReportTable data={data.advertisers} columns={columns} />
    }

    return (
        <div>
            {showCreateAdvertiser && <MerModal showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} mgrId={auth.user.id} refetch={refetch} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertisers</h1>
            </div>
            <Card className="mb-3">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div style={{ width: '300px' }}>Advertisers for:</div>
                        <div className="ml-2">
                            <AdminPicker className="w-100" adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />
                        </div>
                        <select name="status" className="form-control form-control-sm ml-2" type="text" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="">Any Status</option>
                            <option value="N">New Lead</option>
                            <option value="D">Discussion</option>
                            <option value="G">Negotiation</option>
                            <option value="A">Active</option>
                            <option value="C">Cancelled</option>
                        </select>
                        <select name="status" className="form-control form-control-sm ml-2" type="text" defaultValue={withCampaigns} onChange={(e) => setWithCampaigns(e.target.value)}>
                            <option value="false">No campaign filter</option>
                            <option value="true">With active campaigns</option>
                        </select>
                    </div>
                    {showCreateAdvertiser && (
                        <Button variant="warning" size="sm" onClick={() => setIsOpen(true)}>
                            Create Advertiser
                        </Button>
                    )}
                </Card.Header>
                <Card.Body className="overflow-auto p-0">{dataLoaded ? data.advertisers.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</Card.Body>
            </Card>
        </div>
    )
}

export default AdvertiserList
