import Number from '../../../templates/Number/Number'

const ByTimeOfDay = ({ timeOfDay, totalLeads }) => {
    const getHours = (timeOfDayString) => {
        if (timeOfDayString === '1-Overnight') {
            return '10 PM - 5 AM'
        } else if (timeOfDayString === '2-Morning') {
            return '5 AM - 12 PM'
        } else if (timeOfDayString === '3-Afternoon') {
            return '12 PM - 5 PM'
        } else if (timeOfDayString === '4-Evening') {
            return '5 PM - 10 PM'
        } else {
            return 'Unknown'
        }
    }

    return (
        <div>
            <div className="card mb-2">
                <div className="align-items-center p-2 card-header">
                    <div className="font-weight-bold float-left">Time of Day</div>
                </div>
                <div className="card-body p-2">
                    <table className="table table-card table-borderless table-striped table-hover">
                        <thead className="border-bottom">
                            <tr>
                                <th scope="col">Period</th>
                                <th scope="col" className="text-right">
                                    Lead %
                                </th>
                                <th scope="col" className="text-right">
                                    Lead Count
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {timeOfDay.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>No stats available</td>
                                </tr>
                            ) : (
                                timeOfDay.map((item) => (
                                    <tr key={item.time_of_day}>
                                        <td>
                                            {item.time_of_day} ({getHours(item.time_of_day)})
                                        </td>
                                        <td align="right">{totalLeads > 0 ? Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((item.time_of_day_count / totalLeads) * 100) : 0}%</td>
                                        <td align="right"><Number value={item.time_of_day_count} /></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ByTimeOfDay
