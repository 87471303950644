import callAPI from '../../../../../../helpers/callAPI'

const AppNoAnswerModal = ({ handleClose, affiliateId, showSystemMessage }) => {
    const submitForm = (event) => {
        event.preventDefault()

        callAPI(`affiliate/${affiliateId}/noAnswer`, 'PUT', {})
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Follow-up Saved and E-Mail sent')
                    handleClose(null, true)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'An error occurred while processing the application')
            })
    }

    return (
        <div className="card">
            <div className="card-header">No Answer</div>
            <div className="card-body">
                <form onSubmit={submitForm} method="POST">
                    <div>This will send the affiliate an email and set a followup.</div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary mt-2">
                            Confirm no answer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AppNoAnswerModal
