import { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import SetModal from './SetModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ListCountryRedirects = ({ showSystemMessage }) => {

    document.title = 'Country Redirect Sets'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_country_redirect_sets')

    const [isOpen, setIsOpen] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const { data, setData, dataLoaded, refetch } = useCallApi(`campaigns/listCountryRedirects`, 'GET', {}, [], showSystemMessage)

    const deleteSet = async (setName, offerId) => {
        const response = await callAPI(`campaigns/${offerId}/set/${setName}`, 'DELETE')
        if (response.success) {
            refetch()
            showSystemMessage('success', 'Set deleted successfully')
            setDeleteIndex(-1)
        } else {
            showSystemMessage('error', 'Error deleting set')
            setDeleteIndex(-1)
        }
    }

    const calcClassName = (status) => {
        switch (status) {
            case 'Hidden':
                return 'bgWarning25'
            case 'By Request':
                return 'bgWarning25'
            case 'Active':
                return ''
            case 'Pending':
                return 'bgDanger25'
            case 'Testing':
                return 'bgDanger25'
            case 'Submitted':
                return 'bgDanger25'
            case 'Cancelled':
                return 'bgDanger25'
            default:
                return 'bgDanger25'
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Campaign Country Redirect Sets</h1>
            </div>
            <Card className="mb-3">
                <SetModal refetch={refetch} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} data={data} dataLoaded={dataLoaded} />
                <div className="d-flex justify-content-end align-items-center p-3">
                    <Button className="ml-2" onClick={() => setIsOpen(true)} variant="primary" size="warning">
                        Add Campaign to Redirect Set
                    </Button>
                </div>
                <Table striped hover className="m-0">
                    <thead>
                        <tr>
                            <th>Set Name</th>
                            <th>Campaign ID</th>
                            <th>Campaign Name</th>
                            <th>Campaign Status</th>
                            <th>Country</th>
                            <th />
                        </tr>
                    </thead>
                    {dataLoaded ? (
                        <>
                            {data.success && data.countryRedirectsList.length > 0 ? (
                                <tbody>
                                    {data.countryRedirectsList.map((item, index) => {
                                        return (
                                            <tr key={index} className={calcClassName(item.status)}>
                                                <td>{item.set_name}</td>
                                                <td>{item.offer_id}</td>
                                                <td>
                                                    <Campaign oid={item.offer_id} name={item.name} />
                                                </td>
                                                <td>{item.status}</td>
                                                <td>
                                                    {item.country_code} - {item.country_name}
                                                </td>
                                                <td>
                                                    {deleteIndex === index ? (
                                                        <Button variant="danger" size="sm" onClick={() => deleteSet(item.set_name, item.offer_id)}>
                                                            Confirm Delete
                                                        </Button>
                                                    ) : (
                                                        <Button variant="warning" size="sm" onClick={() => setDeleteIndex(index)}>
                                                            Delete
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="5">No data found</td>
                                    </tr>
                                </tbody>
                            )}
                        </>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="5">
                                    <Loading />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </Card>
        </div>
    )
}

export default ListCountryRedirects
