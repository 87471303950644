import React, { useState } from "react"
import { Card } from "react-bootstrap"
import ReactTooltip from "react-tooltip"

import useCallApi from "../../../hooks/useCallApi"
import StatusCircle from "../../../templates/StatusCircle/StatusCircle"
import Loading from "../../../templates/UI/Loading/Loading"
import ReportTable from "../../../templates/ReportTable/ReportTable"
import OutOfOfficeModal from "./OutOfOfficeModal/OutOfOfficeModal"
import { useAuth, useProtectedPage } from "../../../context/AuthContext"

const ManageOutOfOffice = ({ showSystemMessage }) => {

    document.title = 'Manage Out of Office'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'manage_out_of_office')

    const [admin, setAdmin] = useState({})
    const [isOpen, setIsOpen] = useState(false)    

    const { data, dataLoaded, refetch } = useCallApi(`admin/listadmins?mode=A`, 'GET', {}, [], showSystemMessage)    

    // admin_id, status, admin_type, full_name, out_of_office, manager

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (tableData) => {
                    return (
                        <button
                            className="btn btn-sm btn-link"
                            onClick={() => {
                                setAdmin(tableData.data[tableData.row.index])
                                setIsOpen(true)
                            }}
                        >
                            {tableData.data[tableData.row.index].full_name}
                        </button>
                    )
                },
            },
            {
                Header: 'ID',
                accessor: 'admin_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Email',
                accessor: 'e_mail',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? value : <span className="text-danger">ROLE NOT SET</span>}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'A' ? 'Active' : 'Cancelled'}</>
                },
            },
            {
                Header: 'Out of office',
                accessor: 'out_of_office',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'Y' ? <div className="ml-2" data-tip="Out of office"><StatusCircle type="danger" /></div> : ''}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.adminsList}
                    columns={columns}
                    getRowProps={(row) => {
                        if (row.original.status === 'C') {
                            return {
                                className: `bgDanger25`,
                            }
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    const handleClose = (isEdited = false) => {
        setIsOpen(false)

        if (isEdited) {
            refetch()
        }
    }

    return (
        <div className="row">
            <div className="col-lg-6">
                <Card>
                    {isOpen && <OutOfOfficeModal isOpen={isOpen} handleClose={handleClose} admin={admin} showSystemMessage={showSystemMessage} />}
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div>Manage Out of Office</div>
                    </Card.Header>
                    <div className="card-body m-0 p-0">
                        <ReactTooltip />
                        {dataLoaded ? data.adminsList.length === 0 ? <div className="p-3">No admins found.</div> : getReportOutput() : <Loading></Loading>}
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default ManageOutOfOffice
