import Linkify from 'linkify-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'reactstrap'

import Flags from '../../../../../components/Flags/Flags'
import Followups from '../../../../../components/Followups/Followups'
import callAPI from '../../../../../helpers/callAPI'
import Affiliate from '../../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../../templates/UI/Loading/Loading'
import AppRejectModal from '../Components/AppRejectModal/AppRejectModal'
import AppApproveModal from '../VerifyApplication/AppApproveModal/AppApproveModal'
import ExtraInfo from '../VerifyApplication/ExtraInfo/ExtraInfo'
import AppNoAnswerModal from './AppNoAnswerModal/AppNoAnswerModal'

const ReviewApplication = ({ affiliateId, showSystemMessage, handleReviewClose, manager, setUpdateList }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliateInfo, setAffiliateInfo] = useState({})
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [showVerifyModal, setShowVerifyModal] = useState(false)
    const [showNoAnswerModal, setShowNoAnswerModal] = useState(false)
    const [showNotesModal, setShowNotesModal] = useState(false)
    const [flags, setFlags] = useState([])

    useEffect(async () => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setDataLoaded(false)

        let affInfoResult = await callAPI(`affiliate/${affiliateId}/reviewApplication`, 'GET')

        if (affInfoResult.success === false) {
            showSystemMessage('error', 'There was an error while fetching the data. Please try again')
        }

        if (affInfoResult['success'] === true) {
            setAffiliateInfo(affInfoResult.affiliate_info)
            setFlags(affInfoResult.flags)
            setDataLoaded(true)
        }
    }

    const toggleModal = () => {
        setShowRejectModal(false)
        setShowVerifyModal(false)
        setShowNoAnswerModal(false)
        setShowNotesModal(false)
    }

    const handleClose = (event, isEdited = false) => {
        if (event) event.preventDefault()

        fetchData()

        setShowRejectModal(false)
        setShowVerifyModal(false)
        setShowNoAnswerModal(false)
        if (isEdited) {
            handleReviewClose()
        }
    }

    const handleNotesClose = (isEdited = false) => {
        setShowNotesModal(false)

        if (isEdited) {
            fetchData()
        }
    }

    const handleNotesInfo = (extraInfo, additionalContacts) => {
        setAffiliateInfo({ ...affiliateInfo, extra_info: extraInfo, additional_contacts: additionalContacts })
    }

    return (
        <div className="card">
            <Modal isOpen={showRejectModal} size="lg" toggle={toggleModal}>
                <AppRejectModal affiliateName={affiliateInfo.name} affiliateId={affiliateId} handleClose={handleClose} showSystemMessage={showSystemMessage} complianceReviewed={false} />
            </Modal>
            <Modal isOpen={showVerifyModal} size="lg" toggle={toggleModal}>
                <AppApproveModal affiliateName={affiliateInfo.name} affiliateId={affiliateId} handleClose={handleClose} showSystemMessage={showSystemMessage} setUpdateList={setUpdateList} />
            </Modal>
            <Modal isOpen={showNoAnswerModal} size="sm" toggle={toggleModal}>
                <AppNoAnswerModal affiliateName={affiliateInfo.name} affiliateId={affiliateId} handleClose={handleClose} showSystemMessage={showSystemMessage} />
            </Modal>
            <Modal isOpen={showNotesModal} size="lg" toggle={toggleModal}>
                <ExtraInfo affiliateName={affiliateInfo.name} affiliateId={affiliateId} affExtraInfo={affiliateInfo.extra_info} additionalContacts={affiliateInfo.additional_contacts} handleClose={handleNotesClose} showSystemMessage={showSystemMessage} updateExtraInfo={handleNotesInfo} />
            </Modal>
            <div className="card-header">
                <div className="font-weight-bold float-left">Review Application</div>
                <div className="float-right">
                    <Link target="_blank" to={`/affiliate/${affiliateId}`}>
                        View Affiliate
                    </Link>
                </div>
            </div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        {affiliateInfo.experience_level >= 2 && affiliateInfo.country_tier === 1 && <div className="alert alert-success font-weight-bold m-3">⚠️ Applicant is experienced from Tier 1 country. Approve after 3rd call without contact. ⚠️</div>}

                        {flags.length > 0 && <div className="alert alert-warning font-weight-bold m-3">Flags: <br />{flags.map(flag => <div key={flag.flag_id}> {flag.flag_name} ({flag.flag_id}) </div>)}</div>}

                        {affiliateInfo.marketing.campaign !== '' && <div className="alert alert-success font-weight-bold m-3">📣 Marketing Application: {affiliateInfo.marketing.campaign} / {affiliateInfo.marketing.platform}</div>}

                        {affiliateInfo.extra_info && <div className="alert alert-danger font-weight-bold m-3">Compliance Notes: {affiliateInfo.extra_info}</div>}
                        <table className="table table-striped table-borderless m-0">
                            <tbody style={{ lineHeight: '25px' }}>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Company</span>
                                        <span>{affiliateInfo.name}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Payee</span>
                                        <span>{affiliateInfo.payment_method === 'N' ? 'Not Applicable' : affiliateInfo.payment_method}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">ID #</span>
                                        <span>{affiliateInfo.id}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Name</span>
                                        <span>{affiliateInfo.contact}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Country</span>
                                        <span>{affiliateInfo.country}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Current Time</span>
                                        <span>{affiliateInfo.timezone}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Mobile</span>
                                        <span>{affiliateInfo.mobile}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">phone</span>
                                        <span>{affiliateInfo.phone}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Skype</span>
                                        <span>
                                            <a href={`skype:${affiliateInfo.skype}?chat`}>{affiliateInfo.skype}</a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Email</span>
                                        <span>
                                            <a href={`mailto:${affiliateInfo.email}`}>{affiliateInfo.email}</a>
                                        </span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Heard from</span>
                                        <span>
                                            {affiliateInfo.heard_from.source} {affiliateInfo.heard_from.source === 'other' && '-'} {affiliateInfo.heard_from.details}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Referrer</span>
                                        <span>
                                            {affiliateInfo.referrer_id > 0 ? <Affiliate aid={affiliateInfo.referrer_id} name={affiliateInfo.referrer_name} status={affiliateInfo.referrer_status} priority={affiliateInfo.referrer_priority} /> : 'None'}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Referring URL</span>
                                        <span>
                                            <Linkify>{affiliateInfo.referring_url}</Linkify>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Campaign Interest</span>
                                        <span>{affiliateInfo.campaign_interest}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Website</span>
                                        <span>
                                            <Linkify>{affiliateInfo.website}</Linkify>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Experience Level</span>
                                        <span>{affiliateInfo.experience}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Promotional Method</span>
                                        <span>{affiliateInfo.promotion}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Experience Description</span>
                                        <span>{affiliateInfo.experience_desc}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="my-2" />
                        <div className="text-center">
                            <button className="btn btn-warning" onClick={() => setShowNotesModal(true)}>
                                Notes
                            </button>
                            <button className="btn btn-warning ml-5" onClick={() => setShowNoAnswerModal(true)}>
                                No Answer
                            </button>
                            <button className="btn btn-danger ml-5" onClick={() => setShowRejectModal(true)}>
                                Reject
                            </button>
                            <button className="btn btn-primary ml-5" onClick={() => setShowVerifyModal(true)} disabled={affiliateInfo.status === 'A' || affiliateInfo.status === 'C'}>
                                Approve
                            </button>
                        </div>
                        <hr className="my-2 mb-0" />
                    </div>
                ) : (
                    <Loading />
                )}
                {dataLoaded ? <div className="p-1"><Followups showSystemMessage={showSystemMessage} id={affiliateId} managerName={affiliateInfo.manager_name} type="affiliate" /></div> : <Loading></Loading>}
                {/* {dataLoaded ? <Notes showSystemMessage={showSystemMessage} id={affiliateId} managerName={affiliateInfo.manager_name} type="affiliate" affiliateId={affiliateId} notes={affiliateInfo.notes} /> : <Loading></Loading>} */}
                {dataLoaded ? <div className="p-1"><Flags showSystemMessage={showSystemMessage} affiliateId={affiliateId} /></div> : <Loading></Loading>}
            </div>
        </div>
    )
}

export default ReviewApplication
