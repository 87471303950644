import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { result } from 'lodash'
import { useState } from 'react'

import AffiliateSearch from '../../../components/AffiliateSearch/AffiliateSearch'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AddLeads = ({ campaignId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'add_leads')

    const [affiliate, setAffiliate] = useState({ id: -1, name: '' })
    const [rate, setRate] = useState()
    const [offerType, setOfferType] = useState('')
    const [results, setResults] = useState([])
    const [submitting, setSubmitting] = useState('ready')

    const { dataLoaded, data, setData, refetch } = useCallApi(`campaign/${campaignId}/rates`, 'GET', {}, [campaignId])

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    if (dataLoaded){
        document.title = `${data.campaign.name}: Add Leads`
    } else {
        document.title = 'Add Leads'
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // e.target.lead_count.setCustomValidity('Any error I want')
        setSubmitting('submitting')

        if (affiliate.id <= 0) {
            showSystemMessage('error', 'Affiliate ID is missing')
            setSubmitting('ready')
            return
        }

        validate(e)
        e.target.reportValidity()

        let postBody = {
            affiliate_id: affiliate.id,
            rate_name: rate,
            offer_type: offerType,
            leads: e.target.lead_count.value,
            confirmLeads: e.target.confirm_lead_count.value,
            sale_amount: offerType === 'P' || offerType === 'M' ? e.target.sale_amount.value : 0,
        }

        callAPI(`campaign/${campaignId}/addLeads`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setResults(result.results)
                    setSubmitting('success')                    
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting('ready')
                }
            })
            .catch((error) => {
                console.log(`Error while adding leads: ${error}`)
                showSystemMessage('error', 'An error occurred while adding leads')
                setSubmitting('ready')
            })
    }

    const validate = (e) => {
        let validated = true

        if (e.target.lead_count.value !== e.target.confirm_lead_count.value) {
            e.target.confirm_lead_count.setCustomValidity('Lead counts must be same')
            validated = false
        }
    }

    const handleChange = (e) => {
        setOfferType(e.target.selectedOptions[0].getAttribute('data_offer_type'))
        setRate(e.target.value)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Add Leads</h1>
                <div className="ml-5">
                    <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="d-flex p-3 align-items-center">
                            <h1 className="mb-h1-dark mr-2">{dataLoaded ? <Campaign oid={campaignId} name={data.campaign.name} status={data.campaign.status.value} /> : spinner}</h1>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <table className="table table-card m-0 table-borderless">
                                    {dataLoaded ? (
                                        <tbody>
                                            <tr>
                                                <td width="30%">Affiliate</td>
                                                <td className="w-50">
                                                    <AffiliateSearch setAffiliate={setAffiliate} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Rate Name</td>
                                                <td className="w-50">
                                                    <select name="rate_name" className="form-control" onChange={handleChange} required>
                                                        <option value=""></option>
                                                        {data.rates.map((rate) => (
                                                            <option data_offer_type={rate.offer_type} value={rate.rate_name} key={rate.rate_name}>{`${rate.rate_name} - (${rate.affiliate_rate} / ${rate.network_rate})`}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Leads to Add</td>
                                                <td>
                                                    <input className="form-control" name="lead_count" placeholder="0" required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Confirm Leads to Add</td>
                                                <td>
                                                    <input className="form-control" name="confirm_lead_count" placeholder="0" required />
                                                </td>
                                            </tr>
                                            {(offerType === 'P' || offerType === 'M') && (
                                                <tr>
                                                    <td>Sale Amount</td>
                                                    <td>
                                                        <input className="form-control" name="sale_amount" placeholder={0} required />
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>
                                                    {
                                                        submitting === 'ready' ?
                                                            <input className="btn btn-primary" type="submit" />
                                                        : submitting === 'submitting' ?
                                                            <button className="btn btn-primary" disabled={true}>Submit Query <FontAwesomeIcon icon="spinner" spin /></button>
                                                        : submitting === 'success' ?
                                                            <button className="btn btn-primary" disabled={true}>Submit Query</button>
                                                        :
                                                            <input className="btn btn-primary" type="submit" />
                                                    }                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <Loading />
                                    )}
                                </table>
                            </form>
                            <br />
                            {results.length > 0 && (
                                <div>
                                    {results.map((result, index) => (
                                        <div key={index}>
                                            {result.returnCode === '0' ? <span className="text-success">Lead {index + 1} added successfully </span> : <span className="text-danger">Lead {index + 1} RESULTED IN ERROR</span>}
                                            <br />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddLeads
