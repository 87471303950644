import React, { useEffect, useState } from 'react'
import { Trash2 } from 'react-feather'

export default function Role({ role, record, addDeleteRole, changePrivilege, mode }) {
    const [deleteInit, setDeleteInit] = useState(false)

    useEffect(() => {
        // unInitialize delete
        setDeleteInit(false)
    }, [record])

    return (
        <div key={role.id} className="d-flex justify-content-between flex-wrap mb-2 bg-light border rounded p-2">
            <div className="pr-3 font-weight-bold d-flex align-items-center">{role.name}</div>
            <div className="d-flex flex-wrap">
                {Object.keys(role.privileges).map((key) => {
                    return (
                        <div key={key} className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id={`${record.id}-${role.id}-${key}`} value={role.privileges[key]} defaultChecked={role.privileges[key]} disabled={mode === 'portal' && key === 'view'} onClick={() => changePrivilege(mode, record.id, role.id, key, role.privileges[key])} />
                            <label className="form-check-label" htmlFor={`${record.id}-${role.id}-${key}`}>
                                {key}
                            </label>
                        </div>
                    )
                })}
                <div>
                    {deleteInit ? (
                        <button className="btn btn-lnk btn-sm text-danger" onClick={() => addDeleteRole('delete', mode, record.id, role.id)}>
                            Confirm
                        </button>
                    ) : (
                        <button className="btn btn-sm">
                            <Trash2 size={16} color="red" onClick={() => setDeleteInit(true)} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
