import PostbackElement from '../CampaignSettings/Edit/Rates/PostbackElement/PostbackElement'

export default function TestPostbacks({ campaign, campaignId }) {
    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Test Postbacks</div>
            </div>
            <div className="card-body">
                {campaign.rates.map((rate, index) => (
                    <div key={index}>
                        <div>Rate Name: {rate.rate_name}</div>
                        <PostbackElement campaign={campaign} rate={rate} campaignId={campaignId} />
                    </div>
                ))}
            </div>
        </div>
    )
}
