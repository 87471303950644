import React from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../../helpers/callAPI'
import Affiliate from '../../../../../templates/Affiliate/Affiliate'
import { useRef } from 'react'

const TerminateAffiliate = (props) => {
    const { affiliateId, showSystemMessage, affiliateName, status, priority, handleClose, refetch } = props

    const reasonRef = useRef()
    const complianceReasonRef = useRef()
    const notesRef = useRef()

    const terminateAffiliate = () => {       

        const postBody = {
            reason: reasonRef.current ? reasonRef.current.value : 'GF',
            complianceReason: complianceReasonRef.current ? complianceReasonRef.current.value : 'Fraud',
            notes: notesRef.current ? notesRef.current.value : ''
        }

        let url = `affiliate/${affiliateId}/terminate`

        callAPI(url, 'PUT', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    handleClose()
                    refetch()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    handleClose()
                    refetch()
                }
            })
            .catch((error) => {
                console.log(`Error while terminating affiliate: ${error.message}`)
                showSystemMessage('error', `Connection Error - ${error.message}`)
                handleClose()
                refetch()
            })
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Terminate Affiliate: <Affiliate aid={affiliateId} name={affiliateName} status={status} priority={priority} />
                </Modal.Title>
            </Modal.Header>
            <div className="card">
                <div className="card-body p-3">                    
                    <table className="table table-borderless table-card">
                        <tbody>
                            <tr>
                                <td width="60%">E-Mail Content:</td>
                                <td>
                                    <select name="reason" ref={reasonRef} defaultValue="GF" className="form-control">
                                        <option value="GF">Generic Fraud</option>
                                        <option value="ST">Spamming Twitter</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Compliance Reason:</td>
                                <td>
                                    <select name="compliance_reason" ref={complianceReasonRef} defaultValue="Fraud" className="form-control">
                                        <option value="Fraud">Fraud</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Enter additional info for notes section:</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <textarea className="form-control" ref={notesRef} rows={4} cols={100} name="notes" />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-danger" onClick={()=>terminateAffiliate()}>
                                        Terminate
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TerminateAffiliate
