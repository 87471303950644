import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import EmployeePicker from '../../../../components/EmployeePicker/EmployeePicker'
import Graph from '../../../../components/Graph/Graph'
import TimeScalePicker from '../../../../components/TimeScalePicker/TimeScalePicker'
import Dollars from '../../../../templates/Dollars/Dollars'

import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import styles from './SAmSummary.module.scss'
import { useAuth, useSetManager } from '../../../../context/AuthContext'

const SAmSummary = (props) => {
    const auth = useAuth()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [graphLoaded, setGraphLoaded] = useState(false)
    const [scale, setScale] = useState('Monthly')
    const [report, setReport] = useState([])

    const [amSummary, setAmSummary] = useState({})

    const { manager, setManager, showSystemMessage } = props

    useEffect(() => {
        setDataLoaded(false)
        fetchData()
    }, [scale, manager])

    let graphData

    let labels = []
    let data = []

    report.forEach((stat) => {
        scale.toLowerCase() === 'daily' ? labels.push(moment(stat.date).utc().format('ddd MM-DD')) : labels.push(stat.date)
        data.push(stat.margin.toFixed(2))
    })

    graphData = {
        labels: labels,
        datasets: [
            {
                data: data,
                fill: true,
                backgroundColor: 'rgba(16, 159, 182, 0.2)',
                borderColor: 'rgba(16, 159, 182, 1)',
            },
        ],
    }

    async function fetchData() {
        let url1 = `summary/seniorAffiliateManager/${manager.id}`
        let url2 = `reports/earnings/${scale}?adminId=${manager.id}`

        let amResult = await callAPI(url1, 'GET')
        let earningsResult = await callAPI(url2, 'GET')

        if (amResult.success === false || earningsResult.success === false) {
            showSystemMessage('error', 'There was an error while fetching the data. Please try again.')
        }

        if (amResult.success === true) {
            setAmSummary(amResult)
        }

        if (earningsResult.success === true) {
            setReport(earningsResult.report)
        }

        setGraphLoaded(true)
        setDataLoaded(true)
    }

    return (
        <div className="card mb-3">
            <div className={`m-3 d-flex ${styles.customFlexDirection}`}>
                <div className={`font-weight-bold text-secondary ${styles.fontSize125}`}>
                    Hello <span className="text-dark">{manager.name.split(' ')[0]}</span>
                </div>
                {useSetManager(auth.permissions, 'snr_am_dashboard') && <EmployeePicker employee={manager} setEmployee={setManager} role={'AM_SENIOR'} />}
            </div>
            <hr className="my-0" />
            <div className="card-body" style={{ fontSize: '.9rem' }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <span className="font-weight-bold mr-2">Margin</span>
                        <TimeScalePicker timeScale={scale} setTimeScale={setScale} options={['Daily', 'Monthly']} />
                    </div>
                    <div>
                        Today &nbsp;
                        <span className="mb-element active">
                            <Link to={`/reports/margin?mgrid=${manager.id}&startDate=${moment().startOf('day').format('YYYY-MM-DD')}&endDate=${moment().startOf('day').format('YYYY-MM-DD')}`} className="name font-weight-bold">{dataLoaded ? <Dollars value={amSummary.margin.today} /> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</Link>
                        </span>
                    </div>
                    <div>
                        Month &nbsp;
                        <span className="mb-element transfers">
                            <Link to={`/reports/margin?mgrid=${manager.id}&startDate=${moment().startOf('month').format('YYYY-MM-DD')}&endDate=${moment().endOf('month').format('YYYY-MM-DD')}`} className="name font-weight-bold">
                                {/* {graphData.lastMonth.out} &nbsp; */}
                                {dataLoaded ? <Dollars value={amSummary.margin.month} /> : <FontAwesomeIcon icon="spinner" size="1x" spin />}
                            </Link>
                        </span>
                    </div>
                </div>

                {graphLoaded && dataLoaded ? (
                    <div className="align-items-center d-flex">
                        <Graph data={graphData} dollars={true} />
                    </div>
                ) : (
                    <Loading />
                )}

                <hr className="my-0" />
                {dataLoaded ? (
                    <>
                        <div className="my-3">
                            <div className="d-flex justify-content-between">
                                <div>
                                    High &nbsp;
                                    <span className="mb-element green-highlight">
                                        <span className="name  font-weight-bold">{amSummary.affiliates.high.affiliate_manager}</span>
                                    </span>
                                    / {amSummary.affiliates.high.network}
                                </div>
                                <div>
                                    Medium &nbsp;
                                    <span className="mb-element highlight">
                                        <span className="name  font-weight-bold">{amSummary.affiliates.medium.affiliate_manager} </span>
                                    </span>
                                    / {amSummary.affiliates.medium.network}
                                </div>
                                <div>
                                    New &nbsp;
                                    <span className="mb-element purple-highlight">
                                        <span className="name  font-weight-bold">{amSummary.affiliates.new.affiliate_manager}</span>
                                    </span>
                                    / {amSummary.affiliates.new.network}
                                </div>
                            </div>
                            <ProgressBar className="my-2">
                                <ProgressBar variant="green-highlight" now={(amSummary.affiliates.high.affiliate_manager / amSummary.affiliates.total.affiliate_manager) * 100} />
                                <ProgressBar variant="yellow-highlight" now={(amSummary.affiliates.medium.affiliate_manager / amSummary.affiliates.total.affiliate_manager) * 100} />
                                <ProgressBar variant="purple-highlight" now={(amSummary.affiliates.new.affiliate_manager / amSummary.affiliates.total.affiliate_manager) * 100} />
                            </ProgressBar>
                        </div>
                        <hr className="my-0" />

                        <div className="d-flex justify-content-between pt-3">
                            <div>
                                Followups Today &nbsp;
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Followups</Tooltip>}>
                                    <span>
                                        <span className="text-primary font-weight-bold">
                                            <Link to={`/affiliates/followupSummaryReport?adminId=${manager.id}&startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>{amSummary.followups_today}</Link>
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                Month Pitches &nbsp;
                                {/* <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">
                                Approved/Processed
                            </Tooltip>}
                        > */}
                                <span className="mb-element">
                                    <span className="name">{amSummary.pitches.this_month}</span>
                                </span>
                                {/* </OverlayTrigger> */}
                            </div>
                            <div>
                                90d Pitch Margin &nbsp;
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Margin</Tooltip>}>
                                    <span className="mb-element green-highlight">
                                        <span className="name text-primary font-weight-bold">
                                            <Link to={`/affiliates/pitchReport?adminId=${manager.id}&startDate=${moment().add(-90, 'days').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}&startDate2=${moment().add(-90, 'days').format('YYYY-MM-DD')}&endDate2=${moment().format('YYYY-MM-DD')}`}>
                                                <Dollars value={amSummary.pitches['90d_margin']} />
                                            </Link>
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                Affs with Leads &nbsp;
                                <strong>{amSummary.affiliates_with_leads}</strong>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className={`d-flex justify-content-center p-2 bg-info ${styles.bgInfo25}`}>
                <Link to={`/admin/AMPerformanceReport?adminId=${manager.id}`} className="text-primary">
                    View Performance Report
                </Link>
            </div>
        </div>
    )
}

export default SAmSummary
