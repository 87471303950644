import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import EmployeePicker from '../../../../components/EmployeePicker/EmployeePicker'
import Form from 'react-bootstrap/Form'
import callAPI from '../../../../helpers/callAPI'
import CampaignSearch from '../../../../components/CampaignSearch/CampaignSearch'

const CampaignSlidersModal = ({ modalData, setModalData, showSystemMessage, fetchCampaigns, formData, setFormData }) => {
    const [offer, setOffer] = useState({ name: '', id: null })
    useEffect(() => {
        const getData = async () => {
            if (modalData.type === 'create') {
                setFormData({
                    offer: {
                        name: '',
                        id: null,
                    },
                    url: null,
                    desc: null,
                    imgName: null,
                    customBtnTxt: 'View Offer',
                    pinned: null,
                })
            } else if (modalData.slider.id !== null) {
                const result = await callAPI(`campaignSlider/${modalData.slider.id}`, 'GET')
                if (result.success) {
                    setFormData({
                        offer: {
                            name: result.campaign.name,
                            id: result.campaign.id,
                        },
                        url: result.url,
                        desc: result.description,
                        imgName: result.image,
                        customBtnTxt: result.button_text,
                        pinned: result.pinned == 'Y' ? true : false,
                    })
                }
            }
        }
        getData()
    }, [modalData.slider.id])

    useEffect(() => {
        setFormData({ ...formData, offer })
    }, [offer])

    const editSlider = async () => {
        const result = await callAPI(`campaignSlider/${modalData.slider.id}`, 'PUT', formData)
        if (result.success) {
            setModalData((prevState) => ({ ...prevState, isOpen: false }))
            showSystemMessage('success', 'Slider updated successfully')
            fetchCampaigns()
        } else {
            setModalData((prevState) => ({ ...prevState, isOpen: false }))
            showSystemMessage('error', 'There was an error updating the slider')
            fetchCampaigns()
        }
    }

    const createSlider = async () => {
        const result = await callAPI(`campaignSlider`, 'POST', formData)
        if (result.success) {
            setModalData((prevState) => ({ ...prevState, isOpen: false }))
            showSystemMessage('success', 'Slider created successfully')
            fetchCampaigns()
        } else {
            setModalData((prevState) => ({ ...prevState, isOpen: false }))
            showSystemMessage('error', 'There was an error creating the slider')
            fetchCampaigns()
        }
    }

    const deleteSlider = async () => {
        const result = await callAPI(`campaignSlider/${modalData.slider.id}`, 'DELETE')
        if (result.success) {
            setModalData((prevState) => ({ ...prevState, isOpen: false }))
            showSystemMessage('success', 'Slider deleted successfully')
            fetchCampaigns()
        } else {
            setModalData((prevState) => ({ ...prevState, isOpen: false }))
            showSystemMessage('error', 'There was an error deleting the slider')
            fetchCampaigns()
        }
    }

    return (
        <Modal show={modalData.isOpen} onHide={() => setModalData((prevState) => ({ ...prevState, isOpen: false }))}>
            <Modal.Header closeButton>
                <Modal.Title>Campaign Slider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex m-2 align-items-center">
                    <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                        Campaign:{' '}
                    </div>
                    <span className="ml-2 w-100">
                        <CampaignSearch showSystemMessage={showSystemMessage} text={modalData.type == 'create' ? '' : modalData.campaign.name} setCampaign={setOffer} campaign={modalData.type == 'create' ? null : modalData.campaign} />
                    </span>
                </div>
                <div className="d-flex m-2 align-items-center">
                    <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                        URL:{' '}
                    </div>
                    <span className="ml-2 w-100">
                        <Form.Control className="w-100" size="md" onChange={(event) => setFormData({ ...formData, url: event.target.value })} value={formData.url} />
                    </span>
                </div>
                <div className="d-flex m-2 align-items-center">
                    <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                        Description:{' '}
                    </div>
                    <span className="ml-2 w-100">
                        <Form.Control className="w-100" size="md" onChange={(event) => setFormData({ ...formData, desc: event.target.value })} value={formData.desc} />
                    </span>
                </div>
                <div className="d-flex m-2 align-items-center">
                    <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                        <span style={{ whiteSpace: 'nowrap' }}> Image Name: </span>
                    </div>
                    <span className="ml-2 w-100">
                        <Form.Control className="w-100" size="md" onChange={(event) => setFormData({ ...formData, imgName: event.target.value })} value={formData.imgName} />
                    </span>
                </div>
                <div className="d-flex m-2 align-items-center">
                    <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                        <span style={{ whiteSpace: 'nowrap' }}>Custom Button Text: </span>
                    </div>
                    <span className="ml-2 w-100">
                        <Form.Control className="w-100" size="md" onChange={(event) => setFormData({ ...formData, customBtnTxt: event.target.value })} value={formData.customBtnTxt} />
                    </span>
                </div>
                <div className="d-flex m-2 align-items-center">
                    <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                        Pinned:{' '}
                    </div>
                    <span className="ml-2 w-100">
                        <Form.Check type="checkbox" size="md" onChange={(event) => setFormData({ ...formData, pinned: event.target.checked })} checked={formData.pinned} />
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {modalData.type === 'create' ? (
                    <Button variant="primary" onClick={createSlider}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={editSlider}>
                            Save
                        </Button>
                        <Button variant="danger" onClick={deleteSlider}>
                            Delete
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}
export default CampaignSlidersModal
