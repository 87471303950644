import React from 'react'

const BizMonth = (props) => {
    const record = props.record

    return (
        <React.Fragment>
            <tr key={`${record.db_date}-${record.adv_in_active_status_at_month_end}`} style={{ fontSize: '13px' }} className="text-right text-muted">
                <td>{record.full_name}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_in_active_status_at_month_end)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_with_sales_in_month)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_promoted_to_new_status_in_month)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_promoted_to_discussion_status_in_month)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_promoted_to_negotiation_status_in_month)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_promoted_to_active_status_in_month)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_old_cancel_greater_3_mos)}</td>
                <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.adv_new_cancel_less_3_mos)}</td>
            </tr>
        </React.Fragment>
    )
}

export default BizMonth
