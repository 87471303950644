import React, { useState } from 'react'
import { MoreVertical } from 'react-feather'
import { Link } from 'react-router-dom'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'

export default function ReversalReportDropdown({ advertiserId, affiliateId, campaignId, date, startDate, endDate }) {
    let [dropdownOpen, setDropdownOpen] = useState(false)
    const { initStartDate, initEndDate } = useDateRangeFilter()

    const buildUrl = (url) => {
        if (date) {
            url = url + `?startDate=${date}&endDate=${date}`
        } else {
            let finalStartDate = startDate ? startDate : initStartDate
            let finalEndDate = endDate ? endDate : initEndDate
            url = url + `?startDate=${finalStartDate}&endDate=${finalEndDate}`
        }

        if (advertiserId > 0) {
            url = url + `&advertiserId=${advertiserId}`
        }

        if (affiliateId > 0) {
            url = url + `&affiliateId=${affiliateId}`
        }

        if (campaignId > 0) {
            url = url + `&campaignId=${campaignId}`
        }

        return url
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction="left">
            <DropdownToggle tag="a" onClick={() => setDropdownOpen(!dropdownOpen)} data-toggle="dropdown" aria-expanded={dropdownOpen} className="report-more-toggle">
                <MoreVertical size={20} />
            </DropdownToggle>
            <DropdownMenu>
                {!affiliateId && (
                    <>
                        <Link className="dropdown-item" to={buildUrl(`/reports/reversals/affiliates`, false)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Affiliate
                        </Link>
                        <DropdownItem divider className="m-0" />{' '}
                    </>
                )}
                {!advertiserId && !campaignId && (
                    <>
                        <Link className="dropdown-item" to={buildUrl(`/reports/reversals/advertisers`, false)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Advertiser
                        </Link>
                        <DropdownItem divider className="m-0" />{' '}
                    </>
                )}
                {!campaignId && (
                    <>
                        <Link className="dropdown-item" to={buildUrl(`/reports/reversals/campaigns`, false)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Campaign
                        </Link>
                        <DropdownItem divider className="m-0" />{' '}
                    </>
                )}
                <Link className="dropdown-item" to={buildUrl(`/reports/reversals/reverser`, false)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                    By Reverser
                </Link>
            </DropdownMenu>
        </Dropdown>
    )
}
