import React, { Component } from 'react'
import CharLimit from '../templates/CharLimit'
import QuestionDisclaimer from '../templates/QuestionDisclaimer'

class TextShort extends Component {
    state = {}
    render() {
        let { question, disclaimer, placeholder, order, questionId, charLimit, callToAction } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Text Short</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-3">
                    <input className="form-control" type="text" defaultValue={placeholder} placeholder="Placeholder text" onChange={this.props.handleChangePlaceholder(questionId)} />
                </div>
                <div className="mt-4">
                    <div className="mt-3">
                        <input className="form-control mb-button-input-text" type="text" defaultValue={callToAction} placeholder="Custom Call to Action" onChange={this.props.handleChangeCallToAction(questionId)} />
                    </div>
                </div>
                <QuestionDisclaimer disclaimer={disclaimer} handleChangeDisclaimer={this.props.handleChangeDisclaimer} questionId={questionId} />
                <hr></hr>
                <CharLimit questionId={questionId} charLimit={charLimit} handleChangeCharLimit={this.props.handleChangeCharLimit} />
            </div>
        )
    }
}

export default TextShort
