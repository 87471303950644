import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import useCallApi from '../../hooks/useCallApi';
import moment from 'moment';
import Loading from '../../templates/UI/Loading/Loading';

export default function TrailingTwelveMonthGraph({affiliateId, advertiserId, showSystemMessage}){

    const {data, dataLoaded} = useCallApi(`reports/monthly?${affiliateId > 0 ? `affiliateId=${affiliateId}` : `advertiserId=${advertiserId}`}&startDate=${moment().add(-12, 'months').startOf('month').format('YYYY-MM-DD')}&endDate=${moment().endOf('month').format('YYYY-MM-DD')}&withAvatar=true`, 'GET', {}, [], showSystemMessage)

    return (
        <div>
            {
                dataLoaded && data.report.length > 0 ?
                    <div className="p-2">
                        <Line
                            data={{
                                labels: data.report.map((item) => moment(item.date).utc().format('MMM-YY')),
                                datasets: [
                                    {
                                        data: data.report.map((item) => item.affiliate_earnings.toFixed(2)),
                                        backgroundColor: 'rgba(23,162, 184, 0.1)',
                                        borderColor: 'rgba(23, 162, 184, 1)',
                                        fill: false,
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                legend: false,
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                display: true,
                                                autoSkip: true,                        
                                                maxTicksLimit: 10,
                                                callback: function(value, index, values) {                           
                                                    return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)                                                   
                                                }
                                            },
                                        },
                                    ]
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {                    
                                            return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tooltipItem.yLabel)                   
                                        }
                                    }
                                }
                            }}
                            width={320}
                            height={200}
                        />
                    </div>
                : dataLoaded && data.report.length === 0 ?
                    <></>
                :
                    <div className="p-2 d-flex align-items-center" style={{height:'212px'}}>
                        <Loading/>
                    </div>
            }        
        </div>
    )
    
}