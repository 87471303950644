import { useEffect, useState } from 'react'

import callAPI from '../../../../../../helpers/callAPI'
import MatchingAffiliatesTable from '../MatchingAffiliatesTable/MatchingAffiliatesTable'

const MatchingAffiliates = ({ matchType, affiliateId, phone, mobile, imType, imId, ipAddress, contact, company, address1, city, showSystemMessage, imageId, passwordText }) => {
    const [matchingAffiliates, setMatchingAffiliates] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [matchingContact, setMatchingContact] = useState([])
    const [matchingCompany, setMatchingCompany] = useState([])

    useEffect(() => {
        let url = getURL()

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    if (matchType === 'contact') {
                        setMatchingCompany(result.matching_company)
                        setMatchingContact(result.matching_contact)
                    } else {
                        setMatchingAffiliates(result.matching_affiliates)
                    }

                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while getting matching affiliates')
            })
    }, [imageId])

    const getURL = () => {
        if (matchType === 'phone') {
            title = `Other affiliates (up to 10) using one of these phone numbers:`
            return `compliance/${affiliateId}/matching/phone?m=${mobile}&p=${phone}`
        } else if (matchType === 'id') {
            title = `Other affiliates (up to 10) with this ID number:`
            return `compliance/${affiliateId}/matching/id?id=${imageId}`
        } else if (matchType === 'im') {
            title = `Other affiliates (up to 10) using this IM handle:`
            return `compliance/${affiliateId}/matching/im?type=${imType}&imid=${imId}`
        } else if (matchType === 'ip') {
            title = `Other affiliates (up to 10) that signed up from this IP:`
            return `compliance/${affiliateId}/matching/ip?ip=${ipAddress}`
        } else if (matchType === 'address') {
            title = `Other affiliates (up to 10) that signed up from this IP:`
            return `compliance/${affiliateId}/matching/address?add1=${address1}&city=${city}`
        } else if (matchType === 'password') {
            title = `Other affiliates (up to 10) with the same password:`
            return `compliance/${affiliateId}/matching/password?pw=${passwordText}`
        } else if (matchType === 'contact') {
            title = `Other affiliates with the same contact:`
            return `compliance/${affiliateId}/matching/contact?contact=${contact}&company=${company}`
        }
    }

    let title = ''

    if (matchType === 'phone') {
        title = `Other affiliates (up to 10) using one of these phone numbers:`
    } else if (matchType === 'id') {
        title = `Other affiliates (up to 10) with this ID number:`
    } else if (matchType === 'im') {
        title = `Other affiliates (up to 10) using this IM handle:`
    } else if (matchType === 'ip') {
        title = `Other affiliates (up to 10) that signed up from this IP:`
    } else if (matchType === 'address') {
        title = `Other affiliates (up to 10) that signed up from this IP:`
    } else if (matchType === 'password') {
        title = <>Other affiliates (up to 10) with the same password: <span className='font-weight-bold'>{passwordText}</span></>
    } else if (matchType === 'contact') {
        title = `Other affiliates with the same contact:`
    }

    return (
        <>
            {matchType !== 'contact' ? (
                matchingAffiliates.length > 0 && (
                    <tr>
                        <td colSpan={3}>
                            <span className="d-block text-secondary">{title}</span>
                            <MatchingAffiliatesTable data={matchingAffiliates} />
                        </td>
                    </tr>
                )
            ) : (
                <>
                    {matchingContact.length > 0 && (
                        <tr>
                            <td colSpan={3}>
                                <span className="d-block text-secondary">{title}</span>
                                <MatchingAffiliatesTable data={matchingContact} />
                            </td>
                        </tr>
                    )}

                    {matchingCompany.length > 0 && (
                        <tr>
                            <td colSpan={3}>
                                <span className="d-block text-secondary">Other affiliates with the same company: </span>
                                <MatchingAffiliatesTable data={matchingCompany} />
                            </td>
                        </tr>
                    )}
                </>
            )}
        </>
    )
}

export default MatchingAffiliates
