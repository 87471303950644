import CampaignTrafficType from '../../../components/CampaignTrafficType/CampaignTrafficType'

const TrafficTypes = ({ trafficTypes }) => {
    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Traffic Types</div>
            </div>
            <div className="card-body p-0">
                <div className="d-flex flex-wrap m-2">
                    {/* <CampaignTrafficType allowed={trafficTypes.desktop_flag} name="Desktop" /> */}
                    <CampaignTrafficType allowed={trafficTypes.site_traffic_flag} name="Display" />
                    <CampaignTrafficType allowed={trafficTypes.social_media_flag} name="Social" />
                    <CampaignTrafficType allowed={trafficTypes.contextual_flag} name="Contextual" />
                    <CampaignTrafficType allowed={trafficTypes.search_flag} name="Search" />
                    <CampaignTrafficType allowed={trafficTypes.mobile_flag} name="Mobile Ads" />
                    <CampaignTrafficType allowed={trafficTypes.incentive_flag} name="Incentive" />
                    <CampaignTrafficType allowed={trafficTypes.solo_emails_flag} name="Email" />
                    <CampaignTrafficType allowed={trafficTypes.native_flag} name="Native Ads" />
                    <CampaignTrafficType allowed={trafficTypes.brand_bid_flag} name="Brand Bidding" />
                </div>
            </div>
        </div>
    )
}

export default TrafficTypes
