import React, { useState } from 'react'
import { Card, Form, Table } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const TopCampaignByCountry = ({ showSystemMessage }) => {
    document.title = 'Top Campaigns By Country'
    const [country, setCountry] = useState('US')
    const { dataLoaded: countryDataLoaded, data: countryData } = useCallApi(`countryList`, 'GET', {}, [])
    const { data, dataLoaded } = useCallApi(`advertisers/topCampaignsForCountry?countryId=${country}`, 'GET', {}, [country], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'campaign.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: 'Status',
                accessor: 'campaign.status.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div className="text-right">Lead Count</div>,
                accessor: 'leadCount',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{Intl.NumberFormat('en-GB').format(value)}</>
                },
            },
            {
                Header: <div className="text-right">30d Earnings</div>,
                accessor: 'thirtyDayEarnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.campaigns} columns={columns} totals={data.totals} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Top Campaigns by Country</h1>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <Card>
                        <Card.Header className="d-flex align-items-center">
                            <h1 className="mb-h1-dark">Top campaigns in the last 30 days for: </h1>
                            <span className="w-25 ml-2">
                                <Form.Control as="select" onChange={(e) => setCountry(e.target.value)} value={country} size="sm">
                                    <option value="">Select a country</option>
                                    {countryDataLoaded
                                        ? countryData.countries.map((country, index) => (
                                              <option key={index} value={country.country_code}>
                                                  {country.country_name}
                                              </option>
                                          ))
                                        : null}
                                </Form.Control>
                            </span>
                        </Card.Header>
                        {dataLoaded ? data.campaigns.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default TopCampaignByCountry
