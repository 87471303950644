import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import Loading from '../../../templates/UI/Loading/Loading'

import { Line } from 'react-chartjs-2'
import moment from 'moment'

import './ReportAffSignup.css'
import callAPI from '../../../helpers/callAPI'

class ReportAffSignup extends Component {
    state = {
        dataLoaded: false,
        report: [],
        chartStats: [],
        trendStats: [],
        startDate: '',
        endDate: '',
    }

    componentDidMount() {
        const fetchData = async () => {
            this._isMounted = true

            this.setState({
                dataLoaded: false,
                startDate: moment(this.props.startDate).startOf('day').format('YYYY-MM-DD'),
                endDate: moment(this.props.endDate).startOf('day').format('YYYY-MM-DD'),
            })

            /* Fetch Affiliate Applications Stats from DB and populate the reports table and charts */

            let url = `reports/affiliate_application?startDate=${this.props.startDate}&endDate=${this.props.endDate}`

            if (this.props.mgrid > 0) {
                url = url + `&mgrid=${this.props.mgrid}`
            }

            callAPI(url, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        if (this._isMounted) {
                            this.setState({
                                dataLoaded: true,
                                report: result.reports.applicationReport,
                                chartStats: result.reports.applicationReport,
                                trendStats: result.reports.trendReport,
                            })
                        }
                    } else if (result['success'] === false) {
                        if (result.code !== 401) {
                            this.props.showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                        }
                    } else {
                        this.props.showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    this.props.showSystemMessage('error', 'An error occurred while getting report')
                    this.setState({
                        ...this.state,
                        dataLoaded: true,
                    })
                })
        }
        fetchData()
    }

    render() {
        let chartLabels = []
        let lineData_total_apps = []
        let lineData_active = []
        let lineData_terminated = []
        let lineData_approved = []
        let lineData_rejected = []
        let lineData_high = []
        let lineData_medium = []
        let lineData_new = []
        let lineData_low = []
        let lineData_very_low = []

        let trendLabels = []
        let trend_total_apps = []
        let trend_approved = []

        if (this.state.chartStats.length > 0) {
            this.state.chartStats.forEach((stat) => {
                chartLabels.push(moment.utc(stat.date).format('YYYY-MM-DD'))
                lineData_total_apps.push(stat.total_apps)
                lineData_active.push(stat.total_active)
                lineData_terminated.push(stat.terminated)
                lineData_approved.push(stat.approved)
                lineData_rejected.push(stat.rejected)
                lineData_high.push(stat.high)
                lineData_medium.push(stat.medium)
                lineData_new.push(stat.new)
                lineData_low.push(stat.low)
                lineData_very_low.push(stat.very_low)
            })
            chartLabels.reverse()
            lineData_total_apps.reverse()
            lineData_active.reverse()
            lineData_terminated.reverse()
            lineData_approved.reverse()
            lineData_rejected.reverse()
            lineData_high.reverse()
            lineData_medium.reverse()
            lineData_new.reverse()
            lineData_low.reverse()
            lineData_very_low.reverse()
        }

        if (this.state.trendStats.length > 0) {
            this.state.trendStats.forEach((stat) => {
                trendLabels.push(stat.the_date)
                trend_total_apps.push(stat.total_apps)
                trend_approved.push(stat.approved)
            })
        }

        return (
            <React.Fragment>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {/* Plot the chart */}
                        <div className="chart-container">
                            <article className="canvas-conatainer">
                                <Line
                                    data={{
                                        labels: chartLabels,
                                        datasets: [
                                            {
                                                label: 'Total Apps',
                                                data: lineData_total_apps,
                                                fill: false,
                                                backgroundColor: 'rgb(255, 99, 132)',
                                                borderColor: 'rgb(255, 99, 132, 0.9)',
                                            },
                                            {
                                                label: 'Total Active Affs',
                                                data: lineData_active,
                                                fill: false,
                                                backgroundColor: 'rgb(118, 122, 166)',
                                                borderColor: 'rgb(118, 122, 166, 0.9)',
                                            },
                                            {
                                                label: 'Terminated',
                                                data: lineData_terminated,
                                                fill: false,
                                                backgroundColor: 'rgb(10, 21, 242)',
                                                borderColor: 'rgb(10, 21, 242, 0.2)',
                                            },
                                            {
                                                label: 'Approved',
                                                data: lineData_approved,
                                                fill: false,
                                                backgroundColor: 'rgb(75, 192, 192)',
                                                borderColor: 'rgb(75, 192, 192,0.2)',
                                            },
                                            {
                                                label: 'Rejected',
                                                data: lineData_rejected,
                                                fill: false,
                                                backgroundColor: 'rgb(222, 115, 24)',
                                                borderColor: 'rgb(222, 115, 24,0.2)',
                                            },
                                        ],
                                    }}
                                    options={{
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        beginAtZero: true,
                                                    },
                                                },
                                            ],
                                        },
                                        legend: {
                                            display: true,
                                            position: 'bottom',
                                        },
                                    }}
                                    height={50}
                                />
                            </article>
                        </div>
                        <div className="chart-container">
                            <article className="canvas-conatainer">
                                <Line
                                    data={{
                                        labels: chartLabels,
                                        datasets: [
                                            {
                                                label: 'High',
                                                data: lineData_high,
                                                fill: false,
                                                backgroundColor: 'rgb(255, 99, 132)',
                                                borderColor: 'rgb(255, 99, 132,0.95)',
                                            },
                                            {
                                                label: 'Medium',
                                                data: lineData_medium,
                                                fill: false,
                                                backgroundColor: 'rgb(10, 21, 242)',
                                                borderColor: 'rgb(10, 21, 242, 0.2)',
                                            },
                                            {
                                                label: 'New',
                                                data: lineData_new,
                                                fill: false,
                                                backgroundColor: 'rgb(75, 192, 192)',
                                                borderColor: 'rgb(75, 192, 192, 0.2)',
                                            },
                                            {
                                                label: 'Low',
                                                data: lineData_low,
                                                fill: false,
                                                backgroundColor: 'rgb(222, 115, 24)',
                                                borderColor: 'rgb(222, 115, 24, 0.2)',
                                            },
                                            {
                                                label: 'Very Low',
                                                data: lineData_very_low,
                                                fill: false,
                                                backgroundColor: 'rgb(55, 137, 20)',
                                                borderColor: 'rgb(55, 137, 20, 0.2)',
                                            },
                                        ],
                                    }}
                                    options={{
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        beginAtZero: true,
                                                    },
                                                },
                                            ],
                                        },
                                        legend: {
                                            display: true,
                                            position: 'bottom',
                                        },
                                    }}
                                    height={50}
                                />
                            </article>
                        </div>
                    </React.Fragment>
                ) : (
                    <div></div>
                )}
                <table className="table table-striped table-card table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col" colSpan="5" className="border-right"></th>
                            <th scope="col" colSpan="5" className="text-center">
                                Transfers
                            </th>
                        </tr>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col" className="text-center">
                                Total Apps Received
                            </th>
                            <th scope="col" className="text-center">
                                Terminated
                            </th>
                            <th scope="col" className="text-center">
                                Approved
                            </th>
                            <th scope="col" className="text-center border-right">
                                Rejected
                            </th>
                            <th scope="col" className="text-center">
                                High
                            </th>
                            <th scope="col" className="text-center">
                                Medium
                            </th>
                            <th scope="col" className="text-center">
                                New
                            </th>
                            <th scope="col" className="text-center">
                                Low
                            </th>
                            <th scope="col" className="text-center">
                                Very Low
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataLoaded ? (
                            <React.Fragment>
                                {this.state.report.length === 0 ? (
                                    <tr>
                                        <td colSpan="10">No stats for this date range.</td>
                                    </tr>
                                ) : (
                                    <React.Fragment>
                                        {this.state.report.map((item) => {
                                            return (
                                                <tr key={`${item.date} - ${item.total_apps}`}>
                                                    <td>{moment.utc(item.date).format('YYYY-MM-DD')}</td>
                                                    <td className="text-center">
                                                        {item.total_apps ? (
                                                            <>
                                                                <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&ta=true`} onClick={this.toggle}>
                                                                    {item.total_apps}
                                                                </Link>
                                                                <span className="p-2" data-tip="% currently active">
                                                                    ({((item.total_active / item.total_apps) * 100).toFixed(2)}%)
                                                                </span>
                                                            </>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.terminated ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&as=C`} onClick={this.toggle}>
                                                                {item.terminated}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.approved ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&as=A`} onClick={this.toggle}>
                                                                {item.approved}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center border-right">
                                                        {item.rejected ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&as=R`} onClick={this.toggle}>
                                                                {item.rejected}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.high ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&ap=1`} onClick={this.toggle}>
                                                                {item.high}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.medium ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&ap=2`} onClick={this.toggle}>
                                                                {item.medium}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.new ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&ap=3`} onClick={this.toggle}>
                                                                {item.new}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.low ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&ap=4`} onClick={this.toggle}>
                                                                {item.low}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.very_low ? (
                                                            <Link to={`/reports/affiliate_earnings?startDate=${moment.utc(this.state.startDate).format('YYYY-MM-DD')}&endDate=${moment.utc(this.state.endDate).format('YYYY-MM-DD')}&filterStartDate=${moment.utc(item.date).format('YYYY-MM-DD')}&filterEndDate=${moment.utc(item.date).format('YYYY-MM-DD')}&ap=5`} onClick={this.toggle}>
                                                                {item.very_low}
                                                            </Link>
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <ReactTooltip />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <tr>
                                <td colSpan="10" align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {/* Plot the trend graph */}
                        <div className="chart-container">
                            <h3 className="trendGraphTitle">12-month Trend Report</h3>
                            <article className="canvas-conatainer">
                                <Line
                                    data={{
                                        labels: trendLabels,
                                        datasets: [
                                            {
                                                label: 'Total Apps',
                                                data: trend_total_apps,
                                                fill: false,
                                                backgroundColor: 'rgb(255, 99, 132)',
                                                borderColor: 'rgb(255, 99, 132, 0.9)',
                                            },
                                            {
                                                label: 'Approved',
                                                data: trend_approved,
                                                fill: false,
                                                backgroundColor: 'rgb(75, 192, 192)',
                                                borderColor: 'rgb(75, 192, 192,0.2)',
                                            },
                                        ],
                                    }}
                                    options={{
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        beginAtZero: true,
                                                    },
                                                },
                                            ],
                                        },
                                        legend: {
                                            display: true,
                                            position: 'bottom',
                                        },
                                    }}
                                    height={100}
                                />
                            </article>
                        </div>
                    </React.Fragment>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        )
    }
}

export default ReportAffSignup
