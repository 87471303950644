import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Loading from '../../../templates/UI/Loading/Loading'
import useCallApi from '../../../hooks/useCallApi'

const ClickReferrersSummary = ({ showSystemMessage, affiliateId }) => {
    const { data, dataLoaded } = useCallApi(`reports/clickurls?aid=${affiliateId}&startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().add(1, 'days').format('YYYY-MM-DD')}`, 'GET', {}, [affiliateId])

    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Top Click Referrers (Today)</div>
                <div className="float-right">
                    <Link to={`/affiliate/${affiliateId}/clickReferrers?startDate=${moment().format('YYYY-MM-DD')}`} className="text-primary">
                        List All
                    </Link>
                </div>
            </div>
            <div>
                {dataLoaded ? (
                    data.report.length === 0 ? (
                        <div className="p-2">No stats available</div>
                    ) : (
                        <table className="table table-striped table-card table-hover">
                            <tbody>
                                {data.report.slice(0, 2).map((item) => (
                                    <tr>
                                        <td>
                                            <a target="_blank" href={`${item.referring_url}`}>{item.referring_url}</a>
                                        </td>
                                        <td className="text-right">{item.the_count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </div>
    )
}

export default ClickReferrersSummary
