import { useAuth } from '../../context/AuthContext'
import useCallApi from '../../hooks/useCallApi'

export default function MailerTypeSelect({ mailerType, setMailerType }) {
    const { data, dataLoaded } = useCallApi('batchmail/types', 'GET', {}, [])

    return (
        <select className="form-control" onChange={(e) => setMailerType(e.target.value)} value={mailerType}>
            <option value=""></option>
            {dataLoaded && data.types.length > 0
                ? data.types.map((type) => {
                      return <option value={type.id}>{type.name}</option>
                  })
                : ''}
        </select>
    )
}
