import Loading from '../../../templates/UI/Loading/Loading'
import { Modal, Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import callAPI from '../../../helpers/callAPI'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'

const SetModal = ({ showSystemMessage, isOpen, setIsOpen, refetch, data, dataLoaded }) => {
    const [setName, setSetName] = useState('')
    const [campaign, setCampaign] = useState('')

    const createSet = async () => {
        const result = await callAPI(`campaigns/${campaign.id}/set/${setName}`, 'POST')
        if (result.success) {
            refetch()
            showSystemMessage('success', 'Set created successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Campaign to Country Redirect Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Set Name: </span>
                            <div className="w-75">
                                <Form.Control as="select" onChange={(e) => setSetName(e.target.value)}>
                                    <option value="">Select Set Name</option>
                                    {data.setNamesList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.set_name}>
                                                {item.set_name}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Campaign: </span>
                            <div className="w-75">
                                <CampaignSearch setCampaign={setCampaign} />
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={createSet}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SetModal
