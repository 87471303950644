import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Card, Table } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useHistory } from 'react-router-dom'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const PerformanceBonus = ({ showSystemMessage, mgrId }) => {
    document.title = 'Affiliate Performance Bonus Candidates'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_bonus_candidates')

    const defaultMgrId = Number(mgrId) ? Number(mgrId) : 0
    const [adminId, setAdminId] = useState(defaultMgrId)
    const { data, dataLoaded } = useCallApi(`affiliates/performanceBonus?mgrId=${adminId}`, 'GET', {}, [adminId], showSystemMessage)
    const history = useHistory()
    useEffect(() => {
        history.push(`/affiliates/performanceBonus?mgrId=${adminId}`)
    }, [adminId])

    const seconds_to_now = moment().diff(moment().startOf('month'), 'seconds')
    const seconds_all_month = moment().endOf('month').diff(moment().startOf('month'), 'seconds')
    const month_ratio = seconds_all_month / seconds_to_now

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].aff_status_value} priority={tableData.data[tableData.row.index].aff_priority_value} />
                },
            },
            {
                Header: () => <div className="text-right">M1 Earnings</div>,
                accessor: 'm1_earnings',
                conditionalClassName: (value, data) => (data.months_in === 1 && value > 1000 ? 'text-right bgSuccess25' : data.months_in === 1 ? 'text-right bgWarning25' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">M2 Earnings</div>,
                accessor: 'm2_earnings',
                conditionalClassName: (value, data) => (data.months_in === 2 && value > 1000 ? 'text-right bgSuccess25' : data.months_in === 2 ? 'text-right bgWarning25' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">M3 Earnings</div>,
                accessor: 'm3_earnings',
                conditionalClassName: (value, data) => (data.months_in === 3 && value > 1000 ? 'text-right bgSuccess25' : data.months_in === 3 ? 'text-right bgWarning25' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">MTD Earnings</div>,
                accessor: 'mtd_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">Projected MTD</div>,
                accessor: 'projected',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Dollars value={Number(tableData.data[tableData.row.index].mtd_earnings) * Number(month_ratio)} />
                },
            },
            {
                Header: () => <div className="text-center">Months In</div>,
                accessor: 'months_in',
                className: 'text-center',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.perfBonus[0]} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <div>Performance Bonus Candidates for</div>
                        <div className="ml-2">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR']} defaultSelf />
                        </div>
                    </Card.Header>
                    {dataLoaded ? data.perfBonus[0].length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
                </Card>
            </div>
        </div>
    )
}

export default PerformanceBonus
