import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Button } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import PullAffiliateModal from '../../../components/PullAffiliateModal/PullAffiliateModal'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'

const KPIReport = ({ showSystemMessage, campaignId }) => {
    const history = useHistory()
    const location = useLocation()
    const [adminId, setAdminId] = useState(location.search ? queryString.parse(location.search).adminId : 0)
    const [reportData, setReportData] = useState([])

    const { dataLoaded, data, refetch } = useCallApi(`campaign/${campaignId}/KPIReport?mgrId=${adminId}`, 'GET', {}, [campaignId, adminId])
    const [pullAffiliateModal, setPullAffiliateModal] = useState({
        open: false,
        affiliate: {
            id: 0,
            name: '',
        },
        campaign: {
            id: campaignId,
            name: '',
        },
    })

    useEffect(() => {
        if (dataLoaded) {
            let temp = []
            Array.isArray(data.report) &&
                data.report.forEach((item) => {
                    if (item !== null) {
                        temp.push(item)
                    }
                })
            data.campaign && setPullAffiliateModal({ ...pullAffiliateModal, campaign: { id: campaignId, name: data.campaign.name } })
            setReportData(temp)
        }
    }, [data, dataLoaded])

    const getReportOutput = () => {
        const kpiColumns =
            data?.kpis?.map((kpi, index) => {
                return {
                    Header: `${kpi.kpi_name} (${kpi.is_hard_kpi === 'Y' ? 'Hard' : ''} ${kpi.goal_value} ${kpi.goal_type === 'p' ? '%' : ''})`,
                    accessor: `kpis[${index}].percentage`,
                    conditionalClassName: (value, row) => {
                        let style = ''

                        if (row.kpis[index]) {
                            if (row.kpis[index].is_hard_kpi === 'Y') {
                                style += 'font-weight-bold '
                            } else {
                                style += ''
                            }
                            if (row.kpis[index].total_clicks > row.kpis[index].kpi_threshold) {
                                if (row.kpis[index].status === 'm') {
                                    style += ' bg-success'
                                } else {
                                    style += ' bg-danger'
                                }
                            } else {
                                style += ' bg-warning'
                            }
                            return style
                        } else {
                            return 'bg-warning'
                        }
                    },
                    Cell: (tableData) => {
                        return <div className="d-flex justify-content-end">{tableData.data[tableData.row.index].kpis[index] ? tableData.data[tableData.row.index].kpis[index].percentage : '0'}</div>
                    },
                }
            }) || []

        let columns = [
            {
                Header: 'Aff. ID',
                accessor: 'affiliate.id',
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} />
                },
            },
            ...kpiColumns,
            {
                Header: '',
                accessor: 'kpis[0].total_clicks',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <Button variant="link" size="sm" onClick={() => setPullAffiliateModal({ ...pullAffiliateModal, open: true, affiliate: { id: tableData.data[tableData.row.index].affiliate.id, name: tableData.data[tableData.row.index].affiliate.name } })}>
                                Pull
                            </Button>
                        </div>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={reportData} columns={columns} />
            </React.Fragment>
        )
    }

    useEffect(() => {
        let url = `/campaign/${campaignId}/KPIReport?adminId=${adminId}`
        history.push(url)
    }, [adminId])

    return (
        <div>
            {/* {pullAffiliateModal.open && <PullAffiliateModal data={pullAffiliateModal} setData={setPullAffiliateModal} showSystemMessage={showSystemMessage} />} */}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">KPI Report</h1>
                <div className="ml-5">
                    <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">For</h1>
                        </div>
                        <div className="mr-4">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                        </div>
                    </div>
                    {dataLoaded ? reportData.lenth > 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default KPIReport
