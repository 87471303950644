const ByUserAgent = ({ userAgents, totalLeads }) => {
    return (
        <div>
            <div className="card mb-2">
                <div className="align-items-center p-2 card-header">
                    <div className="font-weight-bold float-left">Top 10 User Agents</div>
                </div>
                <div className="card-body p-2">
                    <table className="table table-card table-borderless table-striped table-hover">
                        <thead className="border-bottom">
                            <tr>
                                <th scope="col">User Agent</th>
                                <th scope="col" className="text-right">
                                    Lead %
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {userAgents.length === 0 ? (
                                <tr>
                                    <td colSpan={2}>No stats available</td>
                                </tr>
                            ) : (
                                userAgents.map((item, index) => (
                                    <tr key={`${index}-${item.user_agent}`}>
                                        <td>{item.user_agent}</td>
                                        <td align="right">{totalLeads > 0 ? Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((item.lead_count / totalLeads) * 100) : 0}%</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ByUserAgent
