import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Loading from '../../../../templates/UI/Loading/Loading'
import Pill from '../../../../templates/UI/Pill/Pill'
import { colors } from '../../../../theme/variables'
import callAPI from '../../../../helpers/callAPI'
import getFollowupResult from '../../../../helpers/getFollowupResult'
import styles from './BoomarkedAffiliates.module.scss'
import Affiliate from '../../../../templates/Affiliate/Affiliate'

const BookmarkedAffiliates = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliates, setAffiliates] = useState([])

    const { showSystemMessage, manager } = props

    useEffect(async () => {
        let url = `affiliates/bookmarked?adminId=${manager.id}`

        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', 'There was an error while fetching bookmarked affiliates. Please try again.')
        }

        setAffiliates(result.affiliates)
        setDataLoaded(true)
    }, [manager.id])

    return (
        <div className="card mb-2 overflow-auto">
            <div className="card-header">Bookmarked Affiliates</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    affiliates.length === 0 ? (
                        <div className="p-2 border-top">No Stats Available </div>
                    ) : (
                        <table className="table table-striped table-hover m-0">
                            <thead>
                                <tr className="text-secondary">
                                    <th>ID</th>
                                    <th>Affiliate</th>
                                    <th>Priority</th>
                                    <th>Next</th>
                                    <th className="text-right">Last</th>
                                </tr>
                            </thead>
                            <tbody>
                                {affiliates.map((item) => {
                                    const pastDate = moment().isAfter(item.followups.next_followup_date) ? true : false

                                    return (
                                        <tr key={item.affiliate.id} className={pastDate ? 'bgDanger25' : null}>
                                            <td>{item.affiliate.id}</td>
                                            <td>
                                                <Affiliate aid={item.affiliate.id} name={item.affiliate.name} status={item.affiliate.status} priority={item.affiliate.priority} nextFollowupDate={item.followups.next_followup_date} />
                                            </td>
                                            <td>{item.affiliate.priority}</td>
                                            <td>{item.followups.next_followup_date}</td>
                                            <td className="text-primary text-right">
                                                <OverlayTrigger
                                                    trigger={['hover', 'focus']}
                                                    placement="bottom"
                                                    overlay={
                                                        <Popover id="popover-positioned-bottom" arrowprops={{ style: { display: 'none' } }} style={{ minWidth: '300px', maxWidth: '100%' }}>
                                                            <Popover.Title className="d-flex justify-content-between" style={{ fontSize: '14px' }}>
                                                                <div>
                                                                    <div className="mr-2" style={{ width: 'max-content' }}>
                                                                        {moment(item.followups.last_followup.followup_date).format('YYYY/MM/DD HH:mm')}
                                                                    </div>
                                                                    <div className="mr-2 text-muted" style={{ width: 'max-content' }}>
                                                                        {item.followups.last_followup.admin.name}
                                                                    </div>
                                                                </div>
                                                                <span>
                                                                    <Pill text={getFollowupResult(item.followups.last_followup.result)} borderColor={colors.success} />
                                                                </span>
                                                            </Popover.Title>
                                                            <Popover.Content>{item.followups.last_followup.note}</Popover.Content>
                                                        </Popover>
                                                    }
                                                >
                                                    <Link to={`/affiliate/${item.affiliate.id}`}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Link>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default BookmarkedAffiliates
