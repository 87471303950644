const TrafficCountries = (props) => {
    return (
        <div className="card mb-3">
            <div className="float-left font-weight-bold border-bottom pb-2 card-header">Target Countries</div>
            <div className="p-2">{props.targetCountries.join(', ')}</div>
        </div>
    )
}

export default TrafficCountries
