import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import ParamDropdown from '../../Templates/ParamDropdown/ParamDropdown'

class ParamModal extends Component {
    state = {}

    render() {
        return (
            <Modal isOpen={this.props.paramModalOpen} toggle={this.props.toggleParamModal} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title">Customer Data Parameters</h5>
                    <button type="button" className="close" onClick={this.props.toggleParamModal}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    {this.props.parameters.map((parameter, index) => {
                        return (
                            <div className="mb-3 d-flex align-items-center">
                                <ParamDropdown questionId={this.props.questionId} parameterId={parameter.parameterId} index={index} handleChangeParam={this.props.handleChangeParam} showSystemMessage={this.props.showSystemMessage} />{' '}
                                <button onClick={() => this.props.handleRemoveParam(this.props.questionId, parameter.parameterId)} className="ml-2 btn btn-danger badge">
                                    X
                                </button>
                            </div>
                        )
                    })}
                    <div className="text-center">
                        <button className="btn btn-warning" onClick={() => this.props.handleAddParam(this.props.questionId)}>
                            + Param
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ParamModal
