import React, { useState } from 'react'
import moment from 'moment'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import { Modal } from 'react-bootstrap'
import ApplicationModalContent from './EditApplication/ApplicationModalContent/ApplicationModalContent'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import { FaTimes } from 'react-icons/fa'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import MarketingAdvertiserApplications from '../../Dashboard/components/MarketingAdvertiserApplications/MarketingAdvertiserApplications'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const AdvertiserApplications = ({ advertiserId, showSystemMessage }) => {
    document.title = 'Advertiser Applications'

    let location = useLocation()
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_applications')

    let urlParams = queryString.parse(location.search)
    let initialStatus = urlParams.status ? urlParams.status : 'P'
    let initialSource = urlParams.source ? urlParams.source : ''    

    const [showApplicationModal, setShowApplicationModal] = useState(false)
    const [applicationId, setApplicationId] = useState(0)
    const [status, setStatus] = useState(initialStatus)
    const [source, setSource] = useState(initialSource)
    
    const { selectedStartDate, selectedEndDate, initStartDate, initEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter('all')

    let apiUrl = `advertisers/applications?status=${status}&source=${source}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`

    const buildUrl = (status, source, startDate, endDate) => {
        let url = `/advertisers/applications`
        url += status ? `?status=${status}` : '?status=P'
        url += source ? `&source=${source}` : ''
        url += startDate ? `&startDate=${startDate}` : ''
        url += endDate ? `&endDate=${endDate}` : ''
        return url
    }

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [])

    const handleApplicationClick = (e, id) => {
        e.preventDefault()
        setApplicationId(id)
        setShowApplicationModal(true)
    }

    const handleClose = (isEdited = false, actionType = null) => {
        setShowApplicationModal(false)

        if (isEdited) {
            refetch()
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Contact',
                accessor: 'name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Company Name',
                accessor: 'company',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign Name',
                accessor: 'campaign_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Source',
                accessor: 'source',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: '',
                accessor: 'id',
                Cell: (tableData) => {
                    return (
                        <>
                            <a href={`/advertisers/applications/${tableData.data[tableData.row.index].id}`} className="btn btn-warning" onClick={(e) => handleApplicationClick(e, tableData.data[tableData.row.index].id)}>
                                Edit
                            </a>
                        </>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.applications} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <Modal show={showApplicationModal} onHide={handleClose} size="lg">
                <ApplicationModalContent applicationId={applicationId} showSystemMessage={showSystemMessage} handleClose={handleClose} refetch={refetch} />
            </Modal>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Application</h1>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-2 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">Status:</h1>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='mr-2'>
                                            <select className="form-control form-control-sm" name="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option value="P">Pending</option>
                                                <option value="C">Created</option>
                                                <option value="R">Rejected</option>
                                            </select>
                                        </div>
                                        <div>
                                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                        </div>
                                        <div>
                                            <Link className="btn btn-sm btn-primary" to={buildUrl(status, source, selectedStartDate, selectedEndDate)}>Submit</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {
                                    source !== '' &&
                                        <div className="alert alert-info d-inline-block mx-3"><div className="d-flex align-items-center">Source: {source} <Link to={buildUrl(status, '', selectedStartDate, selectedEndDate)} className="btn btn-link text-danger btn-sm ml-2"> <FaTimes /> </Link></div></div>                                    
                                }                        
                                {
                                    (initStartDate && initEndDate) &&
                                        (initStartDate == '2000-01-01' && initEndDate == moment().endOf('month').format('YYYY-MM-DD')) ?
                                            ''
                                        :
                                            <div className="alert alert-info d-inline-block mx-3"><div className="d-flex align-items-center">{initStartDate} - {initEndDate} <Link to={buildUrl(status, source, false, false)} className="btn btn-link text-danger btn-sm ml-2"> <FaTimes /> </Link></div></div>
                                }
                            </div>

                            {dataLoaded ? data.applications.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <MarketingAdvertiserApplications startDate={initStartDate} endDate={initEndDate} />
                </div>
            </div>
            
        </div>
    )
}

export default AdvertiserApplications
