import React, { useState } from 'react'
import BizMonthTotals from './BizMonthTotals'

const BizYear = (props) => {
    const [openYear, setOpenYear] = useState(false)

    const record = props.record

    return (
        <React.Fragment>
            <tr key={`${record.year}-${record.year_totals.total_disc}`} className="font-weight-bold">
                <td>{record.year}</td>
                <td className="text-right">{record.report[0].month_totals.total_active_status}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_with_sales)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_new)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_disc)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_neg)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_promoted_to_active)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_old_cancel)}</td>
                <td className="text-right">{Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(record.report[0].month_totals.total_new_cancel)}</td>
                <td id={record.year}>
                    <span className="badge badge-primary" style={{ cursor: 'pointer' }} onClick={() => setOpenYear(!openYear)}>
                        {openYear ? '-' : '+'}
                    </span>
                </td>
            </tr>

            {openYear && record.report.map((record) => <BizMonthTotals record={record} />)}
        </React.Fragment>
    )
}

export default BizYear
