import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Affiliate from '../../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../../templates/UI/Loading/Loading'
import TimePicker from '../../../AvatarSurvey/Survey/TimePicker/TimePicker'

const DayPartModal = ({ isOpen, closeModal, selectedSetId, propertyId, showSystemMessage }) => {
    const [schedule, setSchedule] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [affiliate, setAffiliate] = useState({})

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        fetchData()
    }, [propertyId])

    const fetchData = () => {
        setDataLoaded(false)

        const authToken = localStorage.getItem('adm-auth-token')

        const siteId = selectedSetId.split('-')[0]
        const surveyId = selectedSetId.split('-')[1]
        const affiliateId = selectedSetId.split('-')[2]

        let url = `${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/propertySet/schedule?siteId=${siteId}&surveyId=${surveyId}`

        if (affiliateId) {
            url += `&affiliateId=${affiliateId}`
        }

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    setSchedule(result.schedule)
                    setAffiliate(result.affiliate)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Property scheduling: ', error.message)
                showSystemMessage('error', 'Connection error.')
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let invalidTime = false

        schedule.map((item) => {
            if (parseInt(item.to) - parseInt(item.from) <= 0) {
                invalidTime = true
            }
        })

        if (invalidTime) {
            showSystemMessage('error', 'End time must be later than start time')
            return
        }

        setSubmitting(true)

        const authToken = localStorage.getItem('adm-auth-token')

        const body = {
            schedule: schedule,
            siteId: selectedSetId.split('-')[0],
            surveyId: selectedSetId.split('-')[1],
            affiliateId: selectedSetId.split('-')[2],
        }

        fetch(`${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/propertySet/schedule`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken,
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Property set has been scheduled.')
                    closeModal(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Property scheduling: ', error.message)
                showSystemMessage('error', 'Connection error.')
                setSubmitting(false)
            })
    }

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Day Part Scheduling</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-secondary">
                    If a schedule is added to this site/survey set, the set will <strong>only</strong> be active during the scheduled times.
                </div>
                <form onSubmit={handleSubmit}>
                    <table className="table table-borderless table-card m-0 p-0">
                        <tbody>
                            {affiliate && (
                                <tr>
                                    <td>
                                        Affiliate: <Affiliate aid={affiliate.affiliate_id} name={affiliate.name} />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td className="align-items-center">
                                    {dataLoaded ? (
                                        schedule.length > 0 ? (
                                            schedule.map((item, index) => (
                                                <div className="d-flex align-items-center justify-content-between mb-2" key={index}>
                                                    <select name="day" className="form-control form-control-sm mr-2" value={item.day} onChange={(e) => setSchedule(schedule.map((time, i) => (i === index ? { ...time, day: e.target.value } : time)))}>
                                                        <option value="Sun">Sun</option>
                                                        <option value="Mon">Mon</option>
                                                        <option value="Tue">Tue</option>
                                                        <option value="Wed">Wed</option>
                                                        <option value="Thu">Thu</option>
                                                        <option value="Fri">Fri</option>
                                                        <option value="Sat">Sat</option>
                                                    </select>
                                                    <div className="mr-2">From:</div>
                                                    <TimePicker value={item.from} handleChange={(value) => setSchedule(schedule.map((time, i) => (i === index ? { ...time, from: value } : time)))} />
                                                    <div className="mr-2">To:</div>
                                                    <TimePicker value={item.to} handleChange={(value) => setSchedule(schedule.map((time, i) => (i === index ? { ...time, to: value } : time)))} />
                                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setSchedule(schedule.filter((time, i) => i !== index))}>
                                                        Delete
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <span className="text-muted mr-2">No Schedule</span>
                                        )
                                    ) : (
                                        <Loading />
                                    )}
                                    <span>
                                        <button type="button" className="btn btn-warning btn-sm" onClick={() => setSchedule([...schedule, { day: 'Sun', from: '00:00', to: '00:00' }])}>
                                            Add
                                        </button>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <button className="btn btn-sm btn-primary" type="submit" disabled={submitting}>
                                        {submitting ? spinner : 'Save'}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default DayPartModal
