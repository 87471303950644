import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'
import LoginAsAdvertiser from '../LoginAsAdvertiser/LoginAsAdvertiser'
import AdvertiserContactInfo from './AdvertiserContactInfo/AdvertiserContactInfo'
import InvoiceInfo from './InvoiceInfo/InvoiceInfo'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'

const AdvertiserInfo = ({ advertiserId, showSystemMessage, setIsOpen, limitedCreditFlag }) => {
    // name, manager name
    // status, location
    // Name, Skype, Phone, email
    // IM handle, mobile, Email, website (link)
    // Today, Best Month
    const history = useHistory()
    const auth = useAuth()
    let showAdvertiserSettings = useProtectedContent(auth.permissions, 'advertiser_settings')

    const { data, dataLoaded, refetch } = useCallApi(`advertiser/${advertiserId}`, 'GET', {}, [advertiserId])

    const { data: mapData, dataLoaded: mapDataLoaded } = useCallApi(`advertiser/${advertiserId}/mapaccount`, 'GET', {}, [advertiserId])

    const getUrl = (url) => {
        let formattedURL = url.substring(0, 4) === 'http' ? url : url.substring(0, 5) === 'https' ? url : `http://${url}`

        return formattedURL
    }

    return (
        <div className="card pt-2 mb-3">
            {dataLoaded ? (
                <React.Fragment>
                    <div className="d-flex justify-content-between border-bottom p-2 align-items-center">
                        <div>
                            <h5 className="font-weight-bold">
                                {
                                    showAdvertiserSettings ?
                                        <Link to={`/advertiser/${advertiserId}/edit`}>{data.general_info.name}</Link>
                                        :
                                        <>{data.general_info.name}</>
                                }
                            </h5>
                        </div>
                        <div className="d-flex align-items-center">
                            {data.general_info.contract_url && <a target="_blank" href={getUrl(data.general_info.contract_url)} className='mr-3'>Contract</a>}
                            {data.general_info.status.toLowerCase() === 'cancelled' ?
                                <button className='btn btn-secondary' disabled>Not Active</button>
                                : ((mapData && mapData.admin.user_id > 0) ? <LoginAsAdvertiser advertiserId={advertiserId} showSystemMessage={showSystemMessage} /> : <button className='btn btn-sm btn-warning' onClick={() => history.push(`/advertiser/${advertiserId}/createMapAccount`)}>Create MAP account</button>)}
                        </div>
                    </div>
                    <div className="border-bottom p-2">
                        <div className="text-secondary">Manager: {data.adv_manager.name} / Bizdev: {data.bizdev_manager.name}</div>
                    </div>
                    <div className="d-flex align-items-center p-2 border-bottom">
                        <Pill text={data.general_info.status} />
                        <div className="align-middle text-secondary text-center pl-3" style={{ maxWidth: '250px' }}>
                            {data.general_info.state}, {data.general_info.country} <br></br>
                        </div>
                    </div>
                    <AdvertiserContactInfo advertiserId={advertiserId} contactInfo={data.contact_info} additionalContacts={data.additional_contacts} email={data.contact_info.e_mail} contact={data.general_info.contact} refetch={refetch} />
                    <InvoiceInfo invoiceFrequency={data.general_info.invoice_frequency} paymentTerms={data.general_info.payment_terms} balances={data.balances} limitedCreditFlag={limitedCreditFlag} />
                </React.Fragment>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default AdvertiserInfo
