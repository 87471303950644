import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { HelpCircle } from 'react-feather'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import callAPI from '../../../../helpers/callAPI'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'
import Dollars from '../../../../templates/Dollars/Dollars'

const NewlyRunCampaigns = () => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [selectedStatPeriod, setSelectedStatPeriod] = useState('Monthly')

    let statLabelClasses = 'btn btn-secondary'
    useEffect(() => {
        const fetchCampaigns = async () => {
            const data = await callAPI(`campaigns/newlyRun/${selectedStatPeriod}`, 'GET', null)
            if (data.success) {
                setData(data)
            } else {
                console.log(data.errors.message)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchCampaigns()
    }, [selectedStatPeriod])

    const handleStatTimeChange = (event) => {
        event.preventDefault()
        setSelectedStatPeriod(event.target.value)
        setDataLoaded(false)
    }

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <ReactTooltip />
                <span>
                    Newly Run Campaigns <HelpCircle className="text-primary" size={14} data-tip="Campaigns that are being run for the first time ever during the selected period, across the entire network." />
                </span>
                <Link to={`/campaigns/newlyRun`}>List All</Link>
            </Card.Header>
            <div className="d-flex justify-content-center">
                <div className="m-2 btn-group btn-group-sm btn-group-toggle w-75" data-toggle="buttons" role="group">
                    <label className={selectedStatPeriod === 'Monthly' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="Monthly" onClick={handleStatTimeChange} /> Last 30 days
                    </label>
                    <label className={selectedStatPeriod === 'Weekly' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option2" className="change-graph-option" autoComplete="off" value="Weekly" onClick={handleStatTimeChange} /> Last 7 days
                    </label>
                </div>
            </div>
            <Card.Body className="p-0 overflow-auto table-container-scroll">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Campaign</th>
                            <th className="text-right">Margin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.campaigns.length === 0 ? (
                                    <tr>
                                        <td colSpan={3}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.campaigns.map((campaign) => (
                                            <tr key={campaign.id}>
                                                <td xs={3}>{campaign.id}</td>
                                                <td xs={6}>
                                                    <Campaign oid={campaign.id} name={campaign.name} status={campaign.status} />
                                                </td>
                                                <td xs={3} align="right">
                                                    <Link to={`/reports/stats/affiliates?withAvatar=true&campaignId=${campaign.id}&startDate=${selectedStatPeriod === 'Monthly' ? moment().add(-30, 'days').format('YYYY-MM-DD') : moment().add(-7, 'days').format('YYYY-MM-DD')}`} className="text-primary font-weight-bold">
                                                        <Dollars value={campaign.margin} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default NewlyRunCampaigns
