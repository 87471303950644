import React, { Component } from 'react'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'

class SurveyDropdown extends Component {
    state = {
        dataLoaded: false,
        propertySetModalOpen: false,
    }

    componentDidMount() {
        const fetchData = async () => {
            const result = await callAPI(`avatar/surveys`, 'GET')
            if (result['success'] === true) {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    surveys: result.surveys,
                })
            } else if (result['success'] === false) {
                this.setState({
                    dataLoaded: true,
                })
            } else {
                this.props.showSystemMessage('error', result.errors.message)
                this.setState({
                    dataLoaded: true,
                })
            }
        }
        fetchData()
    }

    render() {
        return this.state.dataLoaded ? (
            <select name="site-dropdown" className="form-control" onChange={this.props.handleSurveyChange} required>
                <option></option>
                {this.state.surveys.map((survey) => {
                    return <option value={survey.survey_id}>{survey.description}</option>
                })}
            </select>
        ) : (
            <Loading />
        )
    }
}

export default SurveyDropdown
