import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import Dollars from '../../../templates/Dollars/Dollars'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import ReversalReportDropdown from '../ReversalReportDropDown/ReversalReportDropDown'
import Number from '../../../templates/Number/Number'
import moment from 'moment'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Campaign from '../../../templates/Campaign/Campaign'

const ByCampaign = ({ showSystemMessage, setAffiliate, setAdvertiser }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])
    const location = useLocation()
    const { initStartDate, initEndDate } = useDateRangeFilter()
    const { affiliateId, advertiserId, buildUrl } = useQueryParams()

    useEffect(() => {
        getReport()
    }, [location])

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`reports/reversals/campaigns?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    setTotals(result.totals)
                    result.affiliate && setAffiliate(result.affiliate)
                    result.advertiser && setAdvertiser(result.advertiser)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', 'Could not load reversals report.')
                }
            })
            .catch((error) => {
                console.log(`Error while fetching reversals report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching reversals report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'campaign.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: 'Ad Manager',
                accessor: 'campaign.manager.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Rev Leads</div>,
                accessor: 'rev_leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Number value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Tot Leads</div>,
                accessor: 'tot_leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Number value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>%age of leads reversed</div>,
                accessor: 'pct_leads_reversed',
                conditionalClassName: (value) => (value > 30 ? 'text-right bgDanger25' : 'text-right'),
                Cell: ({ cell: { value } }) => {
                    return <>{value} %</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Rev. Margin</div>,
                accessor: 'rev_margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },            
            {
                Header: <div style={{ textAlign: 'right' }}>Tot. Acct. Margin</div>,
                accessor: 'tot_margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-center">Earliest - latest lead</div>,
                accessor: 'earliest_lead_date',
                className: 'text-center',
                Cell: (tableData) => {
                    return (
                        <>
                            {moment(tableData.data[tableData.row.index].earliest_lead_date).format('YYYY-MM-DD')} - {moment(tableData.data[tableData.row.index].latest_lead_date).format('YYYY-MM-DD')}
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: 'None',
                className: 'text-right',
                Cell: (tableData) => {
                    return <ReversalReportDropdown campaignId={tableData.data[tableData.row.index].campaign.id} affiliateId={affiliateId} advertiserId={advertiserId} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    return <div>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
}

export default ByCampaign
