import { Card, Table } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Loading from '../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const MissingInvoiceInfo = ({ showSystemMessage }) => {
    document.title = 'Missing Invoice Info'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_invoices')

    const { data, dataLoaded } = useCallApi('advertisers/missingInvoiceInfo', 'GET', {}, [], showSystemMessage)
    const calcMissingInvoiceInfo = (row) => {
        let missingInfo = ''
        if (row.accounting_name === '') {
            missingInfo += 'Accountant Name - '
        }
        if (row.accounting_email === '') {
            missingInfo += 'Accountant E-Mail - '
        }
        if (row.stats_flag === 'U') {
            missingInfo += 'Stats Flag - '
        }
        if (row.reversals_flag === 'U') {
            missingInfo += 'Reversals Flag - '
        }
        if (row.payment_terms === 0) {
            missingInfo += 'Payment Terms - '
        }
        if (row.invoice_frequency === 'U') {
            missingInfo += 'Invoice Frequency - '
        }
        if (row.invoice_frequency === 'W' && row.weekly_invoice_day_of_week === -1) {
            missingInfo += 'Invoice Day of Week - '
        }
        return missingInfo.slice(0, -3)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Missing Invoice Info</h1>
            </div>
            <Card>
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th className="border-top-0">Advertiser</th>
                            <th className="border-top-0">Manager</th>
                            <th className="border-top-0">Missing Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            data.report.length > 0 ? (
                                data.report.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Advertiser name={row.advertiser.name} id={row.advertiser.id} />
                                        </td>
                                        <td>{row.manager.name}</td>
                                        <td>{calcMissingInvoiceInfo(row)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No missing invoice information found.</td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td colSpan="3">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </div>
    )
}

export default MissingInvoiceInfo
