import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'

const AddTransaction = ({ maxMoney, isOpen, setIsOpen, affiliate = { id: 1, name: '', priority: '', status: '' }, showSystemMessage, refetch }) => {
    const [formData, setFormData] = useState({ type: 'P' })
    const [submitting, setSubmitting] = useState(false)

    const createTransaction = async () => {

        setSubmitting(true)

        let data = await callAPI(`affiliate/${affiliate.id}/${maxMoney ? 'maxmoney/' : ''}accountHistory/transaction`, 'POST', { ...formData, maxMoney: maxMoney })
        if (data.success) {
            setIsOpen(false)
            showSystemMessage('success', data.message)
            refetch()
        } else {
            showSystemMessage('error', 'Error adding transaction.')
            setSubmitting(true)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add {maxMoney ? 'MaxMoney ' : ''}Transaction for: <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    <span className="w-50">Transaction Type:</span>
                    <select className="form-control form-control-md" onChange={(event) => setFormData((prev) => ({ ...prev, type: event.target.value }))} value={formData.type}>
                        <option value="P">Payment</option>
                        <option value="E">{maxMoney ? 'MM points ' : 'Commissions '}Earned</option>
                        {/* <option value="B">Bonus</option> */}
                    </select>
                </div>
                <div className="d-flex align-items-center">
                    <span className="w-50">Amount:</span>
                    <Form.Control type="text" placeholder="Amount" className="mt-2" onChange={(event) => setFormData((prev) => ({ ...prev, amount: event.target.value }))} value={formData.amount} />
                </div>
                <div className="d-flex align-items-center">
                    <span className="w-50">Notes:</span>
                    <Form.Control type="text" placeholder="Notes" className="mt-2" onChange={(event) => setFormData((prev) => ({ ...prev, notes: event.target.value }))} value={formData.notes} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant="primary" name="Add Transaction" onClick={createTransaction} spin={submitting} />
            </Modal.Footer>
        </Modal>
    )
}

export default AddTransaction
