import moment from 'moment'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Dollars from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import { useEffect, useState } from 'react'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const PageViews = ({ showSystemMessage, defaultAdminId }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'admin_page_views')

    const [adminId, setAdminId] = useState(defaultAdminId)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, refetch } = useCallApi(`admin/${adminId}/pageViews?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date/Time',
                accessor: 'viewdate',
                Cell: ({ cell }) => moment(cell.row.original.viewdate).utc().format('YYYY-MM-DD HH:mm:ss'),
            },            
            {
                Header: 'Page',
                accessor: 'screen_name',
                Cell: ({ cell }) => cell.row.original.screen_name + cell.row.original.query_string,
            },
            {
                Header: 'Query Params',
                accessor: 'query_string',
                Cell: ({ cell: { value } }) => <>{value}</>,
                hide: true
            },
        ]

        return (
            <>
                <ReportTable data={data.pageViews} columns={columns} />
            </>
        )
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="mb-h1-dark d-flex justify-content-start align-items-center">
                    <span className="d-flex mr-2 align-items-center">
                        Page Views
                        <span className="ml-2 ">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role="" />
                        </span>
                    </span>
                    <span className="d-flex ml-2">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                history.push(`/admin/${adminId}/pageViews?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
                                refetch()
                            }}
                        >
                            Refresh
                        </Button>
                    </span>
                </h1>
            </Card.Header>
            <Card.Body className="p-0 m-0">{dataLoaded ? getReportOutput() : <Loading />}</Card.Body>
        </Card>
    )
}

export default PageViews
