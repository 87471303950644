import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import questionTypes from '../Survey/data/avatarQuestionTypes'
import QuestionMapItem from './Templates/QuestionMapItem'
import ExtraParamItem from './Templates/ExtraParamItem'
import FraudOverrides from './Templates/FraudOverrides'

class AddEventModal extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
    }

    state = {
        eventName: '',
        action: 'POST',
        mode: 1,
        url: '',
        postType: 'FORM',
        postResponseType: 'JSON',
        postJsonKey: '',
        postJsonValue: '',
        postTextValue: '',
        postSaleKey: '',
        postDataKey: '',
        eventCompleteMsg: '',
        listArrayKey: '',
        listTitleKey: '',
        listParagraphKey: '',
        listUrlKey: '',
        listImageKey: '',
        listButtonText: 'Learn More',
        conditions: [],
        questionMap: [],
        fraudOverrides: [],
        rateName: '',
        payout: 'N',
        trackingType: 'L',
        trafficType: 'Z',
        merchantId: 0,
        override: false,
        delete: false,
        prepop: 'N',
    }

    componentDidMount() {
        this.setState({
            eventName: this.props.eventName ? this.props.eventName : '',
            action: this.props.action ? this.props.action : 'POST',
            mode: this.props.mode ? parseInt(this.props.mode) : 1,
            url: this.props.url ? this.props.url : '',
            postType: this.props.postType ? this.props.postType : 'FORM',
            postResponseType: this.props.postResponseType ? this.props.postResponseType : 'JSON',
            postJsonKey: this.props.postJsonKey ? this.props.postJsonKey : '',
            postJsonValue: this.props.postJsonValue ? this.props.postJsonValue : '',
            postTextValue: this.props.postTextValue ? this.props.postTextValue : '',
            postSaleKey: this.props.postSaleKey ? this.props.postSaleKey : '',
            postDataKey: this.props.postDataKey ? this.props.postDataKey : '',
            eventCompleteMsg: this.props.eventCompleteMsg ? this.props.eventCompleteMsg : '',
            listArrayKey: this.props.listArrayKey ? this.props.listArrayKey : '',
            listTitleKey: this.props.listTitleKey ? this.props.listTitleKey : '',
            listParagraphKey: this.props.listParagraphKey ? this.props.listParagraphKey : '',
            listUrlKey: this.props.listUrlKey ? this.props.listUrlKey : '',
            listImageKey: this.props.listImageKey ? this.props.listImageKey : '',
            listButtonText: this.props.listButtonText ? this.props.listButtonText : 'Learn More',
            conditions: this.props.conditions ? this.props.conditions : [],
            questionMap: this.props.questionMap ? this.props.questionMap : [],
            fraudOverrides: this.props.fraudOverrides ? this.props.fraudOverrides : [],
            rateName: this.props.rateName ? this.props.rateName : '',
            payout: this.props.payout ? this.props.payout : 'N',
            trackingType: this.props.trackingType ? this.props.trackingType : 'L',
            trafficType: this.props.trafficType ? this.props.trafficType : 'Z',
            merchantId: this.props.merchantId ? this.props.merchantId : 0,
            override: this.props.override ? this.props.override : false,
            prepop: this.props.prepop ? this.props.prepop : 'N',
        })
    }

    saveEvent = (e) => {
        e.preventDefault()

        // Get the max eventId
        let startEvent =
            this.props.events.length > 0
                ? Math.max.apply(
                      Math,
                      this.props.events.map((event) => {
                          return event.eventId
                      })
                  )
                : 0

        const eventName = this.state.eventName
        const mode = e.target.trigger.value
        const action = e.target.action.value
        const url = e.target.url ? e.target.url.value : ''
        const postType = e.target.post_type ? e.target.post_type.value : ''
        const postResponseType = e.target.response_type ? e.target.response_type.value : ''
        const postJsonKey = e.target.post_success_json_key ? e.target.post_success_json_key.value : ''
        const postJsonValue = e.target.post_success_json_value ? e.target.post_success_json_value.value : ''
        const postTextValue = e.target.post_success_text_value ? e.target.post_success_text_value.value : ''
        const postSaleKey = e.target.post_sale_amount_key ? e.target.post_sale_amount_key.value : ''
        const postDataKey = e.target.post_data_key ? e.target.post_data_key.value : ''
        const eventCompleteMsg = e.target.eventCompleteMsg ? e.target.eventCompleteMsg.value : ''
        const listArrayKey = e.target.list_array_key ? e.target.list_array_key.value : ''
        const listTitleKey = e.target.list_title_key ? e.target.list_title_key.value : ''
        const listParagraphKey = e.target.list_paragraph_key ? e.target.list_paragraph_key.value : ''
        const listUrlKey = e.target.list_url_key ? e.target.list_url_key.value : ''
        const listImageKey = e.target.list_image_key ? e.target.list_image_key.value : ''
        const listButtonText = e.target.list_button_text ? e.target.list_button_text.value : ''
        const conditions = this.state.conditions
        const questionMap = this.state.questionMap
        const fraudOverrides = this.state.fraudOverrides
        const rateName = this.state.rateName
        const eventId = this.props.eventId ? parseInt(this.props.eventId) : startEvent + 1
        const dbEventId = this.props.dbEventId ? parseInt(this.props.dbEventId) : 0
        const payout = this.state.payout
        const trackingType = this.state.trackingType
        const trafficType = this.state.trafficType
        const merchantId = this.state.merchantId
        const override = this.state.override
        const prepop = e.target.prepop ? e.target.prepop.value : 'N'

        this.props.editEvent(eventId, dbEventId, eventName, mode, action, url, postType, postResponseType, postJsonKey, postJsonValue, postTextValue, postSaleKey, postDataKey, eventCompleteMsg, listArrayKey, listTitleKey, listParagraphKey, listUrlKey, listImageKey, listButtonText, conditions, questionMap, fraudOverrides, rateName, payout, trackingType, trafficType, merchantId, override, prepop, this.props.editing)
    }

    handleChangeAction = (e) => {
        this.setState({
            ...this.state,
            action: e.target.value,
        })
    }

    handleChangeEventName = (e) => {
        this.setState({
            ...this.state,
            eventName: e.target.value,
        })
    }

    handleChangeRateName = (e) => {
        this.setState({
            ...this.state,
            rateName: e.target.value,
        })
    }

    handleChangeTrigger = (e) => {
        this.setState({
            ...this.state,
            mode: parseInt(e.target.value),
            action: ['POST', 'AFFILIATE'].findIndex((action) => this.state.action === action) ? this.state.action : 'POST',
        })
    }

    handleChangeUrl = (e) => {
        this.setState({
            ...this.state,
            url: e.target.value,
        })
    }

    handleChangePostType = (e) => {
        this.setState({
            ...this.state,
            postType: e.target.value,
        })
    }

    handleChangeResponseType = (e) => {
        this.setState({
            ...this.state,
            postResponseType: e.target.value,
        })
    }

    handleChangeJsonKey = (e) => {
        this.setState({
            ...this.state,
            postJsonKey: e.target.value,
        })
    }

    handleChangeJsonValue = (e) => {
        this.setState({
            ...this.state,
            postJsonValue: e.target.value,
        })
    }

    handleChangeTextValue = (e) => {
        this.setState({
            ...this.state,
            postTextValue: e.target.value,
        })
    }

    handleChangeSaleKey = (e) => {
        this.setState({
            ...this.state,
            postSaleKey: e.target.value,
        })
    }

    handleChangeDataKey = (e) => {
        this.setState({
            ...this.state,
            postDataKey: e.target.value,
        })
    }

    handleChangeEventCompleteMsg = (e) => {
        this.setState({
            ...this.state,
            eventCompleteMsg: e.target.value,
        })
    }

    handleChangeListArrayKey = (e) => {
        this.setState({
            ...this.state,
            listArrayKey: e.target.value,
        })
    }

    handleChangeListTitleKey = (e) => {
        this.setState({
            ...this.state,
            listTitleKey: e.target.value,
        })
    }

    handleChangeListParagraphKey = (e) => {
        this.setState({
            ...this.state,
            listParagraphKey: e.target.value,
        })
    }

    handleChangeListUrlKey = (e) => {
        this.setState({
            ...this.state,
            listUrlKey: e.target.value,
        })
    }

    handleChangeListImageKey = (e) => {
        this.setState({
            ...this.state,
            listImageKey: e.target.value,
        })
    }

    handleChangeListButtonText = (e) => {
        this.setState({
            ...this.state,
            listButtonText: e.target.value,
        })
    }

    handleChangePayout = (e) => {
        this.setState({
            ...this.state,
            payout: e.target.value,
        })
    }

    handleChangeTrackingType = (e) => {
        this.setState({
            ...this.state,
            trackingType: e.target.value,
        })
    }

    handleChangeMerchantId = (e) => {
        this.setState({
            ...this.state,
            merchantId: e.target.value,
        })
    }

    handleChangeOverrideAffiliate = (e) => {
        this.setState({
            ...this.state,
            override: {
                ...this.state.override,
                affiliateId: e.target.value,
            },
        })
    }

    handleChangeOverrideEvent = (e) => {
        this.setState({
            ...this.state,
            override: {
                ...this.state.override,
                eventId: e.target.value,
            },
        })
    }

    handleChangeTrafficType = (e) => {
        this.setState({
            ...this.state,
            trafficType: e.target.value,
        })
    }

    handleChangePrepop = (e) => {
        this.setState({
            ...this.state,
            prepop: e.target.value,
        })
    }

    getConditionQuestionOptions = (questionType, options, condition, outerIndex, innerIndex) => {
        let conditionValue = condition.value
        let questionOptions = <div>Question type {questionType} does not exist.</div>

        let definedType = questionTypes.find((type) => {
            return type.type === questionType
        })

        if (definedType !== undefined) {
            questionOptions = definedType.formOptions(this.props.questionId, outerIndex, innerIndex, options, condition, conditionValue, this.handleChangeLogicAnswer)
        }

        return questionOptions
    }

    getConditionableQuestions = () => {
        // We want to only display options in the select for question where conditionable is true.

        let conditionableTypes = questionTypes.filter((questionType) => {
            return questionType.conditionable === true
        })

        let options = this.props.survey.map((question) => {
            let conditionalCheck = conditionableTypes.find((questionType) => {
                return questionType.type === question.type
            })

            if (conditionalCheck !== undefined) {
                return (
                    <option key={question.questionId} value={question.questionId}>
                        Q{question.order}: {question.question} ({question.type})
                    </option>
                )
            }

            return true
        })

        return options
    }

    handleAddLogic = (e) => {
        e.preventDefault()
        // Add a condition for the first question to the current event
        const survey = _.cloneDeep(this.props.survey)

        let conditionableTypes = questionTypes
            .filter((questionType) => {
                return questionType.conditionable === true
            })
            .map((conditionableType) => {
                return conditionableType.type
            })

        // Get the questionId for the first conditionable question
        let firstQuestion = survey.find((question) => {
            return conditionableTypes.includes(question.type)
        })

        // Get the values
        let value = ''
        if (firstQuestion.options.length > 0) {
            value = firstQuestion.options[0].value
        }

        let operator = ''
        if (firstQuestion.type === 'TI') {
            // Only applies to text int
            operator = 'EQ'
        }

        const conditions = _.cloneDeep(this.state.conditions)

        conditions.push([
            {
                conditionId: 0,
                questionId: firstQuestion.questionId,
                value: value,
                operator: operator,
            },
        ])

        this.setState({
            ...this.state,
            conditions: conditions,
        })
    }

    handleAddInnerLogic = (e, outerIndex) => {
        e.preventDefault()

        const survey = _.cloneDeep(this.props.survey)

        let conditionableTypes = questionTypes
            .filter((questionType) => {
                return questionType.conditionable === true
            })
            .map((conditionableType) => {
                return conditionableType.type
            })

        // Get the questionId for the first conditionable question
        let firstQuestion = survey.find((question) => {
            return conditionableTypes.includes(question.type)
        })

        // Get the values
        let value = ''
        if (firstQuestion.options) {
            if (firstQuestion.options.length > 0) {
                value = firstQuestion.options[0].value
            }
        }

        let operator = ''
        if (firstQuestion.type === 'TI') {
            // Only applies to text int
            operator = 'EQ'
        }

        const conditions = _.cloneDeep(this.state.conditions)

        conditions[outerIndex].push({
            conditionId: 0,
            questionId: firstQuestion.questionId,
            value: [value],
            operator: operator,
        })

        this.setState({
            ...this.state,
            conditions: conditions,
        })
    }

    handleChangeLogicQuestion = (event, outerIndex, innerIndex) => {
        // This will be the new question ID the condition is based one
        let conditionQuestionId = parseInt(event.target.value)

        const survey = _.cloneDeep(this.props.survey)

        // Get the values for the condition question
        let conditionQuestion = survey.find((question) => {
            return question.questionId === conditionQuestionId
        })

        let value = ''
        if (conditionQuestion.options && conditionQuestion.options.length > 0) {
            value = [conditionQuestion.options[0].value]
        }

        let operator = ''
        if (conditionQuestion.type === 'TI') {
            // Only applies to text int
            operator = 'EQ'
        }

        const conditions = _.cloneDeep(this.state.conditions)

        conditions[outerIndex][innerIndex] = {
            conditionId: conditions[outerIndex][innerIndex].conditionId,
            questionId: conditionQuestionId,
            value: value,
            operator: operator,
        }

        this.setState({
            ...this.state,
            conditions: conditions,
        })
    }

    handleChangeLogicAnswer = (event, questionId, key, outerConditionIndex, innerConditionIndex) => {
        // We have to leave questionID as this is what the function expects in avatarQuestionTypes

        let choice = event.target.value

        /*             
            [ [{} AND {}] OR [{}] ] 

            question.conditions [ outerIndex0 [ innerIndex0{}, innerIndex1{} ], outerIndex1[ innerIndex0{}] ]            
        
        */

        // Update the value for this particular condition
        // The key could be 'value', 'operator', etc. Anything key within the condition object

        const conditions = _.cloneDeep(this.state.conditions)

        conditions[outerConditionIndex][innerConditionIndex][key] = choice

        this.setState({
            ...this.state,
            conditions: conditions,
        })
    }

    handleRemoveLogic = (e, outerConditionIndex, innerConditionIndex) => {
        // Find the condition to remove

        e.preventDefault()

        const conditions = _.cloneDeep(this.state.conditions) // CANT just use [...this.state.conditions] because it is a NESTED array. Need a DEEP clone

        conditions[outerConditionIndex].splice(innerConditionIndex, 1)

        // // // If the outerCondition is empty, splice that the whole thing
        if (conditions[outerConditionIndex].length === 0) {
            conditions.splice(outerConditionIndex, 1)
        }

        this.setState({
            ...this.state,
            conditions: conditions,
        })
    }

    activateDelete = (e) => {
        e.preventDefault()

        this.setState({
            ...this.state,
            delete: true,
        })
    }

    handleDeleteEvent = (e) => {
        e.preventDefault()
        const eventId = parseInt(this.props.eventId)
        this.props.deleteEvent(eventId)
    }

    handleChangeQuestionMap = (e, questionId, subQuestion) => {
        e.preventDefault()

        let questionMap = _.cloneDeep(this.state.questionMap)
        let found

        if (subQuestion !== '') {
            found = questionMap.find((question) => question.questionId === questionId && question.subQuestion === subQuestion)
        } else {
            found = questionMap.find((question) => question.questionId === questionId)
        }

        if (found) {
            // Edit the mapping
            found.fieldName = e.target.value
            found.subQuestion = subQuestion
        } else {
            questionMap.push({
                questionId: questionId,
                subQuestion: subQuestion,
                fieldName: e.target.value,
                choices: [],
            })
        }

        this.setState({
            ...this.state,
            questionMap: questionMap,
        })
    }

    handleChangeQuestionMapChoice = (e, questionId, optionId) => {
        e.preventDefault()

        let questionMap = _.cloneDeep(this.state.questionMap)
        let foundQuestion = questionMap.find((question) => question.questionId === questionId)

        if (foundQuestion) {
            if (foundQuestion.choices) {
                let foundChoice = foundQuestion.choices.find((choice) => choice.optionId === optionId)
                if (foundChoice) {
                    foundChoice.optionId = optionId
                    foundChoice.value = e.target.value
                } else {
                    foundQuestion.choices.push({
                        optionId: optionId,
                        value: e.target.value,
                    })
                }
            }
        }

        this.setState({
            ...this.state,
            questionMap: questionMap,
        })
    }

    getQuestionMappingList = () => {
        // First get all the questions.
        return this.props.survey.map((question, index) => {
            // Check if the questionType has fields (meaning it would be short form).
            let thisQuestionType = questionTypes.find((questionType) => questionType.type === question.type)

            if (thisQuestionType.mappable) {
                if (thisQuestionType.fields) {
                    // We'll need one text input per field.
                    return thisQuestionType.fields.map((field) => {
                        let fieldName = ''
                        let choices = []
                        let foundMapping = this.state.questionMap.find((map) => map.questionId === question.questionId && map.subQuestion === field)
                        if (foundMapping) {
                            fieldName = foundMapping.fieldName
                        }

                        return <QuestionMapItem key={`${question.questionId}-${field}`} question={question} fieldName={fieldName} choices={choices} field={field} handleChangeQuestionMap={this.handleChangeQuestionMap} handleChangeQuestionMapChoice={this.handleChangeQuestionMapChoice} />
                    })
                } else {
                    // There are no fields
                    let fieldName = ''
                    let choices = []
                    let foundMapping = this.state.questionMap.find((map) => map.questionId === question.questionId)
                    if (foundMapping) {
                        fieldName = foundMapping.fieldName
                        choices = foundMapping.choices
                    }
                    return <QuestionMapItem key={`${index}`} question={question} fieldName={fieldName} choices={choices} handleChangeQuestionMap={this.handleChangeQuestionMap} handleChangeQuestionMapChoice={this.handleChangeQuestionMapChoice} />
                }
            }

            return true
        })
    }

    handleChangeExtraParam = (e, paramId, mode) => {
        e.preventDefault()

        let questionMap = _.cloneDeep(this.state.questionMap)
        let found = questionMap.find((question) => question.paramId === paramId && question.questionId === 0)

        if (found) {
            // Edit the mapping
            found[mode] = e.target.value
        }

        this.setState({
            ...this.state,
            questionMap: questionMap,
        })
    }

    handleAddExtraParam = (e) => {
        e.preventDefault()

        let questionMap = _.cloneDeep(this.state.questionMap)

        // Get the current highest paramId
        let currentParams = questionMap.filter((paramItem) => paramItem.paramId)
        let paramIdArray = currentParams.map((param) => param.paramId)

        console.log(paramIdArray)

        let highestParamId = 0
        if (currentParams.length > 0) {
            highestParamId = Math.max(...paramIdArray)
        }

        questionMap.push({
            questionId: 0,
            paramId: parseInt(highestParamId) + 1,
            subQuestion: '',
            fieldName: '',
            choices: [],
            value: '',
        })

        this.setState({
            ...this.state,
            questionMap: questionMap,
        })
    }

    handleRemoveExtraParam = (e, paramId) => {
        e.preventDefault()

        let questionMap = _.cloneDeep(this.state.questionMap)

        let extraParamIndex = questionMap.findIndex((mapItem) => mapItem.paramId === paramId)

        questionMap.splice(extraParamIndex, 1)

        this.setState({
            ...this.state,
            questionMap: questionMap,
        })
    }

    handleChangeFraudOverride = (fraudId) => {
        let fraudOverrides = _.cloneDeep(this.state.fraudOverrides)

        let fraudType = fraudOverrides.find((type) => type.id === fraudId)

        fraudType.override = !fraudType.override

        console.log(fraudId, fraudOverrides)

        this.setState({
            ...this.state,
            fraudOverrides: fraudOverrides,
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.closeModal} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title">{this.props.eventId ? 'Edit event' : 'Add a custom event'}</h5>
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form ref={this.form} onSubmit={this.saveEvent}>
                        {this.state.override && this.props.dbEventId && (
                            <div className="alert alert-info">
                                Override for affiliate:{' '}
                                <Link target="_blank" to={`/affiliate/${this.state.override.affiliateId}`}>
                                    {this.state.override.contact}
                                </Link>
                                <br></br>
                                Override for event: <strong>{this.state.override.eventName}</strong>
                            </div>
                        )}
                        {this.state.override && !this.props.dbEventId && (
                            <div className="alert alert-info">
                                <div className="form-group">
                                    <label htmlFor="override_affiliate">Override for affiliate:</label>
                                    <input type="text" required name="override_affiliate" className="form-control" defaultValue={this.state.override.affiliateId} onChange={(e) => this.handleChangeOverrideAffiliate(e)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="override_event_id">Override for event:</label>
                                    <select name="override_event_id" className="form-control" defaultValue={this.state.override.eventId} onChange={(e) => this.handleChangeOverrideEvent(e)}>
                                        <option value=""></option>
                                        {this.props.normalEvents.map((event) => {
                                            return (
                                                <option key={event.eventId} value={event.eventId}>
                                                    {event.eventName}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        )}
                        {!this.state.override && (
                            <div className="form-group">
                                <label htmlFor="event_name">Name:</label>
                                <input name="event_name" type="text" className="form-control" defaultValue={this.props.eventName} onChange={(e) => this.handleChangeEventName(e)} />
                            </div>
                        )}
                        <div className="form-group">
                            <label htmlFor="trigger">Trigger:</label>
                            <select name="trigger" className="form-control" defaultValue={this.state.mode} onChange={(e) => this.handleChangeTrigger(e)}>
                                <option value={1}>Survey completion (fires when survey submits)</option>
                                <option value={2}>Upon condition fulfillment (fires immediately when conditions are met)</option>
                            </select>
                        </div>
                        {this.state.mode !== 2 && (
                            <div className="form-group">
                                <label htmlFor="url">Event Completion message:</label>
                                <input name="eventCompleteMsg" type="text" className="form-control" defaultValue={this.state.eventCompleteMsg} onChange={(e) => this.handleChangeEventCompleteMsg(e)} />
                            </div>
                        )}
                        {!this.state.override && (
                            <div className="form-group">
                                <label htmlFor="traffic_type">Traffic Type:</label>
                                <select name="traffic_type" className="form-control" defaultValue={this.state.trafficType} onChange={(e) => this.handleChangeTrafficType(e)}>
                                    <option value="Z">Any</option>
                                    <option value="A">Affiliate</option>
                                    <option value="N">Organic (no click ID)</option>
                                </select>
                            </div>
                        )}
                        <div className="form-group">
                            <label htmlFor="action">Event type:</label>
                            <select name="action" className="form-control" defaultValue={this.state.action} onChange={(e) => this.handleChangeAction(e)}>
                                <option value="POST">Post/send data in background</option>
                                {this.state.mode === 1 && <option value="LIST">List links from marketplace</option>}
                                {this.state.mode === 1 && <option value="REDIRECT">Redirect</option>}
                                {this.state.mode === 1 && <option value="POPUP">Popup</option>}
                                {this.state.mode === 1 && <option value="IFRAME">Load Iframe</option>}
                                <option value="NONE">None</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="payout">Payout type:</label>
                            <select name="payout" className="form-control" defaultValue={this.state.payout} onChange={(e) => this.handleChangePayout(e)}>
                                <option value="N">Network (Commissions are paid to MaxBounty based on rate name)</option>
                                <option value="A">Affiliate (Commissions are shared with affiliate based on rate name)</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="tracking_type">Tracking type:</label>
                            <select name="tracking_type" className="form-control" defaultValue={this.state.trackingType} onChange={(e) => this.handleChangeTrackingType(e)}>
                                <option value="L">Lead - Lead will track upon event triggering.</option>
                                <option value="E">Event - Tracks an event only. Leads must come from subsequent postback/API pull.</option>
                            </select>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="rate_name">Rate Name:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.state.rateName}
                                    onChange={(e) => {
                                        this.handleChangeRateName(e)
                                    }}
                                ></input>
                            </div>
                            <div className="col-6">
                                <label htmlFor="merchant_id">Invoice Merchant ID (0 if N/A):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.state.merchantId}
                                    onChange={(e) => {
                                        this.handleChangeMerchantId(e)
                                    }}
                                ></input>
                            </div>
                        </div>
                        {this.state.action === 'LIST' && (
                            <div className="alert alert-secondary">
                                <h1 className="mb-h1-dark">List Settings</h1>
                                <hr></hr>
                                <div className="alert alert-info">CASE SENSITIVE. Use commas to separate Keys if there is nesting in the response.</div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label htmlFor="list_array_key">Array Key (the key storing the list of options):</label>
                                        <input name="list_array_key" type="text" className="form-control" defaultValue={this.state.listArrayKey} onChange={(e) => this.handleChangeListArrayKey(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col">
                                        <label htmlFor="list_title_key">Title Key:</label>
                                        <input name="list_title_key" type="text" className="form-control" defaultValue={this.state.listTitleKey} onChange={(e) => this.handleChangeListTitleKey(e)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="list_paragraph_key">Description Key:</label>
                                        <input name="list_paragraph_key" type="text" className="form-control" defaultValue={this.state.listParagraphKey} onChange={(e) => this.handleChangeListParagraphKey(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col">
                                        <label htmlFor="list_url_key">Click URL Key:</label>
                                        <input name="list_url_key" type="text" className="form-control" defaultValue={this.state.listUrlKey} onChange={(e) => this.handleChangeListUrlKey(e)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="list_image_key">Image Key:</label>
                                        <input name="list_image_key" type="text" className="form-control" defaultValue={this.state.listImageKey} onChange={(e) => this.handleChangeListImageKey(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label htmlFor="list_button_text">Button Text:</label>
                                        <input name="list_button_text" type="text" className="form-control" defaultValue={this.state.listButtonText} onChange={(e) => this.handleChangeListButtonText(e)} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.action !== 'NONE' && (
                            <div className="form-group">
                                <label htmlFor="url">{this.state.action == 'REDIRECT' ? <React.Fragment>Redirect URL:</React.Fragment> : this.state.action == 'POPUP' ? <React.Fragment>Popup URL:</React.Fragment> : this.state.action == 'IFRAME' ? <React.Fragment>iFrame URL:</React.Fragment> : <React.Fragment>POST URL:</React.Fragment>}</label>
                                <input name="url" type="text" className="form-control" defaultValue={this.state.url} onChange={(e) => this.handleChangeUrl(e)} />
                            </div>
                        )}
                        {(this.state.action === 'POST' || this.state.action === 'LIST') && (
                            <React.Fragment>
                                <div className="form-group row">
                                    <div className="col">
                                        <label htmlFor="url">Post type:</label>
                                        <select name="post_type" className="form-control" defaultValue={this.state.postType} onChange={(e) => this.handleChangePostType(e)}>
                                            <option value="FORM">Form-data</option>
                                            <option value="JSON">JSON</option>
                                            <option value="GET">URL params only</option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="response_type">Response type:</label>
                                        <select name="response_type" className="form-control" defaultValue={this.state.postResponseType} onChange={(e) => this.handleChangeResponseType(e)}>
                                            <option value="TEXT">Text</option>
                                            <option value="JSON">JSON</option>
                                        </select>
                                    </div>
                                </div>
                                {this.state.postResponseType === 'JSON' && (
                                    <div className="alert alert-secondary">
                                        <div className="alert alert-info">
                                            Use commas to separate Keys if there is nesting in the response. For example:
                                            <br></br>
                                            <code>&#123; "response": &#123; "lead": true, "commission": 123 &#125; &#125;</code>
                                            <br></br>
                                            You would type: "response,lead" for the Success Response Key, and "response, commission" for the Sale Amount Key.
                                        </div>
                                        <div className="form-group row">
                                            <div className="col">
                                                <label htmlFor="url">
                                                    <br></br>Success Response Key (case-sensitive):
                                                </label>
                                                <input name="post_success_json_key" type="text" className="form-control" defaultValue={this.state.postJsonKey} onChange={(e) => this.handleChangeJsonKey(e)} />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="action">Success Response Value. Use "mb_exists" if only checking for existence of a key:</label>
                                                <input name="post_success_json_value" type="text" className="form-control" defaultValue={this.state.postJsonValue} onChange={(e) => this.handleChangeJsonValue(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <label htmlFor="url">Sale Amount Key (case-sensitive):</label>
                                                <input name="post_sale_amount_key" type="text" className="form-control" defaultValue={this.state.postSaleKey} onChange={(e) => this.handleChangeSaleKey(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <label htmlFor="url">ID Key (case-sensitive). Will be the lead data for the lead:</label>
                                                <input name="post_data_key" type="text" className="form-control" defaultValue={this.state.postDataKey} onChange={(e) => this.handleChangeDataKey(e)} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.postResponseType === 'TEXT' && (
                                    <div className="alert alert-secondary">
                                        <div className="form-group">
                                            <label htmlFor="url">Success Response Text:</label>
                                            <input name="post_success_text_value" type="text" className="form-control" defaultValue={this.state.postTextValue} onChange={(e) => this.handleChangeTextValue(e)} />
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {(this.state.action !== 'NONE' && this.props.survey.length) > 0 && (
                            <div className="card mb-3">
                                <div className="card-header">
                                    <h1 className="mb-h1-dark">Question map</h1>
                                </div>
                                <div className="card-body">
                                    {this.state.action === 'POST' || this.state.action === 'LIST' ? (
                                        <div className="alert alert-info">
                                            This is how the API in the above POST URL expects to receive the data from this form. Values are <strong>case-sensitive</strong>.<br />
                                            You can perform nesting like so: zipcode,data.respondent.0.zipCode
                                            <br />
                                            The above means the data will be sent like this:
                                            <br />
                                            <code>&#123; "zipcode": user_input, "data": &#123; "respondent": [ &#123; zipCode: user_input &#125; ] &#125; &#125;</code>
                                        </div>
                                    ) : (
                                        <div className="alert alert-info">
                                            There are 2 modes for mapping data:
                                            <br />
                                            <ol>
                                                <li>Normal: Data will be mapped to individual parameters, with inputted names below.</li>
                                                <li>Prepop: Data will be URL encoded and appended onto the URL in the x= parameter.</li>
                                            </ol>
                                            <div className="my-2">
                                                <label htmlFor="prepop">
                                                    <strong>Mapping Mode</strong>
                                                </label>
                                                <select name="prepop" className="form-control" defaultValue={this.state.prepop} onChange={(e) => this.handleChangePrepop(e)}>
                                                    <option value="N">Normal</option>
                                                    <option value="Y">Prepop</option>
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    <table className="table table-striped table-hover mb-0">
                                        <tbody>
                                            {this.getQuestionMappingList()}
                                            {this.state.questionMap.map((mapItem) => {
                                                if (mapItem.questionId === 0 && mapItem.paramId) {
                                                    return <ExtraParamItem key={mapItem.paramId} paramId={mapItem.paramId} fieldName={mapItem.fieldName} value={mapItem.value} handleChangeExtraParam={this.handleChangeExtraParam} handleRemoveExtraParam={this.handleRemoveExtraParam} />
                                                }
                                                return true
                                            })}
                                        </tbody>
                                    </table>
                                    {/* Adding non-question mappings */}
                                    <div className="text-center mt-2">
                                        <button className="btn btn-warning" onClick={(e) => this.handleAddExtraParam(e)}>
                                            + Param
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="alert alert-secondary mt-2">
                            Macros:<br></br>
                            <code>
                                Event token: #EVENT#<br></br>
                                Affiliate ID: #MBAID#<br></br>
                                Click ID: #ACID#<br></br>
                                Subid 1: #SUB1#<br></br>
                                Subid 2: #SUB2#<br></br>
                                Subid 3: #SUB3#<br></br>
                                Subid 4: #SUB4#<br></br>
                                Subid 5: #SUB5#<br></br>
                                IP Address: #IP#<br></br>
                                User Agent: #USERAGENT#
                            </code>
                        </div>
                        {this.props.survey.length > 0 && (
                            <React.Fragment>
                                <div className="card mb-3">
                                    <div className="card-header">
                                        <h1 className="mb-h1-dark">Trigger Conditions</h1>
                                    </div>
                                    <div className="card-body">
                                        {this.state.conditions.map((condition, outerIndex) => {
                                            return (
                                                <div key={outerIndex}>
                                                    <div className="border rounded my-3 p-3">
                                                        {condition.map((singleCondition, innerIndex) => {
                                                            const conditionQuestion = this.props.survey.find((question) => question.questionId === singleCondition.questionId)
                                                            const { options = '', type } = conditionQuestion
                                                            return (
                                                                <div key={innerIndex}>
                                                                    <div className="alert alert-primary">
                                                                        <div className="form-group">
                                                                            <select className="form-control" defaultValue={singleCondition.questionId} onChange={(e) => this.handleChangeLogicQuestion(e, outerIndex, innerIndex)}>
                                                                                {this.getConditionableQuestions()}
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            {
                                                                                // We need to get the question type, and then get the values
                                                                                this.getConditionQuestionOptions(type, options, singleCondition, outerIndex, innerIndex)
                                                                            }
                                                                            <div className="text-right mt-2">
                                                                                <button className="btn btn-danger btn-sm" onClick={(e) => this.handleRemoveLogic(e, outerIndex, innerIndex)}>
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {condition.length - 1 !== innerIndex && (
                                                                        <h5 className="text-muted text-center">
                                                                            <span>And</span>
                                                                        </h5>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                        <div className="text-center">
                                                            <button className="btn btn-warning" onClick={(e) => this.handleAddInnerLogic(e, outerIndex)}>
                                                                + AND Condition
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {this.state.conditions.length - 1 > outerIndex && (
                                                        <h5 className="text-muted text-center">
                                                            <span>Or</span>
                                                        </h5>
                                                    )}
                                                </div>
                                            )
                                        })}
                                        <div className="text-center">
                                            <button className="btn btn-warning" onClick={(e) => this.handleAddLogic(e)}>
                                                + Condition
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        <FraudOverrides fraudOverrides={this.state.fraudOverrides} handleChangeFraudOverride={this.handleChangeFraudOverride} />
                        <div className="text-center">
                            <button className="btn btn-primary">{this.props.eventId ? 'Save Changes' : 'Add Event'}</button>
                            {this.props.eventId && !this.state.delete && (
                                <button className="btn btn-danger ml-5" onClick={(e) => this.activateDelete(e)}>
                                    Delete
                                </button>
                            )}
                            {this.props.eventId && this.state.delete && (
                                <button className="btn btn-danger ml-5" onClick={(e) => this.handleDeleteEvent(e)}>
                                    Confirm Delete
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default AddEventModal
