import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

export default function AdminDropdown() {
    const auth = useAuth()
    const showMBDomains = useProtectedContent(auth.permissions, 'mb_domains')
    const showAffPaymentNotices = useProtectedContent(auth.permissions, 'send_aff_payment_notices')
    const showManageFlags = useProtectedContent(auth.permissions, 'manage_flags')
    const showManageAdministrators = useProtectedContent(auth.permissions, 'manage_administrators')
    const showManagePermissions = useProtectedContent(auth.permissions, 'manage_permissions')
    const showPayoutInstructions = useProtectedContent(auth.permissions, 'payout_instructions')
    const showAffiliateTips = useProtectedContent(auth.permissions, 'affiliate_tips')
    const showAffiliateHelp = useProtectedContent(auth.permissions, 'affiliate_help')
    const showTwofasetup = useProtectedContent(auth.permissions, 'twofasetup')
    const showBatchTransferAffiliates = useProtectedContent(auth.permissions, 'affiliate_batch_transfer_am')
    const showManageOutOfOffice = useProtectedContent(auth.permissions, 'manage_out_of_office')
    const showStaffDirectory = useProtectedContent(auth.permissions, 'staff_directory_report')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right">
                Admin
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                {showManageAdministrators && (
                    <>
                        <Link className="dropdown-item" to="/admin/managers" onClick={toggle}>
                            Manage Administrators
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                {showStaffDirectory && (
                    <>
                        <Link className="dropdown-item" to="/admin/staffDirectory" onClick={toggle}>
                            Staff Directory
                        </Link>
                    </>
                )}
                {showManagePermissions && (
                    <>
                        <Link className="dropdown-item" to="/authSettings" onClick={toggle}>
                            Permission Settings
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                {showBatchTransferAffiliates && (
                    <>
                        <Link className="dropdown-item" to="/affiliates/batchTransfer" onClick={toggle}>
                            Batch Transfer Affiliates
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )
                }
                {
                    showManageOutOfOffice && (
                        <>
                            <Link className="dropdown-item" to="/admin/outofoffice" onClick={toggle}>
                                Manage Out Of Office
                            </Link>
                            <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        </>
                    )
                }
                <Link className="dropdown-item" to="/vacation" onClick={toggle}>
                    Vacation
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/holdPaymentAnalyzer" onClick={toggle}>
                    Hold Payment Analyzer
                </Link>
                {showManageFlags && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/admin/flags" onClick={toggle}>
                            Manage Flags
                        </Link>
                    </>
                )}
                {showAffiliateHelp && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/admin/helptext" onClick={toggle}>
                            Affiliate Help Text
                        </Link>
                    </>
                )}
                {showAffiliateTips && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/admin/affiliateTips" onClick={toggle}>
                            Affiliate Tips
                        </Link>
                    </>
                )}
                {showMBDomains && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/admin/domains" onClick={toggle}>
                            Tracking Domains
                        </Link>
                    </>
                )}
                {showPayoutInstructions && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/admin/payoutInstructions" onClick={toggle}>
                            Payout Instructions
                        </Link>
                    </>
                )}
                {showAffPaymentNotices && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/paymentNotices" onClick={toggle}>
                            Send Affiliate Payment Notices
                        </Link>
                    </>
                )}
                {showTwofasetup && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/admin/twofasetup" onClick={toggle}>
                            2FA Setup
                        </Link>
                    </>
                )}                
            </DropdownMenu>
        </Dropdown>
    )
}
