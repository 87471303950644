import React from 'react'

const Priority = ({ priority }) => {
    let classes = 'badge badge-secondary'

    switch (priority) {
        case 'high' || 'medium':
            classes = 'badge badge-warning'
            break
        default:
            classes = 'badge badge-secondary'
            break
    }

    return <div className={classes}>{priority}</div>
}

export default Priority
