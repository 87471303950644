import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

const AvatarDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right">
                Avatar
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                <Link className="dropdown-item" to="/avatar/dashboard" onClick={toggle}>
                    Dashboard
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/avatar/propertyManager" onClick={toggle}>
                    Property Manager
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/avatar/siteManager" onClick={toggle}>
                    Site Design Manager
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/avatar/surveyManager" onClick={toggle}>
                    Survey Manager
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/avatar/reports/property" onClick={toggle}>
                    Property Report
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/avatar/reports/affiliate" onClick={toggle}>
                    Affiliate Report
                </Link>
            </DropdownMenu>
        </Dropdown>
    )
}

export default AvatarDropdown
