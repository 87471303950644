import { Card, Button, Table } from 'react-bootstrap'
import { useState } from 'react'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import moment from 'moment'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'

const OTP = ({ affiliateId, showSystemMessage }) => {
    const { dataLoaded, data } = useCallApi(`affiliate/${affiliateId}/otpTokens`, 'GET', {}, [affiliateId])
    document.title = `${dataLoaded ? `${data.affiliate.name}: ` : ''}OTP Tokens`
    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">OTP Tokens</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <Card>
                        <Card.Body className="p-0">
                            <div className="d-flex p-3">
                                <div className="mr-4 d-flex align-items-center">
                                    Last 10 token requests for:
                                    <h1 className="mb-h1-dark ml-2">{dataLoaded ? <Affiliate aid={data.affiliate.affiliate_id} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : null}</h1>
                                </div>
                            </div>
                            <Table striped hover className="m-0">
                                <thead>
                                    <tr>
                                        <th>Token</th>
                                        <th>Reason</th>
                                        <th>Expiry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded ? (
                                        <>
                                            {data.requests.length === 0 ? (
                                                <tr>
                                                    <td colSpan={5}>No data.</td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {data.requests.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.token}</td>
                                                                <td>{item.reason}</td>
                                                                <td>{moment(item.expiry).format('YYYY/MM/DD')}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={5} align="center">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default OTP
