import React, { useState, useEffect } from 'react'
import { Modal } from 'reactstrap'
import SiteDropdown from '../../../Templates/SiteDropdown/SiteDropdown'
import SurveyDropdown from '../../../Templates/SurveyDropdown/SurveyDropdown'

export default function PropertySetOverrideModal({ propertyId, modalOpen, setModalOpen, refresh, setRefresh, showSystemMessage }) {
    const [overrideSiteId, setOverrideSiteId] = useState(0)
    const [overrideSurveyId, setOverrideSurveyId] = useState(0)
    const [affiliateId, setAffiliateId] = useState(0)
    const [submitting, setSubmitting] = useState(false)

    const saveOverride = () => {
        setSubmitting(true)

        const authtoken = localStorage.getItem('adm-auth-token')

        fetch(`${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/overrides`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken,
            },
            body: JSON.stringify({
                affiliate_id: affiliateId,
                override_site_id: overrideSiteId,
                override_survey_id: overrideSurveyId,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Override Saved')
                    setSubmitting(false)
                    setRefresh(!refresh)
                    setModalOpen(false)
                } else if (result['success'] === false) {
                    setSubmitting(false)
                    showSystemMessage('error', result.errors.message)
                } else {
                    setSubmitting(false)
                    showSystemMessage('error', 'Error fetching site data.')
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Site/Set Overrides: ', error.message)
                setSubmitting(false)
                showSystemMessage('error', 'Connection error.')
            })
    }

    return (
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} className="modal-sm">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h1 className="mb-h1-dark">Add Override</h1>
                    <button type="button" className="close" onClick={() => setModalOpen(false)}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="card-body">
                    <label htmlFor="site-dropdown">Affiliate ID:</label>
                    <input type="text" className="form-control mb-2" onChange={(e) => setAffiliateId(e.target.value)}></input>

                    <label htmlFor="site-dropdown">Site:</label>
                    <SiteDropdown handleSiteChange={(e) => setOverrideSiteId(parseInt(e.target.value))} />

                    <label htmlFor="site-dropdown" className="mt-2">
                        Survey:
                    </label>
                    <SurveyDropdown handleSurveyChange={(e) => setOverrideSurveyId(parseInt(e.target.value))} />
                    <div className="text-right mt-3">
                        {submitting ? (
                            <button className="btn btn-primary btn-sm" disabled>
                                Save
                            </button>
                        ) : (
                            <button className="btn btn-primary btn-sm" onClick={saveOverride}>
                                Save
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
