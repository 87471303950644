import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Campaign from '../../../templates/Campaign/Campaign'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import EditCampaignPhase from '../../Dashboard/components/CampaignsInSetup/EditCampaignPhase/EditCampaignPhase'

const WorksheetReport = ({ showSystemMessage }) => {

    document.title = 'Campaign Worksheet Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_worksheet_report')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [selectedCampaignid, setSelectedCampaignId] = useState(0)
    const [showCampaignPhaseModal, setShowCampaignPhaseModal] = useState(false)

    useEffect(() => {
        getReport()
    }, [])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'offer_id',
            },
            {
                Header: 'Campaign',
                accessor: 'offer_name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].offer_name} />
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'merchant_name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].merchant_id} name={tableData.data[tableData.row.index].merchant_name} />
                },
            },
            {
                Header: 'Advertiser Manager',
                accessor: 'full_name',
            },
            {
                Header: 'Started',
                accessor: 'db_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}</>
                },
            },
            {
                Header: 'Phase',
                accessor: 'phase_name',
                Cell: (tableData) => {
                    return (
                        <Button variant="primary" size="sm" onClick={() => {
                            setSelectedCampaignId(tableData.data[tableData.row.index].offer_id)
                            setShowCampaignPhaseModal(true)
                        }}>
                            {tableData.data[tableData.row.index].phase_name}
                        </Button>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = 'campaigns/worksheetReport'

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.data)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }

                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(`Error while fetching top campaigns: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching top campaigns')
            })
    }

    return (
        <div>
            <Modal show={showCampaignPhaseModal} onHide={() => setShowCampaignPhaseModal(false)}>
                <EditCampaignPhase
                    handleClose={() => setShowCampaignPhaseModal(false)}
                    selectedCampaignId={selectedCampaignid}
                    showSystemMessage={showSystemMessage}
                />
            </Modal>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Campaign Worksheet Report</h1>
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default WorksheetReport
