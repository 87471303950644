import { Line } from 'react-chartjs-2'
import { useState, useEffect } from 'react'

const Graph = ({ data, dollars }) => {
    const [graphData, setGraphData] = useState({
        labels: [1, 2, 3, 4, 5],
        datasets: [
            {
                label: 'first dataset',
                data: [1, 2, 3, 4, 5],
                fill: true,
                backgroundColor: data.bgColor,
                borderColor: data.borderColor,
            },
        ],
    })

    const options = {
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        display: true,
                        autoSkip: true,                        
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if (dollars){
                                return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)
                            } else {
                                return Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)
                            }                            
                        }
                    },
                },
            ]
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
            },
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    if (dollars){
                        return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tooltipItem.yLabel)
                    } else {
                        return Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tooltipItem.yLabel)
                    }  
                }
            }
        }
    }

    useEffect(() => {}, [data])

    return <Line data={data} options={options} />
}
export default Graph
