export const colors = {
    success: '#5cb85c',
    greenHighlight: '#8FFFA9',
    yellowHighlight: '#FFE18F',
    purpleHighlight: '#ffd7f4',
    applications: '#FFE9F9',
    transfers: '#CCF6FF',
    orange: '#F8A82A',
    danger: '#dc3545',
    blueHighlight: '#b9e2ff',
    greenHighlight: '#8fffa9',
    yellowHighlight: '#ffe18f',
    purpleHighlight: '#ffd7f4',
    redHighlight: '#e94f58',
    applications: '#ffe9f9',
    transfers: '#ccf6ff',
}

export const breakpoints = {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
}
