import React, { useState, useEffect } from 'react'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function AvatarSummaryStats({ showSystemMessage }) {
    const { data, dataLoaded } = useCallApi(`avatar/dashboardStats`, 'GET', {}, [], showSystemMessage)

    return (
        <div className="card mb-2" style={{ height: '146px' }}>
            <div className="card-header d-flex justify-content-between">
                <div>Avatar Stats</div>
                <Link to={`/avatar/reports/property`}>Reports</Link>
            </div>
            <div>
                {dataLoaded ? (
                    <table className="table table-card table-hover mb-0">
                        <thead>
                            <tr>
                                <th scope="col" className="border-top-0">
                                    Stats
                                </th>
                                <th scope="col" className="border-top-0" style={{ textAlign: 'right' }}>
                                    Margin
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Today</td>
                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.today)}</td>
                            </tr>
                            <tr>
                                <td>Yesterday</td>
                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.yesterday)}</td>
                            </tr>
                            <tr>
                                <td>This Month</td>
                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.thisMonth)}</td>
                            </tr>
                            <tr>
                                <td>Last Month</td>
                                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.lastMonth)}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </div>
    )
}
