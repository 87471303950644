import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Dollars from '../../../../templates/Dollars/Dollars'

export default function MarketingCampaignSummary(showSystemMessage) {
    const { data, dataLoaded, refetch } = useCallApi('marketing/campaigns', 'GET', {}, [], showSystemMessage)

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Active Marketing Campaigns</div>
                <Link to={'/marketing/campaigns'}>Manage</Link>
            </div>
            <div className="table-container-scroll">
                {dataLoaded ? (
                    <table className="table hover striped mb-0 pb-0" style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Campaign</th>
                                <th className="text-right">Signups</th>
                                <th className="text-right">Margin</th>
                            </tr>
                        </thead>
                        <tbody className="m-0">
                            {data.marketing_campaigns.active.length > 0 ? (
                                data.marketing_campaigns.active.map((campaign) => {
                                    return (
                                        <tr>
                                            <td>{campaign.id}</td>
                                            <td>{campaign.name}</td>
                                            <td className="text-right">{Intl.NumberFormat('en-GB').format(campaign.signups)}</td>
                                            <td className="text-right">
                                                <Dollars value={campaign.margin} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>No records.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}
