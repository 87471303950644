import getTrafficType from '../../../helpers/getCreativeType'
import Number from '../../../templates/Number/Number'

const ByTrafficTypes = ({ trafficTypes }) => {
    const getTraffic = (type) => {
        let trafficCode = `Unknown`

        if (type.indexOf('-') > -1) {
            let array = type.split('-')
            trafficCode = getTrafficType(array[0])
        } else {
            if (type !== '') trafficCode = type
        }
        return trafficCode
    }

    return (
        <div>
            <div className="card mb-2">
                <div className="align-items-center p-2 card-header">
                    <div className="font-weight-bold float-left">By Traffic Type</div>
                </div>
                <div className="card-body p-2">
                    <table className="table table-card table-striped table-borderless table-hover">
                        <thead className="border-bottom">
                            <tr>
                                <th scope="col">Traffic Types</th>
                                <th scope="col" className="text-right">
                                    Leads
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {trafficTypes.length === 0 ? (
                                <tr>
                                    <td colSpan={2}>No stats available</td>
                                </tr>
                            ) : (
                                trafficTypes.map((item, index) => (
                                    <tr key={`${index}-${item.traffic_type}`}>
                                        <td>{getTraffic(item.traffic_type)}</td>
                                        <td align="right"><Number value={item.traffic_count} /></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ByTrafficTypes
