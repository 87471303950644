import React from 'react'
import ReactTooltip from 'react-tooltip'

const Notes = ({ affiliate, setIsDisabled, notes }) => {
    return (
        <div className="card">
            <ReactTooltip />
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Notes</div>
            </div>
            <div className="card-body p-0">
                <table className="table m-0" width="100%">
                    <tbody>
                        <tr>
                            <td className="border-right text-center">
                                <textarea name="notes" className="form-control" style={{ height: '300px' }} defaultValue={notes !== undefined ? notes : affiliate.account.notes} onChange={() => setIsDisabled(false)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Notes
