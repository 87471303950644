import React, { useState, useEffect } from 'react'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import PriorityPicker from '../../../components/PriorityPicker/PriorityPicker'
import CustomFlags from '../../../components/CustomFlags/CustomFlags'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import AdvertiserSearch from '../../../components/AdvertiserSearch/AdvertiserSearch'
import MarketingPlatformDropdown from '../../Marketing/Components/MarketingPlatformDropdown/MarketingPlatformDropdown'
import MarketingCampaignDropdown from '../../Marketing/Components/MarketingCampaignDropdown/MarketingCampaignDropdown'
import MaxMoneyPromoDropdown from '../../../components/MaxMoneyPromoDropdown/MaxMoneyPromoDropdown'
import Mailer from '../../../components/Mailer/Mailer'
import { Modal } from 'react-bootstrap'
import MailerRecipientList from '../../../components/Mailer/MailerRecipientList'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'

export default function AffiliateMailer({ showSystemMessage }) {
    document.title = 'Affiliate Mailer'

    const auth = useAuth()
    const showSenders = useProtectedContent(auth.permissions, 'mailer_sender_unrestricted')

    // Filters
    const [emailPurpose, setEmailPurpose] = useState('general')
    const [affiliatePriority, setAffiliatePriority] = useState({ code: 0 })
    const [affiliateManager, setAffiliateManager] = useState(['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEMLEAD'].includes(auth.user.role) ? auth.user.id : {})
    const [flag, setFlag] = useState(false)
    const [promotionType, setPromotionType] = useState('campaign')
    const [promotionItem, setPromotionItem] = useState(false)
    const [promotionOption, setPromotionOption] = useState([])
    const [marketingCampaign, setMarketingCampaign] = useState('')
    const [marketingPlatform, setMarketingPlatform] = useState('')
    const [incentive, setIncentive] = useState(false)
    const [network, setNetwork] = useState(false)
    const [maxmoneyPromoId, setMaxmoneyPromoId] = useState(false)
    const [keywords, setKeywords] = useState('')
    const [viewedCampaign, setViewedCampaign] = useState(false)
    const [pitchedCampaign, setPitchedCampaign] = useState(false)
    const [excludedAffiliates, setExcludedAffiliates] = useState('')
    const [showModal, setShowModal] = useState(false)

    const handlePreview = async () => {
        setShowModal(true)
    }

    const editPromotionOption = (value) => {
        let newArr = [...promotionOption]

        if (newArr.includes(value)) {
            newArr.splice(promotionOption.indexOf(value), 1)
        } else {
            newArr.push(value)
        }

        if (value === 'A') {
            if (promotionOption.indexOf('N') >= 0) {
                newArr.splice(promotionOption.indexOf('N'), 1)
            }
        } else if (value === 'N') {
            if (promotionOption.indexOf('A') >= 0) {
                newArr.splice(promotionOption.indexOf('A'), 1)
            }
        }

        setPromotionOption(newArr)
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Mailer</h1>
            </div>
            <div className="row">
                <div className="col-xl-3">
                    <div className="card mb-3">
                        <div className="p-2 d-flex justify-content-between align-items-center font-weight-bold border-bottom">
                            Recipient Filters
                            <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
                                <MailerRecipientList filters={{ emailPurpose, affiliatePriority, affiliateManager, flag, promotionType, promotionItem, promotionOption, marketingCampaign, marketingPlatform, incentive, network, maxmoneyPromoId, keywords, viewedCampaign, pitchedCampaign, excludedAffiliates }} showSystemMessage={showSystemMessage} />
                            </Modal>
                        </div>
                        <table className="table table-striped table-card m-0">
                            <tbody>
                                <tr>
                                    <td className="border-top-0">Communication Preferences</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select
                                                className="form-control form-control-sm mr-5"
                                                name="purpose"
                                                onChange={(e) => {
                                                    setEmailPurpose(e.target.value)
                                                }}
                                            >
                                                <option value="general">General Mailing</option>
                                                <option value="offer">Campaign Change Notice</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-top-0">Affiliate Priority</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <PriorityPicker selectedPriority={affiliatePriority} setSelectedPriority={setAffiliatePriority} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Affiliate Manager</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            {
                                                ['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEMLEAD'].includes(auth.user.role) ?
                                                    <div>{auth.user ? auth.user.name : 'You'}</div>

                                                    :
                                                    <AdminPicker adminId={affiliateManager} setAdminId={setAffiliateManager} role={['AM_JUNIOR', 'AM_SENIOR']} />
                                            }

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>With Flag</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select
                                                className="form-control form-control-sm"
                                                defaultValue={flag ? flag : ''}
                                                onChange={(e) => {
                                                    setFlag(e.target.value)
                                                }}
                                            >
                                                <option value=""></option>
                                                <CustomFlags />
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Affiliate Promotion History</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select
                                                className="form-control form-control-sm mb-2"
                                                onChange={(e) => {
                                                    setPromotionType(e.target.value)
                                                    setPromotionItem(false)
                                                    setPromotionOption([])
                                                }}
                                            >
                                                <option value="campaign">Campaign</option>
                                                <option value="advertiser">Advertiser</option>
                                            </select>
                                            {promotionType === 'campaign' ? (
                                                <div>
                                                    <div className="mb-2">
                                                        <CampaignSearch campaign={promotionItem ? promotionItem : false} setCampaign={setPromotionItem} />
                                                    </div>
                                                    <div className="alert alert-info mb-2">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id="promoSwitch1" onClick={() => editPromotionOption('A')} checked={promotionOption.includes('A')} />
                                                            <label className="custom-control-label" for="promoSwitch1">
                                                                Affiliates who HAVE promoted this campaign in the past 60 days
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="alert alert-info mb-2">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id="promoSwitch2" onClick={() => editPromotionOption('N')} checked={promotionOption.includes('N')} />
                                                            <label className="custom-control-label" for="promoSwitch2">
                                                                Affiliates who have NOT promoted this campaign in the past 60 days
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="alert alert-info mb-2">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id="promoSwitch3" onClick={() => editPromotionOption('V')} checked={promotionOption.includes('V')} />
                                                            <label className="custom-control-label" for="promoSwitch3">
                                                                Affiliates who have LOOKED at this campaign in the past 60 days but NOT promoted it
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="mb-2">
                                                        <AdvertiserSearch advertiser={promotionItem ? promotionItem : false} setAdvertiser={setPromotionItem} />
                                                    </div>
                                                    <div className="alert alert-info mb-2">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id="promoSwitch1" onClick={() => editPromotionOption('A')} checked={promotionOption.includes('A')} />
                                                            <label className="custom-control-label" for="promoSwitch1">
                                                                Affiliates who HAVE promoted at least one of this advertiser's campaigns in the past 60 days
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="alert alert-info mb-2">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id="promoSwitch2" onClick={() => editPromotionOption('N')} checked={promotionOption.includes('N')} />
                                                            <label className="custom-control-label" for="promoSwitch2">
                                                                Affiliates who have NOT promoted any of this advertiser's campaigns in the past 60 days
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Registered From Marketing Campaign</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <div className="mb-2">
                                                <MarketingCampaignDropdown setMarketingCampaignId={setMarketingCampaign} />
                                            </div>
                                            <div>
                                                <MarketingPlatformDropdown setMarketingPlatformId={setMarketingPlatform} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Incentive</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select
                                                className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    setIncentive(e.target.value)
                                                }}
                                            >
                                                <option value=""></option>
                                                <option value="N">Non-Incentive Only</option>
                                                <option value="Y">Incentive Only</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Network</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select
                                                className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    setNetwork(e.target.value)
                                                }}
                                            >
                                                <option value=""></option>
                                                <option value="N">Non-Network Only</option>
                                                <option value="Y">Network Only</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Registered to MaxMoney Promo</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <MaxMoneyPromoDropdown promoId={maxmoneyPromoId} setPromoId={setMaxmoneyPromoId} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Keywords in Affiliate's Search History</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={(e) => {
                                                    setKeywords(e.target.value)
                                                }}
                                            ></input>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pitched Campaign (30D)</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <CampaignSearch campaign={pitchedCampaign ? pitchedCampaign : false} setCampaign={setPitchedCampaign} order={1} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Affiliate ID's to exclude</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                placeholder="Separate with commas. Ex: 123456, 98765"
                                                onChange={(e) => {
                                                    setExcludedAffiliates(e.target.value)
                                                }}
                                            ></input>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <div className="p-2">
                                            <button className="btn btn-warning" onClick={handlePreview}>
                                                Preview Recipients
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-9">
                    <Mailer type={'affiliate'} filters={{ emailPurpose, affiliatePriority, affiliateManager, flag, promotionType, promotionItem, promotionOption, marketingCampaign, marketingPlatform, incentive, network, maxmoneyPromoId, keywords, viewedCampaign, pitchedCampaign, excludedAffiliates }} showSystemMessage={showSystemMessage} />
                </div>
            </div>
        </React.Fragment>
    )
}
