import moment from 'moment'
import React, { useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import useQueryParams from '../../../hooks/useQueryParams'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'

export default function MaxMoneyAffs({ showSystemMessage, promoId, isModal, handleClose }) {

    const { adminId, setAdminId, buildUrl } = useQueryParams()
    const history = useHistory()
    const location = useLocation()

    // const [employee, setEmployee] = useState(mgrId ? mgrId : 0)
    const { data, setData, dataLoaded, refetch } = useCallApi(`maxmoney/${promoId}/affiliates${adminId > 0 ? `?mgrId=${adminId}` : ''}`, 'GET', {}, [location], showSystemMessage)

    document.title = 'MaxMoney Affiliates'

    useEffect(() => {
        // window.history.pushState({}, '', `/maxmoney/${promoId}/affiliates${employee ? `?mgrid=${employee.id}&mgrname=${employee.name}` : ''}`)
    }, [adminId])

    const updateAffiliateStatus = async (affiliateId, status, index) => {
        const response = await callAPI(`maxmoney/${promoId}/affiliates/${affiliateId}`, 'PUT', { status })
        if (response.success) {
            showSystemMessage('success', 'Affiliate status updated successfully')
            let temp = data.report
            temp[index].status = status
            setData({ ...data, report: temp })
        } else {
            showSystemMessage('error', response.errors.message)
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].affiliate_status} priority={tableData.data[tableData.row.index].aff_priority} />
                }
            },
            {
                Header: 'Registration Date',
                accessor: 'start_date',
                Cell: ({ value }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: 'End Date',
                accessor: 'end_date',
                Cell: ({ value }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (tableData) => {
                    return <Form.Control as="select" value={tableData.data[tableData.row.index].status} onChange={(e) => updateAffiliateStatus(tableData.data[tableData.row.index].affiliate_id, e.target.value, tableData.row.index)}>
                        <option value="A">Active</option>
                        <option value="D">Disabled</option>
                    </Form.Control>
                }
            }
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.report} columns={columns} />
            </React.Fragment>
        )
    }

    const refreshReport = (e) => {
        e.preventDefault()
        if (isModal) {
            refetch()
            return
        }

        history.push(buildUrl(`/maxmoney/${promoId}/affiliates?z=1`))
    }

    return (
        <div className='m-0'>
            {!isModal && <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">MaxMoney Affiliates</h1>
            </div>}

            <Card className="m-0 overflow-auto">
                <Card.Header>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div>
                                Affiliate Registrations for:
                            </div>
                            <div className="mx-3 w-25">
                                <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JR_TEAMLEAD', 'AM_SENIOR']} />
                            </div>
                            <div className='ml-5'>
                                <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                    Refresh Report
                                </button>
                            </div>
                        </div>
                        {isModal &&
                            <button className='btn btn-lg font-weight-bold' onClick={handleClose}>X</button>
                        }
                    </div>
                </Card.Header>

                <div className='p-3'>
                    {dataLoaded && <>
                        <div><span className='font-weight-bold'>Promo Type:</span> {data.reportInfo.promo_type}</div>
                        <div> <span className='font-weight-bold'>Period:</span> {moment(data.reportInfo.start_date).utc().format('YYYY-MM-DD')} to {moment(data.reportInfo.end_date).utc().format('YYYY-MM-DD')}</div>
                        <div><span className='font-weight-bold'>Total Registrations:</span> {data.affiliate_count}</div></>}
                </div>

                {dataLoaded ? data.report.length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
            </Card>
        </div>
    )
}
