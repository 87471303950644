import React, { Component } from 'react'

class QuestionMapItem extends Component {
    state = {
        open: false,
    }

    setOpen = () => {
        this.setState({
            open: !this.state.open,
        })
    }

    render() {
        const { question, fieldName, field, choices } = this.props

        return (
            <React.Fragment>
                <tr>
                    <td valign="middle">
                        Q{question.order}: {question.question} {field && `: ${field}`}
                    </td>
                    <td>
                        <input className="form-control m-0" type="text" defaultValue={fieldName} onChange={(e) => this.props.handleChangeQuestionMap(e, question.questionId, field ? field : '')} />
                    </td>
                    <td>
                        {question.options.length > 0 && (
                            <span className="badge badge-primary" style={{ cursor: 'pointer' }} onClick={() => this.setOpen()}>
                                {this.state.open ? '-' : '+'}
                            </span>
                        )}
                    </td>
                </tr>
                {/* Does this question have options? If it does, we will need value mappings*/}
                {question.options.length > 0 &&
                    this.state.open &&
                    question.options.map((option, index) => {
                        return (
                            <tr key={`${option.value}-${index}`}>
                                <td valign="middle">
                                    <span className="text-secondary ml-5">{option.value}</span>
                                </td>
                                <td align="right">
                                    <input className="form-control text-secondary w-50" type="text" defaultValue={choices.find((choice) => choice.optionId === option.optionId) ? choices.find((choice) => choice.optionId === option.optionId).value : ''} onChange={(e) => this.props.handleChangeQuestionMapChoice(e, question.questionId, option.optionId ? option.optionId : 0)} />
                                </td>
                                <td></td>
                            </tr>
                        )
                    })}
            </React.Fragment>
        )
    }
}

export default QuestionMapItem
