import React, { useState, useEffect, useContext } from 'react';
import { SystemMessageContext } from '../../../../context/SystemMessageContext';

export default function AdvertiserApprovalInfo({advertiserId, approvalInfo, setIsDisabled}){
    
    const { showSystemMessage } = useContext(SystemMessageContext)

    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Advertiser Approval Info</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>                        
                        <tr>                           
                            <td className="border-right text-right">Approval Requirements</td>
                            <td className="border-right">
                                <div className="mr-2">
                                    <select name="approval_requirements" className="form-control" defaultValue={approvalInfo.placement_approval_requirement} onChange={() => setIsDisabled(false)} required>
                                        <option value="NOT_REQUIRED">Placement not needed</option>
                                        <option value="ADVERTISER_REQUIRED">Placement needed with advertiser approval</option>
                                        <option value="MB_REQUIRED">Placement needed with approval at our discretion</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="20%" className="border-right text-right">
                                Other requirements
                            </td>
                            <td className="border-right" colSpan={3}>
                                <textarea name="approval_notes" cols={80} rows={8} className="form-control" defaultValue={approvalInfo.custom_approval_notes} onChange={() => setIsDisabled(false)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}