import React from 'react'
import { Mail, MessageCircle, Smartphone } from 'react-feather'
import { FaSkype, FaExternalLinkAlt } from 'react-icons/fa'
import convertUrl from '../../../../helpers/convertUrl'

const AffiliateContactInfo = (props) => {
    let imType = ''

    if (props.imType === 'A' || props.imType === 'M') {
        imType = <><MessageCircle size={14} fill="black" /> &nbsp;{props.imHandle ? props.imHandle : 'N/A'}</>
    } else if (props.imType === 'S') {
        imType = <><FaSkype size={18} color="#007bff" />
            &nbsp;
            {props.imHandle ? <a href={`skype:${props.imHandle}?chat`}>{props.imHandle}</a> : 'N/A'}</>
    } else if (props.imType === 'Y') {
        imType = <>
            <svg xmlns="http://www.w3.org/2000/svg" height="1.25em" viewBox="0 0 512 512" color='#4A00A0'><path d="M223.69,141.06,167,284.23,111,141.06H14.93L120.76,390.19,82.19,480h94.17L317.27,141.06Zm105.4,135.79a58.22,58.22,0,1,0,58.22,58.22A58.22,58.22,0,0,0,329.09,276.85ZM394.65,32l-93,223.47H406.44L499.07,32Z" /></svg>
            &nbsp;
            {props.imHandle ? props.imHandle : 'N/A'}</>
    } else if (props.imType === 'N') {
        imType = <><MessageCircle size={14} fill="black" /> &nbsp;{props.imHandle ? props.imHandle : 'N/A'}</>
    }

    let mailToLink = `mailto:${props.email}`

    if (props.additionalEmails && props.additionalEmails.length > 0) {
        mailToLink += `?cc=${props.additionalEmails.replaceAll(',', ';')}`
    }

    return (
        <div className="d-flex align-items-center justify-content-lg-between flex-wrap p-2">
            <div className="px-1">
                {imType}
            </div>
            <div className="px-1">
                <Smartphone size={16} />
                {props.mobile ? props.mobile : 'N/A'}
            </div>
            <div className="px-1">
                <a href={mailToLink}>
                    <Mail size={20} style={{ paddingRight: '5px' }} />
                    Email
                </a>
            </div>
            <div className="px-1">
                <a href={`${convertUrl(props.url)}`} rel="noopener noreferrer" target="_blank">
                    Visit Website &nbsp;
                    <FaExternalLinkAlt color="#007bff" />
                </a>
            </div>
        </div>
    )
}

export default AffiliateContactInfo
