import { useState } from 'react'

export default function useDownloadCSV() {
    const [CSVFilename, setCSVFilename] = useState('')
    const [CSVHeaderLabels, setCSVHeaderLabels] = useState([])
    const [CSVDataLabels, setCSVDataLabels] = useState([])
    const [CSVReport, setCSVReport] = useState([])

    return { CSVFilename, setCSVFilename, CSVHeaderLabels, setCSVHeaderLabels, CSVDataLabels, setCSVDataLabels, CSVReport, setCSVReport }
}
