import React, { useEffect, useState } from 'react'
import useDateRangeFilter from '../../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../../hooks/useQueryParams'

import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import Dollars from '../../../../templates/Dollars/Dollars'
import Number from '../../../../templates/Number/Number'
import ReportTable from '../../../../templates/ReportTable/ReportTable'
import Loading from '../../../../templates/UI/Loading/Loading'
import ReportsStatsDropdown from '../../Templates/ReportsStatsDropdown/ReportsStatsDropdown'

export default function AdvertiserReport({ showSystemMessage, setAffiliate, setCampaign, setAdvertiser, setCSVFilename, setCSVHeaderLabels, setCSVDataLabels, setCSVReport }) {
    const { initStartDate, initEndDate } = useDateRangeFilter()
    const { affiliateId, withAvatar, navigation, buildUrl } = useQueryParams()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    useEffect(() => {
        setCSVHeaderLabels(['Advertiser Id', 'Advertiser', 'Clicks', 'Conversions', 'Conversion Ratio', 'Net Earnings', 'Aff Earnings', 'Sales', 'Margin', 'NPM', 'Contribution', 'EPC', 'nEPC'])
        setCSVDataLabels(['advertiser_id', 'advertiser_name', 'clicks', 'conversions', 'conversions', 'network_earnings', 'affiliate_earnings', 'sales', 'margin', 'margin_ratio', 'contribution', 'epc', 'nepc'])
        getReport()
    }, [])

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`reports/advertisers?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    result.affiliate && setAffiliate(result.affiliate)
                    setReport(result.report)
                    setTotals(result.totals)
                    setCSVReport(result.report)
                    setCSVFilename(`Advertiser Report - ${initStartDate}-${initEndDate}`)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching advertiser report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching advertiser report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Advertiser Id',
                accessor: 'advertiser_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser_name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].advertiser_id} name={tableData.data[tableData.row.index].advertiser_name} status={tableData.data[tableData.row.index].advertiser.status.value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                accessor: 'clicks',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={buildUrl(`/reports/stats/daily?startDate=${initStartDate}&endDate=${initEndDate}&advertiserId=${tableData.data[tableData.row.index].advertiser_id}`)}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}`}</Link>
                }
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                accessor: 'conversions',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={buildUrl(`/reports/stats/conversions?startDate=${initStartDate}&endDate=${initEndDate}&advertiserId=${tableData.data[tableData.row.index].advertiser_id}`)}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}`}</Link>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>CR %</div>,
                accessor: 'conversion_ratio',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{!isNaN(value) ? <Number value={value} decimals={2} /> : 0} %</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Net Earnings</div>,
                accessor: 'network_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Aff Earnings</div>,
                accessor: 'affiliate_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sales',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>NPM</div>,
                accessor: 'margin_ratio',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <><Number value={value * 100} decimals={2} />%</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Contribution</div>,
                accessor: 'contribution',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <><Number value={value * 100} decimals={2} />%</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>EPC</div>,
                accessor: 'epc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>nEPC</div>,
                accessor: 'nepc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: ' ',
                className: 'text-right',
                Cell: (tableData) => {
                    return <ReportsStatsDropdown reportType="Advertisers" advertiserId={tableData.data[tableData.row.index].advertiser_id} affiliateId={affiliateId} withAvatar={withAvatar} navigation={navigation} showSystemMessage={showSystemMessage} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    return <div>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
}
