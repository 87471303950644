import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import Loading from '../../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom'
import useCallApi from '../../../../hooks/useCallApi'

const DashboardSummary = ({ showSystemMessage }) => {
    const { data, dataLoaded } = useCallApi(`avatar/dashboardStats`, 'GET', {}, [])

    return (
        <div className="col-md-6">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h1 className="mb-h1-dark">Summary</h1>
                    <Link className="btn btn-primary btn-sm" to="reports/property">
                        Detailed Reports
                    </Link>
                </div>
                <div className="card-body">
                    {dataLoaded ? (
                        <React.Fragment>
                            <div className="text-center font-weight-bold">
                                <u>30 Day Margin</u>
                            </div>
                            <Line
                                data={{
                                    labels: data.dailyStatsLabels,
                                    datasets: [
                                        {
                                            label: 'Margin',
                                            data: data.dailyStats,
                                            fill: false,
                                            backgroundColor: 'rgb(255, 99, 132)',
                                            borderColor: 'rgb(255, 99, 132, 0.9)',
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    callback(value) {
                                                        // you can add your own method here (just an example)
                                                        return `$${Number(value).toLocaleString('en')}`
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        display: false,
                                    },
                                }}
                                height={75}
                            />
                            <hr></hr>
                            <div className="w-50">
                                <table className="table table-card table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Stats</th>
                                            <th scope="col" style={{ textAlign: 'right' }}>
                                                Margin
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Today</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.today)}</td>
                                        </tr>
                                        <tr>
                                            <td>Yesterday</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.yesterday)}</td>
                                        </tr>
                                        <tr>
                                            <td>This Month</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.thisMonth)}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Month</td>
                                            <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(data.lastMonth)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>
                    ) : (
                        <Loading></Loading>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DashboardSummary
