import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Card, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loading from '../../../../templates/UI/Loading/Loading'
import Pill from '../../../../templates/UI/Pill/Pill'
import { colors } from '../../../../theme/variables'

import getFollowupResult from '../../../../helpers/getFollowupResult'
import useCallApi from '../../../../hooks/useCallApi'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import '../../../../theme/classes.scss'

const ComplianceFollowups = ({ manager, mode }) => {
    let apiUrl = mode ? `compliance/followups?startDate=2003-01-01&endDate=${moment().add(1, 'day').format('YYYY-MM-DD')}&mode=${mode}` : `compliance/followups?startDate=2003-01-01&endDate=${moment().add(1, 'day').format('YYYY-MM-DD')}&adminId=${manager.id}`

    const { dataLoaded, data } = useCallApi(apiUrl, 'GET', {}, [mode])

    let queueLink = mode === 'team' || mode === 'unassigned' ? `/compliance/followups?startDate=2003-01-01&endDate=${moment().add(1, 'day').format('YYYY-MM-DD')}&mode=${mode}` : `/compliance/followups?startDate=2003-01-01&endDate=${moment().add(1, 'day').format('YYYY-MM-DD')}&adminId=${manager.id}`

    const style = (date) => {
        if (date < moment().utc().format('YYYY-MM-DD')) {
            return 'bgDanger25'
        } else {
            return ''
        }
    }

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                {!mode ? (
                    <div className="font-weight-bold" style={{ fontSize: '1.5rem' }}>
                        <span className="text-secondary">Hello </span>
                        <span className="text-dark">{manager.name.split(' ')[0]}</span> <br />
                        <span style={{ fontSize: '1rem' }}>Your Followups</span>
                    </div>
                ) : mode === 'team' ? (
                    <div>Compliance Team Followups</div>
                ) : (
                    <div>Unassigned Followups</div>
                )}

                <div className="align-items-center">
                    {!mode && (
                        <>
                            <div>&nbsp;</div> <br />
                        </>
                    )}
                    In Queue{' '}
                    <Link to={queueLink} className="ml-1">
                        {dataLoaded ? <>{!data || data.affiliates.length === 0 ? 0 : data.affiliates.length}</> : 0}
                    </Link>
                </div>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th>Priority</th>
                            <th>Next</th>
                            <th>Last</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.affiliates.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.affiliates.map((item, index) => {
                                            if (index < 15) {
                                                return (
                                                    <tr key={index} className={style(item.followups.next_followup_date)}>
                                                        <td>{item.affiliate.id}</td>
                                                        <td>
                                                            <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} status={`${item.affiliate.status.value}`} priority={`${item.affiliate.priority.value}`} />
                                                        </td>
                                                        <td>{item.affiliate.priority.name}</td>
                                                        <td>{moment(item.followups.next_followup_date).utc().format('YYYY-MM-DD')}</td>
                                                        <td xs={3}>
                                                            <OverlayTrigger
                                                                trigger={['hover', 'focus']}
                                                                placement="bottom"
                                                                overlay={
                                                                    <Popover
                                                                        id="popover-positioned-bottom"
                                                                        arrowprops={{ style: { display: 'none' } }}
                                                                        style={{
                                                                            minWidth: '300px',
                                                                            maxWidth: '500px',
                                                                        }}
                                                                    >
                                                                        <Popover.Title className="d-flex justify-content-between" style={{ fontSize: '14px' }}>
                                                                            <div>
                                                                                <div className="mr-2" style={{ width: 'max-content' }}>
                                                                                    {moment(item.followups.last_followup.followup_date).utc().format('YYYY-MM-DD HH:MM:SS')}
                                                                                </div>
                                                                                <div className="mr-2 text-muted" style={{ width: 'max-content' }}>
                                                                                    {item.followups.last_followup.admin.name}
                                                                                </div>
                                                                            </div>
                                                                            <span>
                                                                                <Pill text={getFollowupResult(item.followups.last_followup.result)} borderColor={colors.success} />
                                                                            </span>
                                                                        </Popover.Title>
                                                                        <Popover.Content>{item.followups.last_followup.note}</Popover.Content>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Link to={`/affiliate/${item.affiliate.id}`}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </Link>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default ComplianceFollowups
