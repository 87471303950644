import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import queryString from 'query-string'
import { Collapse } from 'react-bootstrap'

import SearchOptions from './SearchOptions'
import SearchResults from './SearchResults'

const SearchAdvertisers = ({ showSystemMessage }) => {
    document.title = 'Advertiser Search'

    const [open, setOpen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState()
    const [searchResults, setSearchResults] = useState([])

    const location = useLocation()
    const history = useHistory()

    let urlParams = queryString.parse(location.search)

    const [searchTerm, setSearchTerm] = useState(urlParams.searchTerm ? encodeURIComponent(urlParams.searchTerm) : '')

    const [status, setStatus] = useState(urlParams.status ? urlParams.status : '')

    const [flagId, setFlagId] = useState(urlParams.flagId ? urlParams.flagId : '')

    const [adminId, setAdminId] = useState(urlParams.adminId ? urlParams.adminId : 0)

    const buildUrl = (url) => {
        if (searchTerm !== '') {
            url += `&searchTerm=${encodeURIComponent(searchTerm)}`
        }

        if (status !== '') {
            url += `&status=${status}`
        }

        if (adminId !== 0 && adminId !== '') {
            url += `&adminId=${adminId}`
        }

        if (flagId !== '') {
            url += `&flagId=${flagId}`
        }

        return url
    }

    useEffect(() => {
        setDataLoaded(false)

        // Grab the settings from the URL Bar if location changes
        setSearchTerm(urlParams.searchTerm ? urlParams.searchTerm : searchTerm ? searchTerm : '')
        setStatus(urlParams.status ? urlParams.status : status ? status : '')
        setAdminId(urlParams.adminId && parseInt(urlParams.adminId) > 0 ? parseInt(urlParams.adminId) : parseInt(adminId) > 0 ? adminId : 0)
        setFlagId(urlParams.flagId ? urlParams.flagId : flagId ? flagId : '')

        getResults()
    }, [location])

    const getResults = () => {
        if (location.search.length > 0) {
            // Only if there are query params

            let url = buildUrl('advertisers/search?z=1')

            callAPI(url, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setSearchResults(result.search_results)
                        setDataLoaded(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                        setDataLoaded(true)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', 'An error occurred while getting custom flags')
                    // setDataLoaded(true)
                })
        } else {
            setDataLoaded(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let url = buildUrl(`/advertisers/search?z=1`)
        history.push(url)
    }

    let results

    if (dataLoaded === false) {
        results = <Loading />
    } else {
        results = <SearchResults searchResults={searchResults} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Search</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <form className="form-group mb-0" onSubmit={handleSubmit}>
                        <div className="d-flex p-3 align-items-center justify-content-center" style={{ backgroundColor: '#f4f4f4' }}>
                            <input className="form-control mr-2 w-75" type="text" name="search_term" placeholder="Keyword..." onChange={(e) => setSearchTerm(e.target.value)} autoComplete="off" defaultValue={searchTerm} />
                            <button type="submit" className="btn btn-primary mr-2">
                                Search
                            </button>
                            <button type="button" className="btn btn-link no-shadow" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
                                Search Options
                            </button>
                        </div>
                        <Collapse in={open}>
                            {/* Must wrap the component in <div> to make Collapse work*/}
                            <div>
                                <SearchOptions status={status} setStatus={setStatus} adminId={adminId} setAdminId={setAdminId} flagId={flagId} setFlagId={setFlagId} showSystemMessage={showSystemMessage} />
                            </div>
                        </Collapse>
                    </form>
                    {results}
                </div>
            </div>
        </div>
    )
}

export default SearchAdvertisers
