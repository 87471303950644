import moment from 'moment'
import React from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'

export default function PromotionsTable({ type, data, dataLoaded, refetch, setModalId, setIsCreate, setAffId, createButton }) {
    const calcPromoType = (t) => {
        switch (t) {
            case 'OFFER_SPECIFIC':
                return 'Specified offer'
            case 'NEVER_RUN':
                return 'Offer never run by affiliate'
            case 'BEAT_YOUR_BEST':
                return 'Beat your best'
            default:
                return t
        }
    }

    const calcCampaignCell = (campaigns) => {
        if (campaigns.length === 0) {
            return 'No Campaigns Found'
        }
        const cell = campaigns.map((campaign, index) => {
            return (
                <div key={index}>
                    <Campaign oid={campaign.offer_id} name={campaign.name} />
                </div>
            )
        })
        return cell
    }

    return (
        <Card className="mb-3 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center">
                {type.charAt(0).toUpperCase() + type.slice(1)} Promotions
                <Link
                    to="/maxmoney/create"
                    size="lg"
                    variant="warning"
                    onClick={(e) => {
                        e.preventDefault()
                        setIsCreate(true)
                        setModalId(1)
                    }}
                >
                    {createButton && (
                        <Button variant="warning" size="sm">
                            Create Promo
                        </Button>
                    )}
                </Link>
            </Card.Header>
            <Table striped bordered className='m-0'>
                <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th width="25%">Promo Name</th>
                        <th>Promo Type</th>
                        <th>Campaigns</th>
                        <th>Reward</th>
                        <th>Reward Bump</th>
                        <th>Net Rate Bump</th>
                        <th>Created By</th>
                        <th>Status</th>
                        <th>MM Earned</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        <>
                            {data[type].length === 0 ? <tr>
                                <td colSpan={12}>No data.</td>
                            </tr> : data[type].map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{moment.utc(item.start_date).format('YYYY/MM/DD')}</td>
                                        <td>{moment.utc(item.end_date).format('YYYY/MM/DD')}</td>
                                        <td>{item.promo_name}</td>
                                        <td>{calcPromoType(item.promo_type)}</td>
                                        <td>{calcCampaignCell(item.campaigns)}</td>
                                        <td>{item.reward_pct * 100}%</td>
                                        <td>{item.reward_pct_bump * 100}%</td>
                                        <td>{item.network_rate_bump * 100}%</td>
                                        <td>{item.full_name}</td>
                                        <td>{item.status === 'A' ? 'Active' : 'Disabled'}</td>
                                        <td>
                                            <Dollars value={parseInt(item.tot_earnings).toFixed(2) > 0 ? parseInt(item.tot_earnings).toFixed(2) : 0} />
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <Link to={`/maxmoney/${item.promo_id}/report`} className="mr-1">
                                                    Stats
                                                </Link>
                                                <a
                                                    href={`/maxmoney/${item.promo_id}/affiliates`}
                                                    type="button" className="btn btn-sm btn-warning mr-1" onClick={(e) => {
                                                        e.preventDefault()
                                                        setAffId(item.promo_id)
                                                    }} size="sm">
                                                    Aff List
                                                </a>
                                                <Link
                                                    to={`/maxmoney/${item.promo_id}/edit`}
                                                    variant="warning"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setIsCreate(false)
                                                        setModalId(item.promo_id)
                                                    }}
                                                    size="sm"
                                                    className="mr-1"
                                                >
                                                    <Button variant="warning" size="sm">
                                                        Edit
                                                    </Button>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    ) : (
                        <Loading />
                    )}
                </tbody>
            </Table>
        </Card>
    )
}
