import { useState } from 'react'
import callAPI from '../../../helpers/callAPI'

const TipaltiMissingDocs = ({ showSystemMessage }) => {

    document.title = 'Affiliates Missing Tipalti Documents'

    const [dataLoaded, setDataLoaded] = useState(false)
    const [flagCount, setFlagCount] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault()

        let finalList = []

        finalList = e.target.affiliate_list.value.split(/[\n,]+/)

        let postBody = {
            affiliate_list: finalList,
            tipalti_requirements: e.target.tipalti_requirements.value,
        }

        callAPI(`affiliates/tipaltiMissingDocs`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setFlagCount(result.flag_count)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while sending missing Tipalti notices.')
            })
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliates Missing Tipalti Documents</h1>
            </div>

            <div className="card">
                <div className="card-body p-0">
                    <form onSubmit={handleSubmit}>
                        <table className="table table-borderless table-card">
                            <tbody>
                                <tr>
                                    <td>
                                        Copy and paste the data sent by Tipalti (max 1000)
                                        <br /> <br />
                                        e.g. affiliate_id - Missing document name
                                    </td>
                                    {dataLoaded ? (
                                        <td className="text-center font-weight-bold">
                                            <h4>Results of Process</h4>
                                        </td>
                                    ) : (
                                        <td>&nbsp;</td>
                                    )}
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <textarea cols={40} rows={15} name="affiliate_list" className="form-control" required />
                                    </td>
                                    <td className="align-self-start d-flex">
                                        {dataLoaded ? (
                                            <span>
                                                A reminder email has been sent out to <b>{flagCount}</b> affiliates asking them to submit the missing documents to Tipalti.
                                                <br />
                                                <br />
                                                These affiliates will be prompted with a popup reminder to do so every time they login to the MaxBounty portal.
                                                <br />
                                                <br />
                                                Once they submit the documents, they can self-affirm in the popup and the reminder flag will be removed automatically.
                                                <br />
                                            </span>
                                        ) : (
                                            <span>
                                                Tipalti has notified MaxBounty that: <br />
                                                <select name="tipalti_requirements" className="form-control" defaultValue="aff_docs_needed">
                                                    <option value="aff_docs_needed">Additional docs are needed to complete setup</option>
                                                    <option value="aff_nonpayable">Affiliate is non-payable due to not submiting required docs</option>
                                                </select>
                                            </span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        {!dataLoaded ? (
                                            <button type="submit" className="btn btn-primary">
                                                Send reminder Email
                                            </button>
                                        ) : (
                                            <button type="submit" className="btn btn-primary" disabled>
                                                Send reminder Email
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TipaltiMissingDocs
