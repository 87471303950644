import React from 'react'

import maxBountyLogoDesktop from '../../../../assets/images/MaxBounty_Horizontal_White.png'
import maxBountyLogoMobile from '../../../../assets/images/MaxBounty_Image_White.png'

import './Logo.css'

const logo = (props) => (
    <React.Fragment>
        <div className="Logo mobile-hide">
            <img src={maxBountyLogoDesktop} alt="MaxBounty" />
        </div>
        <div className="Logo mobile-show">
            <img src={maxBountyLogoMobile} alt="MaxBounty" />
        </div>
    </React.Fragment>
)

export default logo
