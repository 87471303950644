
import CustomFlags from '../../../components/CustomFlags/CustomFlags'

import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import advertiserStatuses from '../../../data/AdvertiserStatuses'

const SearchOptions = ({ status, setStatus, adminId, setAdminId, flagId, setFlagId, showSystemMessage }) => {
    return (
        <table className="table table-card text-nowrap">
            <tbody style={{ lineHeight: '40px' }}>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Advertiser Manager &nbsp;&nbsp;&nbsp;
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />
                        </span>
                        {/* REFACTOR - could use value ='A' and so on instead of full text */}
                        <span className="d-flex align-items-center ml-3">
                            Status&nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Any</option>
                                {advertiserStatuses.advertiserStatuses.map((status) => {
                                    return <option value={`${status.value}`} key={status.value}>{status.text}</option>
                                })}
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Custom Flag&nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="flag" defaultValue={flagId} onChange={(e) => setFlagId(e.target.value)}>
                                <option value="">Select Flag</option>
                                <CustomFlags target="advertiser" showSystemMessage={showSystemMessage} />
                            </select>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default SearchOptions
