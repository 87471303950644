import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import Campaign from '../../../../templates/Campaign/Campaign'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'

const ReportSubid = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [offer, setOffer] = useState({ offerId: 0, name: '' })
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, offerId = 0, startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            if ((propertyId > 0 || siteId > 0 || surveyId > 0) && affiliateId > 0) {
                setDataLoaded(false)

                let fetchUrl = `reports/avatar/subid?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=${offerId}`
                const result = await callAPI(fetchUrl, 'GET')
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setProperty({ propertyId: propertyId, name: result.property })
                    setSite({ siteId: siteId, name: result.site })
                    setSurvey({ surveyId: surveyId, name: result.survey })
                    setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                    setOffer({ offerId: offerId, name: result.offer })
                    setReport(result.report)
                    setTotals(result.totals)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                setDataLoaded(true)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, offerId, startDate, endDate])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={report}
                    totals={totals}
                    columns={[
                        {
                            Header: 'Offer ID',
                            accessor: 'offer.id',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'OfferFilter',
                            accessor: 'offer.name',
                            hide: true,
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Offer',
                            accessor: 'offer',
                            Cell: (tableData) => {
                                return <Campaign oid={tableData.data[tableData.row.index].offer.id} name={tableData.data[tableData.row.index].offer.name} status={tableData.data[tableData.row.index].offer.status} />
                            },
                        },
                        {
                            Header: 'Property',
                            accessor: 'property',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Site',
                            accessor: 'site',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Survey',
                            accessor: 'survey',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Subid',
                            accessor: 'subid',
                            Cell: ({ cell: { value } }) => {
                                return <input className="form-control form-control-sm" disabled type="text" style={{ maxWidth: '150px' }} value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Completions/Starts</div>,
                            accessor: 'surveyStats',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                            accessor: 'clicks',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/reports/daily?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&subid1=${tableData.data[tableData.row.index].subid}`}>{Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}</Link>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                            accessor: 'conversions',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/reports/conversions?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&subid1=${tableData.data[tableData.row.index].subid}`}>{Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}</Link>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Aff leads</div>,
                            accessor: 'payableLeads',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
                            accessor: 'cost',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars cost value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Revenue</div>,
                            accessor: 'revenue',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                            accessor: 'sales',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>eCPA</div>,
                            accessor: 'eCPA',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>epc</div>,
                            accessor: 'epc',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                            accessor: 'margin',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars bold highlight value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Margin %</div>,
                            accessor: 'marginPct',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Contribution %</div>,
                            accessor: 'marginContribution',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'leadActionId',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return (
                                    <>
                                        <ReportsMoreAvatar avatarSurveyQuestionReport avatarSurveyResponseReport avatarConversionReport avatarEventReport avatarOfferReport affiliateId={affiliateId} offerId={offerId} propertyId={propertyId} siteId={siteId} surveyId={surveyId} subid1={tableData.data[tableData.row.index].subid ? tableData.data[tableData.row.index].subid : ''} startDate={startDate} endDate={endDate} />
                                    </>
                                )
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliate.affiliateId}`} forText={affiliate.name}></DrilldownItem>}
                {offer.offerId > 0 && <DrilldownItem for="Offer" forUrl={`/offer/${offer.offerId}`} forText={offer.name}></DrilldownItem>}
            </div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportSubid
