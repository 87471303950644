import React, { useState } from 'react'

import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'
import Loading from '../../../templates/UI/Loading/Loading'
import affiliatePriorities from '../../../data/AffiliatePriorities'

const ReportTransferredAffiliates = ({ showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [showReport, setShowReport] = useState(false)

    let priorities = affiliatePriorities.affiliatePriorities

    const handleSubmit = (e) => {
        e.preventDefault()

        let postBody = {
            list: e.target.list_data.value,
        }

        callAPI(`affiliates/transferred`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    setShowReport(true)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching transferred affiliates report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching transferred affiliates report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Aff ID',
                accessor: 'affiliate_id',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].affiliate.affiliate_id}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.affiliate_id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.aff_priority} />
                },
            },
            {
                Header: 'AM Name',
                accessor: 'full_name',
                Cell: (tableData) => {
                    return <span>{tableData.data[tableData.row.index].affiliate.full_name}</span>
                },
            },
            {
                Header: 'Priority',
                accessor: 'aff_priority',
                Cell: (tableData) => {
                    let value = priorities.filter((priority) => {
                        if (priority.value === tableData.data[tableData.row.index].affiliate.aff_priority) {
                            return priority.text
                        }
                    })

                    return <span>{value[0].text}</span>
                },
            },
            {
                Header: 'Xfer Date',
                accessor: 'xferDate',
                Cell: (tableData) => {
                    return <span>{tableData.data[tableData.row.index].xferDate}</span>
                },
            },
            {
                Header: 'Earnings Before',
                accessor: 'before',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].earnings.before ? <Dollars value={tableData.data[tableData.row.index].earnings.before} /> : '-'}</>
                },
            },
            {
                Header: 'Earnings After',
                accessor: 'after',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].earnings.after ? <Dollars value={tableData.data[tableData.row.index].earnings.after} /> : '-'}</>
                },
            },
            {
                Header: 'Current',
                accessor: 'current',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].earnings.current ? <Dollars value={tableData.data[tableData.row.index].earnings.current} /> : '-'}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Transferred Affiliates</h1>
                        </div>
                    </div>
                    {!showReport ? (
                        <form onSubmit={handleSubmit}>
                            <table className="table table-card table-borderless m-0 p-2" cellPadding={2}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            Upload Transferred List in TSV format:
                                            <br />
                                            <i>affiliate_id[TAB]transfer_date (yyyy/mm/dd)</i>
                                            <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td width="15%">TSV Data</td>
                                        <td width="85%">
                                            <textarea rows={20} cols={40} name="list_data" className="form-control w-50" required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <CustomButton name="Generate Report" type="submit" variant="primary" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    ) : (
                        <div className="card">
                            <div className="card-body p-0">
                                {/* <div className="d-flex p-3"> */}
                                {/* <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark">Dormant Affiliates for:</h1>
                                    </div> */}
                                {/* <div className="mr-4">
                                        <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR']} />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                            Refresh Report
                                        </button>
                                    </div> */}
                                {/* </div> */}
                                {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ReportTransferredAffiliates
