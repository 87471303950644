import Loading from '../../../../templates/UI/Loading/Loading'
import { Modal, Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import callAPI from '../../../../helpers/callAPI'

const TrackingModal = ({ showSystemMessage, isOpen, setIsOpen, data, setData, dataLoaded, isCreate, setIsCreate, id, refetch }) => {
    const [formData, setFormData] = useState({ platform_name: '', supports_multiple_postback: 'N', api_tracking: 'N' })

    const createTracking = async () => {
        const result = await callAPI('advertisers/platformsList', 'POST', isCreate ? formData : data.platformsList[id])
        if (result.success) {
            refetch()
            showSystemMessage('success', 'Tracking created successfully')
            setFormData({ platform_name: '', supports_multiple_postback: '', api_tracking: '' })
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const updateTracking = async () => {
        const result = await callAPI('advertisers/platformsList', 'PUT', data.platformsList[id])
        if (result.success) {
            refetch()
            showSystemMessage('success', 'Tracking updated successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const deleteTracking = async () => {
        const result = await callAPI('advertisers/platformsList', 'DELETE', data.platformsList[id])
        if (result.success) {
            setIsCreate(true)
            refetch()
            showSystemMessage('success', 'Tracking deleted successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Advertiser Platform</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Platform Name: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.platform_name : data.platformsList[id].platform_name} onChange={(e) => (isCreate ? setFormData({ ...formData, platform_name: e.target.value }) : setData({ platform_name: e.target.value }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Multiple Postbacks: </span>
                            <div className="w-75">
                                <Form.Control as="select" onChange={(e) => (isCreate ? setFormData({ ...formData, supports_multiple_postback: e.target.value }) : setData({ supports_multiple_postback: e.target.value }))} value={isCreate ? formData.supports_multiple_postback : data.platformsList[id].supports_multiple_postback}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span style={{ width: '20%' }}>API Tracking: </span>
                            <div className="w-75">
                                <Form.Control as="select" onChange={(e) => (isCreate ? setFormData({ ...formData, api_tracking: e.target.value }) : setData({ api_tracking: e.target.value }))} value={isCreate ? formData.api_tracking : data.platformsList[id].api_tracking}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </div>
                        </div>
                    </>
                ) : (
                    <td>
                        <Loading />
                    </td>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="warning" onClick={() => createTracking()}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="warning" onClick={() => updateTracking()}>
                            Update
                        </Button>
                        <Button variant="danger" onClick={() => deleteTracking()}>
                            Delete
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default TrackingModal
