import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'

import Loading from '../../../templates/UI/Loading/Loading'
import ReportAffPayTotalsDetails from '../ReportAffPayTotalsDetails/ReportAffPayTotalsDetails'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ReportAffPaymentTotals = (props) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_payment_totals')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])
    const [grandTotals, setGrandTotals] = useState([])
    const [platform, setPlatform] = useState()
    const [paymentMethod, setPaymentMethod] = useState()
    const [paymentMode, setPaymentMode] = useState()
    const [showModal, setShowModal] = useState(false)

    const { showSystemMessage } = props

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setDataLoaded(false)

        const result = await callAPI(`reports/affiliates/payment_totals`, 'GET')
        if (result['success'] === true && result.report) {
            setReport(result.report)
            setTotals(result.totals)
            setGrandTotals(result.grand_total)
            setDataLoaded(true)
        } else if (result['success'] === false) {
            if (result.code !== 401) {
                showSystemMessage('error', 'There was an error fetching the data. Please try again.')
            }
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const paymentList = (paymentMethod) => {
        switch (paymentMethod) {
            case 'N':
                return 'Payment method not set (N)'
            case 'P':
                return 'Paypal (P)'
            case 'W':
                return 'WIRE (W)'
            case 'R':
                return 'Intercash (R)'
            case 'A':
                return 'ACH (A)'
            case 'C':
                return 'Check (C)'
            case 'E':
                return 'Local Bank Transfer (E)'
            default:
                return `Unknown (${paymentMethod})`
        }
    }

    const handleClick = (e, platform, paymentMethod, paymentMode) => {
        e.preventDefault()
        setPlatform(platform)
        setPaymentMethod(paymentMethod)
        setPaymentMode(paymentMode)
        setShowModal(true)
    }

    const getTable = (platform) => {
        let numberOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        let totalPlatform = `${platform.toLowerCase()}_totals`

        return (
            <table className="table table-striped table-card table-hover text-small table-bordered">
                <thead>
                    <tr>
                        <th scope="col">{platform !== 'Grand' ? platform : <span style={{ width: '100%' }}></span>}</th>
                        <th scope="col" className="text-right">Next Weekly Payment</th>
                        <th scope="col" className="text-right">Next Monthly Payment</th>
                        <th scope="col" className="text-right">Below Payment Minimum</th>
                        <th scope="col" className="text-right">{platform !== 'MaxMoney' ? 'Not Being Paid' : 'Not Redeemed'}</th>
                    </tr>
                </thead>
                {platform !== 'Grand' ? (
                    <tbody>
                        {report[platform.toLowerCase()].map((row) => {
                            let link = `/reports/affiliatePaymentTotalsDetails?platform_name=${platform}&payment_method=${platform !== 'MaxMoney' ? row.payment_method : 'M'}&payment_mode`

                            return (
                                <tr key={row.payment_method ? row.payment_method : 'MaxMoney'}>
                                    <td style={{ width: '23.2%' }}>{platform !== 'MaxMoney' ? paymentList(row.payment_method) : 'MaxMoney'}</td>
                                    <td className="text-right">
                                        <a href={`${link}=WEEKLY`} onClick={(e) => handleClick(e, platform, platform !== 'MaxMoney' ? row.payment_method : 'M', 'WEEKLY')}>
                                            ${Intl.NumberFormat('en-GB', numberOptions).format(row.weekly_payment)}
                                        </a>
                                    </td>
                                    <td className="text-right">
                                        {platform !== 'MaxMoney' ? (
                                            <span>
                                                <a href={`${link}=MONTHLY`} onClick={(e) => handleClick(e, platform, row.payment_method, 'MONTHLY')}>
                                                    ${Intl.NumberFormat('en-GB', numberOptions).format(row.monthly_payment)}
                                                </a>
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {platform !== 'MaxMoney' ? (
                                            <span>
                                                <a href={`${link}=BELOW_MIN`} onClick={(e) => handleClick(e, platform, row.payment_method, 'BELOW_MIN')}>
                                                    ${Intl.NumberFormat('en-GB', numberOptions).format(row.less_than_min)}
                                                </a>
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                    <td className="text-right">
                                        <a href={`${link}=NOT_PAID`} onClick={(e) => handleClick(e, platform, platform !== 'MaxMoney' ? row.payment_method : 'M', 'NOT_PAID')}>
                                            ${Intl.NumberFormat('en-GB', numberOptions).format(row.not_paid)}                                            
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                        <tr className="font-weight-bold">
                            <td>&nbsp;</td>
                            <td className="text-right">${Intl.NumberFormat('en-GB', numberOptions).format(totals[totalPlatform].weekly)}</td>
                            <td className="text-right">{platform !== 'MaxMoney' ? <span>${Intl.NumberFormat('en-GB', numberOptions).format(totals[totalPlatform].monthly)}</span> : ''}</td>
                            <td className="text-right">{platform !== 'MaxMoney' ? <span>${Intl.NumberFormat('en-GB', numberOptions).format(totals[totalPlatform].below)}</span> : ''}</td>
                            <td className="text-right">${Intl.NumberFormat('en-GB', numberOptions).format(totals[totalPlatform].nopay)}</td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="font-weight-bold">
                            <td style={{ width: '23.2%' }}>Grand Total:</td>
                            <td className="text-right">${Intl.NumberFormat('en-GB', numberOptions).format(grandTotals.grand_weekly)}</td>
                            <td className="text-right">${Intl.NumberFormat('en-GB', numberOptions).format(grandTotals.grand_monthly)}</td>
                            <td className="text-right">${Intl.NumberFormat('en-GB', numberOptions).format(grandTotals.grand_below)}</td>
                            <td className="text-right">${Intl.NumberFormat('en-GB', numberOptions).format(grandTotals.grand_nopay)}</td>
                        </tr>
                    </tbody>
                )}
            </table>
        )
    }

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <div className="mb-2">{getTable('Tipalti')}</div>
                <div className="mb-2">{getTable('Payoneer')}</div>
                <div className="mb-2">{getTable('Bitcoin')}</div>
                <div className="mb-2">{getTable('Notset')}</div>
                <div className="mb-2">{getTable('Grand')}</div>
                <div className="mb-2">{getTable('MaxMoney')}</div>
            </React.Fragment>
        )
    }

    const handleClose = (isEdited = false) => {
        setShowModal(false)

        if (isEdited) {
            fetchData()
        }
    }

    return (
        <div>
            <Modal show={showModal} size="lg" onHide={handleClose}>
                <ReportAffPayTotalsDetails platform={platform} paymentMethod={paymentMethod} paymentMode={paymentMode} handleClose={handleClose} showSystemMessage={showSystemMessage} />
            </Modal>
            {dataLoaded ? report.length === 0 ? <div className="p-3"> No Stats for the current parameters. </div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportAffPaymentTotals
