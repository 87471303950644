import { Card } from 'react-bootstrap'

const ReversalDetails = ({ reversals_flag }) => {
    const getReversalDetails = () => {
        switch (reversals_flag) {
            case 'U':
                return 'Unkown'
            case 'L':
                return 'Login for Reversals'
            case 'M':
                return 'Get Reversals from Advertiser'
            case 'F':
                return 'Fraud Pubs Only. Provide Ad-Hoc'
            default:
                return 'Unknown'
        }
    }

    return (
        <Card className="mt-2">
            <Card.Header>
                <div className="font-weight-bold float-left">Reversal Details</div>
            </Card.Header>
            <Card.Body>
                <div>{getReversalDetails()}</div>
            </Card.Body>
        </Card>
    )
}

export default ReversalDetails
