import React, { Component } from 'react'
import QuestionDisclaimer from '../templates/QuestionDisclaimer'

class YesNo extends Component {
    state = {}

    render() {
        let { question, disclaimer, order, questionId } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Yes / No</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-3">
                    <button className="btn btn-lg my-2 d-block w-100 btn-secondary" name="Yes" value="Yes">
                        Yes
                    </button>
                    <button className="btn btn-lg my-2 d-block w-100 btn-secondary" name="No" value="No">
                        No
                    </button>
                </div>
                <QuestionDisclaimer disclaimer={disclaimer} handleChangeDisclaimer={this.props.handleChangeDisclaimer} questionId={questionId} />
            </div>
        )
    }
}

export default YesNo
