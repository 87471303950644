import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Loading = () => {
    return (
        <div className="alert alert-light shadow-sm" style={{ maxWidth: '180px', marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="text-info d-flex">
                <div className="d-flex align-items-center mr-3">
                    <FontAwesomeIcon icon="spinner" size="2x" spin />
                </div>
                <div className="d-flex align-items-center">LOADING</div>
            </div>
        </div>
    )
}

export default Loading
