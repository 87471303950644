import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Sun, Moon } from 'react-feather'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import styles from '../TeamMemberCard/TeamMemberCard.module.scss'
import Dollars from '../Dollars/Dollars'
import StatusCircle from '../StatusCircle/StatusCircle'

const TeamMemberCardMobile = ({ manager, teamType }) => {
    let adminImage = manager.admin.image

    let dashboard

    if (teamType === 'affiliate') {
        dashboard = manager.admin.role === 'AM_SENIOR' ? 'seniorAM' : manager.admin.role === 'AM_JUNIOR' ? 'juniorAM' : 'juniorAMTeamLead'
    } else if (teamType === 'advertiser') {
        if (manager.admin.role === 'ADOPS_TEAMLEAD') {
            dashboard = 'adopsTeamlead'
        } else if (manager.admin.role === 'ADOPS_MANAGER') {
            dashboard = 'adops'
        } else {
            dashboard = 'advertiserMgr'
        }
    } else if (teamType === 'compliance') {
        if (manager.admin.role === 'COMPLIANCE') {
            dashboard = 'compliance'
        } else if (manager.admin.role === 'COMPLIANCE_DIRECTOR') {
            dashboard = 'complianceDirector'
        } else {
            dashboard = 'compliance'
        }
    } else if (teamType === 'bizdev') {
        if (manager.admin.role === 'BIZDEV') {
            dashboard = 'bizdev'
        } else if (manager.admin.role === 'BIZDEV_DIRECTOR') {
            dashboard = 'bizdev'
        } else {
            dashboard = 'bizdev'
        }
    }

    if (adminImage === '' || adminImage === 'TBD' || adminImage === 'NA' || !adminImage || adminImage === 'test') {
        adminImage = 'noImage.jpg'
    }

    if (adminImage === '' || adminImage === 'TBD' || adminImage === 'NA' || !adminImage || adminImage === 'test') {
        adminImage = 'noImage.jpg'
    }

    return (
        <div className={`card mb-3 ${styles.affTeamMobile}`}>
            <table className="table m-0">
                <tbody>
                    <tr>
                        <td className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <Link className="d-flex align-items-center" to={`/dashboard/${dashboard}?adminId=${manager.admin.id}`}>
                                    <button className="btn btn-sm btn-link" type="button">
                                        <img className={styles.headshot} src={`${process.env.REACT_APP_RESOURCE_URI}/images/headshots/${adminImage}`} alt={`${manager.admin.name}`} />
                                    </button>
                                    <div>{manager.admin.name}</div>
                                </Link>
                                <div className="ml-2">{manager.admin.role === 'AM_SENIOR' ? ' (Sr)' : manager.admin.role === 'AM_JUNIOR' ? ' (Jr)' : manager.admin.role === 'AM_JR_TEAMLEAD' ? ' (Jr TL)' : ''}</div>
                                {
                                    manager.vacation_status === 'Y' &&
                                    <div className="mr-2" data-tip="On Vacation">🌴</div>
                                }
                                {manager.out_of_office === 'Y' && (
                                    <div className="ml-2" data-tip="Out of office">
                                        <StatusCircle type="danger" />
                                    </div>
                                )}
                            </div>
                            <div>
                                <Link to="">Top Affs</Link>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        {teamType === 'advertiser' || (teamType === 'affiliate' && manager.admin.role === 'AM_SENIOR') ? (
                            <td className="d-flex text-left">
                                <div className="col-3 font-weight-bold text-left">Margin</div>
                                <div className="col-sm-4 d-flex">
                                    <div className="pr-2">Today</div>
                                    <div>
                                        <span className="mb-element active">
                                            <span className="name">
                                                <Dollars bold value={manager.margin ? manager.margin.today : 0} />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-5 d-flex">
                                    <div className="pr-2">Yesterday</div>
                                    <div>
                                        <span className="mb-element active">
                                            <span className="name">
                                                <Dollars bold value={manager.margin ? manager.margin.yesterday : 0} />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        ) : teamType === 'affiliate' && (manager.admin.role === 'AM_JUNIOR' || manager.admin.role === 'AM_JR_TEAMLEAD') ? (
                            <td className="d-flex">
                                <div className="col-3 font-weight-bold">Stats</div>
                                <div className="col-sm-4 d-flex">
                                    <div>
                                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">This Month</Tooltip>}>
                                            <span>H/M Transfers</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div>
                                        <span className="mb-element purple-highlight font-weight-bold">
                                            <span className="name">{manager.stats.high_medium_transfers_this_month}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-5 d-flex">
                                    <div className="pr-2">
                                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Today</Tooltip>}>
                                            <span>Approved</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div>
                                        <span className="mb-element purple-highlight font-weight-bold">
                                            <span className="name">{manager.stats.approved_applications_today}</span>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        ) : (
                            ''
                        )}
                    </tr>
                    <tr className="d-flex">
                        <td className="d-flex col-sm-7 justify-content-around border-right">
                            <div>
                                <span className="mr-1 text-primary font-weight-bold">
                                    <Link to={teamType === 'affiliate' ? `/affiliates/followups?adminId=${manager.admin.id}` : `/advertisers/followups?adminId=${manager.admin.id}`}>{manager.followups}</Link>
                                </span>
                                <span>{teamType === 'advertiser' ? <Link to={`/advertisers/followupSummaryReport?adminId=${manager.admin.id}`}>F/U</Link> : 'F/U'}</span>
                            </div>
                            <div>
                                <span className="mr-1 text-primary font-weight-bold">{teamType === 'affiliate' ? <Link to={`/affiliates/pitchReport?adminId=${manager.admin.id}`}>{manager.pitches}</Link> : <Link to={`/advertisers?adminId=${manager.admin.id}`}>{manager.advertisers}</Link>}</span>
                                <span>{teamType === 'affiliate' ? 'Pitches' : 'Advertisers'}</span>
                            </div>

                            <div>
                                <span className="mr-1 text-primary font-weight-bold">
                                    <Link to={`/admin/${manager.admin.id}/pageViews?startDate=${moment().format('YYYY-MM-DD')}`}>{manager.page_views}</Link>
                                </span>
                                <span>Views</span>
                            </div>
                        </td>
                        <td className="d-flex col-5 justify-content-around">
                            <div className="mr-1">
                                <Sun size={18} className="pr-1 font-weight-bold" color="#EEBC1D" fill="#EEBC1D" />
                                {manager.activity.first_page_view_today ? moment.utc(manager.activity.first_page_view_today).format('HH:mm') : '-'}
                            </div>
                            <div>
                                <Moon size={18} className="pr-1 font-weight-bold" color="#EEBC1D" fill="#EEBC1D" />
                                {manager.activity.last_page_view_today ? moment.utc(manager.activity.last_page_view_today).format('HH:mm') : <span className="text-center">-</span>}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TeamMemberCardMobile
