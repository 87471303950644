import React, { useState, useEffect } from 'react'
import { Download } from 'react-feather'
import { CSVLink } from 'react-csv'

export default function DownloadCSV({ filename, headerLabels, data, dataLabels }) {
    // headers should be in array format - Eg: ['Referring URL', 'Count']
    // dataLabels should be in array format - EG: ['referring_url', 'clicks.nested.value'] and refer to the object key in the data

    const [preparingCSV, setPreparingCSV] = useState(false)
    const [CSVReady, setCSVReady] = useState(false)
    const [formattedData, setFormattedData] = useState(false)

    useEffect(() => {
        // If the data changes, we need to reset the button to generate the CSV for the new data
        setPreparingCSV(false)
        setCSVReady(false)
    }, [data])

    const generateCSV = () => {
        setPreparingCSV(true)

        let processedData = []

        // Headers
        processedData.push(headerLabels)

        // Data
        data.map((row) => {
            // For each row/line, we only want certain items from the datapoint list in the props
            let line = []

            dataLabels.map((datapoint) => {
                const datapoints = datapoint.split('.').map((item) => item)
                let value = row

                datapoints.map((datapoint) => {
                    value = value[datapoint]
                })

                line.push(value)
            })

            processedData.push(line)
        })

        setFormattedData(processedData)

        setPreparingCSV(false)
        setCSVReady(true)
    }

    const getButton = () => {
        return preparingCSV ? (
            <button className="btn btn-sm btn-secondary ml-2" disabled>
                Preparing CSV...
            </button>
        ) : CSVReady ? (
            <CSVLink className="btn btn-sm btn-success" data={formattedData} filename={filename}>
                <Download size={14} /> Download CSV
            </CSVLink>
        ) : (
            <button onClick={generateCSV} className="btn btn-sm btn-warning ml-2">
                Generate CSV
            </button>
        )
    }

    return <>{getButton()}</>
}
