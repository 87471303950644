import { Card, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'

import moment from 'moment'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
// import MerModal from './MerModal'
import { useAuth, useProtectedContent, useProtectedPage } from '../../../context/AuthContext'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const FollowupSummaryReport = ({ showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_followup_summary')

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const history = useHistory()

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const { data, dataLoaded, refetch } = useCallApi(buildUrl(`affiliates/followupSummaryReport?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`), 'GET', {}, [], showSystemMessage)

    document.title = 'Affiliates Followup Summary Report'

    const refreshReport = (e) => {
        e.preventDefault()
        history.push(`/affiliates/followupSummaryReport?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        refetch()
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: (tableData) => {
                    return <Link to={`/admin/${adminId}/followups?startDate=${moment(tableData.row.original.date).utc().format('YYYY-MM-DD')}&endDate=${moment(tableData.row.original.date).utc().format('YYYY-MM-DD')}`}>{moment(tableData.row.original.date).utc().format('YYYY-MM-DD')}</Link>
                },
            },
            {
                Header: <div className="text-right">Promise to Run</div>,
                accessor: 'PT',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Status Call</div>,
                accessor: 'SC',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Emailed</div>,
                accessor: 'EM',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Called LM/NA</div>,
                accessor: 'CN',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Called Spoke</div>,
                accessor: 'CS',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">IMed NA</div>,
                accessor: 'IN',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">IMed Chatted</div>,
                accessor: 'IC',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">FWD</div>,
                accessor: 'FW',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Other</div>,
                accessor: 'OT',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Total (Non FWD)</div>,
                accessor: 'ALL',
                className: 'text-right',
            }]

        return <ReportTable data={data.report} columns={columns} totals={data.totals} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliates</h1>
            </div>
            <Card className="mb-3">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div>Followup Summary for:</div>
                        <div className="mx-2">
                            <AdminPicker className="w-100" adminId={adminId} setAdminId={setAdminId} role={['AM_SENIOR', 'AM_JUNIOR', 'AM_JR_TEAMLEAD', 'AFFILIATE_DIRECTOR']} />
                        </div>
                        <DateRangeFilter className="w-100" quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button variant="primary" size="sm" className="ml-2" onClick={refreshReport}>
                            Refresh
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="overflow-auto p-0">{dataLoaded ? !(data.report?.length > 0) ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</Card.Body>
            </Card>
        </div>
    )
}

export default FollowupSummaryReport
