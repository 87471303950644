import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

import DashboardProperties from './DashboardProperties/DashboardProperties'
import DashboardSummary from './DashboardSummary/DashboardSummary'

export default function AvatarDashboard(props) {

    document.title = 'Avatar Dashboard'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'avatar')

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Avatar Dashboard</h1>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="p-3 d-flex justify-content-between align-items-center">
                            <h1 className="mb-h1 text-success">Active Properties</h1>
                            <Link to="/avatar/propertyManager" className="btn btn-sm btn-primary">
                                Manage Properties
                            </Link>
                        </div>
                    </div>
                    <div className="py-3">
                        <DashboardProperties status="active" />
                    </div>
                </div>
                <DashboardSummary showSystemMessage={props.showSystemMessage} />
            </div>
        </React.Fragment>
    )
}
