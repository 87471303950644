import React, { useState } from 'react'
import moment from 'moment'

const SotuAffPriority = (props) => {
    const [open, setOpen] = useState(false)

    const getClass = (delta) => {
        return Math.sign(delta) !== -1 ? 'mb-text-sm text-success' : 'mb-text-sm text-danger'
    }

    const { quarter, aff_priority1_count, aff_priority2_count, aff_priority3_count, aff_priority4_count, aff_priority5_count, detailed } = props.quarter

    const nextIndex = props.index + 1

    let aff_priority1_delta = 0
    let aff_priority2_delta = 0
    let aff_priority3_delta = 0
    let aff_priority4_delta = 0
    let aff_priority5_delta = 0

    if (props.report[nextIndex]) {
        aff_priority1_delta = aff_priority1_count - props.report[nextIndex].aff_priority1_count
        aff_priority2_delta = aff_priority2_count - props.report[nextIndex].aff_priority2_count
        aff_priority3_delta = aff_priority3_count - props.report[nextIndex].aff_priority3_count
        aff_priority4_delta = aff_priority4_count - props.report[nextIndex].aff_priority4_count
        aff_priority5_delta = aff_priority5_count - props.report[nextIndex].aff_priority5_count
    }

    return (
        <React.Fragment>
            <tr>
                <td className="font-weight-bold">{quarter}</td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_priority1_count)}
                    <div className={getClass(aff_priority1_delta)}>
                        ({Math.sign(aff_priority1_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_priority1_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_priority2_count)}
                    <div className={getClass(aff_priority2_delta)}>
                        ({Math.sign(aff_priority2_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_priority2_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_priority4_count)}
                    <div className={getClass(aff_priority4_delta)}>
                        ({Math.sign(aff_priority4_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_priority4_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_priority5_count)}
                    <div className={getClass(aff_priority5_delta)}>
                        ({Math.sign(aff_priority5_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_priority5_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_priority3_count)}
                    <div className={getClass(aff_priority3_delta)}>
                        ({Math.sign(aff_priority3_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_priority3_delta)})
                    </div>
                </td>
                <td>
                    <span className="badge badge-primary" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                        {open ? '-' : '+'}
                    </span>
                </td>
            </tr>
            {open &&
                detailed.map((record, subIndex) => {
                    let subDelta1 = 0
                    let subDelta2 = 0
                    let subDelta3 = 0
                    let subDelta4 = 0
                    let subDelta5 = 0
                    const subNextIndex = subIndex + 1

                    if (detailed[subNextIndex]) {
                        subDelta1 = record.aff_priority1_count - detailed[subNextIndex].aff_priority1_count
                        subDelta2 = record.aff_priority2_count - detailed[subNextIndex].aff_priority2_count
                        subDelta3 = record.aff_priority3_count - detailed[subNextIndex].aff_priority3_count
                        subDelta4 = record.aff_priority4_count - detailed[subNextIndex].aff_priority4_count
                        subDelta5 = record.aff_priority5_count - detailed[subNextIndex].aff_priority5_count
                    } else {
                        // If there is none, let's jump to the first of the LAST QUARTER
                        if (props.report[nextIndex]) {
                            if (props.report[nextIndex].detailed[0]) {
                                subDelta1 = record.aff_priority1_count - props.report[nextIndex].detailed[0].aff_priority1_count
                                subDelta2 = record.aff_priority2_count - props.report[nextIndex].detailed[0].aff_priority2_count
                                subDelta3 = record.aff_priority3_count - props.report[nextIndex].detailed[0].aff_priority3_count
                                subDelta4 = record.aff_priority4_count - props.report[nextIndex].detailed[0].aff_priority4_count
                                subDelta5 = record.aff_priority5_count - props.report[nextIndex].detailed[0].aff_priority5_count
                            }
                        }
                    }

                    return (
                        <tr className="text-secondary">
                            <td>
                                <div className="pl-3">{moment(record.date).utc().format('YYYY-MM')}</div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_priority1_count)}
                                <div className={getClass(subDelta1)}>
                                    ({Math.sign(subDelta1) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta1)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_priority2_count)}
                                <div className={getClass(subDelta2)}>
                                    ({Math.sign(subDelta2) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta2)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_priority4_count)}
                                <div className={getClass(subDelta4)}>
                                    ({Math.sign(subDelta4) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta4)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_priority5_count)}
                                <div className={getClass(subDelta5)}>
                                    ({Math.sign(subDelta5) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta5)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_priority3_count)}
                                <div className={getClass(subDelta3)}>
                                    ({Math.sign(subDelta3) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta3)})
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    )
                })}
        </React.Fragment>
    )
}

export default SotuAffPriority
