import React from 'react'

const QuestionDisclaimer = (props) => {
    const { disclaimer, handleChangeDisclaimer, questionId } = props

    return (
        <div className="mt-4">
            <div className="mt-3">
                <input className="form-control" type="text" defaultValue={disclaimer} placeholder="Disclaimer text" onChange={handleChangeDisclaimer(questionId)} />
            </div>
            <div className="alert alert-secondary mt-3">You can map responses to questions in the text. For example: #q1# for text questions, or #q1.firstName# for short answer questions, will map the response for question 1 to the text.</div>
        </div>
    )
}

export default QuestionDisclaimer
