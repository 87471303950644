export default {
    affiliateStatuses: [
        {
            value: 'A',
            text: 'Active',
        },
        {
            value: 'V',
            text: 'Validating',
        },
        {
            value: 'P',
            text: 'Pending',
        },
        {
            value: 'S',
            text: 'Suspended',
        },
        {
            value: 'R',
            text: 'Rejected',
        },
        {
            value: 'L',
            text: 'Closed',
        },
        {
            value: 'R',
            text: 'Dup-Comp Review',
        },
        {
            value: 'C',
            text: 'Cancelled',
        },
    ],
}
