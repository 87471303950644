import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { MoreVertical } from 'react-feather'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal } from 'reactstrap'
import callAPI from '../../../../helpers/callAPI'

export default function AvatarSurveyRecord({ survey, setNewSurveyAdded, showSystemMessage }) {
    let [dropdownOpen, setDropdownOpen] = useState(false)
    let [cloneLoaded, setCloneLoaded] = useState(true)
    let [cloneModalOpen, setCloneModalOpen] = useState(false)
    let [cloneSurveyName, setCloneSurveyName] = useState('')

    async function cloneSurvey() {
        const result = await callAPI(`avatar/survey/${survey.survey_id}/clone`, 'POST', { description: cloneSurveyName })
        if (result['success'] === true) {
            showSystemMessage('success', `Survey: ${survey.description} has been cloned`)
        } else if (result['success'] === false) {
            showSystemMessage('error', result.errors.message)
        } else {
            showSystemMessage('error', result.errors.message)
        }

        setCloneLoaded(true)
        setCloneModalOpen(false)
        setNewSurveyAdded(true)
        setCloneSurveyName('')
    }

    return (
        <tr>
            <td>{survey.survey_id}</td>
            <td>{survey.description}</td>
            <td>
                {survey.active_on_properties.map((property) => {
                    return (
                        <div>
                            <div className="badge badge-light text-left border">
                                <Link to={`/avatar/property/${property.property_id}`}>Property {property.property_id}</Link> {property.domain}
                            </div>
                        </div>
                    )
                })}
            </td>
            <td align="right">
                <div className="d-flex justify-content-end">
                    <Link to={`/avatar/survey/${survey.survey_id}`} className="btn btn-sm btn-warning mr-2">
                        Edit
                    </Link>
                    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction="below">
                        <DropdownToggle tag="a" onClick={() => setDropdownOpen(!dropdownOpen)} data-toggle="dropdown" aria-expanded={dropdownOpen} className="report-more-toggle">
                            <MoreVertical size={20} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link to={`/avatar/reports/survey_responses?surveyId=${survey.survey_id}`} className="btn btn-lnk text-primary">
                                Response Report
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link to={`/avatar/reports/survey_questions?surveyId=${survey.survey_id}`} className="btn btn-lnk text-primary">
                                Dropoff Report
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <button className="btn btn-lnk text-primary" onClick={() => setCloneModalOpen(!cloneModalOpen)}>
                                Clone
                            </button>
                            <DropdownItem divider className="m-0" />
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <Modal isOpen={cloneModalOpen} toggle={() => setCloneModalOpen(!cloneModalOpen)} className="modal-md">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Clone survey: <strong>{survey.description}</strong>
                        </h5>
                        <button type="button" className="close" onClick={() => setCloneModalOpen(false)}>
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="site-dropdown">Choose a name for the new survey:</label>
                        <input type="text" className="form-control" onChange={(e) => setCloneSurveyName(e.target.value)}></input>

                        {cloneLoaded ? (
                            <button className="btn btn-primary btn-sm mt-3" onClick={cloneSurvey}>
                                Clone Survey
                            </button>
                        ) : (
                            <button className="btn btn-secondary btn-sm mt-3" disabled>
                                <FontAwesomeIcon icon="spinner" spin />
                            </button>
                        )}
                    </div>
                </Modal>
            </td>
        </tr>
    )
}
