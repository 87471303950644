import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import AffiliateContactInfo from './AffiliateContactInfo/AffiliateContactInfo'
import AffiliateStats from './AffiliateStats/AffiliateStats'
import Pill from '../../../templates/UI/Pill/Pill'
import LoginAsAffiliate from '../LoginAsAffiliate/LoginAsAffiliate'
import ReviewApplication from '../../Dashboard/components/Applications/ReviewApplication/ReviewApplication'
import moment from 'moment'
import AffiliatePaymentInfo from './AffiliatePaymentInfo/AffiliatePaymentInfo'

const AccountInfo = (props) => {
    // name, manager name, status, priority
    // network, Incentive,
    // IM handle, mobile, Email, website (link)
    // Today, Best Month
    const affiliateInfo = props.affiliateInfo
    const { showSystemMessage, refetch } = props
    const [showApplicationModal, setShowApplicationModal] = useState(false)
    const [updateList, setUpdateList] = useState(false)

    const handleClose = () => {
        setShowApplicationModal(false)
        refetch()
    }

    const toggleModal = () => {
        setShowApplicationModal(false)
        if (updateList) {
            refetch()
        }
    }

    const getOutput = () => {
        if (affiliateInfo.status_letter !== 'A') {

            if (affiliateInfo.verified_flag === 'Y') {
                // show review modal
                return <button className="btn btn-sm btn-warning align-items-center" onClick={() => setShowApplicationModal(true)}>Review Application</button>

            } else if (affiliateInfo.verified_flag === 'N') {
                // show verification modal
                return <button className="btn btn-sm btn-warning" onClick={(e) => { e.preventDefault(); window.open(`/affiliate/${affiliateInfo.affiliate_id}/application/verify`, '_blank', 'height=750,width=800'); }}>Verify Application</button>
            }

        } else {
            return <LoginAsAffiliate affiliateId={affiliateInfo.affiliate_id} showSystemMessage={showSystemMessage} />
        }
    }

    return (
        <div className="card pt-2 mb-2">
            <Modal isOpen={showApplicationModal} toggle={toggleModal} className="modal-lg">
                <ReviewApplication showSystemMessage={showSystemMessage} affiliateId={affiliateInfo.affiliate_id} handleReviewClose={handleClose} setUpdateList={setUpdateList} />
            </Modal>
            <div className="d-flex justify-content-between border-bottom p-2 align-items-center">
                <div className="d-flex">
                    <h5 className="font-weight-bold">
                    {(affiliateInfo.experience_level === 3 || affiliateInfo.experience_level === 2) && affiliateInfo.tier === 1 ? <span className="mr-2">⭐</span> : ''}
                        <Link to={`/affiliate/${affiliateInfo.affiliate_id}/edit`}>{affiliateInfo.name}</Link>
                    </h5>
                    <div className="float-left text-secondary"> &nbsp;/ {affiliateInfo.manager_name}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="mr-2">Registered: {moment(affiliateInfo.status_date).utc().format('YYYY-MM-DD')} </div>
                    {getOutput()}
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                {/* props for <Pill /> : borderColor, textColor */}
                <div style={{ flex: 1 }}>
                    <Pill text={affiliateInfo.status} borderColor={affiliateInfo.status === 'Cancelled' ? '#dc3545' : ''} textColor={affiliateInfo.status === 'Cancelled' ? '#dc3545' : ''} />
                    <Pill text={affiliateInfo.aff_priority} borderColor={affiliateInfo.aff_priority === 'High' ? '#17a2b8' : affiliateInfo.aff_priority === 'Medium' ? '#c79706' : affiliateInfo.aff_priority === 'Low' || affiliateInfo.aff_priority === 'Very Low' ? '#dc3545' : '#343a40'} textColor={affiliateInfo.aff_priority === 'High' ? '#17a2b8' : affiliateInfo.aff_priority === 'Medium' ? '#c79706' : affiliateInfo.aff_priority === 'Low' || affiliateInfo.aff_priority === 'Very Low' ? '#dc3545' : '#343a40'} />
                </div>
                <div style={{ flex: 1 }} className="align-middle text-secondary text-center">
                    {affiliateInfo.contact} <br></br>
                    {affiliateInfo.state ? `${affiliateInfo.state},` : ''} <span className={affiliateInfo.tier === 1 ? 'text-success font-weight-bold' : ''}>{affiliateInfo.country}</span> <br></br>
                    {affiliateInfo.time_zone} ({affiliateInfo.contact_time.text})
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    {affiliateInfo.is_network_flag === 'Y' ? <Pill text="Network" /> : ''}
                    {affiliateInfo.incentive_flag === 'Y' ? <Pill text="Incentive" /> : ''}
                </div>
            </div>
            <AffiliateContactInfo imType={affiliateInfo.im_type} imHandle={affiliateInfo.im_handle} mobile={affiliateInfo.mobile_phone} email={affiliateInfo.e_mail} url={affiliateInfo.url} showSystemMessage={showSystemMessage} additionalEmails={affiliateInfo.additional_contacts} />
            <AffiliatePaymentInfo payment={affiliateInfo.payment} />
            <AffiliateStats affiliateId={affiliateInfo.affiliate_id} showSystemMessage={showSystemMessage} lastLoginDate={affiliateInfo.last_login_date} />
        </div>
    )
}
export default AccountInfo
