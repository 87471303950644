import React, { useState, useEffect } from 'react'

import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import TeamMemberCard from '../../../../templates/TeamMemberCard/TeamMemberCard'
import TeamMemberCardMobile from '../../../../templates/TeamMemberCardMobile/TeamMemberCardMobile'

import styles from './AffiliateTeam.module.scss'
import ReactTooltip from 'react-tooltip'

const AffiliateTeam = (props) => {
    const [juniorAMs, setJuniorAMs] = useState([])
    const [juniorAmLead, setJuniorAmLead] = useState([])
    const [seniorAMs, setSeniorAMs] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    const { showSystemMessage, mode } = props

    useEffect(() => {
        let url = `summary/affiliateTeam/${mode}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setJuniorAMs(result.junior_affiliate_managers)
                    setSeniorAMs(result.senior_affiliate_managers)
                    setJuniorAmLead(result.junior_am_lead)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching affiliate team details')
            })
    }, [])

    return (
        <div className="card mb-3 overflow-auto">
            <div className="card-header">Affiliate Team</div>
            <div className={`${styles.desktopOnly} card-body p-0`} style={{ fontSize: '.9rem' }}>
                {dataLoaded ? (
                    <table className="table table-responsive-md m-0">
                        <thead>
                            <tr className="text-muted">
                                <th>Manager</th>
                                <th>Stats</th>
                                <th>Actions</th>
                                <th>Activity</th>
                                <th className="text-center">Top Affs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(mode === 'all' || mode === 'senior') && seniorAMs.map((manager) => <TeamMemberCard manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="affiliate" />)}
                            {(mode === 'all' || mode === 'junior') && juniorAMs.map((manager) => <TeamMemberCard manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="affiliate" />)}
                            {(mode === 'all' || mode === 'junior') && juniorAmLead.map((manager) => <TeamMemberCard manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="affiliate" />)}
                        </tbody>
                    </table>
                ) : (
                    <Loading></Loading>
                )}
            </div>

            {/* Mobile View */}

            <div className={`${styles.mobileOnly} ${styles.bgGray} card-body p-0`} style={{ fontSize: '.9rem' }}>
                {dataLoaded ? (
                    <div className={`m-0`}>
                        <ReactTooltip />
                        {(mode === 'all' || mode === 'senior') && seniorAMs.map((manager) => <TeamMemberCardMobile manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="affiliate" />)}
                        {(mode === 'all' || mode === 'junior') && juniorAMs.map((manager) => <TeamMemberCardMobile manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="affiliate" />)}
                        {(mode === 'all' || mode === 'junior') && juniorAmLead.map((manager) => <TeamMemberCardMobile manager={manager} showSystemMessage={showSystemMessage} key={manager.admin.id} teamType="affiliate" />)}
                    </div>
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </div>
    )
}

export default AffiliateTeam
