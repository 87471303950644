import React, { Component } from 'react'
import moment from 'moment'
import './Property.css'
import ReactTooltip from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Dollars from '../../../../templates/Dollars/Dollars'

class Property extends Component {
    getSurveyStatus = (status) => {
        if (status.toUpperCase() === 'ACTIVE') {
            return <div className="badge badge-success">{status}</div>
        } else {
            return <i>{status}</i>
        }
    }

    getStartDate = (startDate) => {
        if (startDate !== '1900-01-01T00:00:00.000Z') {
            let start = moment(startDate)
            let end = moment()
            return end.diff(start, 'days') + 1
        } else {
            return 1
        }
    }

    getMargin = (propertySets) => {
        let margin = 0

        for (var i = 0; i < propertySets.length; i++) {
            margin = margin + propertySets[i].margin
        }

        return margin
    }

    getMarginClass = (margin) => {
        console.log(margin)
        if (margin < 0) {
            return 'text-danger'
        } else {
            return 'text-info'
        }
    }

    render() {
        const { property_id: propertyId, domain, status, property_sets: propertySets } = this.props.data
        let statusDotClass = status.toUpperCase() === 'ACTIVE' ? 'text-success' : 'text-danger'

        return (
            <div className="card mb-3">
                <ReactTooltip />
                <div className="card-header p-2 d-flex justify-content-between align-items-center av-property-header">
                    <div>
                        <FontAwesomeIcon icon="circle" size="xs" className={statusDotClass} /> <span className="name">Property {propertyId}</span>
                    </div>
                    {this.props.showEdit && (
                        <div>
                            <Link to={`/avatar/reports/set?propertyId=${propertyId}`} className="btn btn-sm btn-primary">
                                Stats
                            </Link>
                            <Link to={`/avatar/property/${propertyId}`} className="btn btn-sm btn-warning ml-2">
                                View
                            </Link>
                        </div>
                    )}
                </div>
                <div className="card-body p-2 d-flex justify-content-between align-items-center">
                    <div>
                        <a href={domain} target="_blank" rel="noreferrer">
                            {domain}
                        </a>
                    </div>
                    <div>
                        <Dollars bold highlight value={this.getMargin(propertySets)} />
                    </div>
                </div>
                <div className="card-body p-0">
                    <div>
                        <table className="table table-striped table-card table-hover mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Site</th>
                                    <th scope="col">Survey</th>
                                    <th scope="col">Status</th>
                                    <th scope="col" data-tip="Total days running" className="text-right">
                                        Days
                                    </th>
                                    <th scope="col" data-tip="Survey completions/starts this month" className="text-right">
                                        Month C/S %
                                    </th>
                                    <th scope="col" className="text-right">
                                        Month Margin
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {propertySets.length > 0 &&
                                    propertySets.map((propertySet) => {
                                        return (
                                            <tr key={propertySet.survey_id}>
                                                <td>
                                                    <Link to={`site/${propertySet.site_id}`}>{propertySet.meta_title}</Link>
                                                </td>
                                                <td>
                                                    <Link to={`survey/${propertySet.survey_id}`}>{propertySet.survey_description}</Link>
                                                </td>
                                                <td>{propertySet.set_status}</td>
                                                <td align="right">{this.getStartDate(propertySet.start_date)}</td>
                                                <td align="right">
                                                    {propertySet.completions} / {propertySet.starts} ({Intl.NumberFormat('en-GB').format(propertySet.completion_rate * 100)}%)
                                                </td>
                                                <td align="right">
                                                    <Dollars bold highlight value={propertySet.margin} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Property
