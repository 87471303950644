import React, { useState, useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'
import CampaignSearch from '../CampaignSearch/CampaignSearch'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import { useProtectedContent, useAuth } from '../../context/AuthContext'
import AdminPicker from '../AdminPicker/AdminPicker'

export default function CampaignCapWatchModal({ id, campaign, type, cap, open, close, manager, refetch, advertiserId, watch_type, affiliate, showSystemMessage }) {
    const [submitting, setSubmitting] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [adminId, setAdminId] = useState(manager.id)

    const [data, setData] = useState({ id: id > 0 ? id : 0, cap: cap ? cap : 0, type: type ? type.value : 'D', campaign: campaign ? campaign : false, affiliate: affiliate ? affiliate : false, watch_type: watch_type ? watch_type : 'LEAD' })

    const auth = useAuth()
    const showAdminDropdown = useProtectedContent(auth.permissions, 'cap_watch_admin_dropdown')

    const save = (e) => {
        e.preventDefault()
        setSubmitting(true)

        let url = `campaigns/capWatch/${manager.id}`

        callAPI(url, 'POST', data)
            .then((result) => {
                if (result['success'] === true) {
                    setSubmitting(false)
                    refetch()
                    showSystemMessage('success', 'Cap watch saved.')
                    close()
                } else if (result['success'] === false) {
                    setSubmitting(false)
                    showSystemMessage('error', result.errors.message)
                } else {
                    setSubmitting(false)
                    showSystemMessage('error', 'An error occurred while saving cap watch')
                }
            })
            .catch((error) => {
                console.log(`Error while saving cap watch: ${error}`)
                showSystemMessage('error', 'An error occurred while saving cap watch.')
                setSubmitting(false)
            })
    }

    const deleteWatch = () => {
        setDeleting(true)

        let url = `campaigns/capWatch/${manager.id}`

        callAPI(url, 'DELETE', data)
            .then((result) => {
                if (result['success'] === true) {
                    refetch()
                    showSystemMessage('success', 'Cap watch deleted.')
                    close()
                } else if (result['success'] === false) {
                    refetch()
                    setDeleting(false)
                    showSystemMessage('error', 'An error occurred while deleting cap watch.')
                }
            })
            .catch((error) => {
                console.log(`Error while deleting cap watch: ${error}`)
                showSystemMessage('error', 'An error occurred while deleting cap watch.')
                setDeleting(false)
            })
    }

    const handleManagerChange = (mgrId) => {
        setAdminId(mgrId)
        setData({ ...data, assign_to: mgrId })
    }

    return (
        <Modal show={open} onHide={close} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Campaign Watch</Modal.Title>
            </Modal.Header>
            <div className="card">
                <Form onSubmit={save}>
                    <div className="card-body">
                        {showAdminDropdown && <div className="d-flex align-items-center mb-2">
                            <div style={{ width: '15rem' }}>Assign To:</div>
                            <div className="w-100">
                                <AdminPicker role={['ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'MERMGR']} setAdminId={handleManagerChange} adminId={adminId} />
                            </div>
                        </div>}
                        <div className="d-flex align-items-center">
                            <div style={{ width: '15rem' }}>Campaign:</div>
                            <div className="w-100">
                                <CampaignSearch advertiserId={advertiserId > 0 ? advertiserId : false} campaign={campaign} setFormData={setData} order={2} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <div style={{ width: '15rem' }}>Affiliate (Optional):</div>
                            <div className="w-100">
                                <AffiliateSearch affiliate={affiliate} setFormData={setData} order={1} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <div style={{ width: '15rem' }}>Cap amount:</div>
                            <div className="w-100">
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={cap ? cap : ''}
                                    onChange={(e) => {
                                        setData({ ...data, cap: e.target.value })
                                    }}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <div style={{ width: '15rem' }}>Watch:</div>
                            <div className="w-100">
                                <select
                                    className="form-control"
                                    defaultValue={watch_type ? watch_type : 'LEAD'}
                                    onChange={(e) => {
                                        setData({ ...data, watch_type: e.target.value })
                                    }}
                                >
                                    <option value="LEAD">Leads</option>
                                    <option value="EARNINGS">Net Earnings</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <div style={{ width: '15rem' }}>Timeframe:</div>
                            <div className="w-100">
                                <select
                                    className="form-control"
                                    defaultValue={type ? type.value : 'D'}
                                    onChange={(e) => {
                                        setData({ ...data, type: e.target.value })
                                    }}
                                >
                                    <option value="D">Day</option>
                                    <option value="W">Week</option>
                                    <option value="M">Month</option>
                                    <option value="Y">Year</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-3 text-right">
                            <CustomButton name="Save" type="submit" variant="primary" spin={submitting} /> &nbsp;
                            {id > 0 && <CustomButton name="Delete" type="button" variant="danger" spin={deleting} onClick={deleteWatch} />}
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}
