import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import Loading from '../../../templates/UI/Loading/Loading'
import PropertySettings from './PropertySettings/PropertySettings'
import PropertySets from './PropertySets/PropertySets'
import AvatarReportsMore from '../AvatarReports/AvatarReportsMore/AvatarReportsMore'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import callAPI from '../../../helpers/callAPI'

class AvatarProperty extends Component {
    state = {
        dataLoaded: false,
        activateButtonLoaded: true,
        domainSubmitLoaded: true,
        property: {
            propertyId: this.props.propertyId,
            status: '',
            domain: '',
            offer_id: '', // deprecated
            margin: 0,
            siteId: 0,
            surveys: [],
            propertySets: [],
            affiliates: [],
        },
    }

    componentDidMount() {
        this.getPropertyData()
    }

    getPropertyData = async () => {
        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId

        const result = await callAPI(`avatar/property/${propertyId}`, 'GET')

        if (result['success'] === true) {
            console.log(result.property)
            this.setState({
                ...this.state,
                dataLoaded: true,
                property: result.property,
            })
            document.title = `Avatar Property: ${result.property.domain}`
        } else if (result['success'] === false) {
            this.setState({
                dataLoaded: true,
            })
            document.title = `Avatar Property`
        } else {
            this.props.showSystemMessage('error', result.errors.message)
            this.setState({
                dataLoaded: true,
            })
            document.title = `Avatar Property`
        }
    }

    getPropertyStatus = (status) => {
        if (status.toUpperCase() === 'ACTIVE') {
            return <div className="badge badge-success">{status}</div>
        } else {
            return <i>{status}</i>
        }
    }

    handleDomainChange = (e) => {
        this.setState({
            ...this.state,
            property: {
                ...this.state.property,
                domain: e.target.value,
            },
        })
    }

    handleOfferIdChange = (e) => {
        this.setState({
            ...this.state,
            property: {
                ...this.state.property,
                offer_id: e.target.value,
            },
        })
    }

    updateDomain = async () => {
        this.setState({
            ...this.state,
            domainSubmitLoaded: false,
        })

        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId

        const result = await callAPI(`avatar/property/${propertyId}/domain`, 'PUT', {
            domain: this.state.property.domain,
            offerId: this.state.property.offer_id,
        })
        if (result['success'] === true) {
            this.props.showSystemMessage('success', 'Domain has been updated.')
            this.setState({
                domainSubmitLoaded: true,
            })
        } else if (result['success'] === false) {
            this.props.showSystemMessage('error', 'Domain has not been updated.')
            this.setState({
                domainSubmitLoaded: true,
            })
        } else {
            this.props.showSystemMessage('error', result.errors.message)
            this.setState({
                domainSubmitLoaded: true,
            })
        }
    }

    activateProperty = async () => {
        this.setState({
            ...this.state,
            activateButtonLoaded: false,
        })

        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId
        let result = await callAPI(`avatar/property/${propertyId}/activate`, 'PUT')
        if (result['success'] === true) {
            this.props.showSystemMessage('success', 'Property has been activated.')
            this.setState({
                ...this.state,
                activateButtonLoaded: true,
                property: {
                    ...this.state.property,
                    status: 'Active',
                },
            })
        } else if (result['success'] === false) {
            this.setState({
                activateButtonLoaded: true,
            })
        } else {
            this.props.showSystemMessage('error', result.errors.message)
            this.setState({
                activateButtonLoaded: true,
            })
        }
    }

    deactivateProperty = async () => {
        this.setState({
            ...this.state,
            activateButtonLoaded: false,
        })

        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId
        let result = await callAPI(`avatar/property/${propertyId}/deactivate`, 'PUT')
        if (result['success'] === true) {
            this.props.showSystemMessage('success', 'Property has been deactivated.')
            this.setState({
                ...this.state,
                activateButtonLoaded: true,
                property: {
                    ...this.state.property,
                    status: 'Inactive',
                },
            })
        } else if (result['success'] === false) {
            this.setState({
                activateButtonLoaded: true,
            })
        } else {
            this.props.showSystemMessage('error', result.errors.message)
            this.setState({
                activateButtonLoaded: true,
            })
        }
    }

    getDaysActive = (startDate, endDate) => {
        if (startDate !== '1900-01-01T00:00:00.000Z') {
            let start = moment(startDate)
            let end = moment()
            return end.diff(start, 'days') + 1
        } else {
            return 1
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Managing Avatar Property</h1>
                </div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-3">
                                <PropertySettings propertyId={this.state.property.property_id} offerId={this.state.property.offer_id !== 0 ? this.state.property.offer_id : ''} status={this.state.property.status} domain={this.state.property.domain} handleDomainChange={this.handleDomainChange} handleOfferIdChange={this.handleOfferIdChange} updateDomain={this.updateDomain} domainSubmitLoaded={this.state.domainSubmitLoaded} activateButtonLoaded={this.state.activateButtonLoaded} activate={this.activateProperty} deactivate={this.deactivateProperty} showSystemMessage={this.props.showSystemMessage} />

                                <PropertySets propertyId={this.state.property.property_id} propertySets={this.state.property.property_sets} refreshProperty={this.getPropertyData} showSystemMessage={this.props.showSystemMessage} />
                            </div>

                            <div className="col-md-9">
                                <ReactTooltip />
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h1 className="mb-h1-dark">This Month Stats</h1>
                                        <Link className="btn btn-primary btn-sm" to={`/avatar/reports/set?propertyId=${this.state.property.property_id}`}>
                                            Full Site/Survey Set Report
                                        </Link>
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table table-card table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Site</th>
                                                    <th scope="col">Survey</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" data-tip="Total days running" className="text-right">
                                                        Days
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Completions/Starts
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Conversions
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Cost
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Revenue
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Margin
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.property.property_sets.length > 0 &&
                                                    this.state.property.property_sets.map((propertySet) => {
                                                        return (
                                                            <tr key={propertySet.survey_id}>
                                                                <td>
                                                                    <Link to={`/avatar/site/${propertySet.site_id}`}>{propertySet.meta_title}</Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/avatar/survey/${propertySet.survey_id}`}>{propertySet.survey_description}</Link>
                                                                </td>
                                                                <td>{propertySet.set_status}</td>
                                                                <td align="right">{this.getDaysActive(propertySet.start_date, propertySet.end_date)}</td>
                                                                <td align="right">
                                                                    {' '}
                                                                    {propertySet.completions}/{propertySet.starts} ({Intl.NumberFormat('en-GB').format(propertySet.completion_rate * 100)}%)
                                                                </td>
                                                                <td align="right">
                                                                    <Link to={`/avatar/reports/conversions?propertyId=${this.state.property.property_id}&siteId=${propertySet.site_id}&surveyId=${propertySet.survey_id}`}>{propertySet.conversions}</Link>
                                                                </td>
                                                                <td align="right">
                                                                    <Dollars cost value={propertySet.cost} />
                                                                </td>
                                                                <td align="right">
                                                                    <Dollars value={propertySet.revenue} />
                                                                </td>
                                                                <td align="right">
                                                                    <Dollars bold highlight value={propertySet.margin} />
                                                                </td>
                                                                <td align="right">
                                                                    <AvatarReportsMore avatarAffiliateSummaryReport avatarPostReport avatarDailyReport avatarSurveyQuestionReport avatarSurveyResponseReport avatarConversionReport avatarEventReport avatarAffiliateReport propertyId={this.state.property.property_id} siteId={propertySet.site_id} surveyId={propertySet.survey_id} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="card mt-3">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h1 className="mb-h1-dark">Top 20 Affiliates This Month</h1>
                                        <Link className="btn btn-primary btn-sm" to={`/avatar/reports/affiliate?propertyId=${this.state.property.property_id}`}>
                                            Full Affiliate Report
                                        </Link>
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table table-card table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Affiliate</th>
                                                    <th scope="col" className="text-right">
                                                        Completions/Starts
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Conversions
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Cost
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Revenue
                                                    </th>
                                                    <th scope="col" className="text-right">
                                                        Margin
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.property.affiliates.length > 0 &&
                                                    this.state.property.affiliates.map((affiliate) => {
                                                        return (
                                                            <tr key={affiliate.affiliate_id}>
                                                                <td>{affiliate.affiliate_id}</td>
                                                                <td>
                                                                    <Affiliate aid={affiliate.affiliate_id} name={affiliate.name} status={affiliate.status} priority={affiliate.aff_priority} />
                                                                </td>
                                                                <td align="right">
                                                                    {' '}
                                                                    {affiliate.completions}/{affiliate.starts} ({Intl.NumberFormat('en-GB').format(affiliate.completion_rate * 100)}%)
                                                                </td>
                                                                <td align="right">
                                                                    <Link to={`/avatar/reports/conversions?propertyId=${this.state.property.property_id}&siteId=0&surveyId=0&affiliateId=${affiliate.affiliate_id}`}>{affiliate.conversions}</Link>
                                                                </td>
                                                                <td align="right">
                                                                    <Dollars cost value={affiliate.cost} />
                                                                </td>
                                                                <td align="right">
                                                                    <Dollars value={affiliate.revenue} />
                                                                </td>
                                                                <td align="right">
                                                                    <Dollars bold highlight value={affiliate.margin} />
                                                                </td>
                                                                <td align="right">
                                                                    <AvatarReportsMore avatarDailyReport avatarConversionReport avatarEventReport avatarSetReport propertyId={this.state.property.property_id} affiliateId={affiliate.affiliate_id} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default AvatarProperty
