import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import TableContainer from '../TableContainer'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'

const ReportMerchantBalances = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const { showSystemMessage } = props

    useEffect(() => {
        const mbToken = localStorage.getItem('adm-auth-token')
        setDataLoaded(false)

        let url = `${process.env.REACT_APP_API_URL}/reports/merchants/balancelist`

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setReport(result.report)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error while fetching the data. Please try again.')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in ReportMerchantBalances: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }, [])

    // {
    //     "merchant_id": 8011,
    //     "name": "10443336 Canada Corp . O/A Mobile Intelligence Company",
    //     "balance_date": "2019-11-30T23:59:59.000Z",
    //     "payment_terms": -1,
    //     "invoice_frequency": "M",
    //     "final_balance": -2793.36,
    //     "offer_count": 1,
    //     "inv_status": "N",
    //     "inv_date": "2021-11-03T16:33:16.500Z"
    // }

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    reportType="merchant balances"
                    data={report}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'merchant_id',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Name',
                            accessor: 'name',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]

                                return <Link to={`/merfollowup?mid=${tableDataRef.merchant_id}`}>{tableDataRef.name}</Link>
                            },
                        },
                        {
                            Header: 'Balance',
                            accessor: 'final_balance',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: 'Terms',
                            accessor: 'payment_terms',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]
                                let term = ''
                                if (tableDataRef.payment_terms === -1) {
                                    return <>Prepay</>
                                } else {
                                    if (tableDataRef.invoice_frequency === 'W') {
                                        term = 'Weekly'
                                    } else if (tableDataRef.invoice_frequency === 'S') {
                                        term = 'Semi-Monthly'
                                    } else if (tableDataRef.invoice_frequency === 'M') {
                                        term = 'Monthly'
                                    } else if (tableDataRef.invoice_frequency === 'A') {
                                        term = 'Ad-Hoc'
                                    } else {
                                        term = 'Unknown'
                                    }

                                    return (
                                        <>
                                            <span>{term}</span> Net {tableDataRef.payment_terms}
                                        </>
                                    )
                                }
                            },
                        },
                        {
                            Header: 'Inv Status',
                            accessor: 'inv_status',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]

                                if (tableDataRef.inv_status === 'P') {
                                    return <>Requested</>
                                } else if (tableDataRef.inv_status === 'S') {
                                    return <>Sent</>
                                } else {
                                    return <Link to={`/createinvoice?mid=${tableDataRef.merchant_id}`}>Request Invoice</Link>
                                }
                            },
                        },
                        {
                            Header: 'Status Date',
                            accessor: 'inv_date',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]
                                if (tableDataRef.inv_status !== 'N') {
                                    return <>{moment(tableDataRef.inv_date).format('YYYY/MM/DD')}</>
                                } else {
                                    return <>&nbsp;</>
                                }
                            },
                        },
                        {
                            Header: 'Offer Count',
                            accessor: 'offer_count',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Last Invoice Period',
                            accessor: 'balance_date',
                            Cell: ({ cell: { value } }) => {
                                if (value !== '') {
                                    return <>{moment(value).format('YYYY/MM/DD')}</>
                                } else {
                                    return <>&nbsp;</>
                                }
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return <React.Fragment>{dataLoaded ? report.length === 0 ? <div className="p-3">No Stats for this parameter.</div> : getReportOutput() : <Loading></Loading>}</React.Fragment>
}

export default ReportMerchantBalances
