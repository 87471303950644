import Linkify from 'linkify-react'
import { useEffect, useState } from 'react'
import { Check, X } from 'react-feather'
import { Link } from 'react-router-dom'
import { Modal } from 'reactstrap'

import callAPI from '../../../../../helpers/callAPI'
import Loading from '../../../../../templates/UI/Loading/Loading'
import { colors } from '../../../../../theme/variables'
import IpDetailsContent from '../../../../Affiliate/AffiliateLoginHistory/IpDetails/IpDetailsContent'
import AppRejectModal from '../Components/AppRejectModal/AppRejectModal'
import AppVerifyModal from '../ReviewApplication/AppVerifyModal/AppVerifyModal'
import ExtraInfo from './ExtraInfo/ExtraInfo'
import MatchingAffiliates from './MatchingAffiliates/MatchingAffiliates'
import PhoneCheck from './PhoneCheck/PhoneCheck'
import IPCheck from './IPCheck/IPCheck'
import Affiliate from '../../../../../templates/Affiliate/Affiliate'

const VerifyApplication = ({ affiliateId, showSystemMessage, handleReviewClose }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliateInfo, setAffiliateInfo] = useState({})
    const [emailCheck, setEmailCheck] = useState({})
    const [ipDetails, setIpDetails] = useState({})
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [showApprovalModal, setShowApprovalModal] = useState(false)
    const [showNotesModal, setShowNotesModal] = useState(false)
    const [showIpDetailsModal, setShowIpDetailsModal] = useState(false)
    const [checkID, setCheckId] = useState(false)
    const [flags, setFlags] = useState([])

    useEffect(async () => {
        let apiUrl = `affiliate/${affiliateId}/verifyApplication`

        let affInfoResult = await callAPI(apiUrl, 'GET')

        if (affInfoResult.success === false) {
            showSystemMessage('error', affInfoResult.errors.message)
        }

        if (affInfoResult['success'] === true) {
            setAffiliateInfo(affInfoResult.affiliate_info)
            setEmailCheck(affInfoResult.email_check)
            setIpDetails(affInfoResult.ip_details)
            setFlags(affInfoResult.flags)
            setDataLoaded(true)
        }
    }, [])

    let whoIsUrl = `http://www.whois.sc/`
    if (dataLoaded) {
        if (affiliateInfo.website.substring(0, 7) !== 'http://' && affiliateInfo.website.substring(0, 8) !== 'https://') {
            whoIsUrl += `http://` + affiliateInfo.website
        } else {
            whoIsUrl += affiliateInfo.website
        }
    }

    // To get Check/X icon based on IPQS value being true/false
    const getIcon = (status) => {
        if (status) {
            return <Check height={16} width={16} />
        } else {
            return <X height={16} width={16} />
        }
    }

    // To get colour for icon based on IPQS value being true/false
    const getColor = (status) => {
        if (status) {
            return colors.success
        } else {
            return colors.danger
        }
    }

    const toggleModal = () => {
        setShowRejectModal(false)
        setShowApprovalModal(false)
        setShowNotesModal(false)
        setShowIpDetailsModal(false)
    }

    // isEdited - indicates that verify/reject/approve etc modals are used and decision was made. In that case we need to close the parent modal as well.
    const handleClose = (event, isEdited = false) => {
        if (event) event.preventDefault()
        setShowRejectModal(false)
        setShowApprovalModal(false)
        setShowIpDetailsModal(false)
        if (isEdited) {
            handleReviewClose(true)
            window.opener.document.location.reload();
            window.close()
        }
    }

    const handleNotesClose = (isEdited = false) => {
        setShowNotesModal(false)
    }

    // This function is to update extra_info, additional_contacts in local state when they're updated from "Notes" modal
    const handleNotesInfo = (extraInfo, additionalContacts) => {
        setAffiliateInfo({ ...affiliateInfo, extra_info: extraInfo, additional_contacts: additionalContacts })
    }

    // Submitting "Check ID"
    const submidIdForm = (e) => {
        e.preventDefault()

        setCheckId(true)

        let putBody = {
            idNumber: e.target.identification_number.value,
        }

        callAPI(`affiliate/${affiliateId}/idCheck`, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliateInfo({ ...affiliateInfo, image_id: e.target.identification_number.value })
                } else if (result['success'] === false) {
                    showSystemMessage('error', `ID Check error: ${result.errors.message}`)
                }
                setCheckId(false)
            })
            .catch((error) => {
                setCheckId(true)
                showSystemMessage('error', `ID Check error: ${error.message}`)
            })
    }

    return (
        <div className="card">
            <Modal isOpen={showRejectModal} size="lg" toggle={toggleModal}>
                <AppRejectModal affiliateName={affiliateInfo.name} affiliateId={affiliateId} handleClose={handleClose} showSystemMessage={showSystemMessage} complianceReviewed={true} />
            </Modal>
            <Modal isOpen={showApprovalModal} size="lg" toggle={toggleModal}>
                <AppVerifyModal affiliateName={affiliateInfo.name} affiliateId={affiliateId} handleClose={handleClose} showSystemMessage={showSystemMessage} />
            </Modal>
            <Modal isOpen={showNotesModal} size="lg" toggle={toggleModal}>
                <ExtraInfo affiliateName={affiliateInfo.name} affiliateId={affiliateId} affExtraInfo={affiliateInfo.extra_info} additionalContacts={affiliateInfo.additional_contacts} handleClose={handleNotesClose} showSystemMessage={showSystemMessage} updateExtraInfo={handleNotesInfo} />
            </Modal>
            <Modal isOpen={showIpDetailsModal} size="md" toggle={handleClose}>
                <IpDetailsContent ipAddress={affiliateInfo.remote_host} handleClose={handleClose} isModal={true} />
            </Modal>
            <div className="card-header">
                <div className="font-weight-bold float-left">Verify Application</div>
                <div className="float-right">
                    <Link target="_blank" to={`/affiliate/${affiliateId}`}>
                        View Affiliate
                    </Link>
                </div>
            </div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        {affiliateInfo.experience_level >= 2 && affiliateInfo.country_tier === 1 && <div className="alert alert-success font-weight-bold m-3">⚠️ Applicant is experienced from Tier 1 country. Approve after 3rd call without contact. ⚠️</div>}
                        {flags.length > 0 && <div className="alert alert-warning font-weight-bold m-3">{flags.map(flag => <div key={flag.flag_id}> {flag.flag_name} ({flag.flag_id}) </div>)}</div>}
                        {affiliateInfo.marketing.campaign !== '' && <div className="alert alert-success font-weight-bold m-3">📣 Marketing Application: {affiliateInfo.marketing.campaign} / {affiliateInfo.marketing.platform}</div>}
                        <table className="table table-striped table-borderless m-0">
                            <tbody style={{ lineHeight: '25px' }}>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Company</span>
                                        <span>{affiliateInfo.name}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Payee</span>
                                        <span>{affiliateInfo.payment_method === 'N' ? 'Not Applicable' : affiliateInfo.payment_method}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">ID #</span>
                                        <span>{affiliateInfo.id}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Name</span>
                                        <span>{affiliateInfo.contact}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Affiliate Manager</span>
                                        <span>{affiliateInfo.manager_name}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Address</span>
                                        <span>
                                            {affiliateInfo.address1}
                                            <br />
                                            {affiliateInfo.address2 && affiliateInfo.address2}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">City</span>
                                        <span>{affiliateInfo.city}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Country</span>
                                        <span>{affiliateInfo.country}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Zip Code</span>
                                        <span>{affiliateInfo.zipCode}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Mobile</span>
                                        <span>{affiliateInfo.mobile}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">phone</span>
                                        <span>{affiliateInfo.phone}</span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Skype</span>
                                        <span>
                                            <a href={`skype:${affiliateInfo.skype}?chat`}>{affiliateInfo.skype}</a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-block text-secondary">Email</span>
                                        <span>
                                            <a href={`mailto:${affiliateInfo.email}`}>{affiliateInfo.email}</a>
                                        </span>
                                    </td>
                                    <td>
                                        <span className="d-block text-secondary">Heard from</span>
                                        <span>
                                            {affiliateInfo.heard_from.source} {affiliateInfo.heard_from.source === 'other' && '-'} {affiliateInfo.heard_from.details}
                                        </span>
                                    </td>
                                    <span className="d-block text-secondary">Referrer</span>
                                    <span>
                                        {affiliateInfo.referrer_id > 0 ? <Affiliate aid={affiliateInfo.referrer_id} name={affiliateInfo.referrer_name} status={affiliateInfo.referrer_status} priority={affiliateInfo.referrer_priority} /> : 'None'}
                                    </span>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Referring URL</span>
                                        <span>
                                            <Linkify options={{ target: '_blank' }}>{affiliateInfo.referring_url}</Linkify>
                                        </span>
                                    </td>
                                </tr>
                                <tr>                                    
                                    <td colSpan={1}>
                                        <span className="d-block text-secondary">Identification Photos</span>
                                        <button
                                            className="btn btn-link shadow-none"
                                            type="button"
                                            onClick={() => {
                                                window.open(`/affiliate/${affiliateId}/photoId/front`, '_blank', 'height=750,width=800')
                                            }}
                                        >
                                            Front
                                        </button>
                                        <button
                                            className="btn btn-link shadow-none"
                                            type="button"
                                            onClick={() => {
                                                window.open(`/affiliate/${affiliateId}/photoId/back`, '_blank', 'height=750,width=800')
                                            }}
                                        >
                                            Back
                                        </button>
                                    </td>
                                    <td colSpan={2}>
                                        <span className="d-block text-secondary">Check ID (Type "None" if invalid ID is provided)</span>
                                        <span>
                                            <form name="identification_form" method="post" className="d-flex align-items-center" onSubmit={submidIdForm}>
                                                <input type="text" name="identification_number" className="form-control mr-1 w-50" defaultValue={affiliateInfo.image_id} />{' '}
                                                {checkID ? (
                                                    <button type="submit" disabled name="id_submit" className="btn btn-primary">
                                                        Check ID
                                                    </button>
                                                ) : (
                                                    <button type="submit" name="id_submit" className="btn btn-primary">
                                                        Check ID
                                                    </button>
                                                )}
                                            </form>
                                        </span>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td colSpan={3}><Flags flags={flags} showSystemMessage={showSystemMessage} affiliateId={affiliateId} /></td>
                                </tr> */}
                                <MatchingAffiliates matchType="id" affiliateId={affiliateId} imageId={affiliateInfo.image_id} showSystemMessage={showSystemMessage} />
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Email Check</span>
                                        <span className="d-flex justify-content-between align-items-center">
                                            <span style={{ color: getColor(emailCheck.valid) }}>{getIcon(emailCheck.valid)} Valid</span>
                                            <span style={{ color: getColor(emailCheck.disposable) }}>{getIcon(emailCheck.disposable)} Disposable</span>
                                            <span style={{ color: getColor(emailCheck.recent_abuse) }}>{getIcon(emailCheck.recent_abuse)} Recent Abuse</span>
                                            <span>Fraud Score : {emailCheck.fraud_score}</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Quick Location Check</span>
                                        <span>
                                            Accept Language: {ipDetails.accept_language} <br />
                                            IP Address:{' '}
                                            <a
                                                href={`/ipDetails?ip=${ipDetails.ip_address}`}
                                                className="btn btn-link shadow-none"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setShowIpDetailsModal(true)
                                                }}
                                            >
                                                {ipDetails.ip_address}
                                            </a>
                                            <br />
                                            <IPCheck ip={ipDetails.ip_address} showSystemMessage={showSystemMessage} />
                                            <span>
                                                Location: {ipDetails.ip_region}, {ipDetails.ip_city}, {ipDetails.ip_country}
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Phone Check</span>
                                        <PhoneCheck mobile={affiliateInfo.mobile} phone={affiliateInfo.phone} showSystemMessage={showSystemMessage} />
                                    </td>
                                </tr>

                                <MatchingAffiliates matchType="phone" affiliateId={affiliateId} mobile={affiliateInfo.mobile} phone={affiliateInfo.phone} showSystemMessage={showSystemMessage} />
                                <MatchingAffiliates matchType="im" affiliateId={affiliateId} imType={affiliateInfo.im_type} imId={affiliateInfo.skype} showSystemMessage={showSystemMessage} />
                                <MatchingAffiliates matchType="ip" affiliateId={affiliateId} ipAddress={affiliateInfo.remote_host} showSystemMessage={showSystemMessage} />
                                <MatchingAffiliates matchType="address" affiliateId={affiliateId} address1={affiliateInfo.address1} city={affiliateInfo.city} showSystemMessage={showSystemMessage} />
                                <MatchingAffiliates matchType="password" affiliateId={affiliateId} showSystemMessage={showSystemMessage} passwordText={affiliateInfo.password_text} />
                                <MatchingAffiliates matchType="contact" affiliateId={affiliateId} contact={affiliateInfo.contact} company={affiliateInfo.name} showSystemMessage={showSystemMessage} />
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Campaign Interest</span>
                                        <span>{affiliateInfo.campaign_interest ? affiliateInfo.campaign_interest : 'N/A'}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">
                                            Website{' '}
                                            {affiliateInfo.website !== 'Left Blank' && (
                                                <>
                                                    -<a href={whoIsUrl}>WHOIS Check</a>
                                                </>
                                            )}
                                        </span>
                                        <span>
                                            <Linkify>{affiliateInfo.website}</Linkify>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Experience Level</span>
                                        <span>{affiliateInfo.experience}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Promotional Method</span>
                                        <span>{affiliateInfo.promotion}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <span className="d-block text-secondary">Experience Description</span>
                                        <span>{affiliateInfo.experience_desc}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="m-0" />
                        <div className="text-center p-3">
                            <button className="btn btn-warning" onClick={() => setShowNotesModal(true)}>
                                Notes
                            </button>
                            <button className="btn btn-danger ml-5" onClick={() => setShowRejectModal(true)}>
                                Reject
                            </button>
                            {affiliateInfo.image_id.length === 0 ? (
                                <button className="btn btn-primary ml-5" disabled title="Requires ID number check.">
                                    Verify
                                </button>
                            ) : (
                                <button className="btn btn-primary ml-5" onClick={() => setShowApprovalModal(true)}>
                                    Verify
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default VerifyApplication
