import { Form } from 'react-bootstrap'
const PriorityPicker = ({ selectedPriority, setSelectedPriority, className }) => {
    const options = [
        { code: 0, value: 'Any' },
        { code: 1, value: 'High' },
        { code: 2, value: 'Medium' },
        { code: 3, value: 'New' },
        { code: 4, value: 'Low' },
        { code: 5, value: 'Very Low' },
    ]

    return (
        <Form.Control className={`w-50 ${className}`} as="select" size="sm" onChange={(event) => setSelectedPriority({ code: event.target.value, value: options[event.target.value].value })} defaultValue={selectedPriority.code} name="priority">
            {options.map((option, index) => {
                return (
                    <option key={index} value={option.code}>
                        {option.value}
                    </option>
                )
            })}
        </Form.Control>
    )
}

export default PriorityPicker
