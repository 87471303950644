import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { Button, Card } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'

export default function ComplianceFollowups({ showSystemMessage }) {
    const { adminId: urlAdminId, mode, buildUrl } = useQueryParams()
    const [adminId, setAdminId] = useState(urlAdminId ? urlAdminId : 0)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliates, setAffiliates] = useState([])
    const history = useHistory()
    const location = useLocation()

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    useEffect(() => {
        getReport()
    }, [location])

    const getReport = () => {
        setDataLoaded(false)

        let url = (mode === 'team' || mode === 'unassigned') && adminId === 0 ? `compliance/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}&mode=${mode}` : `compliance/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}&adminId=${adminId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliates(result.affiliates)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching compliance followups: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching compliance followups')
            })
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = (mode === 'team' || mode === 'unassigned') && adminId === 0 ? buildUrl(`/compliance/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}&mode=${mode}`) : buildUrl(`/compliance/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)

        // let url = buildUrl(`/compliance/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)

        history.push(url)
    }

    const style = (date) => {
        if (date < moment().utc().format('YYYY-MM-DD HH:mm')) {
            return 'bgDanger25'
        } else {
            return ''
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                conditionalClassName: (value, row) => {
                    return style(row.followups.next_followup_date)
                },
            },
            {
                Header: 'Name',
                accessor: 'affiliate.name',
                Cell: ({ cell }) => <Affiliate name={cell.row.original.affiliate.name} aid={cell.row.original.affiliate.id} status={cell.row.original.affiliate.status.value} priority={cell.row.original.affiliate.priority.value} />,
                conditionalClassName: (value, row) => {
                    return style(row.followups.next_followup_date)
                },
            },
            {
                Header: 'Priority',
                accessor: 'affiliate.priority.name',
                conditionalClassName: (value, row) => {
                    return style(row.followups.next_followup_date)
                },
            },
            {
                Header: 'Next Followup',
                accessor: 'followups.next_followup_date',
                Cell: ({ cell }) => moment(cell.row.original.followups.next_followup_date).utc().format('YYYY-MM-DD'),
                conditionalClassName: (value, row) => {
                    return style(row.followups.next_followup_date)
                },
            },
        ]

        return (
            <>
                <ReportTable data={affiliates} columns={columns} />
            </>
        )
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <Card>
                    <div className="card-body">
                        <div className="d-flex justify-content-start align-items-center">
                            <h1 className="mb-h1-dark mr-2">Compliance Followups For</h1>
                            <span className="mx-2" style={{ width: '20%' }}>
                                <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['COMPLIANCE', 'COMPLIANCE_DIRECTOR']} />
                            </span>
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <Button className="ml-2" onClick={refreshReport} size="sm">
                                Refresh Report
                            </Button>
                        </div>
                    </div>

                    {dataLoaded ? getReportOutput() : <Loading />}
                </Card>
            </div>
        </div>
    )
}
