import React, { Component } from 'react'

class RedirectList extends Component {
    state = { tempFile: '' }

    render() {
        let { question, order, options = [], questionId, negCallToAction } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Redirect List</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control d-inline-block" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-3">
                    {options.map((option, index) => {
                        let classes = "m-3 pb-3 d-flex align-items-center"
                        if (index < options.length - 1) {
                            classes += " border-bottom"
                        }
                        return (
                            <div key={index} className={classes}>
                                <div className="border rounded mr-2">
                                    {option.dbOptionId > 0 ?
                                        <div>
                                            {option.image ? <div className='p-2'><img src={option.image} crossOrigin="" alt="image" style={{ maxWidth: '170px', maxHeight: '110px', border: '2px solid' }} /></div> : <div className="p-2">Upload Image</div>}
                                            <div className="d-flex align-items-center">
                                                <input ref={this.leftBackgroundImage} onChange={this.props.handleChangeRedirectList(questionId, option.optionId, 'image')} type="file" accept="image/png, image/jpeg" className="form-control mb-maxw-500 m-2" name="leftBackgroundImage" />
                                                <button className="btn btn-link" onClick={this.props.handleRemoveImage(questionId, option.optionId)}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="p-2">
                                            You must publish before you can add an image to a new option.
                                        </div>
                                    }
                                </div>
                                <div className="w-100">
                                    <textarea type="text" className="form-control" placeholder={'Description text.'} value={option.description} onChange={this.props.handleChangeRedirectList(questionId, option.optionId, 'description')}></textarea>
                                    <textarea className="form-control mt-2" type="text" defaultValue={option.url} placeholder="Add URL Here" onChange={this.props.handleChangeRedirectList(questionId, option.optionId, 'url')}></textarea>
                                    <input value={option.value} className="form-control mt-2" placeholder="Value for response report" onChange={this.props.handleChangeRedirectList(questionId, option.optionId, 'value')} />
                                </div>
                                {options.length > 1 && (
                                    <button onClick={() => this.props.handleRemoveMultipleChoice(questionId, option.optionId)} className="ml-2 btn btn-danger badge">
                                        X
                                    </button>
                                )}
                            </div>
                        )
                    })}
                    <div className="m-3 text-center">
                        <button className="btn btn-warning" onClick={() => this.props.handleAddMultipleChoice(questionId)}>
                            + Add Link
                        </button>
                    </div>
                    <div className="m-3 text-center">
                        <input className="form-control text-center" type="text" defaultValue={negCallToAction} placeholder="No Thanks!" onChange={this.props.handleChangeNegCallToAction(questionId)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default RedirectList