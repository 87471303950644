import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import AvatarSurveyRecord from './components/AvatarSurveyRecord'

export default function AvatarSurveyManager(props) {

    document.title = 'Avatar Survey Manager'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'avatar')

    const [newSurveyAdded, setNewSurveyAdded] = useState(false)

    const showSystemMessage = props.showSystemMessage
    const { data, dataLoaded } = useCallApi(`avatar/surveys`, 'GET', {}, [])

    useEffect(() => {
        setNewSurveyAdded(false)
    }, [newSurveyAdded]) // Passing an array as second argument = componentDidMount

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Avatar Survey Manager</h1>
            </div>
            {dataLoaded ? (
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h1 className="mb-h1-dark">Survey Manager</h1>
                                <Link to="/avatar/survey/new" className="btn btn-sm btn-warning">
                                    + New Survey
                                </Link>
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Survey ID</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Active on</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.surveys.map((survey) => {
                                            return <AvatarSurveyRecord key={survey.survey_id} survey={survey} showSystemMessage={showSystemMessage} setNewSurveyAdded={setNewSurveyAdded} />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ marginTop: '4rem' }}>
                    <Loading />
                </div>
            )}
        </React.Fragment>
    )
}
