import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

import Graph from '../../../../components/Graph/Graph'
import styles from '../JAmSummary/JAmSummary.module.scss'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import ReactTooltip from 'react-tooltip'
import Number from '../../../../templates/Number/Number'

const AffiliateHealthSummary = (props) => {
    const [selectedGraphTime, setSelectedGraphTime] = useState('daily') // change graph
    const [appsReport, setAppsReport] = useState([])
    const [trendsReport, setTrendsReport] = useState([])
    const [avgCampaigns, setAvgCampaigns] = useState(0)
    const [affsWithleads, setAffsWithLeads] = useState(0)
    const [prevAvgCampaigns, setPrevAvgCampaigns] = useState(0)
    const [prevAffsWithleads, setPrevAffsWithleads] = useState(0)
    const [applications, setApplications] = useState({})
    const [affiliates, setAffiliates] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    const [graphLoaded, setGraphLoaded] = useState(false)

    const { showSystemMessage } = props

    useEffect(async () => {
        let startDate, endDate

        if (selectedGraphTime === 'daily') {
            endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
            startDate = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        } else if (selectedGraphTime === 'monthly') {
            endDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
            startDate = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss')
        }

        let url = `reports/affiliate_application?startDate=${startDate}&endDate=${endDate}`

        let graphResult = await callAPI(url, 'GET')
        let affsResult = await callAPI(`summary/affiliateHealth`, 'GET')

        if (graphResult.success === false || affsResult.success === false) {
            showSystemMessage('error', 'There was an error while fetching the data. Please try again')
        }

        // Data to set up graphs
        if (graphResult.success === true) {
            setAppsReport(graphResult.reports.applicationReport)
            setTrendsReport(graphResult.reports.trendReport)
        }

        // Data to show all affiliate numbers
        if (affsResult.success === true) {
            setAvgCampaigns(affsResult.avg_campaigns_per_affiliate)
            setAffsWithLeads(affsResult.affiliates_with_leads)
            setPrevAvgCampaigns(affsResult.prev_avg_offs_per_aff_30d)
            setPrevAffsWithleads(affsResult.prev_affs_with_leads_30d)
            setApplications(affsResult.applications)
            setAffiliates(affsResult.affiliates)
        }

        setGraphLoaded(true)
        setDataLoaded(true)
    }, [selectedGraphTime])

    let graphData

    if (selectedGraphTime === 'daily') {
        let labels = []
        let totalApps = []

        appsReport.forEach((stat) => {
            labels.push(moment(stat.date).utc().format('ddd MM-DD'))
            totalApps.push(stat.total_apps)
        })

        labels.reverse()
        totalApps.reverse()

        graphData = {
            labels: labels,
            datasets: [
                {
                    data: totalApps,
                    fill: true,
                    backgroundColor: 'rgba(156, 29, 191, 0.2)',
                    borderColor: 'rgba(156, 29, 191, 1)',
                },
            ],
        }
    } else if (selectedGraphTime === 'monthly') {
        let labels = []
        let totalApps = []

        trendsReport.forEach((stat) => {
            labels.push(stat.the_date)
            totalApps.push(stat.total_apps)
        })

        graphData = {
            labels: labels,
            datasets: [
                {
                    data: totalApps,
                    fill: true,
                    backgroundColor: 'rgba(156, 29, 191, 0.2)',
                    borderColor: 'rgba(156, 29, 191, 1)',
                },
            ],
        }
    }

    const handleTimeChange = (event) => {
        setSelectedGraphTime(event.target.value)
    }

    const getDelta = (prev, current) => {
        if (current > prev) {
            return <span style={{ color: 'green', fontSize: '10px' }}>&#9650;</span>
        } else if (prev > current) {
            return <span style={{ color: 'red', fontSize: '10px' }}>&#9660;</span>
        } else {
            return
        }
    }

    return (
        <div className="card mb-2">
            <div className="card-header d-flex justify-content-between">
                <div>MaxBounty Affiliate Summary</div>
                <Link to={`/reports/affiliate_signup`}>Full Report</Link>
            </div>
            <div style={{ fontSize: '.9rem', minHeight: '338.6px' }}>
                <div className="align-items-center d-flex p-2">
                    <div className="font-weight-bold float-left">Apps</div>
                    <div className="ml-3">
                        <select className="form-control form-contorl-sm" onChange={handleTimeChange} defaultValue="daily">
                            <option value="daily">Daily</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>
                </div>
                <div className="align-items-center d-flex" style={{ minHeight: '150px' }}>
                    {graphLoaded ? <Graph data={graphData} /> : <Loading />}
                </div>
                <hr className="mt-0 mb-0" />

                {dataLoaded ? (
                    <>
                        <div className="d-flex justify-content-between">
                            <ReactTooltip />
                            <div className="border-right py-3 px-5">
                                Apps Today &nbsp;
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Approved/Processed</Tooltip>}>
                                    <span className="mb-element">
                                        <span className="name text-primary">
                                            <Link to="/affiliates/applications">
                                                <strong>{applications.approved}</strong>/{applications.total}
                                            </Link>
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="p-3">
                                To Verify &nbsp;
                                <span className="text-primary font-weight-bold">
                                    <Link to="/compliance/applications">{applications.to_verify}</Link>
                                </span>
                            </div>
                            <div className="p-3">
                                To Call &nbsp;
                                <span className="text-primary font-weight-bold">
                                    <Link to="/affiliates/applications">{applications.to_call}</Link>
                                </span>
                            </div>
                        </div>
                        <hr className="mt-0 mb-0" />
                        <div className="d-flex">
                            <div className="w-50 p-3 border-right text-center">
                                Avg Campaigns/Aff (30d) &nbsp;
                                <span className="font-weight-bold" data-tip="Current (Last Month)">
                                    {getDelta(prevAvgCampaigns, avgCampaigns)} <Number value={avgCampaigns} decimals={2} /> (<Number value={prevAvgCampaigns} decimals={2} />)
                                </span>
                            </div>
                            <div className="w-50 p-3 text-center">
                                Affs with leads (30d) &nbsp;
                                <span className="font-weight-bold" data-tip="Current (Last Month)">
                                    {getDelta(prevAffsWithleads, affsWithleads)} <Number value={affsWithleads} /> (<Number value={prevAffsWithleads} />)
                                </span>
                            </div>
                        </div>
                        <hr className="mt-0 mb-0" />
                        <div className="d-flex p-3 justify-content-around">
                            <div>
                                High &nbsp;
                                <span className="mb-element green-highlight" data-tip="Current (Last Month)">
                                    <Link to={`/affiliates/search?z=1&status=A&priority=1`} className="name font-weight-bold">
                                        {getDelta(affiliates.prev_high, affiliates.high)} <Number value={affiliates.high} /> (<Number value={affiliates.prev_high} />)
                                    </Link>
                                </span>
                            </div>
                            <div>
                                Medium &nbsp;
                                <span className="mb-element highlight" data-tip="Current (Last Month)">
                                    <Link to={`/affiliates/search?z=1&status=A&priority=2`} className="name font-weight-bold">
                                        {getDelta(affiliates.prev_medium, affiliates.medium)} <Number value={affiliates.medium} /> (<Number value={affiliates.prev_medium} />)
                                    </Link>
                                </span>
                            </div>
                            <div>
                                New &nbsp;
                                <span className="mb-element purple-highlight" data-tip="Current (Last Month)">
                                    <Link to={`/affiliates/search?z=1&status=A&priority=3`} className="name font-weight-bold">
                                        {getDelta(affiliates.prev_new, affiliates.new)} <Number value={affiliates.new} /> (<Number value={affiliates.prev_new} />)
                                    </Link>
                                </span>
                            </div>
                        </div>
                        {/* <hr className="mt-0 mb-0" /> */}
                        <div className="mx-3 mb-3">
                            <ProgressBar>
                                <ProgressBar variant="green-highlight" now={(affiliates.high / (affiliates.high + affiliates.medium + affiliates.new)) * 100} key={1} />
                                <ProgressBar variant="yellow-highlight" now={(affiliates.medium / (affiliates.high + affiliates.medium + affiliates.new)) * 100} key={2} />
                                <ProgressBar variant="purple-highlight" now={(affiliates.new / (affiliates.high + affiliates.medium + affiliates.new)) * 100} key={3} />
                            </ProgressBar>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default AffiliateHealthSummary
