import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { HelpCircle } from 'react-feather'
import useQueryParams from '../../hooks/useQueryParams'

import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter'

import useMaxbountyEntities from '../../hooks/useMaxbountyEntities'

import ReportAppliedFilters from '../../templates/ReportAppliedFilters/ReportAppliedFilters'

import useDownloadCSV from '../../hooks/useDownloadCSV'
import DownloadCSV from '../../templates/DownloadCSV/DownloadCSV'

import AffiliateNavbar from '../Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'

import ReactTooltip from 'react-tooltip'
import AdminPicker from '../../components/AdminPicker/AdminPicker'
import AdvertiserNavbar from '../Advertiser/AdvertiserNavBar/AdvertiserNavBar'
import CampaignNavbar from '../Campaign/CampaignNavBar/CampaignNavBar'
import AdvertiserReport from './Components/AdvertiserReport/AdvertiserReport'
import AffiliateReport from './Components/AffiliateReport/AffiliateReport'
import CampaignReport from './Components/CampaignReport/CampaignReport'
import ConversionReport from './Components/ConversionReport/ConversionReport'
import CreativesReport from './Components/CreativesReport/CreativesReport'
import DailyReport from './Components/DailyReport/DailyReport'
import EarningsReport from './Components/EarningsReport/EarningsReport'
import LandingPagesReport from './Components/LandingPagesReport/LandingPagesReport'
import MonthlyReport from './Components/MonthlyReport/MonthlyReport'
import SubidReport from './Components/SubidReport/SubidReport'

export default function ReportsStats({ type, showSystemMessage }) {
    const location = useLocation()
    const history = useHistory()

    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { affiliateId, campaignId, advertiserId, creativeId, landingPageId, subid1, withAvatar, setWithAvatar, navigation, buildUrl, adminId, setAdminId, hideNetwork, setHideNetwork, businessAge } = useQueryParams()
    const { affiliate, setAffiliate, campaign, setCampaign, advertiser, setAdvertiser } = useMaxbountyEntities()
    const { CSVFilename, setCSVFilename, CSVHeaderLabels, setCSVHeaderLabels, CSVDataLabels, setCSVDataLabels, CSVReport, setCSVReport } = useDownloadCSV()

    let reportTitle, reportComponent

    let showAdminDropdown = false
    let adminDropdownRoles = false

    let showAvatarSwitch = false
    let showHideNetworkStats = false

    // if (affiliateId === 0 && campaignId === 0 && advertiserId === 0) {
    //     reportComponent = <div className="m-3">Invalid report parameters.</div>
    // } else {
    switch (type) {
        case 'conversions':
            reportTitle = 'Conversions'
            reportComponent = <ConversionReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            break
        case 'earnings':
            reportTitle = 'Earnings'
            reportComponent = <EarningsReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showHideNetworkStats = true
            break
        case 'daily':
            reportTitle = 'Daily'
            reportComponent = <DailyReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showHideNetworkStats = true
            break
        case 'creatives':
            reportTitle = 'Creatives'
            reportComponent = <CreativesReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showHideNetworkStats = true
            break
        case 'landingPages':
            reportTitle = 'Landing Pages'
            reportComponent = <LandingPagesReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showHideNetworkStats = true
            break
        case 'subid':
            reportTitle = 'Subid 1'
            reportComponent = <SubidReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showHideNetworkStats = true
            break
        case 'campaigns':
            reportTitle = 'Campaigns'
            reportComponent = <CampaignReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showAdminDropdown = true
            showHideNetworkStats = true
            adminDropdownRoles = ['AM_SENIOR', 'AM_JUNIOR', 'AM_JR_TEAMLEAD', 'MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']
            break
        case 'affiliates':
            reportTitle = 'Affiliates'
            reportComponent = <AffiliateReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showAdminDropdown = true
            showHideNetworkStats = true
            adminDropdownRoles = ['AM_JUNIOR', 'AM_JR_TEAMLEAD', 'AM_SENIOR']
            break
        case 'advertisers':
            reportTitle = 'Advertisers'
            reportComponent = <AdvertiserReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showAdminDropdown = true
            adminDropdownRoles = ['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']
            break
        case 'monthly':
            reportTitle = 'Monthly'
            reportComponent = <MonthlyReport showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} setCSVFilename={setCSVFilename} setCSVHeaderLabels={setCSVHeaderLabels} setCSVDataLabels={setCSVDataLabels} setCSVReport={setCSVReport} />
            showAvatarSwitch = true
            showHideNetworkStats = true
            break
        default:
            break
        // }
    }

    document.title = `${navigation === 'advertiser' ? `${advertiser.name}: ` : navigation === 'campaign' ? `${campaign.name}: ` : navigation === 'affiliate' ? `${affiliate.name}: ` : ''} ${reportTitle} Report`

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = buildUrl(`${location.pathname}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
                {navigation === 'affiliate' && (
                    <div className="ml-5">
                        <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                    </div>
                )}
                {navigation === 'advertiser' && (
                    <div className="ml-5">
                        <AdvertiserNavbar advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                    </div>
                )}
                {navigation === 'campaign' && (
                    <div className="ml-5">
                        <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <ReactTooltip />
                            <div className="d-flex justify-content-between align-items-center p-3 flex-wrap">
                                <div className="d-flex align-items-center flex-wrap">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">{reportTitle}</h1>
                                    </div>
                                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />

                                    {showAdminDropdown && (
                                        <div className="mr-2">
                                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={adminDropdownRoles} />
                                        </div>
                                    )}

                                    {showAvatarSwitch /*&& ((advertiserId > 0 || affiliateId > 0 || campaignId > 0 || adminId > 0))*/ ? (
                                        <div className="p-1 border rounded mr-2">
                                            <div className="d-flex align-items-center mx-3">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" className="custom-control-input" id="avatarStatsSwitch" onChange={() => setWithAvatar(withAvatar === 'true' ? 'false' : 'true')} defaultChecked={withAvatar === 'true'} />
                                                    <label className="custom-control-label" htmlFor="avatarStatsSwitch">
                                                        Include Avatar Stats <HelpCircle className="text-primary" size={14} data-tip="Including Avatar stats will add additional network earnings attributed to this affiliate." />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {showHideNetworkStats && (
                                        <div className="p-1 border rounded mr-2">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="filterSwitch" onClick={() => setHideNetwork(hideNetwork === 'true' ? 'false' : 'true')} defaultChecked={hideNetwork === 'true'} />
                                                <label className="custom-control-label" htmlFor="filterSwitch">
                                                    Hide Network Stats
                                                </label>
                                            </div>
                                        </div>
                                    )}

                                    <button onClick={refreshReport} className="btn btn-sm btn-primary mr-2">
                                        Refresh Report
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <DownloadCSV filename={CSVFilename} headerLabels={CSVHeaderLabels} data={CSVReport} dataLabels={CSVDataLabels} />
                                </div>
                            </div>
                            <div>
                                <ReportAppliedFilters affiliate={affiliate} campaign={campaign} advertiser={advertiser} creativeId={creativeId} landingPageId={landingPageId} subid1={subid1} businessAge={businessAge} />
                                {reportComponent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
