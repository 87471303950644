import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import { HelpCircle } from 'react-feather'
import ReactTooltip from 'react-tooltip'

class SurveySettingsModal extends Component {
    state = {
        submitButton: this.props.submitButton ? this.props.submitButton : 'Submit',
        submitDisclaimer: this.props.submitDisclaimer ? this.props.submitDisclaimer : '',
        fraudCheck: this.props.fraudCheck ? this.props.fraudCheck : false,
        pushnami: this.props.pushnami,
        backButton: this.props.backButton,
    }

    handleEditSubmitButton = (e) => {
        this.setState({
            ...this.state,
            submitButton: e.target.value,
        })
    }

    handleEditSubmitDisclaimer = (e) => {
        this.setState({
            ...this.state,
            submitDisclaimer: e.target.value,
        })
    }

    handleFraudCheck = (e) => {
        this.setState({
            ...this.state,
            fraudCheck: e.target.checked,
        })
    }

    handlePushnami = (e) => {
        this.setState({
            ...this.state,
            pushnami: {
                ...this.state.pushnami,
                enabled: !this.state.pushnami.enabled,
            },
        })
    }

    handlePushnamiId = (e) => {
        this.setState({
            ...this.state,
            pushnami: {
                ...this.state.pushnami,
                id: e.target.value,
            },
        })
    }

    handlePushnamStep = (e) => {
        this.setState({
            ...this.state,
            pushnami: {
                ...this.state.pushnami,
                step: e.target.value,
            },
        })
    }

    handleBackButtonEnabled = (e) => {
        this.setState({
            ...this.state,
            backButton: {
                ...this.state.backButton,
                enabled: !this.state.backButton.enabled,
            },
        })
    }

    handleBackButtonUrl = (e) => {
        this.setState({
            ...this.state,
            backButton: {
                ...this.state.backButton,
                url: e.target.value,
            },
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggleModal} className="modal-lg">
                <ReactTooltip />
                <div className="modal-header">
                    <h5 className="modal-title">Survey Settings</h5>
                    <button type="button" className="close" onClick={this.props.toggleModal}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label for="submit_button">Submit Button Text (default is "Submit")</label>
                        <input name="submit_button" className="form-control" onChange={(e) => this.handleEditSubmitButton(e)} defaultValue={this.props.submitButton}></input>
                    </div>
                    <div className="form-group">
                        <label for="disclaimer">Submit page disclaimer</label>
                        <textarea name="disclaimer" className="form-control" onChange={(e) => this.handleEditSubmitDisclaimer(e)} defaultValue={this.props.submitDisclaimer}></textarea>
                    </div>
                    <div className="alert alert-info mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="fraudSwitch" onClick={(e) => this.handleFraudCheck(e)} checked={this.state.fraudCheck} />
                            <label className="custom-control-label" for="fraudSwitch">
                                Enable Traffic Filtering
                            </label>{' '}
                            <HelpCircle className="text-primary" size={16} data-tip="If enabled, affiliate conversions will not be paid out unless fraud checks are passed." />
                        </div>
                    </div>
                    <div className="alert alert-info mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="pushnamiSwitch" onClick={this.handlePushnami} checked={this.state.pushnami.enabled} />
                            <label className="custom-control-label" for="pushnamiSwitch">
                                Pushnami Push Notifications
                            </label>{' '}
                            <HelpCircle className="text-primary" size={16} data-tip="Installation of manifest.json and service-worker.js in filesystem is required. Please contact IT if you are making changes to Pushnami on this property for the first time." />
                        </div>
                        {this.state.pushnami.enabled && (
                            <div className="mt-2">
                                <input name="pushnamiId" className="form-control" type="text" value={this.state.pushnami.id} onChange={this.handlePushnamiId} placeholder="Pushnami ID" />
                                <input name="pushnamiStep" className="form-control mt-2" type="text" value={this.state.pushnami.step} onChange={this.handlePushnamStep} placeholder="Question number for push" />
                                <div className="alert alert-primary mt-2 text-small">Disable this setting on the affiliate level by adding &psh=1 to their override landing URL. For example: https://avatarsite.com?mbi=#ACID#&psh=1</div>
                            </div>
                        )}
                    </div>
                    <div className="alert alert-info mt-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="backButtonSwitch" onClick={(e) => this.handleBackButtonEnabled(e)} checked={this.state.backButton.enabled} />
                            <label className="custom-control-label" for="backButtonSwitch">
                                Enable Back Button Override
                            </label>{' '}
                            <HelpCircle className="text-primary" size={16} data-tip="If enabled, browser's back button will go to this URL instead." />
                        </div>
                        {this.state.backButton.enabled && (
                            <div className="mt-2">
                                <input name="backButtonUrl" className="form-control" type="text" value={this.state.backButton.url} onChange={this.handleBackButtonUrl} placeholder="https://website.com" />
                            </div>
                        )}
                    </div>
                    <div className="text-center mt-3">
                        <button className="btn btn-primary" onClick={() => this.props.handleSurveySettings(this.state.submitButton, this.state.submitDisclaimer, this.state.fraudCheck, this.state.pushnami, this.state.backButton)}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default SurveySettingsModal
