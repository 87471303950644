import React, { Component } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { MoreVertical } from 'react-feather'
import { Link } from 'react-router-dom'

class ReportsMoreAvatar extends Component {
    state = {
        dropdownOpen: false,
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    getParams = () => {
        let params = '?z=1'
        if (this.props.propertyId) {
            params = `${params}&propertyId=${this.props.propertyId}`
        }
        if (this.props.siteId) {
            params = `${params}&siteId=${this.props.siteId}`
        }
        if (this.props.surveyId) {
            params = `${params}&surveyId=${this.props.surveyId}`
        }
        if (this.props.affiliateId) {
            params = `${params}&affiliateId=${this.props.affiliateId}`
        }
        if (this.props.offerId) {
            params = `${params}&offerId=${this.props.offerId}`
        }
        if (this.props.subid1) {
            params = `${params}&subid1=${this.props.subid1}`
        }
        if (this.props.clientId) {
            params = `${params}&clientId=${this.props.clientId}`
        }
        if (this.props.eventId) {
            params = `${params}&eventId=${this.props.eventId}`
        }
        if (this.props.startDate) {
            params = `${params}&startDate=${this.props.startDate}`
        }
        if (this.props.endDate) {
            params = `${params}&endDate=${this.props.endDate}`
        }

        return params
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction="left">
                <DropdownToggle tag="a" onClick={this.toggle} data-toggle="dropdown" aria-expanded={this.state.dropdownOpen} className="report-more-toggle">
                    <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.avatarDailyReport && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/daily${this.getParams()}`} onClick={this.toggle}>
                                By Day
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarAffiliateReport && (!this.props.affiliateId || this.props.affiliateId === 0 || this.props.affiliateId === '0') && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/affiliate${this.getParams()}`} onClick={this.toggle}>
                                By Affiliate
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarOfferReport && !this.props.offerId && this.props.subid1 !== '' && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/offer${this.getParams()}`} onClick={this.toggle}>
                                By Offer
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarPropertyReport && !this.props.propertyId && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/property${this.getParams()}`} onClick={this.toggle}>
                                By Property
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarSetReport && !this.props.siteId && !this.props.setId && this.props.propertyId > 0 && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/set${this.getParams()}`} onClick={this.toggle}>
                                By Site/Survey Set
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.subid && this.props.affiliateId > 0 && this.props.propertyId > 0 && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/subid${this.getParams()}`} onClick={this.toggle}>
                                By SubId
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}

                    {this.props.avatarConversionReport && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/conversions${this.getParams()}`} onClick={this.toggle}>
                                Conversion Report
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarEventReport && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/events${this.getParams()}`} onClick={this.toggle}>
                                Event Report
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarSurveyQuestionReport && this.props.surveyId > 0 && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/survey_questions${this.getParams()}`} onClick={this.toggle}>
                                Survey Dropoff Report
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarSurveyResponseReport && this.props.surveyId > 0 && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/survey_responses${this.getParams()}`} onClick={this.toggle}>
                                Survey Response Report
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarPostReport && this.props.surveyId > 0 && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/post${this.getParams()}`} onClick={this.toggle}>
                                Post Report
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.avatarAffiliateSummaryReport && (this.props.propertyId > 0 || this.props.surveyId > 0 || this.props.siteId > 0) && (
                        <>
                            <Link className="dropdown-item" to={`/avatar/reports/affiliateSummary${this.getParams()}`} onClick={this.toggle}>
                                Affiliate Summary
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                    {this.props.searchId && (
                        <>
                            <Link className="dropdown-item" to={`/searchId?actionId=${this.props.actionId}`} onClick={this.toggle}>
                                View Action
                            </Link>
                            <DropdownItem divider className="m-0" />{' '}
                        </>
                    )}
                </DropdownMenu>
            </Dropdown>
        )
    }
}

export default ReportsMoreAvatar
