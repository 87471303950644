import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useQueryParams from '../../../hooks/useQueryParams'

export default function AMPerformanceReport({ showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'AM_performance_report')

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const { data, dataLoaded, refetch } = useCallApi(`admin/AMPerformanceReport?adminId=${adminId}`, 'GET', {}, [adminId], showSystemMessage)
    const history = useHistory()

    useEffect(() => {
        history.push(buildUrl(`/admin/AMPerformanceReport?z=1`))
    }, [adminId, history])

    return (
        <React.Fragment>
            <Card className="mb-3">
                <Card.Header className=" justify-content-between align-items-center">
                    <span className="d-flex align-items-center">
                        <div className="mr-2">Affiliate Manager Performance Report</div>
                        <div>
                            <AdminPicker role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} setAdminId={setAdminId} adminId={adminId} />
                        </div>
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex">
                        <div className="alert alert-success mr-2">
                            MTD Revenue: <span className="font-weight-bold">{dataLoaded && data.success && <Dollars value={data.data.total_margin} />}</span>
                        </div>
                        <div className="alert alert-success">
                            Projected MTD Revenue: <span className="font-weight-bold">{dataLoaded && data.success && <Dollars value={data.data.projected_margin} />}</span>
                        </div>
                    </div>
                    {dataLoaded && data.success ? (
                        <>
                            <h5 className="font-weight-bold">Total Affiliate Margin</h5>
                            <div className="d-flex justify-content-between align-items-center">
                                <Line
                                    data={{
                                        labels: data.data.monthly_margin.map((item) => moment(item.stats_month).utc().format('MMM/YY')),
                                        datasets: [
                                            {
                                                data: data.data.monthly_margin.map((item) => item.monthly_margin.toFixed(2)),
                                                backgroundColor: 'rgba(23,162, 184, 0.1)',
                                                borderColor: 'rgba(23, 162, 184, 1)',
                                                fill: false,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        display: true,
                                                        autoSkip: true,                        
                                                        maxTicksLimit: 10,
                                                        callback: function(value, index, values) {                           
                                                            return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)                                                   
                                                        }
                                                    },
                                                },
                                            ]
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function(tooltipItem, data) {                    
                                                    return '$' + Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tooltipItem.yLabel)                   
                                                }
                                            }
                                        }
                                    }}
                                    width={320}
                                    height={200}
                                />
                            </div>
                            <h5 className="font-weight-bold mt-5">Total Active Affiliates</h5>
                            <div className="d-flex justify-content-between align-items-center">
                                <Line
                                    data={{
                                        labels: data.data.monthly_margin.map((item) => moment(item.stats_month).utc().format('MM/YY')),
                                        datasets: [
                                            {
                                                data: data.data.monthly_margin.map((item) => item.total_affiliates),
                                                backgroundColor: 'rgba(23,162, 184, 0.1)',
                                                borderColor: 'rgba(23, 162, 184, 1)',
                                                fill: false,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                    }}
                                    width={320}
                                    height={200}
                                />
                            </div>

                            <h5 className="font-weight-bold mt-5">Affiliate Margin Threshold</h5>
                            <div className="d-flex justify-content-between align-items-center">
                                <Line
                                    data={{
                                        labels: data.data.monthly_margin.map((item) => moment(item.stats_month).utc().format('MM/YY')),
                                        datasets: [
                                            {
                                                data: data.data.monthly_margin_100.map((item) => item.total_affiliates.toFixed(2)),
                                                backgroundColor: 'rgba(23,162, 184, 0.1)',
                                                borderColor: 'rgba(23, 162, 184, 1)',
                                                fill: false,
                                            },
                                            {
                                                data: data.data.monthly_margin_1000.map((item) => item.total_affiliates.toFixed(2)),
                                                backgroundColor: 'rgba(255, 99, 132, 0.1)',
                                                borderColor: 'rgba(255, 99, 132, 1)',
                                                fill: false,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: false,
                                        legend: false,
                                    }}
                                    width={320}
                                    height={200}
                                />
                            </div>
                            <div className="alert">
                                <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                        <div style={{ height: '15px', width: '15px', backgroundColor: 'rgba(23,162, 184, 1)' }}></div>
                                        <div className="ml-2">Over $100</div>
                                    </div>
                                    <div className="d-flex align-items-center ml-5">
                                        <div style={{ height: '15px', width: '15px', backgroundColor: 'rgba(255, 99, 132, 1)' }}></div>
                                        <div className="ml-2">Over $1,000</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Loading />
                    )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
