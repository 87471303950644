const serverColor = (status) => {
    if (status === "A") {
        return "text-success"
    } else if (status === "C") {
        return "text-danger"
    } else {
        return "text-warning"
    }
}

export default serverColor