import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Advertiser extends Component {
    state = {}
    render() {
        /*
            Allowed props:
                status: highlight, active, cancelled
        */

        let classes = 'mb_element'

        // Issues with this at the moment as we're passing in an object into the prop in several locations. Should just be passing the status.value
        // if (this.props.status !== '') {
        //     if (this.props.status.toUpperCase() === 'CANCELLED' || this.props.status.toUpperCase() === 'C') {
        //         classes = `mb-element cancelled`
        //     }
        // }

        if (this.props.green) {
            classes = `mb-element active`
        }

        return (
            <Link className={classes} to={`/advertiser/${this.props.id}`}>
                <span className="name">{this.props.name}</span>
            </Link>
        )
    }
}

export default Advertiser
