import React from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../../helpers/callAPI'
import Affiliate from '../../../../../templates/Affiliate/Affiliate'
import { useState } from 'react'

const ResetPassword = (props) => {
    const { affiliateId, showSystemMessage, affiliateName, status, priority, handleClose, refetch } = props
    
    const [submitStatus, setSubmitStatus] = useState('unsubmitted')
    const [newPassword, setNewPassword] = useState(false)

    const resetPassword = (e) => {    
        
        e.preventDefault()

        let url = `affiliate/${affiliateId}/resetPassword`

        callAPI(url, 'POST')
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Password has been reset.')
                    setSubmitStatus('success')
                    setNewPassword(result['password'])                    
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitStatus('unsubmitted')
                }
            })
            .catch((error) => {
                console.log(`Error while resetting affiliate password: ${error.message}`)
                showSystemMessage('error', `Error while reseting affiliate password - ${error.message}`)   
                setSubmitStatus('unsubmitted')                               
            })
    }

    const handleSubmitStatus = (e, status) => {
        e.preventDefault()
        setSubmitStatus(status)
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Reset Password : <Affiliate aid={affiliateId} name={affiliateName} status={status} priority={priority} />
                </Modal.Title>
            </Modal.Header>
            <div className="card">
                <div className="card-body p-3">                    
                    <div className="mb-2">Reset the affiliate's password to a new randomly generated password. The password will be displayed in this window after confirming.</div>
                    <div className="text-center">
                        {
                            (submitStatus === 'success' && newPassword) ?
                                <div className="text-center p-2 border rounded">
                                    New password:
                                    <div className='mt-2 font-weight-bold alert alert-secondary'>{newPassword}</div>
                                </div>
                            :
                                submitStatus === 'unsubmitted' ?
                                    <button className="btn btn-outline-primary" onClick={(e)=>handleSubmitStatus(e, 'confirmed')}>
                                        Reset Password
                                    </button>
                                : submitStatus === 'confirmed' ?
                                    <button className="btn btn-primary" onClick={(e)=>resetPassword(e)}>
                                        Confirm
                                    </button>
                                :
                                    <button className="btn btn-secondary" disabled>
                                        Confirm
                                    </button>
                        }
                        
                    </div>                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default ResetPassword
