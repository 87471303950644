import { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import CampaignSearch from '../../../../components/CampaignSearch/CampaignSearch'
import { useHistory } from 'react-router-dom'
import AffiliateSearch from '../../../../components/AffiliateSearch/AffiliateSearch'
import useCallApi from '../../../../hooks/useCallApi'

const JobsModal = ({ showSystemMessage, refetch, jobId, setJobId, isCreate, setJobIsOpen }) => {
    const { dataLoaded, data, setData } = useCallApi(`admin/job/${jobId}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()
    const [formData, setFormData] = useState({
        date: new Date(),
        name: 'TEST',
        campaign: null,
        affiliate: {},
        param1: '',
        param2: '',
        param3: '',
        status: 'P',
    })

    const [campaign, setCampaign] = useState({})

    useEffect(() => {
        if (!isCreate && dataLoaded && data.jobs?.length > 0) {
            const jobIndex = data.jobs.findIndex((job) => parseInt(job.job_id) === parseInt(jobId))
            if (data.jobs[jobIndex] || data.jobs[0].job_id === jobId) {
                setFormData({
                    date: moment(data.jobs[jobIndex].job_date).toDate(),
                    name: data.jobs[jobIndex].job_name,
                    campaign: {
                        id: data.jobs[jobIndex].offer_id,
                        name: data.jobs[jobIndex].offer_name,
                    },
                    affiliate: {
                        id: data.jobs[jobIndex].affiliate_id,
                        name: data.jobs[jobIndex].affiliate_name,
                    },
                    param1: data.jobs[jobIndex].param1,
                    param2: data.jobs[jobIndex].param2,
                    param3: data.jobs[jobIndex].param3,
                    status: data.jobs[jobIndex].job_status,
                })
            }
        }
    }, [data, dataLoaded])

    useEffect(() => {
        if (campaign.id) {
            setFormData({ ...formData, campaign: campaign })
        }
    }, [campaign])

    const validate = () => {
        let isValidForm = true
        if (formData.name === '') {
            showSystemMessage('error', 'Job name is required')
            isValidForm = false
            return isValidForm
        } else if (formData.date === '') {
            showSystemMessage('error', 'Date is required')
            isValidForm = false
        }
        return isValidForm
    }
    const createJob = async () => {
        if (validate()) {
            const result = await callAPI(`admin/job`, 'POST', { ...formData })
            if (result.success) {
                showSystemMessage('success', 'Job created successfully')
                setJobId(24849)
                refetch()
                setJobIsOpen(false)
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const editPromo = async () => {
        if (validate()) {
            const result = await callAPI(`admin/job/${jobId}`, 'PUT', { ...formData })
            if (result.success) {
                showSystemMessage('success', 'Promotion updated successfully')
                setJobId(0)
                refetch()
                setJobIsOpen(false)
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }
    return (
        <Card>
            <Card.Header closeButton>{isCreate ? 'Create Job' : 'Edit Job'}</Card.Header>
            {dataLoaded && (
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Scheduled Date/Time: </span>
                        <div className="w-75">
                            <DatePicker placeholderText="Scheduled Date" className="form-control form-control" selected={formData.date} showTimeSelect onChange={(date) => setFormData({ ...formData, date })} dateFormat="yyyy/MM/dd hh:mm:ss" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Job Name: </span>
                        <div className="w-75">
                            <Form.Control as="select" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })}>
                                <option value="TEST">Test Job</option>
                                <option value="RATE">Change Campaign Rate</option>
                                <option value="PUBRATE">Change Pub Rate</option>
                                <option value="CANCEL">Cancel Campaign</option>
                                <option value="RELAUNCH">Relaunch Campaign</option>
                                <option value="PUBPULL">Remove Pub from Campaign</option>
                                <option value="MERPULL">Remove Pub from Merchant</option>                                
                            </Form.Control>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Campaign: </span>
                        <div className="w-75">
                            <CampaignSearch setCampaign={setCampaign} campaign={formData.campaign} order={2} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Affiliate: </span>
                        <div className="w-75">
                            <AffiliateSearch setFormData={setFormData} affiliate={formData.affiliate.id ? formData.affiliate : false} order={1} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Parameter 1: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.param1} onChange={(e) => setFormData({ ...formData, param1: e.target.value })} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Parameter 2: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.param2} onChange={(e) => setFormData({ ...formData, param2: e.target.value })} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Parameter 3: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.param3} onChange={(e) => setFormData({ ...formData, param3: e.target.value })} />
                        </div>
                    </div>
                </Card.Body>
            )}
            {isCreate ? (
                <Card.Footer className="d-flex justify-content-end">
                    <Button variant="primary" onClick={createJob} size="sm">
                        Create
                    </Button>
                </Card.Footer>
            ) : (
                <Card.Footer className="d-flex justify-content-end">
                    <Button variant="primary" onClick={editPromo} size="sm">
                        Save
                    </Button>
                </Card.Footer>
            )}
        </Card>
    )
}

export default JobsModal
