import moment from 'moment'
import { User } from 'react-feather'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'

const ApplicationListItem = ({ mode, application, handleDupClick, handleClick }) => {

    const getOutput = () => {
        if (mode === 'compliance' || mode === 'marketing') {
            if (application.followup_count === 0) {
                let countryClass = application.country_tier === 1 ? 'text-success font-weight-bold' : ''
                let experienceClass = (application.experience_level.value === 3 || (application.experience_level.value === 2 && application.country_tier === 1 )) ? 'text-success font-weight-bold' : ''

                return (
                    <tr key={application.affiliate.id} className={application.experience_level.value === 3 || (application.experience_level.value === 2 && application.country_tier === 1) ? 'bgSuccess25' : ''}>
                        <td>{application.affiliate.id}</td>
                        <td>
                            <FontAwesomeIcon icon={faAddressCard} className="text-primary mr-1" />
                            <Link to={`/affiliate/${application.affiliate.id}/application/verify`} onClick={(e) => handleClick(e, application.affiliate.id)}>
                                {application.affiliate.name}
                            </Link>
                            {application.experience_level.value === 3 && application.country_tier === 1 ? <span className="ml-2">⭐</span> : ''}
                        </td>
                        <td>
                            <span className={experienceClass}>{application.experience_level.text}</span>
                        </td>
                        <td>
                            <span className={countryClass}>{application.country}</span>
                        </td>
                        <td>
                            {application.affiliate_time}, {application.contact_time}
                        </td>
                        <td>{moment(application.application_date).format('YYYY/MM/DD')}</td>
                        <td className="text-center">
                            {application.affiliate.status.value === 'D' ? (
                                <button className="btn btn-link shadow-none" onClick={() => handleDupClick(application.affiliate.id)}>
                                    <User size={14} color={'red'} fill={'red'} />
                                </button>
                            ) : null}
                        </td>
                    </tr>
                )
            }
        } else if (mode === 'affiliate_manager') {
            if (application.followup_count === 0 || (application.experience_level.value >= 2 && application.followup_count < 3)) {

                let countryClass = application.country_tier === 1 ? 'text-success font-weight-bold' : ''
                let experienceClass = application.experience_level.value === 3 ? 'text-success font-weight-bold' : ''

                return (
                    <tr key={application.affiliate.id} className={application.experience_level.value === 3 || (application.experience_level.value === 1 && application.country_tier === 1) ? 'bgSuccess25' : ''}>
                        <td>{application.affiliate.id}</td>
                        <td>
                            <FontAwesomeIcon icon={faAddressCard} className="text-primary mr-1" />
                            <Link to={`/affiliate/${application.affiliate.id}/application/review`} onClick={(e) => handleClick(e, application.affiliate.id)}>
                                {application.affiliate.name}
                            </Link>
                            {application.experience_level.value === 3 && application.country_tier === 1 ? <span className="ml-2">⭐</span> : ''}
                        </td>
                        <td>
                            <span className={experienceClass}>{application.experience_level.text}</span>
                        </td>
                        <td>
                            <span className={countryClass}>{application.country}</span>
                        </td>
                        <td>
                            {application.affiliate_time}, {application.contact_time}
                        </td>
                        <td>{moment(application.application_date).format('YYYY/MM/DD')}</td>
                    </tr>
                )
            }
        }
    }

    return (
        <>
            {getOutput()}
        </>

    )
}

export default ApplicationListItem