import React, { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useCallApi from '../../../hooks/useCallApi'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import ScreenshotsModal from '../../Campaign/CampaignSettings/Edit/LandingPages/Screenshots'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useEffect } from 'react'
import callAPI from '../../../helpers/callAPI'

const MissingScreenshots = ({ showSystemMessage }) => {

    document.title = 'Missing Screenshots'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaigns_missing_screenshots')

    const [isOpen, setIsOpen] = useState(false)
    const [campaignId, setCampaignId] = useState(0)
    const { data, setData, dataLoaded, refetch } = useCallApi(`campaigns/missingScreenshots`, 'GET', {}, [])

    const [lpDataLoaded, setLpDataLoaded] = useState(false)
    const [lpData, setLpData] = useState(false)
    const [cDataLoaded, setCdataLoaded] = useState(false)
    const [cData, setCData] = useState(false)

    useEffect(async () => {        
        if (campaignId > 0){            
            const result = await callAPI(`campaign/${campaignId}/landingPages`, 'GET')
            if (result['success'] === true) {                
                setLpData(result)
                setLpDataLoaded(true)
            } else if (result['success'] === false) {
                console.log('Error fetching landing pages.')
            } else {
                console.log('Error fetching landing pages.')
            }

            const result2 = await callAPI(`campaign/${campaignId}`, 'GET')
            if (result2['success'] === true) {
                setCData(result2)
                setCdataLoaded(true)
            } else if (result2['success'] === false) {
                console.log('Error fetching campaign info.')
            } else {
                console.log('Error fetching campaign info.')
            }

        }
    }, [campaignId])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'campaign.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status} />
                },
            },
            {
                Header: 'Status',
                accessor: 'campaign.status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser Manager',
                accessor: 'merchantMgr.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'LP Name',
                accessor: 'landingPage.name',
                Cell: (tableData) => {
                    return (
                        <a target="_blank" href={tableData.data[tableData.row.index].landingPage.url}>
                            {tableData.data[tableData.row.index].landingPage.name} <FaExternalLinkAlt color="#007bff" />
                        </a>
                    )
                },
            },
            {
                Header: '',
                accessor: 'campaign',
                Cell: (tableData) => {
                    return (
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                setIsOpen(true)
                                setCampaignId(tableData.data[tableData.row.index].campaign.id)
                            }}
                        >
                            Screenshot
                        </Button>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.campaigns.filter( campaign => campaign.display )} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Live Campaigns with Missing Screenshots</h1>
            </div>
            <Card className="mb-3">
                {dataLoaded && lpDataLoaded && cDataLoaded && (
                    <ScreenshotsModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        showSystemMessage={showSystemMessage}
                        refetch={refetch}
                        campaignId={campaignId}
                        landingPages={lpData.landingPages}
                        campaignData={cData.campaign}
                        dataLoaded={lpDataLoaded}
                        selectedLP={
                            lpData.landingPages.length > 0
                                ? {
                                      lp_record_id: lpData.landingPages[0].lp_record_id,
                                      lp_name: lpData.landingPages[0].lp_name,
                                      lp_landing_url: lpData.landingPages[0].lp_landing_url,
                                      lp_default_flag: lpData.landingPages[0].lp_default_flag,
                                      lp_status: lpData.landingPages[0].lp_status,
                                  }
                                : ''
                        }
                    />
                )}

                {dataLoaded ? data.campaigns.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
            </Card>
        </div>
    )
}

export default MissingScreenshots
