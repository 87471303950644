import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ReportDormantAffiliates = ({ showSystemMessage }) => {
    document.title = 'Affiliates Nearing Dormancy'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_dormant_report')

    const { adminId, setAdminId } = useQueryParams()
    const history = useHistory()

    const [report, setReport] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        getReport()
    }, [])

    const refreshReport = (e) => {
        e.preventDefault()

        let url = `/affiliates/dormant`

        if (adminId > 0) {
            url = url + `?adminId=${adminId}`
        }

        history.push(url)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = `affiliates/dormant`

        if (adminId > 0) {
            url = url + `?mgrId=${adminId}`
        }

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log('Error while fetching dormant affiliates: ', error)
                showSystemMessage('error', 'An error occurred while fetching dormant affiliates')
            })
    }

    const calcTimeString = (time) => {
        let timeString = time.split(',')
        if (timeString[1] !== undefined) {
            return `${moment().add(timeString[1], 'hours').format('HH:mm')}`
        }

        // let timezone = 'a' //affiliateDetails[0][0].time_zone;
        // let timezoneComma = timezone.indexOf(',') + 1
        // let timezoneOffset = timezone.slice(-1 * (timezone.length - timezoneComma))

        // let time = moment().add(timezoneOffset, 'hour').utc().format('hh:mm a')
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} priority={tableData.data[tableData.row.index].aff_priority} />
                },
            },
            {
                Header: 'Affiliate Time',
                accessor: 'best_time_to_call',
                Cell: (tableData) => {
                    let bestTimeToCall = tableData.data[tableData.row.index].best_time_to_call
                    let callTime_text = bestTimeToCall === 1 ? 'Early Morning' : bestTimeToCall === 2 ? 'Late Morning' : bestTimeToCall === 3 ? 'Early Afternoon' : bestTimeToCall === 4 ? 'Late Afternoon' : bestTimeToCall === 5 ? 'Evening' : 'Anytime'

                    return (
                        <>
                            <span className="mr-3">{calcTimeString(tableData.data[tableData.row.index].time_zone, tableData.data[tableData.row.index].best_time_to_call)}</span>
                            <span>{callTime_text}</span>
                        </>
                    )
                },
            },
            {
                Header: 'Lifetime Earnings',
                accessor: 'lifetime_earnings',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Last Login',
                accessor: 'last_login_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'Last Followup',
                accessor: 'last_followup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Affiliates Nearing Dormancy for:</h1>
                        </div>
                        <div className="mr-4">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR']} />
                        </div>
                        <div className="d-flex align-items-center">
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default ReportDormantAffiliates
