import React from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'

import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import useMaxbountyEntities from '../../hooks/useMaxbountyEntities'
import useQueryParams from '../../hooks/useQueryParams'
import ReportAppliedFilters from '../../templates/ReportAppliedFilters/ReportAppliedFilters'
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter'
import ByAdvertiser from './ByAdvertiser/ByAdvertiser'
import ByAffiliate from './ByAffiliate/ByAffiliate'
import ByCampaign from './ByCampaign/ByCampaign'
import ByReverser from './ByReverser/ByReverser'

const ReversalsReport = ({ showSystemMessage, type }) => {
    document.title = 'Reversal Report'

    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const { buildUrl } = useQueryParams()
    const { affiliate, setAffiliate, campaign, setCampaign, advertiser, setAdvertiser } = useMaxbountyEntities()
    const history = useHistory()
    const location = useLocation()

    let reportComponent

    switch (type) {
        case 'affiliates':
            reportComponent = <ByAffiliate showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} />
            break
        case 'campaigns':
            reportComponent = <ByCampaign showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} />
            break
        case 'reverser':
            reportComponent = <ByReverser showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} />
            break
        default:
            reportComponent = <ByAdvertiser showSystemMessage={showSystemMessage} setAffiliate={setAffiliate} setCampaign={setCampaign} setAdvertiser={setAdvertiser} />
            break
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = buildUrl(`${location.pathname}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)

        history.push(url)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reversal Report</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                    Refresh Report
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ReportAppliedFilters affiliate={affiliate} campaign={campaign} advertiser={advertiser} />
                        {reportComponent}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReversalsReport
