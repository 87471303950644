import React, { useState, useEffect } from 'react'
import { HorizontalBar } from 'react-chartjs-2'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import callAPI from '../../../../helpers/callAPI'

const SurveyQuestionReport = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [report, setReport] = useState([])
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, subid1 = '', startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            if (surveyId > 0) {
                setDataLoaded(false)

                let fetchUrl = `reports/avatar/surveyQuestions?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&subid1=${subid1}`

                let result = await callAPI(fetchUrl, 'GET')
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setProperty({ propertyId: propertyId, name: result.property })
                    setSite({ siteId: siteId, name: result.site })
                    setSurvey({ surveyId: surveyId, name: result.survey })
                    setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                    setReport(result.report)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                setDataLoaded(true)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, subid1, startDate, endDate]) // Passing an array as second argument (props), means if the the argument variable has changed, it will re-run this effect.

    const labelArray = []
    const dataArray = []
    const backgroundColorArray = []
    report.map((question, index) => {
        labelArray.push(question.questionText.match(/(?:^|\b)[\w\W]{1,36}(?:\b|$)/gi))
        labelArray[index][0] = `Q${question.questionNumber}: ${labelArray[index][0]}`

        dataArray.push(question.responses)

        // Color should be blue if conditional, red if normal.
        question.conditional ? backgroundColorArray.push('rgb(0, 123, 255)') : backgroundColorArray.push('rgb(220, 53, 69)')

        return true
    })

    const data = {
        labels: labelArray,
        datasets: [
            {
                label: 'Responses submitted',
                data: dataArray,
                fill: false,
                backgroundColor: backgroundColorArray,
                borderColor: backgroundColorArray,
            },
        ],
    }

    const getHeight = () => {
        let questions = report.length
        let height = questions * 80
        return height
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name} cancelDrilldownUrl={`/avatar/reports/survey_questions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=0&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&subid1=${subid1}`}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name} cancelDrilldownUrl={`/avatar/reports/survey_questions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=0&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&subid1=${subid1}`}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliate.affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/survey_questions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0`}></DrilldownItem>}
                {subid1 !== 'undefined' && <DrilldownItem for="Subid1" forText={subid1} cancelDrilldownUrl={`/avatar/reports/survey_questions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}`}></DrilldownItem>}
            </div>
            <div className="d-flex mx-2">
                <div className="mx-2">
                    <span className="badge badge-danger">&nbsp;&nbsp;&nbsp;</span> = Required
                </div>
                <div className="mx-2">
                    <span className="badge badge-primary">&nbsp;&nbsp;&nbsp;</span> = Conditional
                </div>
            </div>
            {dataLoaded ? (
                report.length === 0 ? (
                    <div className="p-3">No stats for the current parameters.</div>
                ) : (
                    <div className="margin-auto" style={{ minHeight: `${getHeight()}px`, maxWidth: '1000px' }}>
                        <HorizontalBar
                            data={data}
                            width={100}
                            height={100}
                            options={{
                                maintainAspectRatio: false,
                                legend: { display: false },
                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                precision: 0,
                                            },
                                        },
                                    ],
                                },
                            }}
                        />
                    </div>
                )
            ) : (
                <Loading></Loading>
            )}
        </div>
    )
}

export default SurveyQuestionReport
