import React, { useState } from 'react'

import useCallApi from '../../../hooks/useCallApi'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import ApprovalQuestionModal from './ApprovalQuestionModal/ApprovalQuestionModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ApprovalQuestions = ({ showSystemMessage }) => {

    document.title = 'Campaign Approval Questions'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_approval_questions')

    const { dataLoaded, data, refetch } = useCallApi(`campaigns/approvalQuestions`, 'GET', {}, [])

    const [isCreate, setIsCreate] = useState(false)
    const [showCreateQuestionModal, setShowCreateQuestionModal] = useState(false)
    const [selectedQuestion, setSelectedQuestion] = useState({})

    const handleClick = (questionId, questionText) => {
        setSelectedQuestion({
            id: questionId,
            text: questionText,
        })
        setShowCreateQuestionModal(true)
    }

    const handleClose = (isEdited) => {
        setShowCreateQuestionModal(false)
        setSelectedQuestion({})
        setIsCreate(false)

        if (isEdited) {
            refetch()
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Question',
                accessor: 'question_text',
                Cell: (tableData) => {
                    return (
                        <button className="btn btn-link shadow-none" onClick={() => handleClick(tableData.data[tableData.row.index].question_id, tableData.data[tableData.row.index].question_text)}>
                            {tableData.data[tableData.row.index].question_text}
                        </button>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.approval_questions} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            {showCreateQuestionModal && <ApprovalQuestionModal question={selectedQuestion} handleClose={handleClose} isOpen={showCreateQuestionModal} isCreate={isCreate} showSystemMessage={showSystemMessage} />}
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="d-flex justify-content-between p-3 align-items-center">
                            <h1 className="mb-h1-dark">Campaign Approval Questions</h1>
                            <button
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    setIsCreate(true)
                                    setShowCreateQuestionModal(true)
                                }}
                            >
                                Create Question
                            </button>
                        </div>
                        {dataLoaded ? data.approval_questions.length === 0 ? <div className="p-3">No questions.</div> : getReportOutput() : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalQuestions
