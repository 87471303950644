import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'

const CustomButton = ({ spin, disabled, name, type, variant, size, onClick }) => {
    const spinner = <FontAwesomeIcon icon="spinner" spin />
    /*
       Initial state: disabled
       enable once a form is edited - just for Edit Adv/Aff/Campaign pages
       On click - disable and show loading spinners instead of button text
    */
    return (
        <Button type={type} variant={variant} disabled={disabled || spin} size={size} onClick={onClick}>
            {spin ? spinner : name}
        </Button>
    )
}

export default CustomButton

/*
Template: 
    <CustomButton variant="success" size="sm" name="Save" type="submit" spin={!dataLoaded} disabled={isDisabled} onClick={clickHandlerFunction} />

    props:
    - spin : determines when to show spinner instead of button name
    - disabled : If the button is meant to be disabled in initial state
*/
