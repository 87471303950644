import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../context/AuthContext'

export default function Campaign(props) {
    /*
        Allowed props:
            status: highlight, active, cancelled
    */

    // TODO - need to change the statuses for campaign
    const auth = useAuth()
    const showEditCampaign = useProtectedContent(auth.permissions, 'campaign_settings')

    let classes = 'mb_element'

    if (props.status) {
        if (props.status.toUpperCase() === 'CANCELLED' || props.status.toUpperCase() === 'DORMANT' || props.status.toUpperCase() === 'C' || props.status.toUpperCase() === 'L') {
            classes = `mb-element cancelled`
        }
    }

    if (props.green) {
        classes = `mb-element active`
    }

    let url = props.oid ? `/campaign/${props.oid}` : ''

    return (
        <div>
            {showEditCampaign && props.oid ? (
                <Link className="mr-2" to={`/campaign/${props.oid}/settings`}>
                    <FontAwesomeIcon icon="cog" />
                </Link>
            ) : (
                ''
            )}
            <Link className={classes} to={{ pathname: url }}>
                <span className="name">{props.name} {((props.status && props.status.toUpperCase() !== 'CANCELLED' && props.status.toUpperCase() !== 'DORMANT' && props.status.toUpperCase() !== 'C' && props.status.toUpperCase() !== 'L') && props.affiliateStatus && props.affiliateStatus !== 'A') && <>⛔</>}</span>
            </Link>
        </div>
    )
}
