import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Calendar } from 'react-feather'
import callAPI from '../../helpers/callAPI'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../CampaignSearch/CampaignSearch'

const PullAffiliateModal = ({ data, setData, showSystemMessage, isOpen, handleClose, advertiserId }) => {
    let [affiliate, setAffiliate] = useState(data.affiliate)
    let [campaign, setCampaign] = useState(data.campaign)
    const [submitting, setSubmitting] = useState(false)
    const [date, setDate] = useState('')

    useEffect(async () => {
        // Check to see if there is already a scheduled job to pull this affiliate, campaign, advertiser combo
        if (isOpen) {
            setAffiliate(data.affiliate)
            setCampaign(data.campaign)
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (moment(date).format('YYYY/MM/DD HH:mm:ss') < moment().format('YYYY/MM/DD HH:mm:ss')) {
            e.target.date_time.setCustomValidity('The date/time you entered has already gone by.')
            e.target.date_time.reportValidity()
            return false
        }

        setSubmitting(true)

        let postBody = {
            affiliate_id: affiliate.id,
            campaign_id: campaign.id,
            date: date,
            reason: e.target.reason.value,
            pull_advertiser: e.target.pull_advertiser.checked,
            notes: e.target.notes.value,
            advertiser_id: advertiserId
        }

        callAPI(`admin/affiliatePull/create`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Affiliate pulled successfully.')
                    handleClose()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                    return false
                }
            })
            .catch((error) => {
                console.log(`Error while pulling affiliate: ${error}`)
                setSubmitting(false)
                showSystemMessage('error', 'An error occurred while pulling affiliate.')
                return false
            })
    }


    const handleDateChange = (date) => {
        let s = document.getElementById('date_time').setCustomValidity('')
        setDate(date)
    }

    const inputChangeHandler = () => {

    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit} method="POST">
                <Modal.Header closeButton>
                    <Modal.Title>Pull Affiliate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table table-borderless m-0'>
                        <tbody>
                            <tr >
                                <td className='w-25'>Affiliate:</td>
                                <td>
                                    <AffiliateSearch affiliate={affiliate} setAffiliate={setAffiliate} />
                                </td>
                            </tr>
                            {campaign.id > 0 && <tr className='mb-2'>
                                <td>Campaign:</td>
                                <td className="w-100">
                                    <CampaignSearch campaign={campaign} setCampaign={setCampaign} />
                                </td>
                            </tr>}
                            {advertiserId > 0 && <tr className='mb-2'>
                                <td>Advertiser:</td>
                                <td>{advertiserId}</td>
                            </tr>}
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Scheduled Date:</td>
                                <td><DatePicker
                                    placeholderText='Select Date & Time'
                                    className='form-control form-control-sm'
                                    selected={date}
                                    minDate={moment().toDate()}
                                    showTimeSelect
                                    dateFormat="yyyy/MM/dd HH:mm:ss"
                                    onChange={(date) => handleDateChange(date)} id="date_time" required autoComplete="off" /><Calendar size={18} color="#007bff" /></td>
                            </tr>
                            <tr>
                                <td className="w-25">Pull from Advertiser:</td>
                                <td>
                                    <input type="checkbox" name="pull_advertiser" defaultChecked={advertiserId > 0 ? true : false} disabled={advertiserId > 0 && !campaign.id ? true : false} onChange={inputChangeHandler} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Reason:
                                </td>
                                <td>
                                    <select className="form-control" name="reason">
                                        <option value={''}>Select Reason</option>
                                        <option value={'LOW_QUALITY'}>Low Quality</option>
                                        <option value={'BUDGET-CAP'}>Budget / Cap</option>
                                        <option value={'FRAUD'}>Fraud</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Notes:
                                </td>
                                <td>
                                    <textarea className="form-control" name="notes"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton spin={submitting} name="Schedule Pull" type="submit" />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default PullAffiliateModal
