const SurveyConditionDisplay = (props) => {
    return (
        <div key={props.outerIndex}>
            <div className="alert alert-secondary m-0 p-1 text-small">
                {props.outerCondition.map((innerCondition, innerIndex) => {
                    return (
                        <div key={innerIndex}>
                            <div className="alert alert-light m-0 p-1">
                                Q{innerCondition.order} = {innerCondition.operator !== '' && innerCondition.operator} {innerCondition.value !== '' ? innerCondition.value : '[Anything]'}
                            </div>
                            {innerIndex < props.outerCondition.length - 1 && <div className="text-center">AND</div>}
                        </div>
                    )
                })}
            </div>
            {props.outerIndex < props.conditions.length - 1 && <div className="text-center">OR</div>}
        </div>
    )
}

export default SurveyConditionDisplay
