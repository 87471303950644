import { useState } from 'react'
import { Card, Table, Button } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import { MailingsModal } from './MailingsModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export const AffiliateInitialMailings = ({ showSystemMessage }) => {

    document.title = 'Affiliate Initial Mailings'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'marketing')

    const { data, dataLoaded, refetch } = useCallApi('marketing/affiliates/mailers', 'GET', {}, [], showSystemMessage)
    const [deleteId, setDeleteId] = useState(null)
    const [selectedMailing, setSelectedMailing] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isCreate, setIsCreate] = useState(true)

    const calcSentFrom = (fromGroup) => {
        switch (fromGroup) {
            case 'S':
                return 'MaxBounty Support'
            case 'A':
                return 'MaxBounty Affiliates'
            case 'P':
                return 'MaxBounty PayChange'
            case 'M':
                return 'Affiliate Manager'
            case 'H':
                return 'Adam Harrison'
            case 'J':
                return 'JP Sauve'
            case 'T':
                return 'Steven Sauve'
            default:
                return 'MaxBounty Support'
        }
    }

    const deleteMailing = async (mailingId) => {
        const result = await callAPI(`marketing/affiliates/mailers/${mailingId}`, 'DELETE')
        if (result.success) {
            showSystemMessage('success', 'Mailing deleted successfully')
            refetch()
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Card>
            <MailingsModal deleteMailing={deleteMailing} refetch={refetch} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} isCreate={isCreate} setIsCreate={setIsCreate} mailing={selectedMailing} />
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="mb-h1-dark">Affiliate Initial Mailings</h1>
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            setIsCreate(true)
                            setSelectedMailing(null)
                            setIsOpen(true)
                        }}
                    >
                        Add Mailing
                    </Button>
                </div>
            </Card.Header>
            <Table striped bordered hover className="m-0">
                <thead>
                    <tr>
                        <th>Delay Days</th>
                        <th>Sent From</th>
                        <th>Subject Line</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        <>
                            {data.success && data.mailerList.length > 0 ? (
                                data.mailerList.map((mailing, index) => (
                                    <tr key={mailing.mailing_id}>
                                        <td>{mailing.delay_days}</td>
                                        <td>{calcSentFrom(mailing.from_group)}</td>
                                        <td>{mailing.subject}</td>
                                        <td>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                className="mr-2"
                                                onClick={() => {
                                                    setIsCreate(false)
                                                    setSelectedMailing(mailing)
                                                    setIsOpen(true)
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            {deleteId !== mailing.mailing_id ? (
                                                <Button variant="warning" size="sm" onClick={() => setDeleteId(mailing.mailing_id)}>
                                                    Delete
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        deleteMailing(mailing.mailing_id)
                                                        setDeleteId(null)
                                                    }}
                                                >
                                                    Confirm
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No mailings found</td>
                                </tr>
                            )}
                        </>
                    ) : (
                        <tr>
                            <td colSpan="4">
                                <Loading />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    )
}
