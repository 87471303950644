import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'
import Loading from '../../../templates/UI/Loading/Loading'
import AdvertiserNavbar from '../AdvertiserNavBar/AdvertiserNavBar'
import AdvertiserContactInfo from './AdvertiserContactInfo/AdvertiserContactInfo'
import AdvertiserGeneralInfo from './AdvertiserGeneralInfo/AdvertiserGeneralInfo'
import AdvertiserInvoiceInfo from './AdvertiserInvoiceInfo/AdvertiserInvoiceInfo'
import AdvertiserNotes from './AdvertiserNotes/AdvertiserNotes'
import AdvertiserApprovalInfo from './AdvertiserApprovalInfo/AdvertiserApprovalInfo'

const AdvertiserSettings = ({ advertiserId, showSystemMessage }) => {

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_settings')

    const [isDisabled, setIsDisabled] = useState(true)
    const [aliases, setAliases] = useState([])
    const [data, setData] = useState({})
    const [platformId, setPlatformId] = useState(0)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [managers, setManagers] = useState({})

    // ContactInfo states

    // const [selectedState, setSelectedState] = useState()
    // const [selectedCountry, setSelectedCountry] = useState()

    const [location, setLocation] = useState({})
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let generalPutBody = {
            name: e.target.name.value,
            status: e.target.status.value,
            ad_manager: managers.advManagerId,
            bizdev_manager: managers.bizdevManagerId,
            network_split_affsub: e.target.network_split_affsub.value,
            multiple_postback: e.target.multiple_postback.value,
            platform_id: platformId,
            aliases: aliases,
            contract_url: e.target.contract_url.value
        }

        let selectedCountry = e.target.country.value,
            selectedState = e.target.state.value

        let contactPutBody = {
            email: e.target.email.value,
            contact: e.target.contact.value,
            title: e.target.title.value,
            streetAddress1: e.target.address1.value,
            streetAddress2: e.target.address2.value,
            city: e.target.city.value,
            state: selectedCountry === 'US' || selectedCountry === 'CA' ? selectedState : e.target.non_state.value,
            nonstate: e.target.non_state.value,
            country: selectedCountry,
            zipcode: e.target.zipcode.value,
            mobilePhone: e.target.mobile.value,
            phone: e.target.phone.value,
            fax: e.target.fax.value,
            imType: e.target.imtype.value,
            imHandle: e.target.imhandle.value,
        }

        let invoicePutBody = {
            invoice_frequency: e.target.invoice_frequency.value,
            payment_terms: e.target.payment_terms.value,
            weekly_invoice_day_of_week: e.target.weekly_invoice_day_of_week.value,
            limited_credit_flag: e.target.limited_credit_flag.value,
            contract_owner: e.target.contract_owner.value,
            maximum_credit: e.target.balance.value,
            stats_flag: e.target.stats_flag.value,
            reversals_flag: e.target.reversals_flag.value,
            accounting_name: e.target.accounting_name.value,
            accounting_email: e.target.accounting_email.value,
            accounting_phone: e.target.accounting_phone.value,
            accounting_im_type: e.target.accounting_imtype.value,
            accounting_im_handle: e.target.accounting_imhandle.value,
            stats_details: e.target.stats_details.value,
            reversals_details: e.target.reversals_details.value,
            invoicing_notes: e.target.invoicing_notes.value,
        }

        let notesPutBody = {
            notes: e.target.notes.value,
        }

        let approvalPutBody = {
            approval_requirements: e.target.approval_requirements.value,
            approval_notes: e.target.approval_notes.value
        }

        const generalInfoUpdateResult = await callAPI(`advertiser/${advertiserId}/generalInfo`, 'PUT', generalPutBody)

        const contactInfoUpdateResult = await callAPI(`advertiser/${advertiserId}/contactInfo`, 'PUT', contactPutBody)

        const invoiceInfoUpdateResult = await callAPI(`advertiser/${advertiserId}/invoiceInfo`, 'PUT', invoicePutBody)

        const approvalInfoUpdateResult = await callAPI(`advertiser/${advertiserId}/approvalInfo`, 'PUT', approvalPutBody)

        const notesUpdateResult = await callAPI(`advertiser/${advertiserId}/notes`, 'PUT', notesPutBody)

        setSubmitting(false)

        if (!generalInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating general information')
            return
        }

        if (!contactInfoUpdateResult['success']) {
            showSystemMessage('error', 'Error while updating contact information')
            return
        }

        if (invoiceInfoUpdateResult.success === false) {
            showSystemMessage('error', 'Error while updating invoice information')
            return
        }

        if (notesUpdateResult.success === false) {
            showSystemMessage('error', 'Error while updating notes')
            return
        }

        if (approvalInfoUpdateResult.success === false) {
            showSystemMessage('error', 'Error while updating approval info')
            return
        }

        showSystemMessage('success', 'Advertiser settings saved')
        fetchData()
    }

    if (dataLoaded && data.general_info) {
        document.title = `${data.general_info.name}: Settings`
    } else {
        document.title = 'Advertiser Settings'
    }

    useEffect(() => {
        fetchData()
    }, [advertiserId])

    const fetchData = () => {
        setDataLoaded(false)

        callAPI(`advertiser/${advertiserId}/details`, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setData(result)
                    setAliases(result.aliases)
                    setManagers({ advManagerId: result.general_info.merchant_mgr_id, bizdevManagerId: result.general_info.bizdev_mgr_id })
                    setPlatformId(result.general_info.merchant_platform_id)
                    setLocation({ state: result.contact_info.state, country: result.contact_info.country_code })
                    setDataLoaded(true)
                    setIsDisabled(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching advertiser details: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching advertiser details')
            })
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">{dataLoaded && <Link to={`/advertiser/${advertiserId}`}>{data.general_info.name}</Link>}</h1>
                <div className="ml-5">
                    <AdvertiserNavbar advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            {dataLoaded ? (
                <React.Fragment>
                    <form onSubmit={handleSubmit}>
                        <div className="card mb-2 text-right">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <p className="mb-h1-dark">Advertiser Settings</p>
                                <CustomButton name="Save" variant="success" type="submit" disabled={isDisabled} spin={submitting} />
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <AdvertiserGeneralInfo generalInfo={data.general_info} aliases={data.aliases} setIsDisabled={setIsDisabled} setAliases={setAliases} managers={managers} setManagers={setManagers} setPlatformId={setPlatformId} platformId={platformId} advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                                    <br></br>
                                    <AdvertiserContactInfo advertiserId={advertiserId} contactInfo={data.contact_info} additionalContacts={data.additional_contacts} setIsDisabled={setIsDisabled} location={location} setLocation={setLocation} refetch={fetchData} />
                                    <br></br>
                                    <AdvertiserApprovalInfo advertiserId={advertiserId} approvalInfo={data.approval_info} setIsDisabled={setIsDisabled} />
                                </div>
                                <div className="col-lg-6">
                                    <AdvertiserInvoiceInfo invoiceInfo={data.invoice_info} setIsDisabled={setIsDisabled} />
                                    <br></br>
                                    <AdvertiserNotes notes={data.notes} setIsDisabled={setIsDisabled} />
                                    <div className="card my-2">
                                        <div className="card-header text-right">
                                            <CustomButton name="Save" variant="success" type="submit" disabled={isDisabled} spin={submitting} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    )
}

export default AdvertiserSettings
