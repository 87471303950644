import React from 'react'
import { useEffect, useState } from 'react'
import { Card, Table, Form } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import callAPI from '../../../helpers/callAPI'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const MerchantApprovalsForAffiliate = ({ affiliateId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_approvals')

    const { dataLoaded, data, refetch } = useCallApi(`affiliate/${affiliateId}/advertiserApprovals`, 'GET', {}, [affiliateId])    

    const [affiliate, setAffiliate] = useState({})
    const [report, setReport] = useState([])

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        if (data.success) {
            setAffiliate(data.affiliate)
            document.title = `${data.affiliate.name}: Advertiser Approvals`
            setReport(data.advertisers)
        }
    }, [dataLoaded])

    const updateApproval = async (merId, merStatus) => {
        const body = {
            merchantId: merId,
            status: merStatus,
        }

        const result = await callAPI(`affiliate/${affiliateId}/advertiserApprovals`, 'PUT', body)

        if (result.success) {
            // Because this is a live change, we need to modify the data so it doesn't rerender the old data.
            report.find((record) => record.id === merId).status = merStatus
            showSystemMessage('success', 'Approval status updated.')
        } else {
            showSystemMessage('error', result.message)
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: 'Advertiser Status',
                accessor: 'advertiser_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser Manager',
                accessor: 'manager.name',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].manager.name}</>
                },
            },
            {
                Header: 'Approval Status',
                accessor: 'status',
                Cell: (tableData) => {
                    return (
                        <>
                            {' '}
                            <Form.Control as="select" defaultValue={tableData.data[tableData.row.index].status == 'A' ? 'A' : 'R'} onChange={(e) => updateApproval(tableData.data[tableData.row.index].id, e.target.value)}>
                                <option value="A">Approved</option>
                                <option value="R">Rejected</option>
                            </Form.Control>
                        </>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Approvals</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <Card>
                <Card.Body className="p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority ? affiliate.priority.value : 0} status={affiliate.status ? affiliate.status.value : 'A'} /> : spinner}</h1>
                            </div>
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                </Card.Body>
            </Card>
        </div>
    )
}

export default MerchantApprovalsForAffiliate
