// import React, { Component } from 'react'
// import { CSSTransition } from 'react-transition-group'
// import { X } from 'react-feather'

// import './SystemMessage.css'

// class SystemMessage extends Component {
//     state = {
//         in: false,
//     }

//     componentDidMount() {
//         this.setState({
//             in: true,
//         })

//         setTimeout(() => this.props.hideSystemMessage(), 5000)
//     }

//     componentWillUnmount() {
//         this.setState({
//             in: false,
//         })
//     }

//     render() {
//         let classes = 'system-message d-flex align-items-center'

//         if (this.props.type === 'success') {
//             classes = classes + ' success'
//         } else if (this.props.type === 'error') {
//             classes = classes + ' error'
//         }

//         return (
//             <CSSTransition in={this.state.in} timeout={200} classNames="systemMessageIn">
//                 <div className="system-message-container">
//                     <div className="system-message-container-holder">
//                         <div className={classes} onClick={this.props.hideSystemMessage}>
//                             <div>{this.props.children}</div>
//                             <div>
//                                 <span>
//                                     <X size="16" style={{ marginTop: '-2px', marginLeft: '5px' }} />
//                                 </span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </CSSTransition>
//         )
//     }
// }

// export default SystemMessage

import { useContext } from "react";
import { X } from "react-feather";
import { CSSTransition } from "react-transition-group";
import { SystemMessageContext } from "../../context/SystemMessageContext";

import { Button } from "react-bootstrap";
import './SystemMessage.css';


const SystemMessage = () => {
    const { systemMessage, setSystemMessage, hideSystemMessage } = useContext(SystemMessageContext);


    if (systemMessage.length > 0) {
        return (
            <CSSTransition in={systemMessage.length > 0} classNames="systemMessageIn">
                <div className="system-message-container">
                    <div className="system-message-container-holder">
                        <div className={`system-message d-flex align-items-center ${systemMessage[0]?.type}`}>
                            <div>{systemMessage[0]?.message}</div>
                            <div>
                                <span>
                                    <Button onClick={hideSystemMessage} variant="outline" style={{ marginTop: '-2px', marginLeft: '5px' }} >
                                        <X size="16"/>
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    } else {
        return (
            null
        )
    }
}

export default SystemMessage 
