import React, { useState, useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import Number from '../../../templates/Number/Number'
import { useHistory } from 'react-router-dom'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const AffiliatesByCountry = ({ showSystemMessage, defaultCountry }) => {
    document.title = 'Top Affiliates by Country'

    const { dataLoaded: countryDataLoaded, data: countryData } = useCallApi(`countryList`, 'GET', {}, [])
    const [country, setCountry] = useState(defaultCountry)
    const { data, dataLoaded } = useCallApi(`affiliates/topByCountry?country=${country}`, 'GET', {}, [country], showSystemMessage)
    const history = useHistory()

    useEffect(() => {
        if (country) {
            history.push(`/affiliates/topByCountry?country=${country}`)
        }
    }, [country])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].aff_status_value} priority={tableData.data[tableData.row.index].aff_priority_value} />
                },
            },
            {
                Header: 'Status',
                accessor: 'aff_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div className="text-right">Lead Count</div>,
                className: 'text-right',
                accessor: 'count',
                Cell: ({ cell: { value } }) => {
                    return <>{<Number value={value} />}</>
                },
            },
            {
                Header: () => <div className="text-right">30d Earnings</div>,
                className: 'text-right',
                accessor: 'thirty_day_earnings',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Affiliate Manager',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.byCountry} columns={columns} totals={data.totals} />
            </React.Fragment>
        )
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-start align-items-center">
                Top Affiliates by Lead Count for Country (Last 30 days):
                <div className="ml-2">
                    <Form.Control as="select" onChange={(e) => setCountry(e.target.value)} value={country}>
                        <option value="">Select a country</option>
                        {countryDataLoaded
                            ? countryData.countries.map((country, index) => (
                                  <option key={index} value={country.country_code}>
                                      {country.country_name}
                                  </option>
                              ))
                            : null}
                    </Form.Control>
                </div>
            </Card.Header>
            {dataLoaded ? data.byCountry.length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default AffiliatesByCountry
