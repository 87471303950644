import { Modal } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const CreateFlag = ({ isOpen, handleClose, showSystemMessage, refetch }) => {
    const createFlag = (e) => {
        e.preventDefault()

        const postBody = {
            flag_name: e.target.flag_name.value,
            flag_target: e.target.flag_target.value,
            //system_flag: e.target.system_flag.value,
        }

        callAPI(`admin/flags`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    refetch()
                    handleClose()
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error while creating the flag. Please try again')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in FlagSettings: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Create New Flag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={createFlag}>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td>Flag Name:</td>
                                <td>
                                    <input type="text" name="flag_name" className="form-control" required />
                                </td>
                            </tr>
                            {/* <tr>
                                <td>System Flag:</td>
                                <td>
                                    <select name="system_flag" className="form-control w-25">
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                </td>
                            </tr> */}
                            <tr>
                                <td>Flag Target:</td>
                                <td>
                                    <select name="flag_target" className="form-control w-25">
                                        <option value="unknown">Unknown</option>
                                        <option value="affiliate">Affiliate</option>
                                        <option value="merchant">Advertiser</option>
                                        <option value="multiple">Multiple</option>
                                        <option value="offer">Campaign</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <CustomButton name="Create" type="submit" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateFlag
