import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import DateRangeFilter from '../../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import AffiliateNavbar from '../../Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'

const CampaignPitchReport = ({ showSystemMessage }) => {
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { affiliateId, campaignId, adminId, setAdminId, navigation, buildUrl } = useQueryParams()

    let apiUrl = buildUrl(`affiliate/${affiliateId}/pitches?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [affiliateId, campaignId], showSystemMessage)

    const history = useHistory()
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        if (data.affiliate) {
            setAffiliate(data.affiliate)
        }
        if (data.campaign) {
            setCampaign(data.campaign)
        }
    }, [data])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'offer_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Campaign name={tableData.data[tableData.row.index].name} oid={tableData.data[tableData.row.index].offer_id} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Date',
                accessor: 'db_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
        ]

        return <ReportTable data={data.pitch_list} columns={columns} />
    }

    const refreshReport = () => {
        refetch()
        let url = buildUrl(`/reports/custom/campaignPitchReport?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Pitches</h1>
                <div className="ml-5">
                    <AffiliateNavbar campaignId={campaignId} affiliateId={affiliateId} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="d-flex p-3 align-items-center">
                            <h1 className="mb-h1-dark mr-2">{dataLoaded ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : spinner}</h1>
                            <div className="d-flex align-items-center">
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                <Button size="sm" onClick={refreshReport}>
                                    Refresh Report
                                </Button>
                            </div>
                        </div>
                        {dataLoaded ? data.pitch_list.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignPitchReport
